import { InstallationListViewProps } from "../types/InstallationListTypes";
import { Link } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import BreadCrumb from "../../../BreadCrumb";
import moment from 'moment';
import Style from '../../Installations.module.scss';
import { t } from "i18next";

const InstallationListView = (props: InstallationListViewProps) => {
    let dataLoaded = props.myInstallations != null;
    let img = "";
    let installations = props.myInstallations ?? []

    if (dataLoaded) {
        props.myInstallations.forEach((i) => { i.parentName = i.parentName ?? " " })
        installations = props.myInstallations.sort((a, b) => (a.parentName.toLowerCase() > b.parentName.toLowerCase()) ? 1 : ((b.parentName.toLowerCase() > a.parentName.toLowerCase()) ? -1 : 0));
    }

    if (props.isLaufen) {
        img = "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg";
    } else {
        img = "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/14_ROCA_Default_Installation.jpeg";
    }
    return (
        <div className={Style.Installations}>
            <div className="row" style={{ margin: '10px 0' }}>
            <BreadCrumb empty={ true }></BreadCrumb>
            <div className="container">
                <div className="col">

                <div>
                    {dataLoaded ? (
                        <div className="row">
                            {installations.map((item, i) => (
                                <div className="col-4 col-xl-3" key={ i}>
                                    <div className="prodChildNoZoom siteCard">
                                        {item.avatarUri == null ? (
                                            <div className="siteCardImg"
                                                style={{ backgroundImage: 'url("'+ img +'")' }}>
                                            </div>
                                        ) : (
                                            <div className="siteCardImg"
                                                style={{ backgroundImage: 'url("'+item.avatarUri+'")' }}>
                                            </div>)
                                        }
                                        <div style={{ marginLeft: '10px', marginTop: '10px', minHeight: '63px' }}>
                                            <h4 style={{ minHeight: '36px' }}> <b> { item.parentName } </b> - {item.name} </h4>
                                            {getAggregatedInfo(item)}
                                        </div>
                                        
                                        {(!item.hasOwnerAccess && (item.hasAccessExpirationAt != null && new Date(item.hasAccessExpirationAt) < new Date())) ?
                                            <div className="linkItem noUnderLine">
                                                <div className="siteCardFooter col noAccess">
                                                    <h4 style={{ float: 'left', color: 'white', marginTop: '11px' }}> {t('errors.expiredAt')} {moment(item.hasAccessExpirationAt).format("DD/MM/YYYY")}  </h4>
                                                </div>
                                            </div>
                                            :
                                            <Link className="linkItem noUnderLine" to={`/installation/${item.id}`}>
                                                <div className="siteCardFooter col">
                                                    <h4 style={{ float: 'left', color: 'white', marginTop: '11px' }}> {t('detail')} </h4> <span className="arrow"> {'--->'} </span>
                                                </div>
                                            </Link>
                                        }

                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                            <div className="loadingParent"><div className="loadingImg"></div></div>
                        )
                    }
                </div>
                </div>
            </div>
        </div>
        </div>
        
    );
};


const renderTooltip = (text) => (props) => (
    <Tooltip id="button-tooltip" {...props}>
        {text}
    </Tooltip>
);

const getAggregatedInfo = (item) => {

    let aggregatedInfo = item.aggregatedInfo ? item.aggregatedInfo : { };

    return (
        <div className="row col-12" style={{ paddingBottom: '5px', paddingRight: 0 }}>
            <div className="col-4" style={{ padding: 0 }}>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Devices")}>
                    <div>
                        <img src={require('../../../../assets/icons/11_00.03_black.svg').default} style={{ height: '15px', float: 'left', marginTop: '2px', marginRight: '5px' }} />
                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalDevices} </div>
                    </div>
                </OverlayTrigger>
            </div>
            <div className="col-4" style={{ padding: 0 }}>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Users")}>
                    <div>
                        <PersonOutlineIcon style={{ height: '15px', float: 'left', marginTop: '2px', marginRight: '5px' }} />
                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalUsers} </div>
                    </div>
                </OverlayTrigger>
            </div>
            <div className="col-4" style={{ padding: 0 }}>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("Gateways")}>
                    <div>
                        <img src={require('../../../../assets/icons/11_00.31_black.png').default} style={{ height: '15px', float: 'left', marginTop: '2px', marginRight: '5px' }} />
                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.gatewayDevices} </div>
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default InstallationListView;