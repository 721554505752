import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Table from '../../CustomComponents/ReactTable/Table';
import '../../../App.css';
import '../../ProductsStyle.css';

const GatewayTable = ({ productKPI, t }) => {
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const tableDataInfo = [];
    if (productKPI) {
      productKPI.forEach((product) => {
        const newProduct = {};
        newProduct.name = renderName(product);
        if (product.kpis && product.kpis.length) {
          product.kpis.forEach((kpi) => {
            if (kpi.value && kpi.value.length) {
              const value = kpi.value[0].value !== undefined ? kpi.value[0].value : 0;
              newProduct[kpi.kpiName] = cleanValue(value);
            }
          });
        }
        tableDataInfo.push(newProduct);
      });
      setTableData(tableDataInfo);
    }
  }, [productKPI]);

  const renderName = (product) => {
    return (
      <div
        onClick={() => this.routeChange(rowData)}
        className={'p-0 pl-4 m-0 cursor-pointer treeH4Parent' + ' eventSelector prdEventId-' + product.id}
        style={{ verticalAlign: 'middle', textTransform: 'none', position: 'inherit' }}
      >
        <div className='treeImage' style={{ backgroundImage: "url('" + product.image + "')" }}>
          <div className={'dotState ' + status.toLowerCase()}></div>
        </div>
        <div className='row ml-1'>
          <h4 className='col-12 p-0 m-0 productType'>{product.typeName}</h4>
          <div className='m-0 TreeDetailProduct'>{product.puuid}</div>
        </div>
      </div>
    );
  };

  const cleanValue = (value) => {
    if (/^-?\d+(\.\d+)?$/.test(value)) {
      const numberValue = Number(value);
      return numberValue % 1 === 0 ? numberValue : numberValue.toFixed(1);
    } else {
      return value;
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: t('gatewayspages.statistics.tableGatewayToDevice.name'),
      align: 'left',
      sort: true,
      headerStyle: () => {
        return { width: '21%' };
      },
    },
    {
      dataField: 'Pollings',
      text: t('gatewayspages.statistics.tableGatewayToDevice.pollings'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'PollingsOffline',
      text: t('gatewayspages.statistics.tableGatewayToDevice.PollingsOffline'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'PollingsOnline',
      text: t('gatewayspages.statistics.tableGatewayToDevice.PollingsOnline'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'AverageRSSI',
      text: t('gatewayspages.statistics.tableGatewayToDevice.averageRSSI'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'OfflineRatio',
      text: t('gatewayspages.statistics.tableGatewayToDevice.OfflineRatio'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'AverageRSSIOffline',
      text: t('gatewayspages.statistics.tableGatewayToDevice.averageRSSIOffline'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
    {
      dataField: 'AverageRSSIOnline',
      text: t('gatewayspages.statistics.tableGatewayToDevice.averageRSSIOnline'),
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '11%' };
      },
    },
  ];

  return (
    <>
      {tableData ? (
        <div className='deviceToGatewayTable'>
          <Table name={'DeviceToGatewayTable'} t={t} resize={true} columns={columns} rows={tableData} page={0} pageSize={0} />
        </div>
      ) : (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(GatewayTable);
