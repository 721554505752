import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../components/ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AuthenticationContext from '../Authentication/types/AuthContextType';
import BreadCrumbHttpClient from '../HttpClient/BreadCrumbHttpClient';

class BreadCrumb extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.dataBreadCrumb = [];
    this.urlBreadCrumb = [];
    this.state = {
      thereAreErrors: false,
      activeClass: '',
      isEmpty: false,
      language: this.props.i18n.language,
    };
    this.id = window.location.pathname.split('/')[2];
    this.level = this.props.level;
  }

  componentDidMount() {
    if (!this.props.empty) this.getBreadCrumb();
    window.addEventListener('scroll', () => {
      let activeClass = 'notop';
      if (window.scrollY <= 130) {
        activeClass = 'top';
      }
      if (this.state.activeClass != activeClass) {
        this.setState({ activeClass });

        let elem = document.getElementById('rootElement');
        if (elem != null) {
          elem.className = activeClass;
        }
      }
    });
  }

  componentDidUpdate() {
    if (this.state.language !== this.props.i18n.language) {
      this.setState({ language: this.props.i18n.language });
    }
    if (!this.props.empty) {
      var id = parseInt(window.location.pathname.split('/')[2]);
      if (id !== this.id) {
        this.id = id;
        this.getBreadCrumb();
      }
    }
  }

  makeBreadCrumb(dataBreadCrumb) {
    try {
      if (!dataBreadCrumb) {
        this.setState({ isEmpty: true });
        return;
      }
      let url = '';
      switch (dataBreadCrumb.type) {
        case 'products':
          url = '/product';
          break;
        case 'gateways':
          url = '/gateway';
          break;
        case 'installations':
          url = '/installation';
          break;
        default:
          url = '/site';
          break;
      }
      let obj = {
        url: url + '/' + dataBreadCrumb.id,
        description: dataBreadCrumb.name,
        type: dataBreadCrumb.type,
        hasAccess: dataBreadCrumb.hasAccess,
      };

      // if (dataBreadCrumb.child != null) {
      if (dataBreadCrumb.type == this.props.level) {
        if (dataBreadCrumb.type == 'products') {
          let tag = '';
          let letter = '';
          if (dataBreadCrumb.letter != null) letter = dataBreadCrumb.letter;
          if (dataBreadCrumb.tag != null) tag = dataBreadCrumb.tag;
          obj['description'] = dataBreadCrumb.name + ' - ' + letter + tag;
        }
        this.urlBreadCrumb.push(obj);
        if (dataBreadCrumb.child != null && dataBreadCrumb.child.type == 'installations') {
          //breadCrumb for installations to draw all route. suppose it only returns one installation.
          this.makeBreadCrumb(dataBreadCrumb.child);
        }
      } else {
        this.urlBreadCrumb.push(obj);
        this.makeBreadCrumb(dataBreadCrumb.child);
      }
      /*} else if (this.urlBreadCrumb.length <= 0) {
                obj['description'] = '';
                this.urlBreadCrumb.push(obj);
            }*/
    } catch (e) {
      this.setState({ thereAreErrors: true });
    }
  }

  printBreadCrumb(item, i, arr) {
    let fspan = '';
    let link = '';
    let lclass = '';
    let state = '';
    if (arr.length - 1 == i) {
      lclass = 'showOnScroll '; //+ (this.state.activeClass != undefined ? this.state.activeClass : "" );
      if (this.props.product != undefined) {
        let pState =
          this.props.product.content.connectionState != undefined ? this.props.product.content.connectionState.toLowerCase() : '';
        state = <div className={'breadCrumbProductState ' + pState}> </div>;
      }
    }

    if (i == 0 && item.description != '') {
      fspan = <span style={{ marginRight: '5px' }}>/</span>;
    }

    if (!item.hasAccess) {
      link = (
        <Link style={{ pointerEvents: 'none' }} color='inherit'>
          {' '}
          {item.description}{' '}
        </Link>
      );
    } else {
      link = (
        <Link color='inherit' to={item.url}>
          {' '}
          {item.description}{' '}
        </Link>
      );
    }
    return (
      <span className={lclass}>
        {' '}
        {fspan} {link} {state}{' '}
      </span>
    );
  }

  render() {
    let dataLoaded = this.urlBreadCrumb.length > 0 || this.props.empty || this.state.isEmpty;

    let thereAreErrors = this.state.thereAreErrors;
    const { t } = this.props;

    let breadClass = '';
    if (this.props.pageNoAccess) breadClass = ' shadowPaeNoAccess';
    if (this.urlBreadCrumb.length <= 0) breadClass += ' bhidden';
    if (this.props.doubleSticky) breadClass += ' hideShadow';
    const isEmpty = () => {
      return (
        <div>
          {' '}
          <span className=''>
            {' '}
            <span style={{ marginRight: '5px' }}>/</span>{' '}
          </span>{' '}
        </div>
      );
    };
    return (
      <React.Fragment>
        {dataLoaded ? (
          thereAreErrors ? (
            <div id='breadcrumbId' className={'error breadCrumb showWhenScroll' + this.state.activeClass}>
              {t('errors.breadCrumb')}
            </div>
          ) : (
            <div
              id='breadcrumbId'
              className={'breadCrumb showWhenScroll ' + this.state.activeClass + breadClass}
              style={{ marginTop: 0 }}
            >
              {!this.props.empty ? (
                <div className='container mt-0 prodInfo d-flex' style={{ minHeight: '25px', overflow: 'hidden' }}>
                  <div className='col d-flex' style={{ height: '20px', alignItems: 'center' }}>
                    <Breadcrumbs maxItems={3} aria-label='breadcrumb' className='breadCrum'>
                      {this.urlBreadCrumb.map((item, i, arr) => (
                        <div key={i}>{this.printBreadCrumb(item, i, arr)}</div>
                      ))}
                    </Breadcrumbs>
                  </div>
                </div>
              ) : (
                isEmpty()
              )}
            </div>
          )
        ) : thereAreErrors ? (
          <div className='error'>{t('errors.breadCrumb')}</div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
  async getBreadCrumb() {
    try {
      this.httpBreadCrumb = new BreadCrumbHttpClient(this.context, this.state.language, this.level, this.id);
      this.dataBreadCrumb = await this.httpBreadCrumb.getBreadCrumb();

      // Test with endpoint in node
      const breadCrumbFromNode = await this.httpBreadCrumb.getBreadCrumbFromNode();
      console.log(breadCrumbFromNode);
      //** */
      
      this.urlBreadCrumb = [];

      this.makeBreadCrumb(this.dataBreadCrumb.content);

      this.setState({ dataLoaded: true });
    } catch (e) {
      this.setState({ thereAreErrors: true });
    }
  }
}

export default withTranslation()(BreadCrumb);
