import { InstallationListContainerProps, InstallationListContainerState } from "../types/InstallationListTypes";
import InstallationListView from "../view/InstallationListView";
import AuthenticationContext, { AuthContextType } from "../../../../Authentication/types/AuthContextType";
import { PureComponent } from "react";
import InstallationHttpClient from "../../../../HttpClient/InstallationsHttpClient";

class InstallationListContainer extends PureComponent<InstallationListContainerProps, InstallationListContainerState> {
    static contextType = AuthenticationContext;

    constructor(props: InstallationListContainerProps) {
      super(props);
      this.state = {
        myInstallations: null,
      };
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        this.getMyInstallations();
    }

    async getMyInstallations() {
        let httpInstallation = new InstallationHttpClient(this.context);
        this.setState({ myInstallations: null });
        var datos = await httpInstallation.InstallationGetInstallations();

        this.setState({ myInstallations: datos.content });
    }
  
    render() {
      return (
        <>
          <InstallationListView
            myInstallations = {this.state.myInstallations}
            isLaufen = {(this.context as AuthContextType).brandCode == "11"}
          ></InstallationListView>
        </>
      );
    }
  }
  
  export default InstallationListContainer;
  