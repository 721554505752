import React from "react";
import { InstallationsAlertReportingViewProps } from "../types/InstallationsAlertReportingTypes";
import Accordions from '@mui/material/Accordion';
import AccordionsSummary from '@mui/material/AccordionSummary';
import AccordionDetail from '@mui/material/AccordionDetails';
import ActiveNotificationsList from '../../../../ProductsComponents/Tabs/Notifications/ActiveNotificationsList';
import ActiveNotification from "../../../../ProductsComponents/Tabs/Notifications/ActiveNotificationsCards.js";
import { t } from 'i18next';

import HistoricNotificationList from "../../../../ProductsComponents/Tabs/Notifications/HistoricNotificationList.js";
const InstallationsAlertReportingView = (props: InstallationsAlertReportingViewProps) => {
    return (
        <React.Fragment>
                <div>
                    <ActiveNotification isSite={true}/>
                </div>
                <div className="installationTable" style={{ marginTop: '30px', width: '100%' }}>
                    <ActiveNotificationsList installation={props.installation} isSite={true} refreshNotifications={props.refreshNotifications.bind(this)} />
                </div>
                <div className="notificationTable" style={{ marginTop: '30px', width: '100%' }}>
                    <Accordions classes={{ root: 'accordion-root-custom' }}>
                        <AccordionsSummary
                            expandIcon={<img src={require('../../../../../assets/icons/chevron-down.svg').default} style={{ width: '24px' }} />}
                            aria-controls="panel1a-content" >
                            <h3>{t('productspages.alertreporting.notificationHistory')}</h3>
                        </AccordionsSummary>
                        <AccordionDetail>
                            <div className="w-100">
                                {props.loadHistoricNotifications ? <HistoricNotificationList isSite={true} /> : (null)}
                            </div>
                        </AccordionDetail>
                    </Accordions>
                </div>
            </React.Fragment>
    );
  };
  
  export default InstallationsAlertReportingView;