import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import GatewayFirmwareUpdate from "../GatewayFirmwareUpdate";

import CustomStatus from '../../ParametersComponents/CustomStatus';
import CustomTextField from '../../ParametersComponents/CustomTextField';
import CustomSelect from '../../ParametersComponents/CustomSelect';
import CustomCheckbox from '../../ParametersComponents/CustomCheckbox';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import GatewaysHttpClient from "../../../HttpClient/GatewaysHttpClient";
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import WarningAmberIcon from '@mui/icons-material/WarningOutlined';

import NoData from "../../NoData";

import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";

class GatewayGeneral extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSendParameters: false,
            loading: false,
            disabledSendParameters: true,
            disabledCancelParameters: true,
            refreshing: false,
            showPassword: false,
            listParametersToSend: []
        }
        this.valueChanged = this.valueChanged.bind(this);
        this.sendParameters = this.sendParameters.bind(this);

        if (this.props.gateway.reportedTwin) {
            this.initValues = {
                Autoupgrade: this.props.gateway.reportedTwin.version !== undefined ? this.props.gateway.reportedTwin.version.autoUpgrade : "",
                Disabletelemetry: this.props.gateway.reportedTwin.comm !== undefined ? this.props.gateway.reportedTwin.comm.disableTelemetry : "",
                CloudSyncInterval: this.props.gateway.reportedTwin.comm !== undefined ? this.props.gateway.reportedTwin.comm.cloudSyncInterval : ""
            }

            this.values = {
                Autoupgrade: this.props.gateway.reportedTwin.version !== undefined ? this.props.gateway.reportedTwin.version.autoUpgrade : "",
                Disabletelemetry: this.props.gateway.reportedTwin.comm !== undefined ? this.props.gateway.reportedTwin.comm.disableTelemetry : "",
                CloudSyncInterval: this.props.gateway.reportedTwin.comm !== undefined ? this.props.gateway.reportedTwin.comm.cloudSyncInterval : ""
            }
        }
        else {
            this.initValues = {
                Autoupgrade: "",
                Disabletelemetry: "",
                CloudSyncInterval: "",
            }
            this.values = {
                Autoupgrade: "",
                Disabletelemetry: "",
                CloudSyncInterval: "",
            }
        }
    }

    componentDidMount() {
        this.http = new GatewaysHttpClient(this.context);
    }

    valueChanged(parameter, value) {

        this.values[parameter.businessLabel] = value;
        if (this.state.disabledCancelParameters || this.state.disabledSendParameters) {
            if (this.initValues[parameter.businessLabel] != value)
                this.setState({ disabledCancelParameters: false, disabledSendParameters: false })
        }
        else if (this.initValues.Autoupgrade == this.values.Autoupgrade && this.initValues.Disabletelemetry == this.values.Disabletelemetry && this.initValues.CloudSyncInterval == this.values.CloudSyncInterval) {
            this.setState({ disabledCancelParameters: true, disabledSendParameters: true })
        }
    }

    async sendParameters() {
        
        const { t } = this.props;

        this.setState({ loading: true, showConfirmCommand: false });

        let twin = [{
            gatewayId: this.props.gateway.id,
            Autoupgrade: this.values.Autoupgrade == 0 ? false : true,
            Disabletelemetry: this.values.Disabletelemetry == 0 ? false : true,
            cloudSyncInterval: this.values.CloudSyncInterval
        }]

        let data = await this.http.PutFirmwareVersion(twin);
        if (data.errors != null) {
            this.setState({ _errors: data.errors, loading: false });
        }
        else{
            this.props.renderMessage('success', t('messages.successCommandSend'));
            this.setState({ loading: false, screen: 'updating', showSendParameters: false, disabledSendParameters: true, disabledCancelParameters: true });
        } 
        this.initValues = {
            Autoupgrade: this.values.Autoupgrade == 0 ? false : true,
            Disabletelemetry: this.values.Disabletelemetry == 0 ? false : true,
            CloudSyncInterval: this.values.CloudSyncInterval
        }
    }

    render() {

        let handleClickShowPassword = (event) => {
            this.setState({ showPassword: !this.state.showPassword })
        }

        let handleMouseDownPassword = (event) => {
            event.preventDefault();
        }


        const { t } = this.props;

        let devicedisconnected = this.props.gateway.productStatus?.deviceStatus == "Offline"

        let openDialogSendParameters = () => {

            let names = {
                CloudSyncInterval: "Polling time",
                Autoupgrade: "Automatic updating",
                Disabletelemetry: "Disable telemetry"
            }

            let listParametersToSend = [];
            for (let key in this.values) {
                let value = this.values[key];
                if (value != this.initValues[key]) {
                    if (key == "CloudSyncInterval") {
                        value += " minutes";
                    }
                    else if (value == 0) {
                        value = "unchecked";
                    } else if (value == 1) {
                        value = "checked";
                    }
                    listParametersToSend.push(<ListItem><ListItemText primary={names[key] + ": " + value} /></ListItem>)
                }
            }
            this.setState({ listParametersToSend: listParametersToSend, showSendParameters: true })
        }

        let cancelParameters = () => {

            this.setState({ refreshing: true });
            setTimeout(() => {
                this.values = JSON.parse(JSON.stringify(this.initValues))
                this.setState({ refreshing: false, disabledSendParameters: true, disabledCancelParameters: true });
            }, 10)

        }

        let handleClose = () => {
            this.setState({ show: false, showSendParameters: false })
        }

        let handleCloseModal = (data = null) => {
            const { t } = this.props;
            this.handleClose();
            if (data != null) {
              this.props.renderMessage('success', t('messages.successCommandSend'));
            }
          };

        let openDialogFirmware = () => {
            this.setState({ show: true })
        }

        let shouldUpdate = false;
        if (this.props.firmwares && this.props.firmwares.activeVersion != this.props.firmwares.newestVersion) shouldUpdate = true;

        let cloudSyncParamObj = {
            table: "gateways",
            smartComponentTypeId: 0,
            name: "Polling Time",
            businessLabel: "CloudSyncInterval",
            permissionType: "",
            online: !devicedisconnected,
            values: [
                {value: 5, label: "5 minutes"},
                {value: 15, label: "15 minutes"},
                {value: 30, label: "30 minutes"}
            ]
        }

        if (this.props.gateway) {

            let reportedTwin = this.props.gateway.reportedTwin;
            let reportedNetwork = this.props.gateway.gateway;

            let reportedTwinRegistered = null;
            let reportedWifiEnable = null;
            let reportedWifiPreference = null;
            let reportedWifiAddress = null;
            let reportedWiredEnable = null;
            let reportedWiredPreference = null;
            let reportedWiredAddress = null;
            let reportedLteEnable = null;
            let reportedLtePreference = null;
            let reportedLteAddress = null;

            if (reportedNetwork) {
                let network = reportedNetwork.network;

                reportedWifiEnable = 0;
                reportedWiredEnable = 0;
                reportedLteEnable = 0;

                if(network != undefined){
                    if (network.wiFi != undefined) {
                        reportedWifiEnable = network.wiFi.enabled;
                        reportedWifiPreference = network.wiFi.preference;
                        if (network.wiFi.active && network.wiFi.iface.ipv4) {
                            reportedWifiAddress = network.wiFi.iface.ipv4.address;
                        }
                    }

                    if (network.wired != undefined) {
                        reportedWiredEnable = network.wired.enabled;
                        reportedWiredPreference = network.wired.preference;

                        if (network.wired.active && network.wired.iface.ipv4) {
                            reportedWiredAddress = network.wired.iface.ipv4.address;
                        }
                    }

                    if (network.lte != undefined) {
                        reportedLteEnable = network.lte.enabled;
                        reportedLtePreference = network.lte.preference;

                        if (network.lte.active && network.lte.iface.ipv4) {
                            reportedLteAddress = network.lte.iface.ipv4.address;
                        }
                    }
                }

            }
            else if (reportedTwin) {
                reportedTwinRegistered = reportedTwin.registered;

                reportedWifiEnable = 0;
                reportedWifiPreference = null;
                reportedWiredEnable = 0;
                reportedWiredPreference = null;

                if(reportedTwinRegistered != undefined)
                {
                    if (reportedTwin.network != undefined) {
                        reportedWifiEnable = reportedTwin.network.wifi.enabled;
                        if (reportedTwin.network.wifi != undefined) {
                            reportedWifiPreference = reportedTwin.network.wifi.preference
                        }
                    }

                    
                    if (reportedTwin.network != undefined) {
                        reportedWiredEnable = reportedTwin.network.wired.enabled;
                        if (reportedTwin.network.wired != undefined) {
                            reportedWiredPreference = reportedTwin.network.wired.preference
                        }
                    }
                }
            }

            // toSend={this.toSend} onChangeCheckbox={this.onChangeParent}
            return (
                <React.Fragment>
                    <Dialog
                        classes={{ paper: "GWModal" }}
                        disableEscapeKeyDown
                        open={this.state.show}
                        onClose={handleClose}>
                        <DialogActions className="jc-spaceBetween">
                            <h2 className="mb-0 ml-4">{t('actions.updatename', { name: 'Firmware' })}
                            </h2>
                            <IconButton  onClick={handleClose}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </DialogActions>
                        <DialogContent>
                            <GatewayFirmwareUpdate handleClose={handleClose} gateway={this.props.gateway} firmwares={this.props.firmwares}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        disableEscapeKeyDown
                        open={this.state.showSendParameters}
                        onClose={handleClose}>
                        <DialogTitle>{t('actions.sendParameters')}</DialogTitle>
                        <DialogContent>
                            {
                                this.state.loading ? (
                                    <div className="row loadingParent">< div className="loadingImg"></div></div >
                                ) : (
                                    <List>
                                        {this.state.listParametersToSend}
                                    </List>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button className="primary pull-right float-right" onClick={handleClose}>{t('actions.close')}</Button>
                            <Button className="primary pull-right float-right" onClick={() => this.sendParameters()}>{t('actions.sendParameters')}</Button>
                        </DialogActions>
                    </Dialog>
                    <div className="row product-list__body">
                        <div style={{ padding: '20px' }} className="row col-12 prodChildNoZoom">
                            <h3 className="col-12">{t('gatewayspages.parameters.network')}</h3>

                            {reportedNetwork || reportedTwinRegistered ?
                                (
                                    <><h4 className="col-12">{t('gatewayspages.parameters.wifi')}</h4>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomStatus parameterValue={reportedWifiEnable ? "0" : "1"} paramObj={{ table: "gateways", smartComponentTypeId: 0, name: "Enabled", businessLabel: "EnabledWifi" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedWifiPreference} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Preference", businessLabel: "Wifipreference" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedWifiAddress} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Address", businessLabel: "WifiAddress" }} /></div>
                                        <h4 className="col-12">{t('gatewayspages.parameters.wired')}</h4>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomStatus parameterValue={reportedWiredEnable ? "0" : "1"} paramObj={{ table: "gateways", smartComponentTypeId: 0, name: "Enabled", businessLabel: "EnabledWired" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedWiredPreference} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Preference", businessLabel: "Wiredpreference" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedWiredAddress} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Address", businessLabel: "WiredAddress" }} /></div>
                                        <h4 className="col-12">{t('gatewayspages.parameters.lte')}</h4>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomStatus parameterValue={reportedLteEnable ? "0" : "1"} paramObj={{ table: "gateways", smartComponentTypeId: 0, name: "Enabled", businessLabel: "EnabledLte" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedLtePreference} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Preference", businessLabel: "LtePreference" }} /></div>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomTextField parameterValue={reportedLteAddress} paramObj={{ table: "gateways", smartComponentTypeId: 0, permissionType: 'R', name: "Address", businessLabel: "LteAddress" }} /></div>
                                    </>
                                ) :
                                <div style={{ margin: "auto" }}>
                                    <NoData></NoData>
                                </div>
                            }

                            {reportedNetwork || reportedTwinRegistered ?
                                (
                                    <>
                                        <h3 className="col-12">{t('gatewayspages.parameters.webserverpassword')}</h3>
                                        <div className="col-12 col-md-6 col-xl-4 paramContainer">
                                            <Input
                                                type={this.state.showPassword ? "text" : "password"}
                                                disabled={true}
                                                value={this.props.gateway.password}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            size="large">
                                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (null)
                            }

                            {this.props.gateway.reportedTwin ?
                                <><h3 className="col-12">{t('firmware.version')}</h3>
                                    <div className="col-12 col-md-6 col-xl-4 paramContainer">
                                        <span className="t3">{this.props.gateway.reportedTwin?.version?.osVersion}</span>
                                    </div></>
                                : (null)}

                            <h3 className="col-12">{t('gatewayspages.parameters.deviceparameters')}</h3>

                            {reportedNetwork || reportedTwinRegistered ?
                                (
                                    <>

                                        {this.state.refreshing ? (
                                            <div className="row col-12 loadingParent">< div className="loadingImg"></div></div >
                                        ) : (
                                            <div className="row col-12" style={{ margin: 0, padding: 0 }}>
                                                    <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomCheckbox style={{ marginTop: '20px' }} parameterValue={this.values.Autoupgrade} paramObj={{ table: "gateways", smartComponentTypeId: 0, name: "Automatic upgrading", businessLabel: "Autoupgrade", online: !devicedisconnected, permissionType: ""  }} toSend={this.valueChanged} /></div>
                                                    <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomSelect  style={{ width: '100%' }} parameterValue={this.values.CloudSyncInterval} paramObj={cloudSyncParamObj} toSend={this.valueChanged} /></div>
                                                    <div className="col-12 col-md-6 col-xl-4 paramContainer"><CustomCheckbox style={{ marginTop: '20px' }} parameterValue={this.values.Disabletelemetry} paramObj={{ table: "gateways", smartComponentTypeId: 0, name: "Disable telemetry", businessLabel: "Disabletelemetry", online: !devicedisconnected, permissionType: "" }} toSend={this.valueChanged} /></div>
                                                    <span style={{ paddingLeft: '15px' }} className="col-12 t3">{t('severalMinutesToApplyChanges')}</span>
                                            </div>
                                        )}
                                        <div className="col-12" style={{ paddingBottom: '15px', marginBottom: '20px', height: '55px' }}>
                                            <Button className="primary pull-right float-right" disabled={this.state.disabledSendParameters} onClick={openDialogSendParameters}>{t('actions.sendParameters')}</Button>
                                            <Button className="primary pull-right float-right" disabled={this.state.disabledCancelParameters} onClick={cancelParameters}>{t('actions.cancel')}</Button>
                                        </div>

                                    </>
                                ) :
                                <div style={{ margin: "auto" }}>
                                    <NoData></NoData>
                                </div>                            }

                        </div>
                        <div className="col-12" style={{ paddingBottom: '15px', marginBottom: '20px', height: '55px' }}>
                            <Button style={{ marginRight: '-22px', padding: '0.375rem 0.75rem' }} className="primary pull-right float-right" onClick={openDialogFirmware}>{t('actions.updatename', { name: 'firmware' })}{shouldUpdate ? (<WarningAmberIcon />) : (null)}</Button>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return <div className="row loadingParent">< div className="loadingImg"></div></div >;
        }
    }
}
export default withTranslation()(GatewayGeneral)