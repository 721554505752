import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { ModalConfirmDeletePackageViewProps } from '../types/HWPackageTypes';

const ModalConfirmDeletePackageView = (props: ModalConfirmDeletePackageViewProps) => (
  <Dialog disableEscapeKeyDown open={props.open} onClose={() => props.handleShowConfirmDeletePackageModal(false)}>
    <DialogTitle>{t('actions.deletename', { name: t('firmwareManagementPage.package') })}</DialogTitle>
    <DialogContent>
      <div>
        <span>{t('actions.confirmdelete', { name: t('firmwareManagementPage.package') })}</span>
      </div>
    </DialogContent>
    <DialogActions>
      <button className='primary' onClick={() => props.deletePackage()}>
        {t('actions.yes')}
      </button>
      <button className='primary' onClick={() => props.handleShowConfirmDeletePackageModal(false)}>
        {t('actions.no')}
      </button>
    </DialogActions>
  </Dialog>
);

export default ModalConfirmDeletePackageView;
