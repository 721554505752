import { PureComponent } from 'react';
import {
  DateRange,
  Granularity,
  ProductsGeneralContainerProps,
  ProductsGeneralContainerState,
} from '../types/ProductGeneralTypes';
import type { AuthContextType } from '../../../../../Authentication/types/AuthContextType';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import ProductsGeneralView from '../views/ProductsGeneralView';

class ProductsGeneralContainer extends PureComponent<ProductsGeneralContainerProps, ProductsGeneralContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: ProductsGeneralContainerProps) {
    super(props);
    this.state = {
      dateRange: this.props.dateRange,
      groupBySelected: this.props.groupBySelected,
      connectionState: this.props.product.content.connectionState ?? 'Offline',
      id: '',
      DateSelector: false,
      allChildComponentsRendered: false,
      componentChilds: {
        ProductsSummaryContainer: false,
        ProductsGeneralInfoLocationContainer: false,
        'KPIsChartContainer_Products-General-LastValues': false,
        'KPIsChartContainer_Products-General-MainCharts': false,
      },
    };
  }

  componentDidMount = () => {
    const id = this.getIdByPath();
    this.setState({ id });
  };

  componentFinished = (componentName: string) => {
    this.setState((prevState) => {
      const updatedComponentChilds = { ...prevState.componentChilds };
      updatedComponentChilds[componentName] = true;
      const allChildComponentsRendered = Object.values(updatedComponentChilds).every((value) => value === true);
      return {
        componentChilds: updatedComponentChilds,
        allChildComponentsRendered: allChildComponentsRendered,
      };
    });
  };

  getIdByPath = () => {
    const context = this.context as AuthContextType;
    const id = context.pathname.split('/').pop();
    return id ? id : '';
  };

  handleGranularityChange(value: Granularity) {
    this.setState({ groupBySelected: value });
  }

  handleDateRangeChange(value: DateRange) {
    this.props.onChangeDate(value, this.state.groupBySelected);
    this.setState({ dateRange: value });
  }

  render() {
    return (
      <ProductsGeneralView
        {...this.state}
        notificationAlerts={this.props.notificationAlerts}
        product={this.props.product}
        handleEditProduct={this.props.handleEditProduct}
        chartViewZones={this.props.chartViewZones}
        productTelemetry={this.props.productTelemetry}
        defaultGranularity={this.props.groupBySelected}
        handleDateRangeChange={this.handleDateRangeChange.bind(this)}
        handleGranularityChange={this.handleGranularityChange.bind(this)}
        componentFinished={this.componentFinished.bind(this)}
        defaultDate={this.props.dateRange}
      />
    );
  }
}

export default ProductsGeneralContainer;
