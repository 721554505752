import { PureComponent } from 'react';
import InstallationsGeneralMainChartsView from '../view/InstallationsGeneralMainChartsView';
import { InstallationsGeneralMainChartsContainerProps, InstallationsGeneralMainChartsContainerState } from '../types/InstallationsGeneralMainChartsTypes';
import AuthenticationContext from "../../../../../../Authentication/types/AuthContextType"

class InstallationsGeneralMainChartsContainer extends PureComponent<InstallationsGeneralMainChartsContainerProps, InstallationsGeneralMainChartsContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: InstallationsGeneralMainChartsContainerProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <InstallationsGeneralMainChartsView></InstallationsGeneralMainChartsView>
      </>
    );
  }
}

export default InstallationsGeneralMainChartsContainer;
