import Style from '../styles/PowerBIView.module.scss';
import { PowerBIViewProps } from '../types/PowerBITypes';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useContext } from 'react';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';

const PowerBIView = (props: PowerBIViewProps) => {
  const handle = useFullScreenHandle();
  const ctx = useContext(AuthenticationContext);

  return (
    <div className={Style.PowerBIView}>
      <div className='powerBI-content'>
        <div className='box-button'>
          <Link onClick={() => ctx.fullScreen(false)} to={'/'}>
            <button>
              <KeyboardBackspaceIcon fontSize='large' />
            </button>
          </Link>
          <button onClick={handle.enter}>
            <FullscreenIcon fontSize='large' />
          </button>
        </div>
        <FullScreen handle={handle}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: props.embed_info.id,
              embedUrl: props.embed_info.embedUrl,
              accessToken: props.embed_info.token,
              tokenType: models.TokenType.Embed,
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
                filterPaneEnabled: false,
                panes: {
                  pageNavigation: {
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  function (event) {
                    console.log(event!.detail);
                  },
                ],
                ['visualClicked', () => console.log('visual clicked')],
                ['pageChanged', (event) => console.log(event)],
              ])
            }
            cssClassName={'reportClass'}
            getEmbeddedComponent={(embeddedReport) => {
              props.setEmbedReport(embeddedReport);
            }}
          />
        </FullScreen>
      </div>
    </div>
  );
};

export default PowerBIView;
