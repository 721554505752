import React, { Component } from 'react';
import '../App.css';
import AuthenticationContext from "../Authentication/types/AuthContextType";
import PageContent from './PageContent';
import BreadCrumb from './BreadCrumb';
import { withRouter } from './withRouter';

class Page extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <BreadCrumb empty={true}></BreadCrumb>
                <PageContent match={this.props.match}/>
            </div>
        );
    }
}
export default withRouter(Page, [{ path:'/page'},{ path:'/page/:culture/:brandId/:pageId'}])