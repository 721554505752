// TODO REFACTOR separate container/view

import React, { Component } from 'react';
import '../../../../../../App.css';
import '../../../../../refreshBorderAnimation.css';
import FirmwaresHttpClient from "../../../../../../HttpClient/FirmwaresHttpClient";
import AuthenticationContext from "../../../../../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SWFirmwareAddForm from "./SWFirmwareAddForm";
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { selectDropdownStyle } from '../../../../config/ManagementConfig';

class SWAddPackage extends Component {

    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.newPackageSysIdRef = React.createRef();
        this.newPackageSmartComponentTypeRef = React.createRef();

        this.state = {
            activeStep: this.props.activeStepPackage ? this.props.activeStepPackage: 0,
            dataPackages: this.props.dataPackages,
            systemIds: null,
            sysFirmwares: [],
            message: null,
            description: "",
            showFirmwareDialog: false,
            smartCompTypes: [],
            editSmartCompo: null,
            itemsList: null,
            type_package: "",
            smartComponentTypesId: null
        };

        this.package = {
            systemId: "",
            smartComponentTypeSelected: "",
            name: "",
            description: "",
            available: true,
            production: true,
            smartComponentFirmwares: {},
            version:"",
            type_package: ""
        }

        this.dialog = {
            smartComponent: null,
            systemId: null,
            target: null
        }

        if (this.props.edit) {
            this.fillPackage();
        }
    }

    setTypePackage = (value) =>{
        this.package = {
            systemId: "",
            smartComponentTypeSelected: "",
            name: "",
            description: "",
            available: true,
            production: true,
            smartComponentFirmwares: {},
            version:"",
            type_package: value
        };    
        this.setState({ type_package: value });
    }

    fillPackage() {
        this.package.systemId = this.props.package.systemId;
        this.package.name = this.props.package.name;
        this.package.description = this.props.package.description;
        this.package.available = this.props.package.available;
        this.package.production = this.props.package.production;
        this.package.version = this.props.package.version;
        for (const value of this.props.package.smartComponentFirmwares) {
            let target = "smartComponent-" + value.smartComponentType.id;
            if (!this.package.smartComponentFirmwares[target]) {
                this.package.smartComponentFirmwares[target] = { label: "", value: "", componentId: value.smartComponentType.id, componentName: value.smartComponentType.name, order: value.packageDependencyOrderNum};
            }
        }
    }
    getSteps() {
        return [t('firmwareManagementPage.createNewPackage'), t('firmwareManagementPage.selectFirmwares'), t('firmwareManagementPage.resume')];
    }

    close(reload = false) {
        this.props.unmountMe(reload);
    }

    async getFirmwareVersions(smartComponentId, smartComponentName) {
        this.http = new FirmwaresHttpClient(this.context, null);
        let allData = await this.http.GetSmartComponent(smartComponentId);
        let data = allData.content;

        let ret = data
        .filter(obj => obj.available && obj.uri)
        .map(obj => ({
          value: obj.id,
          label: `${obj.FirmwareVersion} | ${obj.uri.split("/").pop()}`,
          componentId: smartComponentId,
          componentName: smartComponentName
        }));
        return ret.sort((a, b) => b.value - a.value);
    }

    handlerChangePackageFirmware = (e, target) => {
        let changesInSmartCompo = this.state.editSmartCompo ? this.state.editSmartCompo: []
        changesInSmartCompo[target] = {...changesInSmartCompo[target], ...e}
        this.setState({ editSmartCompo: changesInSmartCompo });
    }

    getSmartCompoTypeWithId = (id) => {
        if(!id){
            return null
        }
        return this.props.allSmartComponentTypes.find(smartCompo => smartCompo.id === id).name;
    }

    async getSystemIdFirmwares() {
        const { t } = this.props;

        let items = [];
        let i = 0;
        let data = []
        if(this.package.type_package === "SystemId"){
            data = this.props.productSystems.filter(e => e.id == this.package.systemId);
        }else if(this.package.type_package === "SmartComponentType"){
            const fullSmartCompoType = this.props.allSmartComponentTypes.filter(smartCompo => smartCompo.id === this.package.smartComponentTypeSelected);            
            data = [{smartComponentTypes: fullSmartCompoType}]
        }        
        
        if (data.length > 0) {
            try{
                for (const value of data[0].smartComponentTypes) {
                    let thisPackage = this.props.package && this.props.package.smartComponentFirmwares[i] ? this.props.package.smartComponentFirmwares[i]: undefined;
                    i++;                
                    let target = "smartComponent-" + value.id;
                    let options = await this.getFirmwareVersions(value.id, value.name);
                    let defaultValue = thisPackage ? options.find(option => option.value == thisPackage.id): undefined
    
                    if (!this.package.smartComponentFirmwares[target] || !defaultValue) {
                        this.package.smartComponentFirmwares[target] = { label: "", value:"", componentId: value.id, componentName: value.name, order: i };
                    }else{
                        this.package.smartComponentFirmwares[target] = { ...defaultValue, order: thisPackage.packageDependencyOrderNum ? thisPackage.packageDependencyOrderNum: i };
                    }
    
                   let obj=  {
                       smartComponent: { value: value.id, label: value.name },
                       systemId: { value: this.package.systemId, label: this.package.systemId },
                        target: target
                    }
                        items.push(
                            <div key={"p-key-" + i} className="row">
                                <div className="col-12 col-xl-8">
                                    <h3>{value.name}</h3>
                                    <div className="row">
                                        <div className="col">
                                            <Select
                                                isDisabled={this.props.package?.inUse}
                                                ref={this.selectRef}
                                                className="react-select-container"
                                                required
                                                styles={selectDropdownStyle({height: 250})}
                                                options={options}
                                                onChange={(e) => {
                                                    this.handlerChangePackageFirmware(e, target, "selectRef")
                                                    document.getElementById(target).value = e.value;
                                                }}
                                                // defaultValue={this.package.smartComponentFirmwares[target].componentId}
                                                defaultValue={thisPackage? options.find(option => option.value == thisPackage.id): ""}
                                            />
                                            <input
                                                id={target}
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{ opacity: 0, height: 0, position: "absolute" }}
                                                required={true}
                                                defaultValue={thisPackage? options.find(option => option.value == thisPackage.id): ""}
                                                // defaultValue={this.package.smartComponentFirmwares[target].componentId}
                                                />
                                        </div>
                                        <div className="col-auto" style={{ minWidth: "100px", maxWidth: "100px" }}>
                                            <TextField inputProps={{ min: "1", max: data[0].smartComponentTypes.length }}
                                                disabled={this.props.package?.inUse}
                                                onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                                onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                                onChange={(event) => {
                                                  event.target.setCustomValidity('');
                                                  this.handlerChangePackageFirmware({order: event.target.value}, target)
                                                }}
                                                type="number"
                                                required
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                label={t('firmwareManagementPage.order')}
                                                defaultValue={this.package.smartComponentFirmwares[target].order} />
                                        </div>
                                        <div className="col-auto">
                                            {/* Open dialog with target as parameter, when save or close update that target */}
                                            {!this.props.package?.inUse ? 
                                               <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => { this.openDialog(obj) }}>
                                               <UploadIcon />
                                           </IconButton>
                                           : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )                
                }
            }
            catch(error){
                console.log(error.message);
            }
            
        }
        this.setState({ itemsList: items });
    }

    openDialog(obj) {
        this.dialog = obj;
        this.setState({ showFirmwareDialog: true });
    }
    fillTitleStep(step) {
        if (typeof this.props.handleStepper === 'function') {
            this.props.handleStepper(step);
        }
    }

    fillSystemIdSelect() {
        //We map them to fit select component
        let sysIds = this.props.productSystems.map(obj => ({ label: obj.id, value: obj.id }));
        let smartComponentTypesId = this.props.allSmartComponentTypes.map(smartCompo => ({ label: `${smartCompo.id} - ${smartCompo.name}`, value: smartCompo.id }));        
        this.setState({ systemIds: sysIds, smartComponentTypesId });
    }

    openDialogFirmware(target) {
        let obj = this.package.smartComponentFirmwares[target];
        let component = {
            label: obj.componentName, value: obj.componentId
        }
        let sys = {
            label: this.package.systemId, value: this.package.systemId
        }
        // target

        this.dialog = {
            smartComponent: component,
            systemId: sys,
            target:target
        }
    }

    componentDidMount() {
      this.setState({ activeStep: 0 });
      this.fillSystemIdSelect()
      this.fillTitleStep(0);
    }

    renderTooltip = (text) => (props) => (
        <Tooltip id="button-tooltip" target="{target.current}" position="fixed" {...props}>
            {text}
        </Tooltip>
    );

    getValueEditTypePackage = () => {
        if(this.package.type_package){
            return this.package.type_package
        }else if(this.props.edit){
            if(this.package && this.package.systemId){
                this.package.type_package = "SystemId"
            }else{
                this.package.type_package = "SmartComponentType"
            }
        } 
        if(this.state.type_package !== this.package.type_package){
            this.setState({ type_package: this.package.type_package });
        }
        return this.package.type_package;    
    }

    getValueEditSelectSmartComponentType = () => {
        if(!this.package.smartComponentTypeSelected && (this.props.package && !this.props.package.systemId) &&
            this.props.package.smartComponentFirmwares &&
            this.props.package.smartComponentFirmwares.length &&        
            this.props.package.smartComponentFirmwares[0].smartComponentType &&
            this.props.package.smartComponentFirmwares[0].smartComponentType.id ){
            this.package.smartComponentTypeSelected = this.props.package.smartComponentFirmwares[0].smartComponentType.id;
        }        
        return this.package.smartComponentTypeSelected;
    }

    getPackageStep0() {
    const { t } = this.props;
    return (
        <div>
            <form id="PackageStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>
                <h2>{t('firmwareManagementPage.createNewPackage')}</h2>
                <div className="row">
                    <div className="col">
                        <h3>{t('firmwareManagementPage.selectType')}</h3>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby='demo-controlled-radio-buttons-group'
                                name='controlled-radio-buttons-group'
                                value={this.getValueEditTypePackage()}
                                onChange={(event) => { this.setTypePackage(event.target.value) }}>                               
                                <FormControlLabel
                                    value='SystemId'
                                    control={<Radio required={true} />}
                                    label={t('firmwareManagementPage.systemId')}
                                    disabled={this.props.modeForm === "edit"}
                                />
                                 <FormControlLabel
                                    value='SmartComponentType'
                                    control={<Radio required={true} />}
                                    label={t('firmwareManagementPage.smartComponentTypeId')}
                                    disabled={this.props.modeForm === "edit"}
                                />
                            </RadioGroup>
                        </FormControl>                     
                    </div>
                </div>
            </form>
        </div>);
    }

    getPackageStep1() {
        const { t } = this.props;
        if(this.package.type_package === "SystemId"){
            setTimeout(() => {
                const newPackageSmartComponentType = this.newPackageSmartComponentTypeRef?.current?.inputRef;
                if (newPackageSmartComponentType) {                
                  newPackageSmartComponentType.setCustomValidity(this.props.t('general.error_required'));
                }
              }, 1000);
            return (
                <div>                   
                    <form id="PackageStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>    
                        <h2>{t('firmwareManagementPage.createNewPackage')}</h2>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h3>{t('firmwareManagementPage.packageName')}*</h3>
                                <TextField 
                                onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onChange={(event) => {
                                  event.target.setCustomValidity('');
                                  this.setValue(event.target.value, 'name');
                                }}
                                required
                                size="small"
                                fullWidth
                                variant="outlined"
                                defaultValue={this.package.name} />
                            </div>
                            <div className="col-12 col-md-3">
                                <h3>{t('firmwareManagementPage.systemId')}*</h3>
                                <Select className="react-select-container"
                                    isDisabled={this.props.package?.inUse}
                                    ref={this.newPackageSysIdRef}
                                    required
                                    styles={selectDropdownStyle({height: 250})}
                                    options={this.state.systemIds}
                                    onChange={(event) => {
                                        this.package.smartComponentFirmwares = {};
                                        this.selectedChange(event.value, "newPackageSysId", "systemId", "newPackageSysIdRef")
                                    }}
                                    defaultValue={{ label: this.package.systemId, value: this.package.systemId }}
                                />
                                <input
                                    id="newPackageSysId"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, position: "absolute" }}
                                    required={true}
                                    defaultValue={this.package.systemId}
                                    disabled={this.props.package?.inUse}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <h3>{t('firmwareManagementPage.version')}* <OverlayTrigger placement="top" delay={{ show: 0, hide: 400 }} overlay={this.renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}><span style={{ position: "relative" }}><HelpIcon style={{position: "absolute", top: '50%', left: '50%', transform: 'translate(15%, -45%)' }} fontSize="medium"></HelpIcon></span></OverlayTrigger></h3>
                                <TextField inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{1,2}[.][0-9]{2}$' }} onChange={(event) => { event.target.setCustomValidity(''); this.setValue(event.target.value, "version") }} required size="small" fullWidth variant="outlined" defaultValue={this.package.version} onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}  onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}/>                            
                            </div>
                        </div>    
                        <div className="row">
                            <div className="col">
                                <h3>{t('forms.description')}</h3>
                                <TextField size="small" fullWidth variant="outlined" onChange={(event) => { this.setValue(event.target.value, "description") }} defaultValue={this.package.description} />
                            </div>
                        </div>    
                        <div className="row">
                            <div className="">
                                <FormGroup>
                                    <div>
                                        <div className="form-check pl-0" style={{ paddingTop: "35px" }}>
                                            <div className="flexContainer">
                                                <div className="col-12 col-md-6 d-flex" style={{ alignitems: "center" }}>
                                                    <div>
                                                        <Form.Check
                                                            className="p-0"
                                                            type="checkbox"
                                                            id="chkAvailable"
                                                            label=""
                                                            defaultChecked={this.package.available}
                                                            onChange={(event) => { this.setValue(event.target.checked, "available") }}
                                                        />                                                    
                                                    </div>
                                                    <span className="t3 mb-0"> {t('firmwareManagementPage.available')} </span>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex mt-4 mt-md-0" style={{ alignitems: "center"}}>
                                                    <div>
                                                        <Form.Check
                                                            className="p-0"
                                                            type="checkbox"
                                                            id="chkProduction"
                                                            label=""
                                                            disabled
                                                            defaultChecked={this.package.production}
                                                        />
                                                    
                                                    </div>
                                                    <span className="t3 mb-0"> {t('firmwareManagementPage.production')} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>        
                    </form>
            </div>);
        }else if(this.package.type_package === "SmartComponentType"){
            const smartComponentTypeSelected = this.getValueEditSelectSmartComponentType()
            setTimeout(() => {
                const select = this.selectRef?.current?.inputRef;
                const newPackageSysId = this.newPackageSysIdRef?.current?.inputRef;
                if (select && newPackageSysId && newPackageSmartComponentType) {
                select.setCustomValidity(this.props.t('general.error_required'));
                newPackageSysId.setCustomValidity(this.props.t('general.error_required'));
                }
            }, 1000);
            return (
                <div>                   
                    <form id="PackageStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>    
                        <h2>{t('firmwareManagementPage.createNewPackage')}</h2>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h3>{t('firmwareManagementPage.packageName')}*</h3>
                                <TextField 
                                onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onChange={(event) => {
                                  event.target.setCustomValidity('');
                                  this.setValue(event.target.value, 'name');
                                }}
                                required
                                size="small"
                                fullWidth
                                variant="outlined"
                                defaultValue={this.package.name} />
                            </div>
                            <div className="col-12 col-md-3">
                                <h3>{t('firmwareManagementPage.smartComponentTypeId')}*</h3>    
                                <Select className="react-select-container"
                                    isDisabled={this.props.modeForm === "edit"}
                                    ref={this.newPackageSmartComponentTypeRef}
                                    required
                                    styles={selectDropdownStyle({height: 250})}
                                    options={this.state.smartComponentTypesId}
                                    onChange={(event) => {
                                        this.package.smartComponentFirmwares = {};
                                        this.selectedChange(event.value, "newPackageSmartComponentType", "smartComponentTypeSelected", "newPackageSmartComponentTypeRef")
                                    }}
                                    defaultValue={smartComponentTypeSelected ? { label:`${smartComponentTypeSelected} - ${this.getSmartCompoTypeWithId(smartComponentTypeSelected)}`, value: smartComponentTypeSelected } : null}
                                />
                                <input
                                    id="newPackageSmartComponentType"
                                    disabled={this.props.modeForm === "edit"}
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0, position: "absolute" }}
                                    required={true}
                                    defaultValue={smartComponentTypeSelected}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <h3>{t('firmwareManagementPage.version')}* <OverlayTrigger placement="top" delay={{ show: 0, hide: 400 }} overlay={this.renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}><span style={{ position: "relative" }}><HelpIcon style={{position: "absolute", top: '50%', left: '50%', transform: 'translate(15%, -45%)' }} fontSize="medium"></HelpIcon></span></OverlayTrigger></h3>
                                <TextField inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{1,2}[.][0-9]{2}$' }} onChange={(event) => { event.target.setCustomValidity(''); this.setValue(event.target.value, "version") }} required size="small" fullWidth variant="outlined" defaultValue={this.package.version} onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}  onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}/>                            
                            </div>
                        </div>    
                        <div className="row">
                            <div className="col">
                                <h3>{t('forms.description')}</h3>
                                <TextField size="small" fullWidth variant="outlined" onChange={(event) => { this.setValue(event.target.value, "description") }} defaultValue={this.package.description} />
                            </div>
                        </div>    
                        <div className="row">
                            <div className="">
                                <FormGroup>
                                    <div>
                                        <div className="form-check pl-0" style={{ paddingTop: "35px" }}>
                                            <div className="flexContainer">
                                                <div className="col-12 col-md-6 d-flex" style={{ alignitems: "center" }}>
                                                    <div>
                                                        <Form.Check
                                                            className="p-0"
                                                            type="checkbox"
                                                            id="chkAvailable"
                                                            label=""
                                                            defaultChecked={this.package.available}
                                                            onChange={(event) => { this.setValue(event.target.checked, "available") }}
                                                        />                                                    
                                                    </div>
                                                    <span className="t3 mb-0"> {t('firmwareManagementPage.available')} </span>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex mt-4 mt-md-0" style={{ alignitems: "center"}}>
                                                    <div>
                                                        <Form.Check
                                                            className="p-0"
                                                            type="checkbox"
                                                            id="chkProduction"
                                                            label=""
                                                            disabled
                                                            defaultChecked={this.package.production}
                                                        />
                                                    
                                                    </div>
                                                    <span className="t3 mb-0"> {t('firmwareManagementPage.production')} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>        
                    </form>
            </div>);
        }  
    }

    getPackageStep2() {
        const { t } = this.props;
        if(!this.state.itemsList){
            this.getSystemIdFirmwares();
        }
        return (
            <form id="PackageStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>
                <React.Fragment>
                    <h2>{this.package.systemId} - {this.package.name}</h2>
                    <span className="t2">{t('firmwareManagementPage.selectFirmwareComponentText')}</span>
                    <div className="row">
                        <div className="col">
                            {this.state.itemsList ? this.state.itemsList : <div className="loadingParent"><div className="loadingImg"></div></div>}
                        </div>
                    </div>                    
                </React.Fragment>
            </form>);
    }

    getPackageStep3() {
        const { t } = this.props;

        if(this.package.type_package === "SystemId"){
            return (
                <React.Fragment>
    
                    <h2>{t('firmwareManagementPage.resume')}</h2>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h3>{t('firmwareManagementPage.packageName')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.name} />
                        </div>
                        <div className="col-12 col-md-3">
                            <h3>{t('firmwareManagementPage.systemId')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.systemId} />
                        </div>
                        <div className="col-12 col-md-3">
                            <h3>{t('firmwareManagementPage.version')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.version} />
                        </div>
                        
                    </div>
    
                    <div className="row">
                        <div className="col">
                            <h3>{t('forms.description')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.description} />
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col">
                            <FormGroup>
                                <div>
                                    <div className="form-check pl-0" style={{ paddingTop: "35px" }}>
                                        <div className="flexContainer">
                                            <div className="col-12 col-md-auto d-flex" style={{ alignitems: "center" }}>
                                                <div>
                                                    <Form.Check
                                                        className="p-0"
                                                        type="checkbox"
                                                        id="chkAvailable-2"
                                                        label=""
                                                        defaultChecked={this.package.available}
                                                        disabled
                                                    />
                                                    
                                                </div>
                                                <span className="t3 mb-0"> {t('firmwareManagementPage.available')} </span>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex mt-4 mt-md-0" style={{ alignitems: "center"}}>
                                                <div>
                                                    <Form.Check
                                                        className="p-0"
                                                        type="checkbox"
                                                        id="chkProduction-2"
                                                        label=""
                                                        defaultChecked={this.package.production}
                                                        disabled
                                                    />
                                                    
                                                </div>
                                                <span className="t3 mb-0">{t('firmwareManagementPage.production')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
    
                    <h2 className="mt-5">{t('firmwareManagementPage.components')}</h2>
                    <div className="row">
                        { this.getResumeComponents()}
                    </div>                   
                </React.Fragment>);
        }else if(this.package.type_package === "SmartComponentType"){
            return (
                <React.Fragment>
    
                    <h2>{t('firmwareManagementPage.resume')}</h2>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h3>{t('firmwareManagementPage.packageName')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.name} />
                        </div>
                        <div className="col-12 col-md-3">
                            {/* <h3>{t('firmwareManagementPage.systemId')}</h3> */}
                            <h3>SmartComponentType</h3>                            
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.smartComponentTypeSelected ? `${this.package.smartComponentTypeSelected} - ${this.getSmartCompoTypeWithId(this.package.smartComponentTypeSelected)}` : null} />
                        </div>
                        <div className="col-12 col-md-3">
                            <h3>{t('firmwareManagementPage.version')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.version} />
                        </div>
                        
                    </div>
    
                    <div className="row">
                        <div className="col">
                            <h3>{t('forms.description')}</h3>
                            <TextField disabled size="small" fullWidth variant="outlined" defaultValue={this.package.description} />
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col">
                            <FormGroup>
                                <div>
                                    <div className="form-check pl-0" style={{ paddingTop: "35px" }}>
                                        <div className="flexContainer">
                                            <div className="col-12 col-md-auto d-flex" style={{ alignitems: "center" }}>
                                                <div>
                                                    <Form.Check
                                                        className="p-0"
                                                        type="checkbox"
                                                        id="chkAvailable-2"
                                                        label=""
                                                        defaultChecked={this.package.available}
                                                        disabled
                                                    />
                                                    
                                                </div>
                                                <span className="t3 mb-0"> {t('firmwareManagementPage.available')} </span>
                                            </div>
                                            <div className="col-12 col-md-6 d-flex mt-4 mt-md-0" style={{ alignitems: "center"}}>
                                                <div>
                                                    <Form.Check
                                                        className="p-0"
                                                        type="checkbox"
                                                        id="chkProduction-2"
                                                        label=""
                                                        defaultChecked={this.package.production}
                                                        disabled
                                                    />
                                                    
                                                </div>
                                                <span className="t3 mb-0">{t('firmwareManagementPage.production')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
    
                    <h2 className="mt-5">{t('firmwareManagementPage.components')}</h2>
                    <div className="row">
                        { this.getResumeComponents()}
                    </div>
                </React.Fragment>);

        }
    }

    getResumeComponents() {
        let r = [];
        let smartCompos = this.state.editSmartCompo;
        if(this.props.modeForm == "edit"){
            smartCompos = this.package.smartComponentFirmwares;
            if(this.state.editSmartCompo){
                Object.keys(this.state.editSmartCompo).forEach(compoName => {
                    Object.keys(this.state.editSmartCompo[compoName]).forEach(attr => {
                            smartCompos[compoName][attr] = this.state.editSmartCompo[compoName][attr]
                    });
                }); 
            }           
        }
        this.package.smartComponentFirmwares = smartCompos;

        if(smartCompos){
            Object.values(smartCompos).forEach((val,i) => {
                r.push((<div key={"components-"+i} className="col-12 col-md-6">
                    <h3>{ val.order ? val.order: i+1 } - {val.componentName}</h3>
                    <TextField disabled size="small" fullWidth variant="outlined" defaultValue={val.label} />
                </div>))
            });
        }
        return r;
    }
    getPackageStepContent(stepIndex) {
        let activeStep = stepIndex;

        switch (activeStep) {
            case 0:
                return this.getPackageStep0();
            case 1:
                return this.getPackageStep1();
            case 2:
                return this.getPackageStep2();
            case 3:
                return this.getPackageStep3();
            default:
                return 'Unknown stepIndex';
        }
    };
    selectedChange = (value, target, object, nameRef) => {
        this.package[object] = value;
        document.getElementById(target).value = value;
        const select = this[nameRef]?.current?.inputRef;
        select.setCustomValidity('');
    };
    setValue = (event, name) => {
        this.package[name] = event;
    };

    saveNewPackage = async () => {
        this.http = new FirmwaresHttpClient(this.context, null);
        try {
            let allData = await this.http.PostSmartComponentPackage(this.package.systemId, this.package.smartComponentTypeSelected, this.package.version, this.package.name, this.package.description, this.package.production);
            if (allData.content) {
                let list = [];
                Object.keys(this.package.smartComponentFirmwares).forEach((compo, i) => {
                    list.push({
                        smartComponentPackageId: allData.content.id,
                        smartComponentFirmwareId: this.package.smartComponentFirmwares[compo].value,
                        orderNum: this.package.smartComponentFirmwares[compo].order ? Number(this.package.smartComponentFirmwares[compo].order): i+1
                    })
                });
                let dependency = await this.http.SmartcomponentPackageDependencyes(list);
                if(dependency.content){
                    this.props.showMessageInTable("messages.successAddPackage", "success");
                }else{
                    this.props.showMessageInTable("messages.errorAddPackage", "error");
                }     
            }
            this.props.unmountMe(true);
        } catch (err) {
            if (err.request?.status == 409) {
                this.props.showMessageInTable("firmwareManagementPage.PackageVersionExists", "error");
            }
            else{
                this.props.showMessageInTable("messages.errorAddPackage", "error");
            }
        }        
    }

    async handleSave() {
        if(this.props.modeForm == "new"){
            // new compo
            this.saveNewPackage()
        }else if(this.props.modeForm == "edit"){
            // edit compo
            let packagePatch = [];
            let newCompo = false
            this.http = new FirmwaresHttpClient(this.context, null);
            Object.keys(this.package).forEach(attr => {
                    if([attr] in this.props.package && (JSON.stringify(this.props.package[attr]) != JSON.stringify(this.package[attr]))){
                        if((attr == 'smartComponentFirmwares' && this.state.editSmartCompo) || attr == 'systemId'){
                            newCompo = true;
                        }else{
                            if(attr != 'smartComponentFirmwares'){
                                packagePatch.push({
                                    "op": "replace",
                                    "path": `/${attr}`,
                                    "value": String(this.package[attr])
                                })
                            }                            
                        }                    
                    } 
            });
            if(newCompo){
                let error = false;
                // delete dependencies
                await Promise.all(this.props.package.smartComponentFirmwares.map(async (compo) => {
                    const response = await this.http.deleteSmartComponentPackageDependencyId(compo.packageDependencyId);
                    if (response.status && response.status !== 200) {
                        error = true;
                    }
                }));
                // delete package
                if (!error) {
                    const response = await this.http.deleteSmartComponentPackage(this.props.package.id);
                    if (response.status && response.status !== 200) {
                        error = true;
                    }
                }
                // create new package
                if (!error) {
                    await this.saveNewPackage()
                }

                if(error){
                    this.props.showMessageInTable("messages.errorAddPackage", "error");
                }

            }else if(packagePatch.length){
                try {
                    let data = await this.http.patchSmartComponent(this.props.package.id, packagePatch);
                    if (data.content) {
                        this.props.showMessageInTable("messages.successAddPackage", "success");
                        this.handleDialogClose();
                    }     
                } catch (err) {
                    this.props.showMessageInTable("messages.errorAddPackage", "error");
                    if (err?.request?.status == 409) {
                        this.props.showMessageInTable("firmwareManagementPage.PackageVersionExists", "error");
                    }
                }
            }
        }  
        this.props.unmountMe(true);      
    }

    setMessage(type, text) {
        this.setState({ message: { type:type, text: text} });
    }
    cleanMessage() {
        if (this.state.message != null) {
            this.setState({ message: null });
        }
    }
    handlePackageBack = () => {
        let s = this.state.activeStep - 1;
        let itemsList = this.state.itemsList
        if (s == 1) {
            itemsList = null;        
        }
        
        this.fillTitleStep(s);
        this.setState({ activeStep: s, itemsList: itemsList });
        
        this.cleanMessage();
    };
    handlePackageNext = () => {        
        let s = this.state.activeStep;
        let itemsList = this.state.itemsList
        
        if (s == 2) {
            itemsList = null
        }
        if (s == 2) {
            if (!this.checkOrderNumbers()) {
                return false;
            }
        }
        s++;
       

        this.fillTitleStep(s);
        this.setState({ activeStep: s, itemsList: itemsList });
        this.cleanMessage();
    };
    checkOrderNumbers() {
        let o = [];
        let correct = true;
        Object.values(this.package.smartComponentFirmwares).forEach((val, i) => {
            if (!o.includes(val.order.toString())) {
                o.push(val.order.toString());
            } else {
                correct = false;
                return;
            }
        });
        if (!correct) { this.setMessage("warning", "Order must be unique"); }
        return correct;
    }
    handleFormSubmit = (e) => {

        this.handlePackageNext();
        e.preventDefault();
    };
    handleFirmwareClick = (evnt) => {
        //Call parent onchange
        if (typeof this.props.handleFirmwareClick === 'function') {
            this.props.handleFirmwareClick();
        }
    };

    getStepButtons(step) {
        const { t } = this.props;
        let bBack = null;
        let bNext = null;
   
        if (step === 0) {
            bBack = (<button onClick={() => this.close()} className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left" tabIndex="0" type="button">
                <span className="MuiButton-label">{t('actions.cancel')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        }else{
            bBack = (<button onClick={this.handlePackageBack} className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left" tabIndex="0" type="button">
                <span className="MuiButton-label">{t('actions.back')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        }

        if (step === 3) {
            bNext = (<button className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right" tabIndex="0" onClick={() => this.handleSave()}>
                <span className="MuiButton-label">{t('actions.save')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        } else {
            bNext = (<button className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right" tabIndex="0" type="submit" form="PackageStep1Form">
                <span className="MuiButton-label">{t('actions.next')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        }

        return <div className="col">
            {bBack}
            {bNext}
        </div>;

    }
    handleDialogClose = (e) => {
        this.setState({ showFirmwareDialog: false, editSmartCompo: null })
    }
    handleClose = (reload = false, obj = null) => {
        this.handleDialogClose();
        if (reload) {
            if(obj != null){
                let target = this.dialog.target;
                this.package.smartComponentFirmwares[target].value = obj.id;
                this.package.smartComponentFirmwares[target].label = obj.blobUri?.split("/").pop();
            }

            this.setState({ itemsList: null }, () => { this.getSystemIdFirmwares(); });
        }
    }
    showAlert() {      
        let r = <div className="mt-4">
            <Alert severity={this.state.message.type}>{this.state.message.text}</Alert>
        </div>
        return r;
    }
    render() {
        return (
            <React.Fragment>
                <Dialog classes={{ paper: "installationModal custom" }} disableEscapeKeyDown open={this.state.showFirmwareDialog} onClose={this.handleDialogClose}>
                    <DialogActions className="jc-spaceBetween">
                        <h2 className="mb-0 ml-4">Step {this.state.activeStep} of 3</h2>
                        <IconButton color="primary" onClick={this.handleDialogClose}>
                            <CloseIcon />
                        </IconButton>

                    </DialogActions>

                    <DialogContent>
                        <SWFirmwareAddForm 
                            type_firmware={this.state.type_package}
                            allSmartComponentTypes={this.props.allSmartComponentTypes}
                            skipType={true}
                            activeStep={1}
                            unmountMe={this.handleClose.bind(this)}
                            smartComponent={this.dialog.smartComponent}
                            systemId={this.dialog.systemId}
                            showMessageInTable={this.props.showMessageInTable}
                            smartComponentTypeSelected={this.package.smartComponentTypeSelected}
                            />
                    </DialogContent>
                </Dialog>
                <div>
                    <div className="prodChildNoZoom p-5">

                        {this.getPackageStepContent(this.state.activeStep)}

                        {this.state.message != null ? this.showAlert() : null}
                        
                    </div>
                    <div className="row">
                        {this.getStepButtons(this.state.activeStep)}
                    </div>
                    

                </div>

            </React.Fragment>
        );
    }
}

export default withTranslation()(SWAddPackage);