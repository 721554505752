import _ from 'lodash';
import { AccessToken, FullAccessToken } from '../types/AuthContextType';
import { SilentRequest } from '@azure/msal-browser/dist/request/SilentRequest';
import { AuthInfoToken } from '../types/LoginType';
import { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import type { LoginContainer } from '../container/LoginContainer';
import { UserMeasure } from '@ComponentsRoot/UsersComponents/Tabs/UserConfigUnits/types/UserConfigUnitsTypes';

class AuthGate {
  private loginContext: LoginContainer;

  constructor(loginContext: LoginContainer) {
    this.loginContext = loginContext;
  }

  checkAccess = async () => {
    if (
      this.loginContext.state.locationHref.includes('page') ||
      this.loginContext.state.locationHref.includes('register') ||
      this.loginContext.state.locationHref.includes('welcome')
    ) {
      this.loginContext.setState({ isAuthenticated: true, loading: false, authInfo: null, freeAccess: true });
    }

    // if (this.loginContext.state.locationHref === '/' && !this.loginContext.state.isAuthenticated) {
    //   this.loginContext.setState({ loading: true, freeAccess: true });
    // }

    // if (
    //   this.loginContext.state.locationHref !== '/' &&
    //   this.loginContext.state.locationHref !== '/home' &&
    //   !this.loginContext.state.isAuthenticated
    // ) {
    //   window.location.href = '/';
    // }
    // else {
    //   if (!this.loginContext.state.isAuthenticated) {
    //     let acToken = JSON.parse(localStorage.getItem('t')!);
    //     if (acToken !== null && new Date(acToken.expiresOn).getTime() > new Date().getTime()) {
    //       this.loginContext.setState({ isAuthenticated: true });
    //     }
    //   }
    // }

    if (!this.loginContext.state.freeAccess) {
      await this.selectAccount();
    }

    this.loginContext.props.MSALInstance.handleRedirectPromise()
      .then(async (response) => {
        if (response) {
          await this.handleResponse(response);
        }
      })
      .catch(async (error: { errorMessage: string }) => {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          await this.forgotPassword();
        } else if (error.errorMessage.indexOf('AADB2C90006') > -1) {
          await this.login();
        } else {
          await this.login();
        }
        console.log('error: ' + error);
      });

    if (this.loginContext.state.locationHref.includes('?action=login')) {
      if (this.loginContext.state.isAuthenticated === false) {
        await this.login();
      }
    }
  };

  getToken = async () => {
    try {
      let acToken: AccessToken = JSON.parse(localStorage.getItem('t')!);
      if (acToken == null || (acToken != null && new Date(acToken.expiresOn).getTime() < new Date().getTime())) {
        let accessToken: FullAccessToken = await this.acquireToken();
        if (accessToken) {
          if (!this.loginContext.state.accessToken || !this.loginContext.state.isAuthenticated) {
            this.loginContext.setState({ accessToken: accessToken, isAuthenticated: true });
          }
          this.setAccessTokenLocalStorage(accessToken);
        }
        return accessToken;
      } else {
        if (!this.loginContext.state.accessToken || !this.loginContext.state.isAuthenticated) {
          this.loginContext.setState({ accessToken: acToken, isAuthenticated: true });
        }
        this.setAccessTokenLocalStorage(acToken);
        return acToken;
      }
    } catch (error) {
      let accessToken: FullAccessToken = await this.acquireToken();
      this.setAccessTokenLocalStorage(accessToken);
      this.loginContext.setState({ isAuthenticated: false, authInfo: null, accessToken: accessToken });
      return accessToken;
    }
  };

  acquireToken = async () => {
    let authInfo = this.loginContext.state.authInfo ? this.loginContext.state.authInfo : await this.selectAccount();
    let silentToken: SilentRequest = {
      account: authInfo as AccountInfo,
      scopes: this.loginContext.props.MSAL_CONFIG.scopes,
      forceRefresh: false,
    };
    let accessToken;

    try {
      if (silentToken.account) {
        accessToken = await this.loginContext.props.MSALInstance.acquireTokenSilent(silentToken);
        return accessToken;
      }

      // if ((!accessToken || !accessToken.accessToken) && authInfo) {
      //   let silentToken = {
      //     loginHint: authInfo.username ? authInfo.username : authInfo.account.username,
      //     scopes: this.loginContext.props.MSAL_CONFIG.scopes
      //       ? this.loginContext.props.MSAL_CONFIG.scopes
      //       : (this.loginContext.props.MSAL_CONFIG.scopes.scopes),
      //   };
      //   accessToken = await this.loginContext.props.MSALInstance.ssoSilent(silentToken);
      // }
    } catch (error) {
      if ((error as { errorCode: string }).errorCode?.includes('interaction')) {
        await this.logout();
      }
    }
    return accessToken;
  };

  setAccessTokenLocalStorage(accessToken) {
    let acToken = {};
    acToken['expiresOn'] = accessToken.expiresOn;
    acToken['accessToken'] = accessToken.accessToken;
    localStorage.removeItem('t');
    localStorage.setItem('t', JSON.stringify(acToken));
  }

  selectAccount = async () => {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = this.loginContext.props.MSALInstance.getAllAccounts();
    if (!currentAccounts || currentAccounts.length < 1) {
      this.loginContext.setState({ isAuthenticated: false });
      return;
    } else if (currentAccounts.length > 1) {
      console.warn('Multiple accounts detected.');
      this.loginContext.setState({ isAuthenticated: false });
      await this.logout();
      return;
    } else if (currentAccounts.length === 1) {
      let account = currentAccounts[0];
      if (account.homeAccountId.indexOf('passwordreset') > 0) {
        this.loginContext.setState({ isAuthenticated: false });
        await this.logout();
        return;
      } else {
        this.loginContext.setState({
          isAuthenticated: Boolean(account),
          authInfo: account,
        });
        return account;
      }
    }
  };

  logout = async () => {
    const authInfo = this.loginContext.state.authInfo as AuthInfoToken;
    localStorage.removeItem('allUserData');
    localStorage.removeItem('userPending');
    localStorage.removeItem('redirect');
    localStorage.removeItem('i18nextLng');
    await this.loginContext.props.MSALInstance.logoutRedirect({
      account: authInfo && authInfo.account ? authInfo.account : null,
      postLogoutRedirectUri: '/',
    });
    this.removeKeysWithWebAPI();
  };

  removeKeysWithWebAPI() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if ((key && key.includes('webapi')) || key == 't') {
        localStorage.removeItem(key);
      }
    }
  }

  forgotPassword = async () => {
    try {
      this.loginContext.setState({ loading: true });
      const resetPasswordResponse = await this.loginContext.props.MSALInstanceResetPassword.loginRedirect(
        this.loginContext.props.MSAL_CONFIG.loginRequest
      );
      await this.handleResponse(resetPasswordResponse);
    } catch (error) {
      await this.login();
    }
  };

  login = async () => {
    try {
      this.loginContext.setState({ loading: true });
      this.removeKeysWithWebAPI();
      const loginResponse = await this.loginContext.props.MSALInstance.loginRedirect(
        this.loginContext.props.MSAL_CONFIG.loginRequest
      );
      await this.handleResponse(loginResponse);
    } catch (error) {
      await this.logout();
    }
  };

  handleResponse = async (response) => {
    /**
     * To see the full list of response object properties, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
     */
    if (response !== null) {
      let account = response;
      if (account.account.homeAccountId.indexOf('passwordreset') > 0) {
        this.loginContext.setState({ isAuthenticated: false });
        await this.logout();
        return;
      } else if (account.account.homeAccountId.indexOf('passwordchange') > 0) {
        this.loginContext.setState({ isAuthenticated: false });
        await this.logout();
        return;
      } else {
        this.loginContext.setState({
          isAuthenticated: Boolean(account),
          authInfo: account,
          loading: false,
        });
        return;
      }
    } else {
      this.loginContext.setState({ isAuthenticated: false });
      await this.selectAccount();
    }
  };

  changePassword = async () => {
    try {
      this.loginContext.setState({ loading: true });
      const changePasswordResponse = await this.loginContext.props.MSALInstanceChangePassword.loginRedirect(
        this.loginContext.props.MSAL_CONFIG.loginRequest
      );
      await this.handleResponse(changePasswordResponse);
    } catch (error) {
      await this.login();
    }
  };

  editProfile = async () => {
    try {
      this.loginContext.setState({ loading: true });
      const editProfileResponse = await this.loginContext.props.MSALInstanceEditProfile.loginRedirect(
        this.loginContext.props.MSAL_CONFIG.loginRequest
      );
      await this.handleResponse(editProfileResponse);
    } catch (error) {
      window.location.href = '/';
    }
  };

  // TODO move to LoginContainer when migrate to Vite
  refreshUserData = async () => {
    localStorage.removeItem('allUserData');
    const newDataUser = await this.loginContext.getAllUserData();
    if (newDataUser && !_.isEqual(newDataUser, this.loginContext.state.userData)) {
      localStorage.setItem('allUserData', JSON.stringify(newDataUser));
      this.loginContext.setStateIfIsDifferent('userData', newDataUser);
      return newDataUser;
    }
  };

  refreshInvitationsAndRequestsPending = async () => {
    localStorage.removeItem('userPending');
    const newUserPending = await this.loginContext.getInvitationsAndRequestsPendingReceived();
    if (newUserPending && !_.isEqual(newUserPending, this.loginContext.state.userPending)) {
      localStorage.setItem('userPending', JSON.stringify(newUserPending));
      this.loginContext.setStateIfIsDifferent('userPending', newUserPending);
    }
    return newUserPending;
  };

  setNewLanguage = async (lang: string) => {
    if (lang !== this.loginContext.state.userPreferences?.language) {
      this.loginContext.patchLocale(lang);
      await this.loginContext.updateLanguageAndMoment(lang);
      this.loginContext.setStateIfIsDifferent('userPreferences', { ...this.loginContext.state.userPreferences!, language: lang });
    }
  };

  setNewMeasures = async (measures: UserMeasure[]) => {
    if (measures !== this.loginContext.state.userPreferences?.measures) {
      this.loginContext.setStateIfIsDifferent('userPreferences', { ...this.loginContext.state.userPreferences!, measures });
    }
  };

  fullScreen = (status = true) => {
    this.loginContext.setState({ fullScreen: status });
  };

  manageLocalStorage = (action: string, keyName: string, value?: string) => {
    if (action === 'get') {
      return localStorage.getItem(keyName);
    } else if (action === 'set') {
      localStorage.setItem(keyName, String(value));
    }
  };
  //** */
}

export default AuthGate;
