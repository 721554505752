import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

class CustomSelect extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, value: this.props.parameterValue};
    }

    componentDidMount() {
        this.setState({ value: this.props.parameterValue });
    }

    render() {
        let dataLoaded = false;
        if (this.props.paramObj != null) {
            dataLoaded = true;
        //    this.setState();
        }
        const { t } = this.props;

        const handleChange = (evnt) => {
            this.setState({ value: evnt.target.value });
            if (this.props.onChangeSelect) this.props.onChangeSelect(evnt.target.value, this.props.paramObj.id);
            if (this.props.toSend) this.props.toSend(this.props.paramObj, evnt.target.value);
            //Call parent onchange
            if (typeof this.props.onChange === 'function') {
                this.props.onChange('select');
            }
        };

        
        const handleDisabledClick = (disabled)=>{
            if(this.props.handleDisabledMessage && disabled){
                this.props.handleDisabledMessage(disabled);
            }
        }

        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
            let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');

            try {
                let style = {}
                if (this.props.propStyle)
                    style = this.props.propStyle
                return (
                    <FormControl variant="standard">
                        <Typography className="t2" id={'ld-' + this.props.paramObj.businessLabel} gutterBottom>
                            {name}
                        </Typography>
                        {this.props.paramObj.infoText ?
                            <div className='info-tooltip-parameter customSelect'>
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        :""}
                        <div onClick={() => handleDisabledClick({deviceSetting:this.props.paramObj.isDeviceSettingsMode, readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
                            <Select
                                variant="standard"
                                style={{ marginBottom: '5px', maxWidth: '100%' }}
                                labelId="demo-mutiple-name-label"
                                id={'d' + this.props.paramObj.businessLabel}
                                value={this.state.value}
                                onChange={handleChange}
                                disabled={ disabled }>
                                {(this.props.paramObj.values.map((value, p) => (
                                    <MenuItem key={p} value={value.value}>{value.label}</MenuItem>
                            )))}
                            </Select>
                        </div>
                    </FormControl>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomSelect)