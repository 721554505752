import Style from '../styles/EmptyBarChartView.module.scss';
import { EmptyBarchartProps } from '../types/ChartsComponents';
import ReactECharts from 'echarts-for-react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptySankeyChartView = (props: EmptyBarchartProps) => {
  const option = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      custom: '`${params.data.name}<br /> ${params.data.value} ${units} - (${params.data.percentage}%)<br />`',
    },
    series: [
      {
        type: 'sankey',
        data: [
          {
            id: 'Life test \r\nTwintronic / Curvetronic - 65659.054',
            name: 'Twintronic / Curvetronic',
            color: '#9B2226',
            value: '65659.05',
            percentage: 33.91,
            children: false,
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
          {
            id: 'Life test \r\nShowertronic V1 - 62415.851',
            name: 'Showertronic V1',
            value: '62415.85',
            percentage: 32.24,
            children: false,
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
          {
            id: 'Life test \r\nLema - 65538.828',
            name: 'Lema',
            color: '#A8DADC',
            value: '65538.83',
            percentage: 33.85,
            children: false,
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
          {
            id: 'Life test ',
            name: 'Life test ',
            color: '#ffa500',
            value: '193613.83',
            percentage: 100,
            children: true,
            itemStyle: {
              color: 'hsl(39, 100%, 75%)',
              borderColor: 'none',
              backgroundColor: 'none',
            },
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
          {
            id: 'IOT Lab',
            name: 'IOT Lab',
            color: '#008000',
            value: '193613.83',
            percentage: 100,
            children: true,
            itemStyle: {
              color: 'hsl(120, 100%, 75%)',
              borderColor: 'none',
              backgroundColor: 'none',
            },
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
          {
            id: 'Total',
            name: 'Total',
            value: '193613.83',
            percentage: 100,
            children: true,
            itemStyle: {
              color: 'hsl(355, 78%, 75%)',
              borderColor: 'none',
              backgroundColor: 'none',
            },
            label: {
              normal: {
                show: true,
                formatter: '-',
                rich: {
                  per: {
                    fontSize: 12,
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'none',
                    padding: [4, 4, 3, 4],
                    borderRadius: 4,
                  },
                },
              },
            },
          },
        ],
        links: [
          {
            target: 'Total',
            value: 193613.83,
            customId: 0,
            id: 'Total_11',
            name: 'Total',
            children: true,
            percentage: 100,
          },
          {
            source: 'Total',
            target: 'IOT Lab',
            color: '#008000',
            value: 193613.83,
            customId: 209,
            customType: 'site',
            id: 'IOT Lab_9',
            name: 'IOT Lab',
            children: true,
            percentage: 100,
          },
          {
            source: 'IOT Lab',
            target: 'Life test ',
            color: '#ffa500',
            value: 193613.83,
            customId: 544,
            customType: 'installation',
            id: 'Life test _7',
            name: 'Life test ',
            children: true,
            percentage: 100,
          },
          {
            source: 'Life test ',
            target: 'Life test \r\nTwintronic / Curvetronic - 65659.054',
            color: '#9B2226',
            value: 65659.05,
            customId: 18,
            customInstallation: 544,
            customSite: 209,
            customType: 'product',
            id: 'Life test \r\nTwintronic / Curvetronic - 65659.054_1',
            name: 'Twintronic / Curvetronic',
            children: false,
            percentage: 33.91,
          },
          {
            source: 'Life test ',
            target: 'Life test \r\nLema - 65538.828',
            color: '#A8DADC',
            value: 65538.83,
            customId: 20,
            customInstallation: 544,
            customSite: 209,
            customType: 'product',
            id: 'Life test \r\nLema - 65538.828_5',
            name: 'Lema',
            children: false,
            percentage: 33.85,
          },
          {
            source: 'Life test ',
            target: 'Life test \r\nShowertronic V1 - 62415.851',
            value: 62415.85,
            customId: 19,
            customInstallation: 544,
            customSite: 209,
            customType: 'product',
            id: 'Life test \r\nShowertronic V1 - 62415.851_3',
            name: 'Showertronic V1',
            children: false,
            percentage: 32.24,
          },
        ],
        height: '90%',
        layoutIterations: 0,
        emphasis: {
          focus: 'adjacency',
        },
        lineStyle: {
          curveness: 0.5,
          color: 'source',
        },
        nodeAlign: 'right',
        nodeGap: 35,
        width: '75%',
      },
    ],
    chart: {
      chartType: 'sankey',
      type: 'kpiFamily',
      library: 'echarts',
      units: 'm³',
      granularity: 'day',
      groupBy: 'day',
      calculation: 'sum',
      title: 'Distribution of water consumption by site, installations, and product types',
      name: 'WaterM3',
      brandCode: 11,
      filterByBrandCode: true,
      groupByEcharts: 'week',
      aggregateBy: 'site',
      page: 'site',
      dateRange: ['2024-05-05T22:00:00.331Z', '2024-05-12T21:59:59.331Z'],
      kpiNameChartType: 'WaterM3-sankey',
      kpiReference: null,
      options: {
        title: {
          text: 'Distribution of water consumption by site, installations, and product types',
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: '{b} : {c}',
          custom: '`${params.data.name}<br /> ${params.data.value} ${units} - (${params.data.percentage}%)<br />`',
        },
        series: [
          {
            type: 'sankey',
            data: null,
            links: null,
            height: '701px',
            layoutIterations: 0,
            emphasis: {
              focus: 'adjacency',
            },
            levels: [
              {
                depth: 0,
                itemStyle: {
                  color: '#fbb4ae',
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6,
                },
              },
              {
                depth: 1,
                itemStyle: {
                  color: '#b3cde3',
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6,
                },
              },
              {
                depth: 2,
                itemStyle: {
                  color: '#ccebc5',
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6,
                },
              },
              {
                depth: 3,
                itemStyle: {
                  color: '#decbe4',
                },
                lineStyle: {
                  color: 'source',
                  opacity: 0.6,
                },
              },
            ],
            lineStyle: {
              curveness: 0.5,
              color: 'source',
            },
            nodeAlign: 'right',
            nodeGap: 35,
            width: '750px',
          },
        ],
      },
      telemetry: false,
      filters: {
        gender: [],
        type: [],
        area: [],
        environment: [],
        productTypeId: [],
        productId: [],
        familyId: [],
      },
      siteReportingPage: true,
    },
  };

  return (
    <div className={Style.EmptyBarChartView}>
      <div className='container-EmptyBarChartView'>
        <div className='box-error'>
          {props.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
          {props.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
          <p className='error-text'>{props.message}</p>
        </div>
        <div className='box-chart'>
          <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />
        </div>
      </div>
    </div>
  );
};

export default EmptySankeyChartView;
