import { t } from 'i18next';
import Style from '../styles/OrganizationView.module.scss';
import { ListUser } from '@ComponentsRoot/Management/types/User';
import Table from '../../../../CustomComponents/ReactTable/Table';

const OrganizationView = (props: { users: ListUser[] }) => {
  const columns = [
    {
      dataField: 'nameToShow',
      text: t('Name'),
      align: 'left',
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'email',
      text: t('Email'),
      align: 'left',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'createdDateTime',
      text: t('Created at'),
      align: 'left',
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'lastSignInDateTime',
      text: t('Last access'),
      align: 'left',
      sort: true,
      hidden: false,
      headerStyle: () => {
        return { width: '25%' };
      },
    }
  ];

  return (
    <div className={Style.organizationView}>
      <div className='container container-organizationView'>
        <Table
          name={'Management-Users'}
          rowLinks={true}
          t={t}
          resize={true}
          columns={columns}
          rows={props.users}
          search
          // page={this.state.page}
          pageSize={15}
          // RowDetail={this.RowDetail.bind(this)}
        />
      </div>
    </div>
  );
};

export default OrganizationView;