import Style from '../styles/EmptyBarChartView.module.scss';
import { EmptyBarchartProps } from '../types/ChartsComponents';
import ReactECharts from 'echarts-for-react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptyGaugeChartView = (props: EmptyBarchartProps) => {
  const option = {
    tooltip: {},
    legend: {
      show: false,
    },
    series: [
      {
        radius: '98%',
        type: 'gauge',
        name: '',
        itemStyle: {
          color: 'hsl(225, 100%, 75%)',
        },
        data: [
          {
            value: 0,
            name: 'Last Value',
          },
        ],
        center: ['50%', '75%'],
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 0.01,
        splitNumber: 1,
        progress: {
          show: true,
          width: 20,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 20,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#999',
          padding: [25, -35, 0, -30],
          fontSize: 10,
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          borderRadius: 8,
          fontSize: 15,
          lineHeight: 40,
          offsetCenter: [0, '-15%'],
          valueAnimation: true,
        },
        height: '200px',
        width: '200px',
      },
      {
        name: '',
        itemStyle: {
          color: 'hsl(225, 100%, 40%)',
        },
        data: [
          {
            value: 0,
            name: 'Average',
          },
        ],
        radius: '98%',
        type: 'gauge',
        center: ['50%', '50%'],
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 0.005,
        zlevel: 1,
        progress: {
          show: true,
          width: 5,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        title: {
          show: false,
        },
      },
    ],
  };

  return (
    <div className={Style.EmptyBarChartView}>
      <div className='container-EmptyBarChartView'>
        <div className='box-error'>
          {props.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
          {props.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
          <p className='error-text'>{props.message}</p>
        </div>
        <div className='box-chart'>
          <div className='gauges-chart-box'>
            <div className='gauge' key={1}>
              <ReactECharts style={{ height: '200px' }} option={option} />
            </div>
            <div className='gauge' key={2}>
              <ReactECharts style={{ height: '200px' }} option={option} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyGaugeChartView;
