import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, timeZoneOffset) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false;
        this.timeZoneOffset = timeZoneOffset ? timeZoneOffset: "00:00"        
    }

    addTimezone = (timeStamp) => {
      const sign = this.timeZoneOffset.includes('-') ? 'min' : 'plus';
      return `${timeStamp}${sign}${(this.timeZoneOffset.replace('+','').replace('-','')).substring(0, 5)}`;
    };

    ProductChartsDatapoints = async (id, kpiName, kpiType, groupBy, granularity, calculation, startTimeStamp, endTimeStamp, type, deviceUnits) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
            "x-device-units": JSON.stringify(deviceUnits)
          },
        };
        const url = `products/${this.language}/${id}/graphic-data-points/${kpiName}/${kpiType}/${groupBy}/${granularity}/${calculation}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${type}`;
        try {
          const response = await axios.get(url, config);      
          if (this.debug) {
            console.log("graphic-data-points: " + url);
            console.log("G2. " + id + " - " + kpiName + " - " + groupBy + " - " + granularity + " - " + calculation + " - " + this.addTimezone(startTimeStamp) + " - " + this.addTimezone(endTimeStamp));
            console.log("response: " + JSON.stringify(response));
          }      
          return response;
        } catch (e) {
          return e.response;
        }
    };      

    ProductChartsStatisticalMeasures = async (id, kpiName, kpiType, startTimeStamp, endTimeStamp, groupBy, filters) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
          }
        };      
        const url = `products/${this.language}/${id}/statistical-measures/${kpiName}/${kpiType}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${groupBy}`;
      
        try {
          const response = await axios.get(url, config);      
          if (this.debug) {
            console.log("statistical-measures: " + url);
            console.log("G2. " + id + " - " + kpiName + " - " + this.addTimezone(startTimeStamp) + " - " + endTimeStamp);
            console.log("response: " + JSON.stringify(response));
          }      
          return response;
        } catch (e) {
          return e.response;
        }
      }
      

}