import { t } from 'i18next';
import Form from 'react-bootstrap/Form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SelectSearch from 'react-select';
import HWHardwareContainer from '../container/HWHardwareContainer';
import DoneIcon from '@mui/icons-material/Done';
import { NoSmartComponent } from '../../../types/FirmwareTypes';
import { TableItemHWHardwareTable } from '../types/HWHardwareTypes';

export const filter = (
  filter: { label: string; value: number } | null,
  handleChangeFilter: HWHardwareContainer['handleChangeFilter']
) => {
  return (
    <>
      <h6 className='d-inline-block mr-3 mb-0 col-auto pl-0'>{t('tables.filter')}:</h6>
      <SelectSearch
        // options={filteredOptionsLabel.map(({ id, ...rest }) => rest)}
        options={[
          { label: 'No Smart Component', value: 1 },
          { label: 'Smart Component', value: 2 },
        ]}
        value={filter}
        className='device-custom-select-control'
        isClearable
        placeholder={t('firmwareManagementPage.smartComponentType')}
        onChange={handleChangeFilter}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
            borderRadius: '3px !important',
          }),

          valueContainer: (provided) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
          }),

          input: (provided) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '32px',
          }),
        }}
      />
    </>
  );
};

export const secondaryFilter = (
  filter: { label: string; value: string } | null,
  handleChangeSecondaryFilter: HWHardwareContainer['handleChangeSecondaryFilter'],
  options: NoSmartComponent[]
) => {
  let optionsFormat = options.map((option: NoSmartComponent) => ({ label: option.name, value: `${option.name}_${option.id}` }));
  return (
    <>
      {/* <h6 className='d-inline-block mr-3 mb-0 col-auto pl-0'>{t('tables.filter')}:</h6> */}
      <SelectSearch
        options={optionsFormat}
        value={filter}
        className='device-custom-select-control'
        isClearable
        placeholder={t('firmwareManagementPage.hardware')}
        onChange={handleChangeSecondaryFilter}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
            borderRadius: '3px !important',
          }),

          valueContainer: (provided) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
          }),

          input: (provided) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '32px',
          }),
        }}
      />
    </>
  );
};

const doneFormatter = (objCheck: TableItemHWHardwareTable) => {
  return objCheck.value && <DoneIcon />;
};

const checkFormatter =
  (patchAvailableCheck: HWHardwareContainer['patchAvailableCheck']) => (objCheck: TableItemHWHardwareTable) => {
    const { row, value, column } = objCheck;
    return (
      <Form>
        <Form.Check
          type='checkbox'
          id={`custom-switch_${row.id}`}
          label=''
          defaultChecked={value}
          disabled={column.name !== 'available'}
          onChange={(e) => patchAvailableCheck(e.target.checked, objCheck)}
        />
      </Form>
    );
  };

const deleteFormatter =
  (handleShowConfirmDeleteHWModal: HWHardwareContainer['handleShowConfirmDeleteHWModal']) =>
  (objCheck: TableItemHWHardwareTable) => {
    return (
      <DeleteOutlineIcon
        id={`btnDelete_${objCheck.row.id}`}
        style={{ width: '24px', cursor: 'pointer' }}
        onClick={() => handleShowConfirmDeleteHWModal(true, objCheck.row)}
      />
    );
  };

export const tableColumnsHWHardware = (
  patchAvailableCheck: HWHardwareContainer['patchAvailableCheck'],
  handleShowConfirmDeleteHWModal: HWHardwareContainer['handleShowConfirmDeleteHWModal']
) => [
  {
    dataField: 'name',
    text: t('firmwareManagementPage.hardware'),
    align: 'left',
    sort: true,
    headerStyle: () => {
      return { width: '20%' };
    },
  },
  {
    dataField: 'description',
    text: t('firmwareManagementPage.description'),
    align: 'left',
    sort: true,
    hidden: false,
    headerStyle: () => {
      return { width: '15%' };
    },
  },
  {
    dataField: 'isNoSmartComponentType',
    text: t('firmwareManagementPage.noSmartComponent'),
    align: 'center',
    formatter: doneFormatter,
    sort: true,
    headerStyle: () => {
      return { width: '20%' };
    },
  },
  {
    dataField: 'idType',
    text: t('firmwareManagementPage.typeIdComponent'),
    align: 'left',
    sort: true,
    headerStyle: () => {
      return { width: '20%' };
    },
  },
  {
    dataField: 'version',
    text: t('firmwareManagementPage.version'),
    align: 'left',
    sort: true,
    hidden: false,
    headerStyle: () => {
      return { width: '20%' };
    },
  },
  {
    dataField: 'available',
    text: t('firmwareManagementPage.available'),
    align: 'center',
    formatter: checkFormatter(patchAvailableCheck),
    sort: true,
    headerStyle: () => {
      return { width: '15%' };
    },
  },
  {
    dataField: 'actions',
    text: '',
    align: 'center',
    formatter: deleteFormatter(handleShowConfirmDeleteHWModal),
    headerStyle: () => {
      return { width: '15%' };
    },
  },
];
