import React from "react";
import { InstallationsSummaryViewProps } from "../types/InstallationsSummaryTypes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from '@mui/material/Button';
import { t } from "i18next";
import Moment from "react-moment";
import SinglePointMap from '../../../../SinglePointMap';
import '../../../../../App.css';
import '../../../../ProductsStyle.css';
const InstallationsSummaryView = (props: InstallationsSummaryViewProps) => {
    let dataLoaded = props.installation != null
    let boton;
    if (props.installation.hasOwnerAccess) {
        boton = <div className="edit-parent" ><Button className="primary float-right w-sm-100" onClick={() => props.handleClick()}> <img src={require('../../../../../assets/icons/edit-18.svg').default} /></Button></div>
    }

    let imageHeader;
    if (dataLoaded) {       
        if (props.installation.avatarUri != null) {
            imageHeader = props.installation.avatarUri;
        }
        else {
            imageHeader = props.isLaufen ? "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg" : "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/14_ROCA_Default_Installation.jpeg";
        }
    
    }

    let map ;
    if (props.installation && props.installation.longitude) {
        map = <SinglePointMap installation={props.installation} />
    }



    return (
        <React.Fragment>
                
                {dataLoaded ? (
                    <div>
                        <div className="row prodInfo" >
                            <div className="col-12">
                                <div className="row prodInfo" style={{ alignItems: "stretch", paddingBottom: "10px" }}>
                                    <div className="col-12 col-md-3 pr-md-3" style={{padding:'0'}}>
                                        <div  style={{
                                            backgroundImage: "url('" + imageHeader +"')",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            minHeight: "200px",
                                            height: '100%',
                                            width: '100%'
                                        }}
                                            className="customImageSize"
                                        >
                                    </div>   
                                       
                                    </div>
                                    <div className="col-12 col-md-9  mt-3 mt-md-0 pt-2">
                                        <div className="row mb-2">
                                            <h2 className="mb-0 col">{props.installation.name}</h2>
                                            {boton}
                                        </div>
                                        <div className="row summaryInfo " style={{ height: "100%" }}>
                                            
                                            <div className="col-12 col-md-4 " style={{ position: "relative", alignSelf: "top" }}>
                                                {getNotifications(props)}
                                                <div className="row pt-2" style={{}}>
                                                   
                                                    {getStatusList("Online", props.onlineProducts, t('tooltips.activeProducts'))}
                                                    {getStatusList("App", props.appProducts, t('tooltips.appProducts'))}
                                                    {getStatusList("Disconnected", props.disconnectedProducts, t('tooltips.unactiveProducts'))}
                                                    
                                                </div>
                                               
                                                <div className="row pt-3">
                                                    <div className="col-12">
                                                        <div className="row m-0">
                                                        
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('actions.devices'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../../../assets/icons/11_00.03_black.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {props.aggregatedInfo.totalDevices} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('users'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../../../assets/icons/PersonOutlineIcon.svg').default} />

                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {props.aggregatedInfo.totalUsers} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('gateways'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../../../assets/icons/11_00.31_black.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {props.aggregatedInfo.gatewayDevices} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installations'))}>
                                                                    <div className="iconParent">
                                                                        <img src={require('../../../../../assets/icons/BusinessIcon.svg').default} />
                                                                        <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {props.aggregatedInfo.totalInstallations} </div>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                         </div>
                                                    </div>
                                                </div>
                                                <h6 className="pt-3 d-none">IOT DATA:</h6>
                                                <div className="t2 d-none"> {props.installation.name}</div>
                                                <div className="t2 d-none"> <b>{t('installationspages.installationsummary.contractnumber')}: </b>--------</div>
                                                
                                            </div>
                                          
                                            <div className="col-12 col-md-5 pt-3 pt-md-0 spacebar" style={{ alignSelf: "top" }}>
                                               
                                                <h6 className="pb-2"> {t('installationspages.installationsummary.address')}: </h6>
                                                <div className="t2"> {props.address}  </div>
                                                <div className="t2"> <b>{t('installationspages.installationsummary.registered')}:</b> <Moment format="D MMM YYYY HH:mm" withTitle locale="es">{props.installation.createdAt}</Moment> </div>

                                               
                                            </div>
                                            <div className="col-12 col-md-3 pt-3 pt-md-0 spacebar"  style={{ alignSelf: "top"  }}>
                                                <h6 className="pb-2">{t('installationspages.installationsummary.contact')}:</h6>
                                                <div className="t2">{props.installation.managerMail}</div>
                                                <div className="t2">{props.installation.managerPhone}</div>
                                                <div className="t2">{props.installation.managerName}</div>
                                                <div className="row d-none">
                                                    <div className="col-12">
                                                        { map }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="loadingParent"><div className="loadingImg"></div></div>
                    )}
            </React.Fragment>
    );
  };

  const renderTooltip = (text) => (props) => (
    <Tooltip id="button-tooltip" {...props}>
        {text}
    </Tooltip>
);

const getStatusList = (type, total, text) => {
    let t = type.toLowerCase();
    if (total <= 0) {
        return;
    }
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 d-flex" style={{ alignItems: "center" }} >
                    <img src={require('../../../../../assets/icons/dot.svg').default} className={"iconStatusList bullet-" + t} height="10" width="10" />
                    <span className="pl-2 t2 bold m-0">{total + " " + text}</span>
                </div>

            </div>
        </div>

    );
}

const getNotifications = (props) => {
    const types = ['alarm', 'warning', 'rule'];
    const names = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
    const name = { "rule": t("productspages.alertreporting.rule"), "alarm": t("productspages.alertreporting.alarm"), "warning": t("productspages.alertreporting.warning") };

    if (props.notifications == undefined || props.notifications == null) {
        return null;
    } else if (props.notifications.length <= 0) {
        return null;
    }
    let notifications = props.notifications[0].alerts;

    let arr:any[] = [];
    types.forEach(element => {
        let data = notifications.find(o => o.type === element);
        if (data == null) {
            return;
        }
        let total = data.total != null ? data.total : 0;

        if (total <= 0) { return }

        let key = 0;

        arr.push(
            <div key={key} className="col-auto pl-0 pr-1">
                <span className={"custom-button-span " + element} style={{ whiteSpace: "nowrap" }}>
                    {total + " " + (total > 1 ? names[element] : name[element])}
                </span>
            </div>);

    });

    let ret = <div className="row">
        <div className="col-12 ">
            <div className="row m-0 listNotificationsItems">
                {arr}
            </div>
            
        </div>
    </div>;

    return ret;
}
  
  export default InstallationsSummaryView;