import React, { Component } from 'react';
import '../../../../App.css';
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

class ProductsTechnicalInformationTechInfo extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        const { t } = props;
        this.title = t("productspages.technical.techDocuments");
        this.documents = [];
        this.handleClick = this.handleClick.bind(this);
        this.corregido = false;
        this.url = this.props.data.brandCode == "11" ? "https://www.laufen.com/LaufenResourceServlet/resources/getMainResource?idObject=" : "https://www.roca.com/RocaResourceServlet/resources/getMainResource?idObject=";
    }


    handleClick(e, docUrl) {
        window.open(docUrl);
    }

    corregirNombreIcono(datos) {
        let ruta = 'file';
        let iconovacio = 'document-24.svg';
        if (this.corregido) //si ya han sido corregido devuelvo el array tal cual.
            return datos;
                          
        let datosSalida = datos;
        //const modifiedArr = datos.map(extension => `$ruta{replace(extension,',','-')}man`);
        for (let i = 0; i <= datosSalida.length - 1; i++) {

            if (datosSalida[i].code == "BIMArchicad") {
                iconovacio = 'file-gsmr-24.svg';
            }
            if (datosSalida[i].code == "BIMRevid") {
                iconovacio = 'file-rfa-24.svg';
            }
            if (datosSalida[i].extension == ".ai") {
                datosSalida[i].extension = iconovacio;
            }
            if (datosSalida[i].extension != undefined) {
                if (datosSalida[i].extension.length < 5) {// Si ya ha sido corregido no lo tocamos
                    if (datosSalida[i].extension != "") {
                        let icono = datosSalida[i].extension.replace('.', '-') + '-24.svg';
                        datosSalida[i].extension = ruta + icono;
                    }
                    else {
                        datosSalida[i].extension = iconovacio;
                    }
                }
            }
            else {
                datosSalida[i].extension = iconovacio;
            }
        }
        return datosSalida;
    }

    drawFileIcon(path){
        return (<img src={require(path)} />)
    }

    dibujarElemento(doc, i) {
        {
            
            if (doc.code == -1) {
                
                return (<div key={doc.code} className="row prodInfo w-100" style={{ margin: '15px', cursor: 'pointer' }}>
                    <img src={require(`../../../../assets/imgs/bim-logo.png`)}  />
                </div>)
            }
            else {
                return(<div key={doc.code} className="row prodInfo w-100" style={{ margin: '15px', cursor: 'pointer' }}>
                    <img src={require(`../../../../assets/icons/${doc.extension}`)} style={{ width: '24px', marginTop: '-19px' }} />
                    <h4 className="animateHover" style={{ marginLeft: '5px' }} onClick={(e) => this.handleClick(e, doc.documentURL)}>{doc.name} - <span className="t3"> ({doc.fileLength == null ? "" : doc.fileLength}) </span></h4>
                </div>)
                
            }
        }
    }

    //////////////////////////////////////////


    accordionBody(title, details) {
        let r = <div className="mb-3" key={title}>
            <Accordion classes={{ root: 'accordion-root-custom' }}>
                <AccordionSummary
                    expandIcon={<img src={require('../../../../assets/icons/chevron-down.svg').default} style={{ width: '24px' }} />}
                    aria-controls="panel1a-content" ><h3>{title}</h3>
                </AccordionSummary>
                <AccordionDetails style={{ justifyContent: "center" }}>
                    {details}
                </AccordionDetails>
            </Accordion>
        </div>
        return r;
    }

    itemFileName(file) {
        let ruta = 'https://strgpiotcoredev.blob.core.windows.net/spareparts-icons/file';
        let iconovacio = 'https://strgpiotcoredev.blob.core.windows.net/spareparts-icons/document-24.svg';
        let doc = iconovacio;

        if (file.code == "BIMArchicad") {
            doc = ruta + '-gsmr-24.svg';
        }
        if (file.code == "BIMRevid") {
            doc = ruta + '-rfa-24.svg';
        }
        if (file.fileName != undefined) {
            let extension = file.fileName.split(".");

            if (extension.length > 0) {
                extension = "." + extension[extension.length-1];
            } else {
                extension = undefined;
            }

            if (extension != undefined &&  extension != ".ai") {
                
                if (extension != "") {
                    let icono = extension.replace('.', '-') + '-24.svg';
                    doc = ruta + icono;
                }
                else {
                    doc = iconovacio;
                }
            }
            else {
                doc = iconovacio;
            }
        }
        

        return doc;
    }

    installationMaintenance(title) {
        //get data to print elements
        let manuals = this.props.data.manuals != undefined ? this.props.data.manuals : [];
        let videos = this.props.data.installationVideos != undefined ? this.props.data.installationVideos : [];
        let manualsList = [];
        let videosList = [];
        let retBody = [];
        let keyId = 0;
        manuals.forEach((e) => {
            //Add all pdf manuals to list
            let url = this.url + e.code;

            let elem =
                <div className="col-12 col-sm-6 col-lg-4" key={keyId}>
                    <a href={url} target="_blank">
                        <h6>
                            <img src={this.itemFileName(e)} style={{ width: "24px" }} />
                            <span className="t3 pl-2" style={{ textTransform: "none" }}>{e.name + " (" + e.fileLength + ")"} </span>
                        </h6>
                    </a>
                </div>;
            manualsList.push(elem);
            keyId++;
        });

        videos.forEach((e) => {
            //Add all video manuals to list
            let url = e.url + "&modestbranding=1&controls=1"; //We add modestbranding to hidde youtube elements

            let elem =
                <div className="col-6 col-sm-4 col-lg-3" key={keyId}>
                    <iframe src={url} style={{ width: "100%" }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    <h6 style={{ overflowWrap: "anywhere" }}>{e.title}</h6>

                </div>;
            videosList.push(elem);
            keyId++;
        });

        if (manualsList.length > 0) {
            //Check if we should add manuals to body return
            retBody.push(<div key={keyId} className="row w-100 mb-4"> {manualsList} </div>);
            keyId++;
        }
        if (videosList.length > 0) {
            //Check if we should add videos to body return (if so add title too)
            retBody.push(<div key={keyId}  className="row w-100 mb-3">
                <h4 className="col-12">Videos</h4>
            </div>);
            keyId++;
            retBody.push(<div key={keyId} className="row w-100 mb-4"> {videosList} </div>);
            keyId++;
        }

        let body = (<div className="row col-12  mx-0">
            {retBody}
        </div>);


        if (retBody.length <= 0) {
            return null;
        }

        return this.accordionBody(title, body);
    }

    technicalDrawings(title) {
        //get data to print elements
        let vTechnicalDrawings = this.props.data.technicalDrawings != undefined ? this.props.data.technicalDrawings : [];
        let technicalList = [];
        let retBody = [];
        let keyId = 0;
        let images = [];
        vTechnicalDrawings.forEach((e) => {
            //Add all pdf Technical Drawings to list
            let url = this.url + e.code;
            let elem = null;
            if (e.fileName) {
                let extension = e.fileName.split(".");
                extension = extension[extension.length - 1];
                if (extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'png') {
                    elem =
                        <div className="col-6 col-sm-4 col-lg-3" key={keyId}>
                            <img src={url} alt={e.name} style={{ width: "100%" }} />

                        </div>;
                    images.push(elem);
                }
            }
            if (elem == null) {
                elem =
                    <div className="col-12 col-sm-6 col-lg-4" key={keyId}>
                        <a href={url} target="_blank">
                            <h6>
                                <img src={this.itemFileName(e)} style={{ width: "24px" }} />
                                <span className="t3 pl-2" style={{ textTransform: "none" }}>{e.name + " (" + e.fileLength + ")"} </span>
                            </h6>
                        </a>
                    </div>;
                technicalList.push(elem);
            }
                      
            keyId++;
        });
        if (technicalList.length > 0) {
            //Check if we should add Technical Drawings to body return
            retBody.push(<div className="row w-100 mb-4" key={keyId}> {technicalList} </div>);
            keyId++;
        }
        if (images.length > 0) {
            /*
            retBody.push(<div key={keyId} className="row w-100 mb-3">
                <h4 className="col-12">Images</h4>
            </div>);
            keyId++;
            */
            retBody.push(<div key={keyId} className="row w-100 mb-4"> {images} </div>);
            keyId++;
        }

        let body = (<div className="row col-12  mx-0">
            {retBody}
        </div>);

        if (retBody.length <= 0) {
            return null;
        }

        return this.accordionBody(title, body);
    }
    resource2D3DItem(e, index) {
        let url = this.url + e.code;


        let elem =
            <div className="col-2" key={index}>
                <a href={url} target="_blank">
                    <h6>
                        <img src={this.itemFileName(e)} style={{ width: "24px" }} />
                    </h6>
                </a>
            </div>;
        return elem;
    }
    resources2D3D(title) {

        let front = this.props.data.drawings2DFront != undefined ? this.props.data.drawings2DFront : [];
        let top = this.props.data.drawings2DTop != undefined ? this.props.data.drawings2DFront : [];
        let side = this.props.data.drawings2DSide != undefined ? this.props.data.drawings2DFront : [];
        let v3d = this.props.data.drawings3D != undefined ? this.props.data.drawings3D : [];
        let frontList = [], topList = [], sideList = [], ret2D = [], retBody = [], v3dList = [];
        let keyId = 0;
        front.forEach((e, index) => {
            frontList.push(this.resource2D3DItem(e, index));
        });

        side.forEach((e, index) => {
            sideList.push(this.resource2D3DItem(e, index));
        });
        top.forEach((e, index) => {
            topList.push(this.resource2D3DItem(e, index));
        });
        v3d.forEach((e, index) => {
            v3dList.push(this.resource2D3DItem(e, index));
        });
        let idR = 0;
        if (frontList.length > 0) {
            ret2D.push(
                <div className="row pb-1" key={keyId}>
                    <div className="col-3">
                        <h6>Front</h6>
                    </div>
                    {frontList}
                </div>
            );
            keyId++;
        }
        if (topList.length > 0) {
            ret2D.push(
                <div className="row pb-1" key={keyId}>
                    <div className="col-3">
                        <h6>Top</h6>
                    </div>
                    {topList}
                </div>
            );
            keyId++;
        }
        if (sideList.length > 0) {
            ret2D.push(
                <div className="row pb-1" key={keyId}>
                    <div className="col-3">
                        <h6>Side</h6>
                    </div>
                    {sideList}
                </div>
            );
            keyId++;
        }
       

        if (ret2D.length > 0) {
            retBody.push(
                <React.Fragment key={keyId}>
                    <div className="row pb-2">
                        <div className="col-12">
                            <h4>2D</h4>
                        </div>
                    </div>
                    {ret2D}
                </React.Fragment>
            );
            keyId++;
        }
        if (v3dList.length > 0) {
            retBody.push(
                <div className="row pt-3" key={keyId}>
                    <div className="col-3">
                        <h4>3D</h4>
                    </div>
                    {v3dList}
                </div>
            );
            keyId++;
        }

        let body = (<div className="row col-12 mb-4  mx-0" >
            <div className="col-12 col-md-6">
                {retBody}
            </div>
        </div>);


        if (retBody.length <= 0) {
            return null;
        }

        return this.accordionBody(title, body);
    }
    BIMS(title) {
        let bims = this.props.data.bims != undefined ? this.props.data.bims : [];
        let bimImg = <img src={require(`../../../../assets/imgs/bim-logo.png`)} />
        let retBody = [];

        bims.forEach((e,index) => {
            //Add all pdf Technical Drawings to list
            let url = e.bimUrl;

            let elem =
                <div className="col-12 col-sm-6 col-lg-4" key={index}>
                    <a href={url} target="_blank">
                        <h6>
                            <img src={ this.itemFileName(e.bimAttribute)} style={{ width:"24px" }} />
                            <span className="t3 pl-2" style={{ textTransform: "none" }}>{e.bimAttribute.label.replace("BIM","")} </span>
                        </h6>
                    </a>
                </div>;
            retBody.push(elem);
        });

        let body = (<div className="row col-12 mb-4 mx-0">
            <div className="row w-100">
                <div className="col-12">
                    {bimImg}
                </div>
               
            </div>
            <div className="row w-100 pt-4">
                {retBody}
            </div>
        </div>);

        if (retBody.length <= 0) {
            return null;
        }

        return this.accordionBody(title, body);
    }

    printContent(t) {
        let content = [];
        let val = null;
        if (this.props.installation != undefined) {
            val = this.installationMaintenance(t('productspages.technical.installationandmaintenance'));
            if (val != null) content.push(val);
        }
        if (this.props.drawings != undefined) {
            val = this.technicalDrawings(t('productspages.technical.technicaldrawings'));
            if (val != null) content.push(val);
        }
        if (this.props.resources2D3D != undefined) {
            val = this.resources2D3D(t('productspages.technical.resources2d3d'));
            if (val != null) content.push(val);
        }
        if (this.props.BIMS != undefined) {
            val = this.BIMS("BIMS");
            if (val != null) content.push(val);
        }
        if(content){
            this.props.hasInfo(true);
        }
        return content;

    }


    render() {
        const { t } = this.props;
        let dataLoaded = this.props.product.content != undefined;
        this.documents = [];
        let texto = '';
        
        if (dataLoaded) {
            if (this.props.product.content.manuals != null && this.props.product.content.manuals.length>0) {
                texto = t('productspages.technical.installationandmaintenance');
                this.documents.push({ textoCabecera: texto, datos: this.corregirNombreIcono(this.props.product.content.manuals) });
            }
            if (this.props.product.content.technicalDrawings != null && this.props.product.content.technicalDrawings.length > 0) {
                texto = t('productspages.technical.technicaldrawings');
                
                this.documents.push({ textoCabecera: texto, datos: this.corregirNombreIcono(this.props.product.content.technicalDrawings) });
            }
            if (this.props.product.content.resources2D3DGroup != null && this.props.product.content.resources2D3DGroup) {
                texto = t('productspages.technical.resources2d3d');
                this.documents.push({ textoCabecera: texto, datos: this.corregirNombreIcono(this.props.product.content.resources2D3DGroup)});
            }
            
            if (this.props.product.content.bims!= null && this.props.product.content.bims.length > 0) {
                texto = "BIMS"
                this.documents.push({ textoCabecera: texto, datos: this.corregirNombreIcono(this.props.product.content.bims) });
                if (!this.corregido)
                    this.documents[this.documents.length -1].datos.unshift({ code: -1, extension: "bim-logo-png" });                
            }
            this.corregido = true;
        }

        //////////////////////////////////////////////////////////////////////////////////////////

        


        return (

            this.printContent(t)
            /*
            dataLoaded ? (
                <div>
                    <div className="row">
                        <div className="col technicalInfo">
                            <h2 > {this.title} </h2>
                            <div>
                                {this.documents.map((item, i) => (
                                    (item.datos !== undefined && item.datos.length > 0) ?
                                        <Accordions>
                                            <AccordionsSummary
                                                expandIcon={<img src={require('../../../../assets/icons/chevron-down.svg')} style={{ width: '24px'}} />}
                                                aria-controls="panel1a-content"
                                            >
                                                <h3>{item.textoCabecera}</h3>
                                            </AccordionsSummary>
                                            <AccordionDetail>
                                                {item.datos.map((doc, i) => (
                                                    this.dibujarElemento(doc, i)

                                                ))}
                                            </AccordionDetail>
                                        </Accordions>
                                        : (null)
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
            <div className="loadingParent"><div className="loadingImg"></div></div>
           
        )
         */
    );
  }
}
export default withTranslation()(ProductsTechnicalInformationTechInfo);