import { PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import {
  ModalInstallationLocationZoomContainerProps,
  ModalInstallationLocationZoomContainerState,
} from '../types/ModalInstallationLocationZoomTypes';
import ModalInstallationLocationZoomView from '../view/ModalInstallationLocationZoomView';
import ZoomInstallationsLayout from '../../../Tabs/ZoomInstallationsLayout';

import { useState } from 'react';
// import Style from '../styles/ModalInstallationLocationZoomView.module.scss';
import Style from '../styles/ModalInstallationLocationZoomView.module.scss';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AutoScale2 from '../../../../AutoScale2';
import { t } from 'i18next';
import { ModalInstallationLocationZoomViewProps } from '../types/ModalInstallationLocationZoomTypes';
import { CSSProperties } from 'react';
import NoData from '../../../../NoData';

class ModalInstallationLocationZoomContainer extends PureComponent<
  ModalInstallationLocationZoomContainerProps,
  ModalInstallationLocationZoomContainerState
> {
  static contextType = AuthenticationContext;

  constructor(props: ModalInstallationLocationZoomContainerProps) {
    super(props);

    this.state = {
      showModal: this.props.showModal,
    };
  }

  componentDidMount() {}

  componentChanged() {}

  handleShowModal = (status) => {
    this.props.handleShowModal(status);
  };

  navigateItemDetail(id) {
    let url = '/product/' + id;
    //this.props.navigate(url)
  }

  render() {
    return (
      <>
        <Modal size='lg' show={this.props.showModal} onHide={() => this.props.handleShowModal(false)} className='zoomContainer'>
          <Modal.Body className={Style.ModalInstallationLocationZoomView}>            
            <ZoomInstallationsLayout {...this.props} />
          </Modal.Body>
          <Modal.Footer>
            <Button className='primary' onClick={() => this.props.handleShowModal(false)}>
              {t('actions.close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalInstallationLocationZoomContainer;
