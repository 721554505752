import { t } from 'i18next';
import Form from 'react-bootstrap/Form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SelectSearch from 'react-select';
import HWPackageContainer from '../container/HWPackageContainer';
import { TableItemHWHardwareTable } from '../../HardwareManagement/types/HWHardwareTypes';
import { HardwarePackage, TableItemHWPackageTable } from '../types/HWPackageTypes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const filter = (
  filter: { label: string; value: string } | null,
  handleChangeFilter: HWPackageContainer['handleChangeFilter'],
  options: HardwarePackage[] | []
) => {
  const optionsFormat = options
    .filter((option) => option.systemId !== undefined)
    .map((option) => ({ label: option.systemId as string, value: option.systemId as string }));

  return (
    <>
      <h6 className='d-inline-block mr-3 mb-0 col-auto pl-0'>{t('tables.filter')}:</h6>
      <SelectSearch
        options={optionsFormat ? optionsFormat : []}
        value={filter}
        className='device-custom-select-control'
        isClearable
        placeholder={t('firmwareManagementPage.systemId')}
        onChange={handleChangeFilter}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
            borderRadius: '3px !important',
          }),

          valueContainer: (provided) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
          }),

          input: (provided) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '32px',
          }),
        }}
      />
    </>
  );
};

export const rowDetail: React.FC<{ row: HardwarePackage }> = ({ row }) => {
  return (
    <div className='row pl-5'>
      {row.noSmartComponents?.length ? (
        <div className='components-box'>
          <div className='smart-compos'>
            <h6>SMART COMPONENT HARDWARES</h6>
            {row.noSmartComponents
              .filter((noSmartCompo) => noSmartCompo.smartComponentType)
              .map((noSmartCompo) => (
                <div className='col-12 pb-1 pl-2'>
                  <span className='t3'>{`${noSmartCompo.name} -> ${noSmartCompo.version}`}</span>
                </div>
              ))}
          </div>
          <div className='nosmart-compos'>
            <h6>NO SMART COMPONENT HARDWARE</h6>
            {row.noSmartComponents
              .filter((noSmartCompo) => noSmartCompo.noSmartComponentType)
              .map((noSmartCompo) => (
                <div className='col-12 pb-1 pl-2'>
                  <span className='t3'>{`${noSmartCompo.name} -> ${noSmartCompo.version}`}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const checkFormatter = (patchCheck: HWPackageContainer['patchCheck']) => (objCheck: TableItemHWHardwareTable) => {
  const { row, value, column } = objCheck;
  return (
    <Form>
      <Form.Check
        type='checkbox'
        id={`custom-switch_${row.id}`}
        label=''
        defaultChecked={value}
        disabled={column.name !== 'available'}
        onChange={(e) => patchCheck(e.target.checked, objCheck, column.name)}
      />
    </Form>
  );
};

const actionsFormatter =
  (
    handleShowConfirmDeleteHWModal: HWPackageContainer['handleShowConfirmDeletePackageModal'],
    handleEditPackage: HWPackageContainer['handleEditPackage']
  ) =>
  (objCheck: TableItemHWPackageTable) => {
    return (
      <>
        <EditOutlinedIcon
          className='edit-button'
          onClick={() => handleEditPackage(objCheck.row)}
        />

        <DeleteOutlineIcon
          id={`btnDelete_${objCheck.row.id}`}
          style={{ width: '24px', cursor: 'pointer' }}
          onClick={() => handleShowConfirmDeleteHWModal(true, objCheck.row)}
        />
      </>
    );
  };

export const tableColumnsHWPackage = (
  patchCheck: HWPackageContainer['patchCheck'],
  handleShowConfirmDeleteHWModal: HWPackageContainer['handleShowConfirmDeletePackageModal'],
  handleEditPackage: HWPackageContainer['handleEditPackage']
) => [
  {
    dataField: 'name',
    text: t('firmwareManagementPage.package'),
    align: 'left',
    sort: true,
    headerStyle: () => {
      return { width: '20%' };
    },
  },
  {
    dataField: 'description',
    text: t('firmwareManagementPage.description'),
    align: 'left',
    sort: true,
    hidden: false,
    headerStyle: () => {
      return { width: '15%' };
    },
  },
  {
    dataField: 'systemId',
    text: t('firmwareManagementPage.systemId'),
    align: 'left',
    sort: true,
    hidden: false,
    headerStyle: () => {
      return { width: '15%' };
    },
  },
  {
    dataField: 'version',
    text: t('firmwareManagementPage.version'),
    align: 'left',
    sort: true,
    hidden: false,
    headerStyle: () => {
      return { width: '10%' };
    },
  },
  {
    dataField: 'available',
    text: t('firmwareManagementPage.available'),
    align: 'center',
    formatter: checkFormatter(patchCheck),
    sort: true,
    headerStyle: () => {
      return { width: '10%' };
    },
  },
  {
    dataField: 'production',
    text: t('firmwareManagementPage.production'),
    align: 'center',
    formatter: checkFormatter(patchCheck),
    sort: true,
    headerStyle: () => {
      return { width: '10%' };
    },
  },
  {
    dataField: 'actions',
    text: '',
    align: 'center',
    formatter: actionsFormatter(handleShowConfirmDeleteHWModal, handleEditPackage),
    headerStyle: () => {
      return { width: '20%' };
    },
  },
];
