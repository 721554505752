import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import { calculatePercentage, getColor, hierarchySortSankey, sortByValueSankey } from '../../utils/chartComponentsUtils';
import _ from 'lodash';
import { withRouter } from '../../../withRouter';
import { Skeleton } from '@mui/material';
import EmptySankeyChartView from '../../views/EmptySankeyChartView';
import {
  ResponseSankeyChartDataPoint,
  SankeyChartContainerProps,
  SankeyChartContainerState,
  SankeyChartDataPointContent,
  SankeyChartDataPointLink,
  SankeyChartOptions,
} from '@ComponentsRoot/ChartsComponents/types/SankeyChartTypes';

class SankeyChartContainer extends PureComponent<SankeyChartContainerProps, SankeyChartContainerState> {
  static contextType = AuthenticationContext;
  constructor(props: SankeyChartContainerProps) {
    super(props);
    this.state = {
      chartsData: false,
      dataPoints: false,
      chartOptions: false,
      ref: null,
      error: false,
      sankeyRef: null,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartsData) {
      this.getDataPoints();
    }
  };

  units;
  getDataPoints = async () => {
    try {
      const response = (await this.props.chartRepository.manageChartType(this.props.chart)) as ResponseSankeyChartDataPoint;
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units;

      if (!dataPoints || !dataPoints.data) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!dataPoints.data.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      } else {
        const chartsData = this.processSankeyDataKPIS(dataPoints);
        const chartOptions = this.getSankeyOptions(chartsData);
        this.setState({
          dataPoints: dataPoints as SankeyChartDataPointContent,
          chartOptions: chartOptions as SankeyChartOptions,
          chartsData: chartsData as SankeyChartDataPointContent,
        });
      }
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  getSankeyOptions = (processedSankeyData: SankeyChartDataPointContent) => {
    const newOptions = _.cloneDeep(this.props.chart.options as SankeyChartOptions);
    newOptions.series[0].data = JSON.parse(JSON.stringify(processedSankeyData.data));
    newOptions.series[0].links = JSON.parse(JSON.stringify(processedSankeyData.links));
    newOptions.chart = this.props.chart;

    const newOptionsProcessed = this.sankeyRenderProcessing(newOptions) as SankeyChartOptions;
    const customTooltip = newOptionsProcessed?.tooltip?.custom || null;

    newOptionsProcessed.tooltip.formatter = () => (params) => {
      if (customTooltip) {
        try {
          return eval(customTooltip);
        } catch (e) {
          return customTooltip;
        }
      }

      return `${params.data.name}<br />
              ${params.data.value} ${this.units} - (${params.data.percentage}%)<br />`;
    };

    return newOptionsProcessed;
  };

  sankeyRenderProcessing = (options: SankeyChartOptions) => {
    const seriesData = options.series[0].data;
    for (let i = 0; i < seriesData.length; i++) {
      const color = getColor(seriesData[i].color);
      let altColor = color;
      let value = '';
      let seriesDataValue = parseFloat(seriesData[i].value);
      if (seriesDataValue % 1 == 0) {
        seriesDataValue = Number(seriesDataValue.toFixed(0));
      } else {
        seriesDataValue = Number(seriesDataValue.toFixed(3));
      }
      value = seriesDataValue + ' ' + t('charts:axisTitles.' + this.units.toLowerCase());
      if (seriesData[i].children) {
        altColor.borderColor = 'none';
        altColor.backgroundColor = 'none';
        seriesData[i]['itemStyle'] = altColor;
      } else {
        altColor.borderColor = 'none';
        altColor.backgroundColor = 'none';
      }

      seriesData.color = altColor;
      let length = 20;
      let name = seriesData[i].name;
      if (name.length > length) {
        name = name.substr(0, length) + '\u2026';
      }

      let percentage = parseFloat(seriesData[i].percentage);
      if (percentage % 1 == 0) {
        percentage = Number(percentage.toFixed(0));
      } else {
        percentage = Number(percentage.toFixed(2));
      }

      let label = {
        normal: {
          show: true,
          formatter: [name + '\r\n' + '{per|' + percentage + '%} (' + value + ')'].join('\n'),
          rich: {
            per: {
              fontSize: 12,
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: altColor.backgroundColor,
              padding: [4, 4, 3, 4],
              borderRadius: 4,
            },
          },
        },
      };
      seriesData[i].label = label;
    }
    options.title!.text = '';
    options.series[0].height = '90%';
    options.series[0].width = '75%';
    delete options.series[0].levels;
    return options;
  };

  processSankeyDataKPIS(dataKPIS: SankeyChartDataPointContent) {
    let { links, data } = dataKPIS;
    const dataIdCounter = {};

    links.forEach((link: SankeyChartDataPointLink, index: number) => {
      // link
      const arrName = link.target.split('\r\n');
      const seemElems = links.filter((elem) => elem.source == link.source);
      let percentage: number = calculatePercentage(link.value, seemElems);
      if (percentage % 1 > 0) {
        percentage = Number(percentage.toFixed(2));
      } else {
        percentage = Number(percentage.toFixed(0));
      }
      const value = link.value % 1 === 0 ? link.value.toFixed(0) : link.value.toFixed(2);

      link.id = `${link.target}_${index + 1}`;
      link.name =
        arrName.length > 1
          ? arrName[arrName.length - 1].replace(new RegExp(`\\s*-\\s*${link.value}|[.,]000`, 'g'), '')
          : arrName[0];
      link.children = arrName.length > 1 ? false : true;
      link.value = parseFloat(value);
      link.percentage = percentage;

      // data
      dataIdCounter[link.target] = (dataIdCounter[link.target] || 0) + 1;
      const uniqueId = dataIdCounter[link.target] > 1 ? `${link.target}_${dataIdCounter[link.target]}` : link.target;
      const { name, color, children } = link;
      data[index] = { id: uniqueId, name, color, value, percentage, children };
    });

    links = sortByValueSankey(dataKPIS) as any;
    links = hierarchySortSankey(dataKPIS);
    return dataKPIS;
  }

  setEchartInstance = (ref) => {
    const { sankeyRef } = this.state;
    if (sankeyRef || !ref) {
      return;
    }

    let d = ref.getEchartsInstance();
    let parent = this;

    d.on('dblclick', (params) => {
      parent.setState({ ref });
      let id = params.data.id;
      let opt = d.getOption();
      let res = opt.series[0].links.find((elem) => elem.id == id);
      if (res?.customType == 'installation' || res?.customType == 'site') {
        parent.props.navigate('/' + res?.customType + '/' + res?.customId);
      } else if (res?.customType == 'product') {
        let parameters = 'product-productTypeId=' + res.customId;
        parameters += res.customInstallation ? '&installation-id=' + res.customInstallation : '';
        parameters += res.customSite ? '&site-id=' + res.customSite : '';
        parent.props.navigate('/filter/generalFilter?' + parameters);
      }
    });
  };

  render() {
    return (
      <div className='sankey-chart-box'>
        <div className='fullWidth mt-4' style={{ minHeight: '800px' }}>
          {this.state.error ? (
            <EmptySankeyChartView {...this.state.error} />
          ) : this.state.chartsData && this.state.chartOptions ? (
            <ReactECharts
              style={{ height: '100%', width: '100%' }}
              option={this.state.chartOptions}
              ref={(e: ReactECharts) => {
                this.setEchartInstance(e);
              }}
            />
          ) : !this.state.dataPoints ? (
            // <Skeleton animation="wave" variant='rectangular' width={966} height={800} style={{ margin: '15px auto' }} />
            <div className='row loadingParent'>
              <div className='loadingImg'></div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(SankeyChartContainer);
