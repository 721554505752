import axios from 'axios';
export default class MethodsHttpClient {
  constructor(context, language, level, id) {
    this.context = context;
    this.debug = false;
    this.level = level;
    this.id = id;
    this.language = language;
  }

  getMeasureUnits = async () => {
    try {
      let accessToken = await this.context.getToken();
      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      let response = await axios.get(`methods/en/measureunits`, config);
      if (this.debug) {
        console.log(`calling: user/en/measureUnits`);
        console.log(`response: ${JSON.stringify(response)}`);
      }
      return response.data;
    } catch (e) {
      return e;
    }
  };
}
