import axios from 'axios';
import i18n from '../i18n/i18n';

export default class HttpClient {
  constructor(context) {
    this.context = context;
    this.language = i18n.language;
    this.debug = false;
  }

  //********************* */

  GetNoSmartComponents = async (noSmartComponentTypeId = '-1') => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.get(
        `nosmartcomponents/${this.language}/getNoSmartComponents/${noSmartComponentTypeId}`,
        config
      );
      // if (this.debug) {
      //   console.log('calling NoSmartComponents ' + 'nosmartcomponents/' + this.language + '/getNoSmartComponents');
      //   console.log('response get nosmartcomponent: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  patchNoSmartComponent = async (noSmartComponentId, patchObjValue) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.post(
        `nosmartcomponents/${this.language}/patchNoSmartComponent/${noSmartComponentId}`,
        patchObjValue,
        config
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error patching smart component:', error);
      throw error;
    }
  };

  deleteNoSmartComponent = async (noSmartComponentId) => {
    try {
      let accessToken = await this.context.getToken();
      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      return await axios.delete(`nosmartcomponents/${this.language}/deleteNoSmartComponent/${noSmartComponentId}`, config);
    } catch (err) {
      console.log(err);
    }
  };

  GetNoSmartComponentTypes = async () => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.get(`nosmartcomponents/${this.language}/getNoSmartComponentTypes/`, config);
      // if (this.debug) {
      //   console.log('calling NoSmartComponents ' + 'nosmartcomponents/' + this.language + '/getNoSmartComponentTypes/' + systemId);
      //   console.log('response nosmartcomponent types: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  SaveNoSmartComponent = async (noSmartComponent) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.post(`nosmartcomponents/${this.language}/noSmartComponent`, noSmartComponent, config);
      // if (this.debug) {
      //   console.log('response post NO smartcomponent: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  GetNoSmartComponentPackages = async () => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.get(`nosmartcomponents/${this.language}/getNoSmartComponentPackages`, config);
      // if (this.debug) {
      //   console.log(
      //     'calling NoSmartComponents ' + 'nosmartcomponents/' + this.language + '/getNoSmartComponentPackages/' + systemId
      //   );
      //   console.log('response nosmartcomponent packages: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  createNoSmartComponentPackage = async (noSmartComponentPackage) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.post(
        'nosmartcomponents/' + this.language + '/noSmartComponentPackage/',
        noSmartComponentPackage,
        config
      );
      // if (this.debug) {
      //   console.log('response post NO smartcomponent packages: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  addDependencyToPackage = async (packageId, noSmartComponentTypeId) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.post(
        `nosmartcomponents/${this.language}/addNoSmartComponentPackageDependency/${packageId}/${noSmartComponentTypeId}`,
        {},
        config
      );
      // if (this.debug) {
      //   console.log('response post NO smartcomponent: ' + JSON.stringify(response));
      // }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  deleteNoSmartComponentDependenciesPackage = async (noSmartCompoId, packageId) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      return await axios.delete(
        `nosmartcomponents/${this.language}/deleteNoSmartComponent-dependencies/${noSmartCompoId}/${packageId}`,
        config
      );
    } catch (err) {
      console.log(err);
    }
  };

  deleteNoSmartComponentPackage = async (packageId) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      return await axios.delete(`nosmartcomponents/${this.language}/deleteNoSmartComponent-package/${packageId}`, config);
    } catch (err) {
      console.log(err);
    }
  };

  patchNoSmartComponentPackage = async (noSmartComponentPackageId, patchObjValue) => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axios.post(
        `nosmartcomponents/${this.language}/patchNoSmartComponent-package/${noSmartComponentPackageId}`,
        patchObjValue,
        config
      );
      return response.data;
    } catch (error) {
      console.error('Error patching smart component:', error);
      throw error;
    }
  };

  //********************* */

  PostNoSmartComponent = async (noSmartComponentTypeId, version, name, description) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    let content = {
      version: version,
      name: name,
      description: description,
      noSmartComponentTypeId: noSmartComponentTypeId,
      available: true,
      mandatory: true,
    };

    var response = await axios.post('nosmartcomponents/' + this.language + '/noSmartComponent/', content, config);
    if (this.debug) {
      console.log('response post NO smartcomponent: ' + JSON.stringify(response));
    }
    return response.data;
  };
}
