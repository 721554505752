import React, { Component } from 'react';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import GatewayGeneral from "./Tabs/GatewayGeneral";
import GatewaySummary from "./Tabs/GatewaySummary";
import GatewayParameters from "./Tabs/GatewayParameters";
import GatewayStatistics  from "./Tabs/GatewayStatistics";
import GatewaysHttpClient from "../../HttpClient/GatewaysHttpClient";
import { withTranslation } from 'react-i18next';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/material/styles';
import { withRouter } from '../withRouter';
import Message from '../Message/Message';


function SetClasses() {
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
        },
    }));
    return useStyles();
}

function SetStyles() {
    const classes = SetClasses();
    return classes;
}

function SetTheme() {
    const theme = useTheme();
    return theme;
}

class GatewayTabs extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.state = {
            tabValue: this.props.tabValue ? this.props.tabValue: 0,
            tabIndex: this.props.tabValue ? this.props.tabValue: 0,
            _data: [],
            show: false,
            showPassword: false,
            firmwares: null,
            gateway: null,
            message: null
        }

        this.http = new GatewaysHttpClient(this.context);
    }

    async componentDidMount() {
        this.http = new GatewaysHttpClient(this.context);
        await this.getGateway()
        await this.getGatewayFirmware()
    }

    handleChange = (event, newValue) => {
        if(newValue == undefined){
            return
        }
        this.setState({ tabValue: newValue });
        this.props.changeTab(newValue);
    };

    handleClick() {
        if (typeof this.props.handleClick === 'function') {
            this.props.handleClick();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            console.log(prevState);
            var id = parseInt(this.props.location.pathname.split('/')[2]);
            if (id != this.id) {
                this.id = id;
                await this.getGateway();
                await this.getGatewayFirmware();
            }
        }
        catch (err) {
            console.error(err)
        }
    }


    async getGateway() {

        try {
            this.setState({ gateway: null, _errors: null });
            let data = await this.http.GetGateway(this.id);
            if (data.status == 200) {
                this.setState({ gateway: data.data.content });
                if (data.errors != null) {
                    this.setState({ _errors: data.errors });
                }
            }
            else {
                this.setState({ _errors: data });
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    async getGatewayFirmware() {

        try {
            // this.setState({ firmwares: null, _errors: null });

            let data = await this.http.GetGatewayFirmware(this.id);
            this.setState({ firmwares: data.content });
            if (data.errors != null) {
                this.setState({ _errors: data.errors });
            }

        }
        catch (err) {
            console.error(err)
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
      
        if (this.state.notificationAlerts != nextState.notificationAlerts) {
            return true;
        }

        if (this.props.gateway.id === nextProps.gateway.id &&
            this.state.tabValue === nextState.tabValue) {
                if(this.state.message === nextState.message){
                    return false;
                }else{
                    return true;
                }
                
        } else {
            return true;
        }
    }

    handleDialogClose = () => {
        this.setState({ message: null });       
      };

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
            }
        });
    };


    render() {
        const { t } = this.props;

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography component={'span'}>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }

        return (
            <React.Fragment>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <div>
                    <AppBar position="static" color="default" className={"headerStickyCustom"}>
                        <Tabs
                            value={this.props.tabValue}
                            onChange={this.handleChange}
                            indicatorColor='primary'
                            variant="fullWidth"
                            className="container gateway-tabs"
                        >
                            <Tab label={<h3 className="tabTitle">{t('productspages.general.tabname')}</h3>} {...a11yProps(0)} />
                            <Tab label={<h3 className="tabTitle">{t('productspages.statistics.tabname')}</h3>} {...a11yProps(1)} />
                            <Tab label={<h3 className="tabTitle">{t('productspages.parameters.tabname')}</h3>} {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.props.tabValue}
                        onChange={this.handleChange}
                        className="container gateway-swipeable"
                    >
                        <TabPanel value={this.props.tabValue} index={0} dir={SetTheme.direction}  style={{overflowX:"hidden"}}>  
                            <GatewaySummary gateway={this.props.gateway} buttonClick={this.handleClick.bind(this)} id={this.id} />
                            <GatewayGeneral gateway={this.props.gateway} />
                        </TabPanel>
                        <TabPanel value={this.props.tabValue} index={1} dir={SetTheme.direction}>
                            <GatewayStatistics gateway={this.props.gateway} t={t}/>
                        </TabPanel>
                        <TabPanel value={this.props.tabValue} index={2} dir={SetTheme.direction}>
                            <GatewayParameters firmwares={ this.props.firmwares } gateway={this.props.gateway} renderMessage={this.renderMessage.bind(this)} />
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(withRouter(GatewayTabs, [{ path: '/gateway/:id' }]));