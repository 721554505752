import React, { Component } from 'react';
import GatewaysHttpClient from "../../HttpClient/GatewaysHttpClient";
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import GatewayTabs from "./GatewayTabs";
import { withTranslation } from 'react-i18next';

import Error from "../Error";
import BreadCrumb from "../BreadCrumb";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GatewayEdit from './GatewayEdit';
import { withRouter } from '../withRouter';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class GatewayDetail extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.state = {
            show: false,
            showPassword: false,
            firmwares: null,
            gateways: null,
            groupBySelected: 'day',
            dateRange: null,
            calOpened: false,
            multi: false,
            tabValue: 0
        };
        this.dateRange = null;
        this.groupBy = 'day';

        this.dateRange = this.getCurrentDateRange();
        this.groupBy = this.getCurrentGroupBy();
    }

    async componentDidMount() {
        window.scrollTo({ top: 0 });
        this.http = new GatewaysHttpClient(this.context);
        await this.getGateway()
        await this.getGatewayFirmware()
    }

    async componentDidUpdate() {
        try {
            var id = parseInt(this.props.location.pathname.split('/')[2]);
            if (id != this.id) {               
                this.id = id;
                await this.getGateway();
                await this.getGatewayFirmware();
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    handleChangeTab(valueTab){
        this.setState({tabValue: valueTab});
    }

    async getGateway() {

        try {
            // this.setState({ gateway: null, _errors: null });
            let data = await this.http.GetGateway(this.id);
            if (data.status == 200) {
                this.setState({ gateways: data.data.content });
                if (data.errors != null) {
                    this.setState({ _errors: data.errors });
                }
            }
            else {
                this.setState({ _errors: data });
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    async getGatewayFirmware() {

        try {
            // this.setState({ firmwares: null, _errors: null });

            let data = await this.http.GetGatewayFirmware(this.id);
            this.setState({ firmwares: data.content });
            if (data.errors != null) {
                this.setState({ _errors: data.errors });
            }

        }
        catch (err) {
            console.error(err)
        }
    }

    handleClick() {
        this.setState({ show: !this.state.show });
    }

    async Refresh() {
        if(this.state.tabValue != 1){
            this.setState({ firmwares: null, gateways: null, _errors: null });
        }
        await this.getGateway();
        await this.getGatewayFirmware();
    }

    onChangeDate(value, groupBy) {
        this.dateRange = value;
        this.groupBy = groupBy;
        localStorage.setItem('CurrentDateRange', value);
        localStorage.setItem('CurrentGroupBy', groupBy);
    }

    getCurrentDateRange() {
        let currentDateRange = null;
        try {
            currentDateRange = JSON.parse(localStorage.getItem('CurrentDateRange')) || null;
        } catch (e) {
            currentDateRange = null;
        }
        return currentDateRange;
    }
    getCurrentGroupBy() {
        let currentGroupBy = 'day';
        try {
            currentGroupBy = JSON.parse(localStorage.getItem('CurrentGroupBy')) || 'day';
        } catch (e) {
            currentGroupBy = 'day';
        }
        return currentGroupBy;
    }

    handleHomeIcon = async (event) => {
        await this.Refresh();
    };


    render() {

        const { t } = this.props;

        let dataLoaded = this.state.gateways ? true : false

        // let gateway = this.state.gateway ?? {};
     
        let editmodecomponent, boton;

        const handleGroupBySelected = (event) => {
            this.setState({ groupBySelected: event.target.value });
        };

        const handleClose = async () => {
            this.setState({ show: !this.state.show, refreshAllComponents: true });
            await this.getGateway();
        };

        if (this.state.show) {
            editmodecomponent = <GatewayEdit gateway={this.state.gateways} level="gateway" onSaveClose={handleClose} id={this.id} mode="edit"></GatewayEdit>
        }

        const ranges = [
            {
                label: 'today',
                value: [new Date(), new Date()]
            },
            {
                label: 'last7Days',
                value: [moment().add(-6, 'd').toDate(), new Date()]
            },
            {
                label: 'Last Month',
                value: [moment().add(-1, 'M').toDate(), new Date()]
            }
        ];
        const scrollToTop = (event) => {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

        const toggle = (event) => {
            if (!this.state.calOpened) {
                this.component.open();
            }
        };
        const openMenu = () => {
            this.setState({ multi: true });
            this.selectItem.focus();
        };
        const closeMenu = () => {
            this.setState({ multi: false });
        };
        return (
            <div id="rootElement">
                {dataLoaded && this.state._errors == null ? (
                    <div id="parentElementIsTop">
                        <Dialog disableEscapeKeyDown open={this.state.show} onClose={handleClose}>
                            <DialogTitle>{t('actions.editname', { name: t('gateway') })}</DialogTitle>
                            <DialogContent>
                                {editmodecomponent}
                            </DialogContent>
                            <DialogActions>

                            </DialogActions>
                        </Dialog>
                        <BreadCrumb level="gateways"></BreadCrumb>
                        <div className="granularityParent">
                            <div className='buttonScroll configButton calendar d-none' onClick={toggle}>
                                <span>
                                    <span className='t2'>
                                        <img
                                            alt='Calendario'
                                            style={{ width: '24px' }}
                                            src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_calendar.svg'
                                        />
                                        <DateRangePicker
                                            isoWeek
                                            format='dd-MM-yyyy'
                                            appearance='subtle'
                                            value={this.state.dateRange}
                                            ranges={ranges}
                                            onOpen={() => {
                                                this.state.calOpened = true;
                                            }}
                                            onClose={() => {
                                                this.state.calOpened = false;
                                            }}
                                            ref={(r) => {
                                                this.component = r;
                                            }}
                                            onChange={(value) => {
                                                this.setState({ dateRange: value });
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </span>
                                </span>
                            </div>
                            <div className='buttonScroll configButton toFilters d-none'>
                                <span>
                                    <span className='t2'>
                                        <img
                                            alt='Filter'
                                            style={{ width: '24px', top: '104px', position: 'absolute', left: '7px' }}
                                            src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_filters.svg'
                                        />
                                        <Select
                                            variant='standard'
                                            value={this.state.groupBySelected}
                                            onChange={{handleGroupBySelected }}
                                            ref={(node) => (this.selectItem = node)}
                                            onFocus={openMenu}
                                            onBlur={closeMenu}
                                            className='granularityRoot'
                                            MenuProps={{ classes: { paper: 'granularity' } }}
                                        >
                                            <MenuItem value='week'> Days </MenuItem>
                                            <MenuItem value='month'> Weeks </MenuItem>
                                        </Select>
                                    </span>
                                </span>
                            </div>
                            <div className='buttonScroll resset' onClick={this.handleHomeIcon}>
                                <span>
                                    <span className='t2'>
                                        <img
                                            alt='Resset'
                                            style={{ width: '24px' }}
                                            src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_arrow-clockwise.svg'
                                        />
                                    </span>
                                </span>
                            </div>
                            <div className='buttonScroll backToTop'>
                                <span>
                                    <span onClick={scrollToTop} className='t2'>
                                        <img
                                            alt='Back to Top'
                                            style={{ width: '24px' }}
                                            src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_chevron_up.svg'
                                        />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <GatewayTabs
                             gateway={this.state.gateways} 
                             handleClick={this.handleClick.bind(this)} 
                             firmwares={this.state.firmwares} 
                             changeTab={this.handleChangeTab.bind(this)}
                             tabValue={this.state.tabValue} />
                        </div>
                    </div>
                    ) : (
                        this.state._errors != null ? (<Error errors={this.state._errors} />) : (<div className="loadingParent">< div className="loadingImg"></div></div >)
                    )}
            </div>
        );
    }
}
export default withTranslation()(withRouter(GatewayDetail, [{ path: '/gateway/:id' }]));