import React, { Component } from 'react';
import '../../App.css';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import UnsubscribeTransferOwnership from './UnsubscribeTransferOwnership';
import UserHttpClient from '../../HttpClient/HttpClient';
import SitesHttpClient from '../../HttpClient/SitesHttpClient';
import InstallationsHttpClient from '../../HttpClient/InstallationsHttpClient';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import GatewaysHttpClient from '../../HttpClient/GatewaysHttpClient';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Message from '../Message/Message';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { manageInfoUser } from '../Common/utils/helpers';

class Unsubscribe extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      showAcceptUnsubscribe: false,
      message: null,
    };
    this.verification = this.verification.bind(this);
  }
  async componentDidMount() {
    this.httpUser = new UserHttpClient(this.context);
    this.httpSite = new SitesHttpClient(this.context);
    this.httpInstallation = new InstallationsHttpClient(this.context);
    this.httpGateway = new GatewaysHttpClient(this.context);
    this.httpInvitation = new InvitationsHttpClient(this.context);
    await this.verification();
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      }
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async verification() {
    let me = await this.getMe();
    let myOwnershipInstallations = await this.getMeOwershipInstallations(me.userId);
    let myOwnershipSites = await this.getMeOwnershipSites(me.userId);
    let myOwnershipGateways = await this.getMeOwnershipGateways(me.userId);

    const countInstallationsMeOwnerShipNotDefault = myOwnershipInstallations.filter((item) => item.isDefault === false).length;
    const countSitesMeOwnerShipNotDefault = myOwnershipSites.filter((item) => item.isDefault === false).length;

    const countInstallationsMeOwnerShipIsDefaultWithProducts = myOwnershipInstallations.filter(
      (item) => item.isDefault === true && item.products.length > 0
    ).length;
    const countSitesMeOwnerShipIsDefaultWithProducts = myOwnershipSites.filter(
      (item) => item.isDefault === true && item.products.length > 0
    ).length;

    let defaultWithProducts = false;
    let canUnsubscribe = true;
    let activeStep = 1;
    if (countInstallationsMeOwnerShipNotDefault > 0 || countSitesMeOwnerShipNotDefault > 0 || myOwnershipGateways.length > 0) {
      defaultWithProducts = false;
      canUnsubscribe = false;
      activeStep = 0;
    } else if (countInstallationsMeOwnerShipIsDefaultWithProducts > 0 || countSitesMeOwnerShipIsDefaultWithProducts > 0) {
      defaultWithProducts = true;
      canUnsubscribe = true;
      activeStep = 1;
    }

    //let mySentInvitations = await this.getMySentInvitations();
    if (myOwnershipSites.length > 0 || myOwnershipInstallations.length > 0 || myOwnershipGateways.length > 0) {
      this.setState({
        myOwnershipInstallations: myOwnershipInstallations,
        myOwnershipSites: myOwnershipSites,
        myOwnershipGateways: myOwnershipGateways,
        activeStep: activeStep,
        canUnsubscribe: canUnsubscribe,
        defaultWithProducts: defaultWithProducts,
      });
    } else {
      this.setState({
        myOwnershipInstallations: myOwnershipInstallations,
        myOwnershipSites: myOwnershipSites,
        activeStep: 1,
        canUnsubscribe: true,
        defaultWithProducts: false,
      });
    }
  }

  async getMe() {
    const userMe = await manageInfoUser(this.context, "userBasicData");
    if (userMe){
      return userMe;
    }else {
      this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async getMySentInvitations() {
    const { t } = this.props;
    let data = await this.httpInvitation.getInvitationsSentFilter('type=InstallationTransferOwnership', 1, 5);
    if (data.errors == null) {
      return data.content;
    } else {
        this.renderMessage('error', t('messages.errorGettingInvitations'));
    }
  }
  async getMeOwershipInstallations(userId) {
    const { t } = this.props;
    let data = await this.httpInstallation.GetInstallationsFilter('userId=' + userId, 0, 1000);
    if (data.errors == null) {
      return data.content;
    } else {
      this.renderMessage('error', t('messages.errorGettingInstallations'));
    }
  }

  async getMeOwnershipSites(userId) {
    const { t } = this.props;
    let data = await this.httpSite.GetSitesFilter('userId=' + userId, 0, 1000);
    if (data.errors == null) {
      return data.content;
    } else {
      this.renderMessage('error', t('messages.errorGettingSites'));
    }
  }

  async getMeOwnershipGateways(userId) {
    const { t } = this.props;
    let data = await this.httpGateway.GetGatewaysFilter('userId=' + userId, 0, 1000);
    if (data.errors == null) {
      return data.content;
    } else {
      this.renderMessage('error', t('messages.errorGettingGateways'));
    }
  }

  async unsubscribe() {
    const { t } = this.props;
    let data = await this.httpUser.unsubscribe();
    if (data.errors == null) {
      this.setState({ showAcceptUnsubscribe: false });
      this.renderMessage('success', t('userpages.unsubscribeMessageOk'));
      let inc = 1;
      this.setState({ activeStep: this.state.activeStep + inc });
      window.location.href = '/home?action=logout';
    } else {
      this.setState({ showAcceptUnsubscribe: false });
      this.renderMessage('error', data.errors.join());
    }
  }

  getSteps() {
    return ['Transfer ownership', 'Confirm'];
  }

  getStepContent(stepIndex, defaultWithProducts) {
    const { t } = this.props;
    switch (stepIndex) {
      case 0:
        return <UnsubscribeTransferOwnership verification={this.verification} />;
      case 1:
        if (defaultWithProducts) {
          return (
            <blockquote className='warningText'>
              <p>{t('userpages.unsubscribeWarningTextDefaultInstallationsWithProducts')}</p>
            </blockquote>
          );
        } else {
          return (
            <blockquote className='warningText'>
              <p>{t('userpages.unsubscribeWarningText')}</p>
            </blockquote>
          );
        }

      default:
        return 'Unknown stepIndex';
    }
  }

  render() {
    const { t } = this.props;
    const steps = this.getSteps();

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        this.setState({ showAcceptUnsubscribe: true });
      } else {
        let inc = 1;
        this.setState({ activeStep: this.state.activeStep + inc });
      }
    };

    const handleClose = () => {
      this.setState({ showAcceptUnsubscribe: false, saving: false });
    };

    const handleBack = () => {
      let desc = 1;
      if (this.state.activeStep == 2 && this.state.myOwnershipSites.length == 0 && this.state.myOwnershipInstallationslength == 0)
        desc = 2;
      this.setState({ activeStep: this.state.activeStep - desc });
    };

    let activeStep = this.state.activeStep;
    let defaultWithProducts = this.state.defaultWithProducts;

    let dataLoaded = false;
    if (this.state.myOwnershipInstallations !== undefined && this.state.myOwnershipSites !== undefined) dataLoaded = true;

    if (dataLoaded) {
      return (
        <React.Fragment>
          {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
          <Dialog disableEscapeKeyDown open={this.state.showAcceptUnsubscribe} onClose={handleClose}>
            <DialogTitle>{t('userpages.unsubscribeConfirmTitle')}</DialogTitle>
            <DialogContent>
              <div>
                <span>{t('userpages.unsubscribeQuestion')}</span>
                {this.state.saving ? (
                  <div className='loadingParent'>
                    <div className='loadingImg'></div>
                  </div>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => this.unsubscribe()}>
                {t('actions.yes')}
              </Button>
              <Button color='primary' onClick={handleClose}>
                {t('actions.no')}
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <blockquote className='warningText'>
                    <Typography>{t('actions.allStepsCompleted')}</Typography>
                  </blockquote>
                </div>
              ) : (
                <div>
                  {/*<Typography>*/}
                  {this.getStepContent(activeStep, defaultWithProducts)}
                  {/*</Typography>*/}
                  <div>
                    {activeStep === steps.length - 1 && defaultWithProducts ? (
                      <Button variant='contained' style={{ marginRight: '5px' }} color='primary' onClick={handleBack}>
                        {t('actions.back')}
                      </Button>
                    ) : null}
                    <Button variant='contained' disabled={!this.state.canUnsubscribe} color='primary' onClick={handleNext}>
                      {activeStep === steps.length - 1 ? t('actions.confirm') : t('actions.next')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }
}

export default withTranslation()(Unsubscribe);
