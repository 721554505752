
import React, { Component } from 'react';
import '../../App.css';
import '../refreshBorderAnimation.css';
import { Tooltip, Whisper, } from 'rsuite';
import ProductsTabs from '../ProductsComponents/Main/ProductsTabs';
import ProductHttpClient from "../../HttpClient/ProductHttpClient";
import ChartHttpClient from "../../HttpClient/ChartHttpClient";
import BreadCrumb from "../BreadCrumb";
import Error from "../Error";
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import SelectMUI from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import ProductEdit from '../ProductsComponents/Main/ProductEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../withRouter';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

class Products extends Component {
    
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.i18n.language,
            _data: [],
            _chartViewZones: [],
            _parametersViewZones: [],
            _productTelemetry: {},
            groupBySelected: 'week',
            dateRange: null,
            calOpened: false,
            multi: false,
            isPlaying: false,
            show:false
        };
        //const setAlignment = 'left';
        //const alignment = 'left';

        this.notificationPage = 1;

        this.dateRange = null;
        this.groupBy = 'week';
        if (this.props.match != undefined && this.props.match.params.id != undefined || this.props.location.pathname.split('/').length>1)
            this.id = this.props.match.params.id == undefined ? parseInt(this.props.location.pathname.split('/')[2]) : this.props.match.params.id;

        this.dateRange = this.getCurrentDateRange();
        this.groupBy = this.getCurrentGroupBy();
        this.refresh = true;
    }

    componentDidMount() {

        window.scrollTo({ top: 0 });

        localStorage.setItem('CurrentDateRange', null);
        localStorage.setItem('CurrentGroupBy', 'week');

        this.dateRange = null;
        this.groupBy = 'week';

        this.getProductData();
        this.getChartViewZones();
        this.getParametersViewZones();
        this.getTelemetry();
    }

    componentDidUpdate() {        
        var idproduct = parseInt(this.props.location.pathname.split('/')[2]);
        if (idproduct != this.id) {
            this.dateRange = this.getCurrentDateRange();
            this.groupBy = this.getCurrentGroupBy();
            this.id = idproduct;
            this.getProductData();
            this.getTelemetry();
            this.getParametersViewZones();
            this.getChartViewZones();
            sessionStorage.removeItem("accordionsExpandedIds");
        }
        this.checkStop();

        if(this.state.language !== this.props.i18n.language){
            this.setState({ language: this.props.i18n.language });
            this.getProductData();
        }        
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout)
    }
    checkStop(checkModal = false) {

        if (!this.state.isPlaying) {
            try {
                this.clearIntervalRefresh(true);

            } catch (e) {

            }
            
            return false;
        }

        if (checkModal) {
            let modals = document.getElementsByClassName("MuiDialog-root");
            let modalsMessage = document.getElementsByClassName("responseDialogRoot messageDialog");

            if (modals.length > modalsMessage.length) {
                //this.clearIntervalRefresh(true);
                return false;
            }

        }
        
        let pause = false;
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has('pause')) {
                pause = urlParams.get('pause');
            }

        } catch (err) {

        }

        if (pause || !this.refresh) {
            try {
                this.clearIntervalRefresh(true);
                this.setState({ isPlaying: false });
                return false;
            } catch (err) {
                return false;
            }
        }


        return true;
    }
    startInterval() {
        
        if (!this.checkStop()) {
            return;
        }
        
       
        try {

            // For some reason getElementById or other normal methots to get html elements are not working
            // so we get the button this way.

            let button = null;
            let buttons = document.getElementsByClassName("refresh-btn-class")
            for (let btn of buttons) {
                button = btn
            }
            if (button) {
                button.classList.remove("border-animation");

                // -> triggering reflow /* The actual magic */
                // without this it wouldn't work. Try uncommenting the line and the transition won't be retriggered.
                // Oops! This won't work in strict mode. Thanks Felis Phasma!
                // element.offsetWidth = element.offsetWidth;
                // Do this instead:

                void button.offsetWidth;

                // -> and re-adding the class
                button.classList.add("border-animation");
            }

            // DURATION OF THE REFRESH TIMEOUT

            // Set to 60 seconds, if changed it ha sto also be changed at refreshBorderAnimation.css line 28

            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                if (this.checkStop(true)) {
                    this.refreshProductData()
                    this.setState({ groupBySelected: this.groupBy, dateRange: this.dateRange })
                } else {
                    this.startInterval();
                }
                
               
            }, 60000);
        }
        catch (err) {
            console.error(err)
        }
    }
    stopRefresh(val) {
        this.refresh = !val;
        
        this.clearIntervalRefresh(val);
    }
    clearIntervalRefresh(val) {
        let button = document.getElementById("refresh-button");
        if (val) {
            if (this.timeout) clearTimeout(this.timeout);
            button.classList.remove("border-animation");
        } else {
            this.startInterval();
        }
    }
    async getProductData() {

        if (this.timeout) clearTimeout(this.timeout)

        localStorage.setItem('currentPageRule', 1);
        localStorage.setItem('sizePerPageRule', 5);

        localStorage.setItem('currentPageHist', 1);
        localStorage.setItem('sizePerPageHist', 5);

        localStorage.setItem('currentPageActive', 1);
        localStorage.setItem('sizePerPageActive', 5);
        
        localStorage.setItem('currentPageNotiHist', 1);
        localStorage.setItem('sizePerPageNotiHist', 5);

        this.setState({ _data: {}, _errors:null });
        localStorage.setItem('CurrentDateRange', null);
        localStorage.setItem('CurrentGroupBy', 'week');

        this.dateRange = this.getCurrentDateRange();
        this.groupBy = this.getCurrentGroupBy();

        this.setState({ _data: {}, _errors:null, dateRange: null, groupBySelected: 'week'});
        this.httpProduct = new ProductHttpClient(this.context, this.id, null, null);
        var data = await this.httpProduct.ProductsGetProduct();
        if (data.status == 200 && data.data != null) {
            this.setState({ _data: data.data, dateRange: null, groupBySelected: 'week' });
            if (data.errors != null) {
                this.setState({ _errors: data.errors });
            }
            else {
                // We wait 1 ms so the page is loaded when we start the refresh interval
                setTimeout(() => {
                    this.startInterval()
                },10)
            }
        }
        else {
            this.setState({ _errors: data });
        }
    }

    async refreshProductData() {
        try {
            let button = document.getElementById("refresh-button")
            button.classList.remove("border-animation");
            void button.offsetWidth;
            button.classList.add("button-refreshing");

            let refreshDate = this.state._data.content.lastTimeSeen

            this.httpProduct = new ProductHttpClient(this.context, this.id, null, null);
            var data = await this.httpProduct.ProductsGetProduct();
            this.getTelemetry();
            if (data.status == 200) {
                data.data.randomValueForceUpdate = Math.random();
                this.setState({ _data: data.data });
                if (data != null && data.errors != null) {
                    this.setState({ _errors: data.errors });
                }
                else {
                    if (refreshDate != data.data.content.lastTimeSeen) {
                      //  this.setState({ _productTelemetry: { } });
                        this.getTelemetry();
                    }
                    this.startInterval();
                }
            }
            else {
                this.setState({ _errors: data });
            }
            button.classList.remove("button-refreshing");
        }
        catch (err) {
            console.error(err);
        }
        
    }

    async getChartViewZones() {
        this.httpChart = new ChartHttpClient(this.context);
        var chartViewZones = await this.httpChart.ProductsGetChartViewZones();
        if (chartViewZones.status == 200)
            this.setState({ _chartViewZones: chartViewZones.data });
    }

    async getParametersViewZones() {
        this.httpProduct = new ProductHttpClient(this.context, this.id, null);
        var parametersViewZones = await this.httpProduct.ProductsGetParametersViewZones();
        if (parametersViewZones.status == 200)
            this.setState({ _parametersViewZones: parametersViewZones.data });
    }

    async getTelemetry() {
        this.httpProduct = new ProductHttpClient(this.context, this.id);
        var telemetry = await this.httpProduct.ProductsGetTelemetry();
        this.setState({ _productTelemetry: telemetry });
    }

    handleEditProduct() {
        this.setState({ show: !this.state.show });
    }

    showSelector(show) {

        try {
            let buttons = document.getElementsByClassName("configButton")

            for (let btn of buttons) {
                if (btn.classList.contains("d-none")) {
                    if (show)
                        btn.classList.remove("d-none");
                }
                else if (!show) {
                    btn.classList.add("d-none");
                }
            }
        }
        catch (err) {
            console.error(err)
        }

    }
    onChangeDate(value, groupBy) {
        localStorage.setItem('CurrentDateRange', value);
        localStorage.setItem('CurrentGroupBy', groupBy);
        this.dateRange = value;
        this.groupBy = groupBy;
    }
    updateParameters() {
        this.refreshProductData();
        this.refresh = true;
    }

    getCurrentDateRange() {
        var currentDateRange = null
        try {
            currentDateRange = JSON.parse(localStorage.getItem('CurrentDateRange')) || null;
        }
        catch (e) {
            currentDateRange = null;
        }
        return currentDateRange;
    }
    getCurrentGroupBy() {
        var currentGroupBy = 'week'
        try {
            currentGroupBy = JSON.parse(localStorage.getItem('CurrentGroupBy')) || 'week';
        }
        catch (e) {
            currentGroupBy = 'week';
        }
        return currentGroupBy;
    }
    vRefresh() {
        this.refreshProductData();
        this.setState({ groupBySelected: this.groupBy, dateRange: this.dateRange });
    }
    render() {
        const { t } = this.props;
        const handleGroupBySelected = (event) => {
            this.setState({ groupBySelected: event.target.value })
        };
        const handleHomeIcon = (event) => {
            this.refresh = true;
            this.setState({ isPlaying: !this.state.isPlaying });
            this.vRefresh();
        };
        function tooltip(text) {
            let t = text.toLowerCase();
            return (<Tooltip style={{ textTransform: "capitalize" }}>
                {t}
            </Tooltip>)
        };
        const ranges = [
            {
                label: 'today',
                value: [new Date(), new Date()]
            },
            {
                label: 'last7Days',
                value: [moment().add(-6, 'd').toDate(), new Date()]
            },
            {
                label: 'Last Month',
                value: [moment().add(-1, 'M').toDate(), new Date()]
            }
        ];
        const scrollToTop = (event) => {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };


        const toggle = (event) => {

            //this.state.calOpened ? //this.component.close() : this.component.open();
            if (!this.state.calOpened) {
                this.component.open();
            }
        }
        const openMenu = () => {
            this.setState({ multi: true });
            this.selectItem.focus();
        };
        const closeMenu = () => {
            this.setState({ multi: false });
        };

        const handleClose = () => {

            this.setState({ show: !this.state.show, refreshAllComponents: true  });
            this.getProductData();
        };

        const dialogHandleClose = (event, reason) => {
            if (reason == 'backdropClick') {
                return false;
            }
            handleClose();
        };
        let dataLoaded = this.state?._data?.content != undefined;

        let editmodecomponent, boton;
        
        if (this.state._data.content != undefined && this.state._data.content.installation?.hasOwnerAccess) {
            //boton = <div className="layoutConfigButtonsParent edit-parent w-sm-100"><Button className="primary w-sm-100" onClick={() => this.handleClick()}><img src={require('../../assets/icons/edit-18.svg')} /></Button></div>
        }

        if (this.state.show) {
            editmodecomponent = <ProductEdit product={this.state._data} level="installations" onSaveClose={handleClose} id={this.id} mode="edit"></ProductEdit>
        }

        return (
            <div id="rootElement">
                {dataLoaded && this.state._errors == null ? (
                    <div id="parentElementIsTop">
                        <Dialog disableEscapeKeyDown open={this.state.show} onClose={dialogHandleClose}>
                            <DialogActions className="jc-spaceBetween">
                                <h2 className="mb-0 ml-4">{t('actions.editname', { name: t('product') })}
                                </h2>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </DialogActions>
                            <DialogContent>
                                {editmodecomponent}
                            </DialogContent>
                            <DialogActions>
                                
                            </DialogActions>
                        </Dialog>
                        <BreadCrumb level="products" product={this.state._data} doubleSticky={true}></BreadCrumb>
                        <div className="granularityParent">
                            <div className="buttonScroll configButton calendar" onClick={toggle}>
                                <span>
                                    <span className="t2">
                                        <img alt="Calendario" style={{ width: "24px"}} src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_calendar.svg" />
                                        <DateRangePicker
                                            isoWeek
                                            format="dd-MM-yyyy"
                                            appearance='subtle'
                                            value={this.state.dateRange}
                                            ranges={ranges}
                                            onOpen={() => {
                                                this.state.calOpened = true;
                                            }}
                                            onClose={() => {
                                                this.state.calOpened = false;
                                            }}
                                            ref={(r) => {
                                                this.component = r;
                                            }}
                                            onChange={value => {
                                                this.setState({ dateRange: value });
                                            }}
                                            style={{ display: "none" }}
                                        />
                                    </span>
                                </span>
                            </div>
                            {this.state._data.content.connectionState != 'App' ?
                            <div className="buttonScroll configButton toFilters" onClick={openMenu}>
                                <span>
                                        <span className="t2">
                                            <img alt="Filter" style={{ width: "24px", top: "104px", position: "absolute", left: "7px" }} src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_filters.svg" />    

                                        <SelectMUI
                                            variant="standard"
                                            value={this.state.groupBySelected}
                                            onChange={handleGroupBySelected}
                                            ref={node => (this.selectItem = node)}
                                            onFocus={openMenu}
                                            onBlur={closeMenu}
                                            open={this.state.multi}
                                            className="granularityRoot"
                                            MenuProps={{ classes: { paper: "granularity" } }}>
                                            <MenuItem value="day"> Hours </MenuItem>
                                            <MenuItem value="week"> Days </MenuItem>
                                            <MenuItem value="month"> Weeks </MenuItem>
                                        </SelectMUI>

                                    </span>
                                </span>
                            </div>
                            :null
                            }
                            <Whisper placement="left" trigger="hover" delayOpen={500} speaker={tooltip(t('productspages.parameters.modifiedParameters'))}>
                                <div id="parameterChanges" className="buttonScroll backToTop d-none" onClick={(e) => {
                                    document.getElementById(sessionStorage.getItem("parametersChangeLocationID")).scrollIntoView({
                                        behavior: 'smooth',
                                        block: "center"
                                    })
                                }} >
                                    <span>
                                        <span className="t2" id="parameterChanges-content">
                                            0

                                        </span>
                                    </span>
                                </div>
                            </Whisper>
                            
                            <div id="refresh-button" className="buttonScroll resset border-animation refresh-btn-class" onClick={handleHomeIcon}>
                                <span>
                                    <span className="t2">
                                        {this.state.isPlaying ?
                                            <img id="refresh-icon" alt="Resset" style={{ width: "24px" }} src={require('../../assets/icons/pause.svg').default} />
                                            :
                                            <img id="refresh-icon" alt="Resset" style={{ width: "24px" }} src={require('../../assets/icons/play.svg').default} />
                                        }

                                    </span>
                                </span>
                            </div>
                            <div className="buttonScroll backToTop" onClick={scrollToTop} >
                                <span>
                                    <span className="t2">
                                        <img alt="Back to Top" style={{ width: "24px" }} src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_chevron_up.svg" />

                                    </span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <ProductsTabs showSelector={this.showSelector} product={this.state._data} groupBySelected={this.state.groupBySelected} dateRange={this.state.dateRange} chartViewZones={this.state._chartViewZones} productTelemetry={this.state._productTelemetry} parametersViewZones={this.state._parametersViewZones} handleEditProduct={this.handleEditProduct.bind(this)} onChangeDate={this.onChangeDate.bind(this)} updateParameters={this.updateParameters.bind(this)} stopRefresh={this.stopRefresh.bind(this)} />
                        </div>
                </div>
                ) : (
                    this.state._errors != null ? (<Error errors={this.state._errors} />) : (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                )}
            </div>
        );
    }

}

export default withTranslation()(withRouter(Products, [{ path: '/product/:id' }]));