import { useState, useEffect, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Message from './components/Message/Message';

type MessageType = {
  type: string;
  content: string;
};

const ConnectionStatus = (props: { children: ReactNode } & WithTranslation) => {
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleOnline = () => {
    setMessage({ type: 'success', content: props.t('reconnected') });
  };

  const handleOffline = () => {
    setMessage({ type: 'error', content: props.t('lostConnection') });
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleCloseMessage = () => {
    setMessage(null);
  };

  return (
    <>
      {props.children}
      {message && <Message {...message} onClose={handleCloseMessage} />}
    </>
  );
};

export default withTranslation()(ConnectionStatus);