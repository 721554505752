import { t } from 'i18next';
import { ConfirmEmailStateType } from '../types/ConfirmEmailStateType';
import Message from '../../Message/Message';
import Style from '../styles/Registration.module.scss';

const ConfirmEmailView = (props: ConfirmEmailStateType) => {
  console.log('ConfirmEmailView rendered');
  return (
    <div className={Style.Registration}>
      {props.message && <Message {...props.message} onClose={props.handleDialogClose} />}
      <div className='register'>
        {props.brandUser ? (
          <div className='registerContainer unified_container'>
            <div className='registerRow'>
              <div className='register-col-lg-6'>
                <div className='panel panel-default'>
                  <div className='panel-body'>
                    <img alt={props.brandUser} className='companyLogo' src={props.brandLogo} />
                    <div id='api' data-name='Unified'>
                      <div id='localAccountForm' className='localAccount' aria-label='Inicie sesión con su cuenta existente.'>
                        <div className='intro'>
                          <span className='h2'>{t('register.enterEmail')}</span>
                        </div>
                        {props.loading ? (
                          <div className='row loadingParent'>
                            <div className='loadingImg'></div>
                          </div>
                        ) : (
                          <div className='entry'>
                            <div className='entry-item'>
                              <input
                                type='email'
                                title={t('register.emailInputTitle') as string}
                                id='signInName'
                                name='Sign in name'
                                // pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                placeholder={t('register.emailInputPlaceHolder') as string}
                                aria-label={t('register.emailInputPlaceHolder') as string}
                                tabIndex={1}
                                value={props.mail}
                                onChange={(e) => props.setMail!(e.target.value)}
                              />
                            </div>
                            <div className='buttons'>
                              <button
                                onClick={() => {
                                  console.log('Register button clicked from view');
                                  props.register!();
                                }}
                                id='next'
                              >
                                {t('register.createAccount')}
                              </button>

                              <button id='back' onClick={() => (window.location.href = '/')}>
                                {t('actions.back')}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='row loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmailView;