import { GeneralNavMenuViewProps } from "../types/GeneralNavMenuType";
import { WithTranslation } from "react-i18next";
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const DropdownGateways  = (props: GeneralNavMenuViewProps & WithTranslation) => {

    let dataLoaded = props.myGateways.content != undefined;
    if (dataLoaded) {

        let listitems = props.myGateways.content.map((item, i) => {
            if (i < 4) {
                let img = "";
                if (props.isLaufen) {
                    img = item.gatewayType?.imageUri ? item.gatewayType.imageUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/DefaultSite.jpg"
                } else {
                    img = item.gatewayType?.imageUri ? item.gatewayType.imageUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/DefaultSite.jpg"
                }


                let itemBody = <div className="row installationRow">
                    <div className="col-2">
                        <div className="menuIcon" style={{ backgroundImage: 'url("' + img + '")' }} > </div>
                    </div>
                    <div className="col-8" style={{ display: "flex", alignItems: "center" }}>
                        <div className="col-8">
                            <div className="menuSpan menuInstallationTitle noUnderLine "><h4 style={{ float: 'left' }}>{item.name}</h4></div>
                            <div className="menuSpan t3 d-none">{item.gatewayId}</div>
                            <div className="menuSpan t3 d-none">{item.description}</div>
                        </div>
                    </div>
                    <div className="col-2" style={{ padding: 0, display: "flex", alignItems: "center", paddingRight: "15px" }}>
                        <div style={{ flexBasis: "100%", visibility: "hidden" }}>

                        </div>
                        {item.products != undefined ?
                            <div style={{ flexBasis: "100%" }} className="magaItemsParent left">
                                <div className="t3" style={{ width: '100%', marginBottom: 0 }}> {item.products.length ?? 0} </div>
                                <img src={require('../../../assets/icons/11_00.03_black.svg').default} />
                            </div> :
                            <div style={{ flexBasis: "100%" }} className="magaItemsParent left">
                                <div className="t3" style={{ width: '100%', marginBottom: 0 }}> 0 </div>
                                <img src={require('../../../assets/icons/11_00.03_black.svg').default} />
                            </div>
                        }

                        <div style={{ flexBasis: "100%" }} className="magaItemsParent right">
                            <div className="t3" style={{ width: '100%', marginBottom: 0 }}> {item.installations.length ?? 0} </div>
                            <img src={require('../../../assets/icons/BusinessIcon.svg').default} />
                        </div>
                    </div>
                </div>

                return (
                    <Link key={"div-" + item.id} style={{ textDecoration: "none" }} onClick={() => { props.showMenu(0) }}  to={`/gateway/${item.id}`}>
                        {itemBody}
                    </Link>
                )
            }

        })

        let urls = props.urlsMenu

        return (
            <div className="row ">
                
                <div className="col-8 header-top">
                    <div style={{ marginLeft: '20%' }}>
                        <h2 style={{marginTop: '25px', padding: '0px'}}>{t('recentlyViewed')}</h2>
                    </div>
                    <div style={{ marginLeft: '20%' }}>
                        {listitems}
                    </div>
                </div>
                <div className="col-4" style={{ margin: 0, padding: 0 }} >
                    <div className="header-top mb-0" style={{ margin: '24px', padding: 0, backgroundImage: 'url("' + urls.imageMenuGateways + '")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} ></div>
                    <div className="d-flex" style={{ justifyContent: "center" }}>  <Link style={{ textDecoration: "none" }} className="m2 linkGoTo" to={`/filter/gateways`}> {t('actions.goToGateways')}  <img src={require('../../../assets/icons/arrow_right-24.svg').default} /> </Link> </div>
                </div>

            </div>
        )
    } else {
        return <div></div>
    }
};

export default DropdownGateways;