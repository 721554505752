import React, { Component } from 'react';
import '../Sites.css';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";

import InstallationsTree from '../../InstallationComponents/Tabs/InstallationsTree';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Button from '@mui/material/Button';
import Error from "../../Error";
import SinglePointMap from '../../SinglePointMap';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Message from "../../Message/Message";

class SiteDetail extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.state = {
            refreshAllComponents: false,
            site: this.props.data,
            notifications: this.props.notifications,
            showMap: false,
            dataLoaded: false
        }
        //this.isLaufen = this.context.brandCode == 11;
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });

        this.isLaufen = this.context.brandCode == 11;

        this.setState({ dataLoaded: true });
       
    }

   
    handleClick() {
        if (typeof this.props.buttonClick === 'function') {
            this.props.buttonClick();
        }
    }
    showMapToggle() {
        // Disabled in Task 3523

        //let v = !this.state.showMap;
        //this.setState({ showMap: v });
    }
    getNotifications() {
        const { t } = this.props;
        const types = ['alarm', 'warning', 'rule'];
        const names = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
        const name = { "rule": t("productspages.alertreporting.rule"), "alarm": t("productspages.alertreporting.alarm"), "warning": t("productspages.alertreporting.warning") };

        if (this.props.notifications == undefined || this.props.notifications == null) {
            return null;
        } else if (this.props.notifications.length <= 0) {
            return null;
        }
        let notifications = this.props.notifications[0].alerts;

        let arr = [];
        let idTypes = 0;
        types.forEach(element => {
            let obj = {};
            let data = notifications.find(o => o.type === element);
            if (data == null) {
                return;
            }
            let total = data.total != null ? data.total : 0;

            if (total <= 0) { return }

            arr.push(
                <div key={'idTypes' + idTypes} className="col-auto pl-0 pr-1">
                    <span className={"custom-button-span " + element} style={{ whiteSpace: "nowrap" }}>
                        {total + " " + (total > 1 ? names[element] : name[element])}
                    </span>
                </div>);
            idTypes++;

        });

        let ret = <div className="row">
            <div className="col-12 ">
                <div className="row m-0 listNotificationsItems">
                    {arr}
                </div>

            </div>
        </div>;

        return ret;
    }
    getStatusList(type, total, text) {
        let t = type.toLowerCase();
        if (total <= 0) {
            return;
        }
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 d-flex" style={{ alignItems: "center" }} >
                        <img src={require('../../../assets/icons/dot.svg').default} className={"iconStatusList bullet-" + t} height="10" width="10" />
                        <span className="pl-2 t2 bold m-0">{total + " " + text}</span>
                    </div>

                </div>
            </div>

        );
    }

    render() {

        const { t } = this.props;

        let notifiactionsReady = this.props.notifications != undefined && this.props.notifications != null ? true : false;
        let dataLoaded = this.props.data ? notifiactionsReady : false


        let aggregatedInfo = {};
        let siteId;
        try {
            if (dataLoaded) {

               

                siteId = this.props.data.id.toString();
                aggregatedInfo = this.props.data.aggregatedInfo;
            }
        } catch (e) {
        }

        const renderTooltip = (text) => (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );
        

        let boton;
        
        if (this.props.data != undefined && this.props.data.hasOwnerAccess) {
            boton = <div className="edit-parent" ><Button className="primary float-right w-sm-100" onClick={() => this.handleClick()}> <img src={require('../../../assets/icons/edit-18.svg').default} />
            </Button></div>
        }

        let img = ""
        let address = ""
        let map = null;

        if (this.props.data && this.state.dataLoaded) {

            if (this.props.data) {
                map = <SinglePointMap installation={this.props.data} />
            }
            if (this.isLaufen) {
                img = this.props.data.avatarUri ? this.props.data.avatarUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/DefaultSite.jpg"
            } else {
                img = this.props.data.avatarUri ? this.props.data.avatarUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/DefaultSite.jpg"
            }
            

            if (this.props.data.address && this.props.data.address != "") {
                address += this.props.data.address
            }
            if (this.props.data.postalCode && this.props.data.postalCode != "") {
                if (address.length > 1) address += " - "
                address += this.props.data.postalCode
            }
            if (this.props.data.city && this.props.data.city != "") {
                if (address.length > 1) address += ", "
                address += this.props.data.city
            }
            if (this.props.data.country && this.props.data.country != "") {
                if (address.length > 1) address += ", "
                address += this.props.data.country
            }
        }

        let appProducts = 0;
        let disconnectedProducts = 0;
        let offlineProducts = 0;
        let onlineProducts = 0;
        if (aggregatedInfo) {
            appProducts = aggregatedInfo.totalDevices - aggregatedInfo.disconnectedDevices - aggregatedInfo.connectedDevices
            if (aggregatedInfo["deviceStatus"] !== undefined) {
                appProducts = 0;
                if (aggregatedInfo["deviceStatus"]["App"] !== undefined) appProducts = aggregatedInfo["deviceStatus"]["App"];
                if (aggregatedInfo["deviceStatus"]["Offline"] !== undefined) disconnectedProducts += aggregatedInfo["deviceStatus"]["Offline"];
                if (aggregatedInfo["deviceStatus"]["Disconnected"] !== undefined) disconnectedProducts += aggregatedInfo["deviceStatus"]["Disconnected"];
                if (aggregatedInfo["deviceStatus"]["Online"] !== undefined) onlineProducts = aggregatedInfo["deviceStatus"]["Online"];
            }
        }

        return (
            < React.Fragment >
                {
                     dataLoaded && this.state._errors == null ? (
                        <div>
                            <div className="container mt-0">
                                <div className="row prodInfo">
                                    <div className="col-12">
                                        <div className="row prodInfo" style={{ alignItems: "stretch", paddingBottom: '10px' }}>
                                            <div className="col-12 col-md-3 pr-md-3" style={{ padding: '0' }}>
                                                <div style={{
                                                    backgroundImage: "url('" + img + "')",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    minHeight: "200px",
                                                    height: '100%',
                                                    width: '100%'
                                                }}>
                                                </div>

                                            </div>
                                            <div className="col-12 col-md-9 mt-3 mt-md-0 pt-2">
                                                <div className="row mb-2">
                                                    <h2 className="mb-0 col">{this.props.data.name}</h2>
                                                    {boton}
                                                </div>
                                                <div className="row summaryInfo ">
                                                    
                                                    <div className="col-12 col-md-4 " style={{ position: "relative" }}>
                                                        {this.getNotifications()}
                                                        <div className="row pt-2" style={{}}>
                                                            {this.getStatusList("Online", onlineProducts, t('tooltips.activeProducts'))}
                                                            {this.getStatusList("App", appProducts, t('tooltips.appProducts'))}
                                                            {this.getStatusList("Disconnected", disconnectedProducts, t('tooltips.unactiveProducts'))}
                                                        </div>
                                                       
                                                        
                                                        <div className="row pt-3">
                                                            <div className="col-12">
                                                                <div className="row m-0">

                                                                    <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('actions.devices'))}>
                                                                            <div className="iconParent">
                                                                                <img src={require('../../../assets/icons/11_00.03_black.svg').default} />
                                                                                <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalDevices} </div>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('users'))}>
                                                                            <div className="iconParent">
                                                                                <img src={require('../../../assets/icons/PersonOutlineIcon.svg').default} />

                                                                                <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalUsers} </div>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('gateways'))}>
                                                                            <div className="iconParent">
                                                                                <img src={require('../../../assets/icons/11_00.31_black.svg').default} />
                                                                                <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.gatewayDevices} </div>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="col pl-0 pr-1" style={{ padding: 0 }}>
                                                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installations'))}>
                                                                            <div className="iconParent">
                                                                                <img src={require('../../../assets/icons/BusinessIcon.svg').default} />
                                                                                <div className="t3" style={{ float: 'left', marginBottom: 0 }}> {aggregatedInfo.totalInstallations} </div>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div className="col-12 col-md-5 pt-3 pt-md-0 spacebar">
                                                        <div onClick={() => this.showMapToggle()}>
                                                            <div className="d-flex">
                                                                <h6 className="col row"> {t('installationspages.installationsummary.address')}: </h6>
                                                                <img style={{ height: "20px" }} className={"d-none col-auto row " + (!this.state.showMap ? "" : "d-none")} src={require('../../../assets/icons/eye.svg').default} />
                                                                <img style={{ height: "20px" }} className={"d-none col-auto row " + (this.state.showMap ? "" : "d-none")} src={require('../../../assets/icons/eye-off.svg').default} />
                                                            </div>
                                                              
                                                            <div className="t2"> {address}  </div>
                                                        </div>
                                                       

                                                        <h6 className="pt-3 d-none">{t('installationspages.installationsummary.iotdata')}:</h6>

                                                        <div className="t2 d-none"> {this.props.data.name}</div>
                                                        <div className="t2 d-none"> <h6 className="d-inline">{t('installationspages.installationsummary.contractnumber')}: </h6>--------</div>

                                                        <div className="t2"> <h6 className="d-inline">{t('installationspages.installationsummary.registered')}:</h6> <Moment format="D MMM YYYY HH:mm" withTitle locale="es">{this.props.data.createdAt}</Moment> </div>
                                                    </div>

                                                    <div className="pt-3 pt-md-0 spacebar col-12 col-md-3 " style={{ position: "relative", minHeight:"200px", overflow:"hidden" }}>
                                                        <div className={!this.state.showMap ? "col notMap showAnimation" : "col notMap hiddeAnimation"}>
                                                            <h6 className="">{t('installationspages.installationsummary.contact')}:</h6>
                                                            <div className="t2">{this.props.data.managerMail}</div>
                                                            <div className="t2">{this.props.data.managerPhone}</div>
                                                            <div className="t2">{this.props.data.managerName}</div>
                                                        </div>
                                                        <div className={"w-100 col " + (this.state.showMap ? "isMap showAnimation" : "isMap hiddeAnimation")}>

                                                            <div className="row ">
                                                                <div className="col-12">
                                                                    {map}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                                

                                <InstallationsTree siteId={siteId} products={this.props.data.products} selectedKey={[]} notifications={this.props.notifications}></InstallationsTree>
                            </div>
                        </div >
                    ) : (

                            this.state._errors != null ? (<Error errors={this.state._errors} />) : (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                        )
                }
            </React.Fragment>
        );
    }

}
export default withTranslation()(SiteDetail);