import { CSSProperties, PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import {
  ProductsGeneralInfoLocationContainerProps,
  ProductsGeneralInfoLocationContainerState,
} from '../types/ProductsGeneralInfoLocation';
import ProductsGeneralInfoLocationView from '../views/ProductsGeneralInfoLocationView';

class ProductsGeneralInfoLocationContainer extends PureComponent<
  ProductsGeneralInfoLocationContainerProps,
  ProductsGeneralInfoLocationContainerState
> {
  static contextType = AuthenticationContext;

  constructor(props: ProductsGeneralInfoLocationContainerProps) {
    super(props);
    this.state = {
      showModal: false,
      productConnectionState: '',
      letter: '',
      iconUri: '',
      floorPlanUri: '',
      placed: false,
      dataLoaded: false,
    };
  }

  componentDidMount = () => {
    const { connectionState, productType, installation, floorPlanX, floorPlanY } = this.props.product.content;
    let placed: boolean | CSSProperties = false;
    if (floorPlanX) {
      placed = {
        position: 'absolute',
        left: `${floorPlanX}%`,
        top: `${floorPlanY}%`,
      };
    }
    this.setState({
      productConnectionState: `${connectionState}Square`,
      letter: productType.letter,
      iconUri: productType.iconUri ? productType.iconUri.replace('black', 'white') : '',
      floorPlanUri: installation.floorPlanUri,
      placed,
      dataLoaded: true,
    });
    {this.props.componentFinished("ProductsGeneralInfoLocationContainer");}
  };

  handleShowModal = (status: boolean) => {
    this.setState({ showModal: status });
  };

  render() {
    return this.state.dataLoaded ? (
      <ProductsGeneralInfoLocationView
        handleShowModal={this.handleShowModal.bind(this)}
        product={this.props.product}
        {...this.state}
      />
    ) : (
      ''
    );
  }
}

export default ProductsGeneralInfoLocationContainer;
