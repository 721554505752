import React, { Component } from 'react';

import '../../../App.css';
import '../../ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import ChartHttpClient from '../../../HttpClient/ChartHttpClient';
import DateSelector from '../../CustomComponents/CustomDateSelector';
import KPIsChartContainer from '../../ChartsComponents/container/KPIsChartContainer';

class SiteReportingModal extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);

    this.state = {
      _data: {},
    };
    this.id = 2;
    this.isTop = true;
    this.menuOpen = false;
  }

  //groupBySelected, dateRange
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getData();
    document.getElementById('dateSelector').parentNode.onscroll = function () {
      this.classList.toggle('notop', this.scrollTop > 90);
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const siteReporting = document.getElementById('siteReporting');
    if (siteReporting) {
      if (scrollPosition >= 108) {
        siteReporting.classList.add('active');
      } else {
        siteReporting.classList.remove('active');
      }
    }
  };

  getData() {
    this.getChartViewZones();
  }

  async getChartViewZones() {
    this.httpChart = new ChartHttpClient(this.context, null, null);
    var chartViewZones = await this.httpChart.SitesGetChartViewZones();
    if (chartViewZones !== undefined && chartViewZones.status == 200) this.setState({ _chartViewZones: chartViewZones.data });
  }

  handleGranularityChange(value) {
    this.setState({ groupBySelected: value });
  }
  handleDateRangeChange(value) {
    this.setState({ dateRange: value });
  }

  render() {
    const { t } = this.props;

    let dataLoaded = this.state._chartViewZones != null;

    let dateLoaded = this.state.dateRange != null;

    return (
      <div id='dateSelector'>
        {dataLoaded ? (
          <React.Fragment>
            <div id='siteReporting' className={`col p-0 my-3 sticky-div siteReporting `}>
              <DateSelector
                style={{ justifyContent: 'right' }}
                className={'row w-100 m-0'}
                defaultGranularity={'week'}
                onDateChange={this.handleDateRangeChange.bind(this)}
                onGranularityChange={this.handleGranularityChange.bind(this)}
                timeframes={['week', 'month', 'year']}
              />
            </div>

            {dateLoaded ? (
              <div
                className={'dashboard-charts general pl-5 pr-5 pb-5 pt-0 prodChildNoZoom m-0 p-3'}
                id='dashboard-charts'
                style={{ minHeight: '100%' }}
              >
                <KPIsChartContainer
                  site='all'
                  withoutBorder={true}
                  aggregateBy={'site'}
                  gender={[]}
                  type={[]}
                  area={[]}
                  showLegendAtPosition={2}
                  environment={[]}
                  familiesSelected={[]}
                  productTypesSelected={[]}
                  productsSelected={[]}
                  groupBySelected={this.state.groupBySelected}
                  dateRange={this.state.dateRange}
                  chartViewZones={this.state._chartViewZones}
                  pageZonePosition='Dashboard-Reporting'
                  legendType='sticky'
                  generalShadow={true}
                  legendTranslate={false}
                  page='site'
                />                
              </div>
            ) : null}
            <div className='d-none'>
              <img src={require('../../../assets/imgs/liters-reporting.PNG').default} className='img-fluid w-100' />
              <img src={require('../../../assets/imgs/liters-reporting2.PNG').default} className='img-fluid w-100' />
              <img
                src={require('../../../assets/imgs/liters-reporting-3.PNG').default}
                className='img-fluid w-100'
                style={{ marginTop: '40px', marginBottom: '40px' }}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(SiteReportingModal);
