import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { t } from 'i18next';
import Form from 'react-bootstrap/esm/Form';
import UserPreferencesContainer from '../container/UserPreferencesContainer';
import { ChangeEvent, useState } from 'react';
import { PreferenceGroup } from '../types/NotificationsPreferencesTypes';
import { MessageType } from '@ComponentsRoot/Management/types/MessageType';
import Message from '../../../../Message/Message';
import UserUnitsContainer from '../../UserConfigUnits/container/UserUnitsContainer';
import UserUnitsView from '../../UserConfigUnits/views/UserUnitsView';
import { User } from '@ComponentsRoot/Management/types/User';
import { Measurement, UserMeasure } from '../../UserConfigUnits/types/UserConfigUnitsTypes';

const UserPreferencesView = (props: {
  preferences: PreferenceGroup;
  handleChange: UserPreferencesContainer['handleChange'];
  updatePreferences: UserPreferencesContainer['updatePreferences'];
  message: MessageType | null;
  closeMessage: UserPreferencesContainer['closeMessage'];

  user: User;
  allMeasures: Measurement[];
  userMeasureUnits?: { initialData: UserMeasure[]; editData: UserMeasure[] };
  editUserMeasureUnits: UserMeasure[];
  postChanges: UserUnitsContainer['postChanges'];
  saveDisabled: boolean;
}) => {
  const [value, setValue] = useState('');
  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <section>
      {props.message && <Message {...props.message} onClose={props.closeMessage} />}
      <h2 className='mb-0'>{t('userpages.userNotificationPreferences')}</h2>
      <div className='row prodChildNoZoom p-4'>
        <h4 className='col-4' style={{ marginTop: '14px' }}>
          {' '}
          {t('userpages.alarms')}{' '}
        </h4>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.email')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='calarmemail'
                label=''
                defaultChecked={props.preferences.alarmPreferences.includes('email')}
                onChange={(evt) => props.handleChange('alarm-email')}
              />
            </Form>
          </div>
        </div>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.push')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='calarmpush'
                label=''
                defaultChecked={props.preferences.alarmPreferences.includes('push')}
                onChange={(evt) => props.handleChange('alarm-push')}
              />
            </Form>
          </div>
        </div>
        <h4 className='col-4' style={{ marginTop: '14px' }}>
          {' '}
          {t('userpages.warnings')}{' '}
        </h4>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.email')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='cwarningemail'
                label=''
                defaultChecked={props.preferences.warningPreferences.includes('email')}
                onChange={(evt) => props.handleChange('warning-email')}
              />
            </Form>
          </div>
        </div>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.push')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='cwarningpush'
                label=''
                defaultChecked={props.preferences.warningPreferences.includes('push')}
                onChange={(evt) => props.handleChange('warning-push')}
              />
            </Form>
          </div>
        </div>

        <h4 className='col-4' style={{ marginTop: '14px' }}>
          {' '}
          {t('userpages.rules')}{' '}
        </h4>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.email')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='cruleemail'
                label=''
                defaultChecked={props.preferences.rulePreferences.includes('email')}
                onChange={(evt) => props.handleChange('rule-email')}
              />
            </Form>
          </div>
        </div>
        <div className='col-4'>
          <div className='flexContainer'>
            <span className='t1'> {t('userpages.push')} </span>
            <Form style={{ marginTop: '12px' }}>
              <Form.Check
                type='checkbox'
                id='crulepush'
                label=''
                defaultChecked={props.preferences.rulePreferences.includes('push')}
                onChange={(evt) => props.handleChange('rule-push')}
              />
            </Form>
          </div>
        </div>
      </div>

      {/* <br></br>
      <br></br>
      <h2>{t('userpages.userViewingPreferences')}</h2>
      <div className='row prodChildNoZoom p-4 box-config-units' style={{ maxWidth: 600 }}>
        <h4 style={{ marginTop: '14px', width: '100%', marginBottom: '20px' }}>{t('userpages.units')}</h4>
        <div className='flexContainer'>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={value}
              onChange={handleChangeRadio}
            >
              <FormControlLabel value='metric' control={<Radio />} label='metric' />
              <FormControlLabel value='imperial' control={<Radio />} label='imperial' />
            </RadioGroup>
          </FormControl>
        </div>
      </div> */}

      <UserUnitsView
        user={props.user}
        allMeasures={props.allMeasures}
        editUserMeasureUnits={props.editUserMeasureUnits}
        postChanges={props.postChanges}
      />

      <Button
        className='btn-save-notification primary pull-right float-right'
        disabled={props.saveDisabled}
        onClick={async () => await props.updatePreferences()}
      >
        {t('actions.save')}
      </Button>
    </section>
  );
};

export default UserPreferencesView;
