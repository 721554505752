import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Form from 'react-bootstrap/Form';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ParameterToolTipV1 from "./ParameterToolTipV1";

class CustomCheckbox extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, value: (this.props.parameterValue == 1 || this.props.parameterValue == true ) };
    }

    componentDidMount() {
        this.setState({ value: (this.props.parameterValue == 1 || this.props.parameterValue == true) });
        
    }


    handleChange = (evnt) => {
        this.setState({ value: evnt.target.checked });

        let value = evnt.target.checked ? 1 : 0
        if (this.props.onChangeCheckbox) this.props.onChangeCheckbox(value, this.props.paramObj.id)
        if (this.props.toSend) this.props.toSend(this.props.paramObj, value);

        //Call parent onchange
        if (typeof this.props.onChange === 'function') {
            this.props.onChange('checkbox');
        }
    };

    onMouseOverItem = e => {
        let menuPosition = document.getElementsByClassName("MuiAppBar-root")[0].getBoundingClientRect().top;
        let sliderPosition = document.getElementById(e.currentTarget.id).getBoundingClientRect().top

        let offset = sliderPosition - menuPosition;

        let element = document.getElementById("tooltip-" + e.currentTarget.id.replace('customCheckbox-', ''));

        if (offset < 325) {
            if (element != undefined) {
                element.classList.remove('box');
                element.classList.add('BottomTooltip');
            }
        }
        else {
            if (element != undefined) {
                element.classList.remove('BottomTooltip');
                element.classList.add('box');
            }
        }
    }

    render() {
        let dataLoaded = false;
        const { t } = this.props;


        if (this.props.paramObj != null) {
            dataLoaded = true;
        //    this.setState();
        }

        const handleDisabledClick = (disabled)=>{
            if(this.props.handleDisabledMessage && disabled){
                this.props.handleDisabledMessage(disabled);
            }
        }

        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
            let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
            let description = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.description');
            
            try {
                let style = this.props.style ?? {};
                return (
                    <div id={'customCheckbox-' + this.props.paramObj.businessLabel} className="flexContainerNoWrap align-center mb-1 tooltipParent" style={style} onMouseOver={this.onMouseOverItem}>
                        <ParameterToolTipV1 values={this.props.telemetryObject} description={description}>
                        <span className="t2 col">
                                {name}
                        </span>
                        </ParameterToolTipV1>
                        {this.props.paramObj.infoText ?
                                <div className='info-tooltip-parameter'>
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        :""}
                        <Form style={{  marginLeft: 'auto', height: '18px' }}>
                        <div onClick={() => handleDisabledClick({deviceSetting:this.props.paramObj.isDeviceSettingsMode, readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
                            <input
                                type="checkbox"
                                id={'c' + this.props.paramObj.businessLabel}
                                label=""
                                defaultChecked={this.state.value}
                                onChange={(evt) => this.handleChange(evt, this.context)}
                                disabled={disabled}
                                className={disabled ? "custom-switch disabled-checkbox" : "custom-switch"}
                            />
                        </div>
        
                        </Form>
                    </div>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomCheckbox)