import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Form from 'react-bootstrap/Form';
import { withRouter } from './withRouter';
import Message from './Message/Message';
import UserHttpClient from "../HttpClient/UserHttpClient";
import AuthenticationContext from "../Authentication/types/AuthContextType";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

class NotificationPreferences extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            show: true,
            preferences: [],
            message: null
        }
    }

    componentDidMount() {
        this.httpUser = new UserHttpClient(this.context);
        this.setState({ show: true });
        this.getPreferences();
    }

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
          }
        });
      };
    
      handleDialogClose = () => {
        this.setState({ message: null });
      };

    async updatePreferences() {
        this.setState({ loading: true });
        let messageObj = {};
        let data = await this.httpUser.PostNotificationPreferences(this.state.preferences);
        if (data.errors) {
            messageObj.message = "error saving"
            messageObj.type = "error"
        }
        else {
            messageObj.message = "succesfully updated"
            messageObj.type = "success"
        }
        this.setState({ loading: false });
        this.renderMessage(messageObj.type, messageObj.message);
    }

    async getPreferences() {
        let data = await this.httpUser.GetNotificationPreferences();
        this.setState({ preferences: data.content, loading: false})
    }

    render() {

        const { t } = this.props

        let handleCloseConfirmCommand = () => {
            this.setState({ show: false })
            this.props.hide();
        }

        let handleChange = (code) => {
            let preferences = JSON.parse(JSON.stringify(this.state.preferences))

            let type = code.split('-')[0], channel = code.split('-')[1]

            let newChannel = true;
            preferences.forEach(preference => {
                if (preference.type === type) {
                    newChannel = false;
                    if (preference.channels.includes(channel)) {
                        preference.channels = preference.channels.filter(e => e !== channel);
                    }
                    else {
                        preference.channels.push(channel);
                    }
                }
            })

            if (newChannel) {
                preferences.push({
                    type: type,
                    channels: [channel]
                })
            }

            this.setState({ preferences: preferences})

        }

        let alarmPreferences = [], warningPreferences = [], rulePreferences = [];
        if (this.state.preferences) {
            this.state.preferences.forEach(preference => {
                switch (preference.type) {
                    case "alarm":
                        alarmPreferences = preference.channels;
                        break;
                    case "warning":
                        warningPreferences = preference.channels;
                        break;
                    case "rule":
                        rulePreferences = preference.channels;
                        break;
                }
            })
        }

        return (
            <React.Fragment>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <Dialog
                    disableEscapeKeyDown
                    open={this.state.show}
                    onClose={handleCloseConfirmCommand}>
                    <DialogActions className="jc-spaceBetween">
                        <h2 className="mb-0 ml-4">{t('userpages.userNotificationPreferences')}
                        </h2>
                        <IconButton onClick={handleCloseConfirmCommand}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </DialogActions> 
                    <DialogContent>
                        {this.state.loading ? (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                            : (
                                <div className="row prodChildNoZoom p-4">
                                    <h4 className="col-4" style={{ marginTop: '14px' }}> Alarms </h4>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> email </span>
                                            <Form style={{ marginTop: '12px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='calarmemail'
                                                    label=""
                                                    defaultChecked={alarmPreferences.includes('email')}
                                                    onChange={(evt) => handleChange('alarm-email')}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> push </span>
                                            <Form style={{ marginTop: '12px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='calarmpush'
                                                    label=""
                                                    defaultChecked={alarmPreferences.includes('push')}
                                                    onChange={(evt) => handleChange('alarm-push')}
                                                />
                                            </Form>
                                        </div>
                                    </div>

                                    <h4 className="col-4" style={{ marginTop: '14px' }}> Warnings </h4>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> email </span>
                                            <Form style={{ marginTop: '12px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cwarningemail'
                                                    label=""
                                                    defaultChecked={warningPreferences.includes('email')}
                                                    onChange={(evt) => handleChange('warning-email')}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> push </span>
                                            <Form style={{ marginTop: '12px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cwarningpush'
                                                    label=""
                                                    defaultChecked={warningPreferences.includes('push')}
                                                    onChange={(evt) => handleChange('warning-push')}
                                                />
                                            </Form>
                                        </div>
                                    </div>

                                    <h4 className="col-4" style={{ marginTop: '14px' }}> Rules </h4>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> email </span>
                                            <Form style={{ marginTop: '12px'}}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='cruleemail'
                                                    label=""
                                                    defaultChecked={rulePreferences.includes('email')}
                                                    onChange={(evt) => handleChange('rule-email')}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="flexContainer">
                                            <span className="t1"> push </span>
                                            <Form style={{ marginTop: '12px' }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id='crulepush'
                                                    label=""
                                                    defaultChecked={rulePreferences.includes('push')}
                                                    onChange={(evt) => handleChange('rule-push')}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-between" }}> 
                        <Button disabled={this.state.loading} className="primary " onClick={handleCloseConfirmCommand}>{t('actions.close')}</Button>
                        <Button disabled={this.state.loading} className="primary pull-right float-right" onClick={() => this.updatePreferences()}>{t('actions.save')}</Button> 
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

}
export default withTranslation()(withRouter(NotificationPreferences));