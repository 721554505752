import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GatewaysHttpClient from "../../HttpClient/GatewaysHttpClient";
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

export class GatewayFirmwareUpdate extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            screen: 'update',
            firmwares: [],
            showConfirmCommand: false,
            version: null,
            message: null
        }

        this.updateFirmware = this.updateFirmware.bind(this);
    }

    componentDidMount() {
        this.http = new GatewaysHttpClient(this.context);
        this.getTwinVersions()
    }

    async getTwinVersions() {
        try {
            let data = await this.http.GetGatewayTwinsFirmware(this.props.gateway.id);
            if (data.errors != null) {
                this.setState({ _errors: data.errors, loading: false });
            }
            else {
                let screen = 'update'
                if (data.content && data.content.desiredVersion != data.content.reportedVersion && data.content.desiredVersion != "*") {
                    screen = 'updating'
                }
                this.setState({loading: false, screen: screen})
            }

        }
        catch (err) {
            this.setState({ loading: false });
            console.error(err)
        }
    }

    async getAvailableVersions() {
        try {
            this.setState({ loading: true })
            let data = await this.http.GetGatewayAvailableFirmwares(this.props.gateway.gatewayTypeId);
            if (data.errors != null) {
                this.setState({ _errors: data.errors, loading: false });
            }
            else {
                let screen = 'list'
                this.setState({loading: false, screen: screen, firmwares: data.content})
            }

        }
        catch (err) {
            this.setState({ loading: false });
            console.error(err)
        }
    }

    setMessage(type, text) {
        this.setState({ message: { type: type, text: text } });
    }

    async updateFirmware() {

        try {

            if (this.state.version) {

                this.setState({ loading: true, showConfirmCommand: false});

                let twin = [{
                    gatewayId: this.props.gateway.id,
                    osVersion: this.state.version,
                    autoUpgrade: true
                }]

                let data = await this.http.PutFirmwareVersion(twin);
                if (data.errors != null) {
                    this.setState({ _errors: data.errors, loading: false });
                    this.setMessage("error", data.errors);
                }
                else this.setState({ loading: false, screen: 'updating' });
            }

        }
        catch (err) {
            this.setState({ loading: false });
            console.error(err);
            this.setMessage("error", err);
        }
    }
    showAlert() {

        let r = <div className="mt-4">
            <Alert severity={this.state.message.type}>{this.state.message.text}</Alert>
        </div>
        return r;
    }
    renderSwitch() {

        const { t } = this.props;

        let shouldUpdate = this.props.firmwares && this.props.firmwares.activeVersion != this.props.firmwares.newestVersion ? true : false;

        let getAvailableVersions = () => {
            this.getAvailableVersions();
        } 

        let back = () => {
            //Back
            this.setState({screen:'update'})
        }

        switch (this.state.screen) {
            case 'update':
                return (
                    <div>
                        {
                            shouldUpdate ? (
                                <div>
                                    <div className="p-4">
                                        <h3 style={{ display: 'flex', padding: '12px 0' }}> Current version is:  <span className={"h4"}  style={{ display: 'contents' }}>{this.props.firmwares.activeVersion}</span></h3>
                                        <h3 style={{ display: 'flex', padding: '12px 0' }}> Do you want to update to the newer version?  <span className={"h4"} style={{ display: 'contents' }}>{this.props.firmwares.newestVersion}</span></h3>
                                    </div>
                                    <div className="col-12" >
                                        {this.state.message != null ? this.showAlert() : null}
                                    </div>
                                    <div className="col-12" style={{ paddingLeft: '0' }}>
                                        <Button style={{ marginLeft: '0', height: "45px" }} className="primary" onClick={() => { this.setState({ showConfirmCommand: true, version: this.props.firmwares.newestVersion }) }}>{t('actions.updatename', { name: 'firmware' })}</Button>
                                        <Button  className="secondary pull-right float-right" onClick={getAvailableVersions}>firmwares</Button>
                                    </div>
                                </div>
                            ) :
                            (<div>
                                <div className="p-4">
                                    <h3 className="pt-0"> {t('firmware.latestVersionInstalled') } </h3> 
                                </div>
                                <div className="col-12" style={{ paddingLeft: '0' }}>
                                    <Button className="secondary pull-right float-right" onClick={getAvailableVersions}>firmwares</Button>
                                </div>
                            </div>

                            )
                        }
                    </div>
                )
            case 'list':
                return (
                    <div>
                        <div className="p-4">
                            <h3 className="pt-0">{t('firmware.selectVersion') }</h3>
                            {this.state.firmwares.map((item, p) => (
                                <div className="row smartComponentRow" key={p}>
                                    <div className="col-12" onClick={() => { this.setState({ showConfirmCommand: true, version: item.version }) }}>
                                        <h4 style={{ margin: 0 }}>{item.version} - {t('firmware.uploadedBy') } {item.uploadBy }</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-12" style={{ paddingLeft: '0' }}>
                            <Button className="secondary pull-right float-right" onClick={back}>{t('actions.back')}</Button>
                        </div>
                    </div>
                )
            case 'updating':
                return (
                    <div className="p-4">
                        <h3 className="pt-0" style={{ display: 'flex', padding: '12px 0' }}> Device is currently updating. It can take some minutes to update. </h3>
                    </div>
                )
        }
    }

    render() {

        const { t } = this.props

        let handleCloseConfirmCommand = () => {
            this.setState({ showConfirmCommand: false})
        }

        return (
            <React.Fragment>
                {/*<DialogActions>*/}
                {/*    <IconButton color="primary" onClick={() => this.props.handleClose()}>*/}
                {/*        <CloseIcon fontSize="large" />*/}
                {/*    </IconButton>*/}
                {/*</DialogActions>*/}
                <Dialog
                    disableEscapeKeyDown
                    open={this.state.showConfirmCommand}
                    onClose={handleCloseConfirmCommand}>
                    <DialogTitle>{t('actions.confirm')}</DialogTitle>
                    <DialogContent>
                        {t('actions.updatename', { name: 'firmware' })}? - {this.state.version}
                    </DialogContent>
                    <DialogActions>
                        <Button className="primary pull-right float-right" onClick={handleCloseConfirmCommand}>{t('actions.close')}</Button>
                        <Button className="primary pull-right float-right" onClick={() => this.updateFirmware()}>{t('actions.confirm')}</Button>
                    </DialogActions>
                </Dialog>
                {/*<IconButton*/}
                {/*    onClick={() => this.props.handleClose()}*/}
                {/*    aria-label="close"*/}
                {/*    className="closeIcon"*/}
                {/*    size="large">*/}
                {/*    <CloseIcon fontSize="large" />*/}
                {/*</IconButton>*/}
                {this.state.loading ? (
                    <div className="row loadingParent">< div className="loadingImg"></div></div>
                ) : (
                        <div>
                            {this.renderSwitch()}
                        </div>
                    )
                }
                
            </React.Fragment>
        );
    }

}
export default withTranslation()(GatewayFirmwareUpdate);