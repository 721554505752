import React, { Component } from 'react';
import '../../App.css';

import { withTranslation } from 'react-i18next';

import AuthenticationContext from '../../Authentication/types/AuthContextType';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import InstallationsHttpClient from '../../HttpClient/InstallationsHttpClient';
import SitesHttpClient from '../../HttpClient/SitesHttpClient';
import GatewaysHttpClient from '../../HttpClient/GatewaysHttpClient';
import UserHttpClient from '../../HttpClient/HttpClient';

import moment from 'moment';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Message from '../Message/Message';

import Button from '@mui/material/Button';
import { manageInfoUser } from '../Common/utils/helpers';
import DatePicker from "react-datepicker";
import { BorderBottom } from '@mui/icons-material';

class Invitation extends Component {
  static contextType = AuthenticationContext;

  // References are declared as an empty property without initializing it in the constructor (because it does not exist in the dom yet)
  select2Ref = null;
  select3Ref = null;
  select5Ref = null;

  constructor(props) {
    super(props);

    this.select1Ref = React.createRef();
    this.select4Ref = React.createRef();

    this.installationId = this.props.installationId;
    this.gatewayId = null;
    this.userPage = false;
    this.state = {
      _data: {},
      open: false,
      dates: [null, null],
      invitationType: '',
      invitationState: 'Pending',
      saving: false,
      page: 0,
      sizePerPage: 5,
      roleSelected: this.props.roleSelected,
      message: null,
      installationImage:
        this.props.installationImage ??
        'https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg',
    };
    this.saveDataInvitation = this.saveDataInvitation.bind(this);
    this.invitation = {};

    this.key = 0;
  }
  async componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.httpUser = new UserHttpClient(this.context);
    this.httpGateway = new GatewaysHttpClient(this.context);
    if (this.props.userPage != null && this.props.userPage != undefined) {
      this.userPage = this.props.userPage;
      await this.getInstallations();
      await this.getSites();
      await this.getGateways(this.state.page, this.state.sizePerPage);
    }
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getInstallations() {
    let data = null;
    this.httpInstallation = new InstallationsHttpClient(this.context);
    data = await this.httpInstallation.InstallationGetInstallations();
    this.setState({ installations: data });
  }

  async getSites() {
    let data = null;
    this.httpSite = new SitesHttpClient(this.context);
    data = await this.httpSite.GetSites();
    this.setState({ sites: data });
  }

  async getGateways(page, sizePerPage) {
    this.setState({ gateways: {} });
    let me = await this.getMe();
    let data = await this.getMeOwershipGateways(me.userId, page, sizePerPage);
    this.setState({ gateways: data, numGateways: data.content.length });
  }

  async getMeOwershipGateways(userId, page, sizePerPage) {
    const { t } = this.props;
    let data = await this.httpGateway.GetGatewaysFilter('userId=' + userId, page, sizePerPage);
    if (data.errors == null) return data;
    else {
      this.renderMessage('error', t('messages.errorGettingGateways'));
    }
  }

  async getMe() {
    const userBasicData = await manageInfoUser(this.context, 'userBasicData');
    if (userBasicData) {
      return userBasicData;
    } else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async saveDataInvitation(e) {
    this.setState({ saving: true });
    // Modificamos los datos en this.installation para luego comparar con los datos del this.state.data y generar el patch
    e.preventDefault();
    try {
      const mail = document.getElementById('txtMail').value;
      let newInvitation = {
        installationId: this.installationId,
        gatewayId: this.gatewayId,
        type: this.state.invitationType,
        state: this.state.invitationState,
        roleId: null,
        mail: mail,
        fromDate: this.state.dates[0] == null ? null : new Date(this.state.dates[0]).toISOString(),
        expiredAt: this.state.dates[1] == null ? null : new Date(this.state.dates[1]).toISOString(),
        brand: this.context.brandCode == 11 ? 'Laufen' : 'Roca',
        callbackUrl: `${window.location.origin}/user?from=invitations&brand=${this.context.brandCode}&mail=${mail}`,
        invitationInstallationImg: this.props.installationImage
      };

      const formulario = new FormData();
      formulario.append('patchoptions', JSON.stringify(newInvitation));

      let data;

      data = await this.SendPost(formulario);

      this.props.onSaveClose(data);
    } catch (e) {
      this.setState({ saving: false });
    }
  }

  async SendPost(formulario) {
    let data = await this.http.PostInvitation(formulario);

    this.props.onSaveClose(data);

    return data;
  }

  handleChange = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.setState({ roleSelected: event.target.value });
  };

  cleanCustomValidityByNameRef = (nameRef) => {
    const select = this[nameRef].current.childNodes[1];
    select.setCustomValidity('');
  };

  handleChangeInvitationType = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.setState({ invitationType: event.target.value });

    // Create and assign the reference when the component is displayed
    if (!this.select2Ref || !this.select3Ref || !this.select5Ref) {
      this.select2Ref = React.createRef();
      this.select3Ref = React.createRef();
      this.select5Ref = React.createRef();
    }
  };


  handleChangeInstallations = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.installationId = event.target.value;
  };

  handleChangeGateways = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.gatewayId = event.target.value;
  };

  dropdownRoles() {
    const handleChange = (event) => {
      //this.product.parentId = event.target.value;
      this.setState({ parentid: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.props.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.props.roles.map((item, i) => {
        this.key++;
        return (
          <MenuItem key={this.key} value={item.id}>
            {item.name}
          </MenuItem>
        );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='roleLabel'>{t('invitation.role')}</InputLabel>
          <Select
            ref={select1Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
            }}
            variant='standard'
            name='Role'
            className='titleProperty'
            labelId='roleLabel'
            id='ddlRoles'
            label='Roles'
            type='email'
            value={this.state.roleSelected != null ? this.state.roleSelected : ''}
            required
            onChange={(e) => this.handleChange(e, 'select1Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  dropdownInstallationsAndSites() {
    const { t } = this.props;

    let dataLoaded = this.state.installations != undefined && this.state.sites != undefined;
    let listInstallations = null;
    let listSites = null;
    if (dataLoaded && this.userPage) {
      listInstallations = this.state.installations.content.map((item, i) => {
        this.key++;
        if (item.hasOwnerAccess)
          return (
            <MenuItem key={'i' + this.key} value={item.id}>
              {item.name}
            </MenuItem>
          );
      });
      listSites = this.state.sites.content.map((item, i) => {
        this.key++;
        if (item.hasOwnerAccess)
          return (
            <MenuItem key={'s' + this.key} value={item.id}>
              {item.name}
            </MenuItem>
          );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='installationLabel'>{t('installation')}</InputLabel>
          <Select
            ref={this.select2Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
            }}
            defaultValue=''
            variant='standard'
            name='installations'
            className='titleProperty'
            labelId='installationLabel'
            id='installations'
            label={t('installation')}
            required
            onChange={(e) => this.handleChangeInstallations(e, 'select2Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listSites}
            {listInstallations}
          </Select>
        </FormControl>
      );
    } else if (!dataLoaded && this.userPage) {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  dropdownGateways() {
    const { t } = this.props;

    let dataLoaded = this.state.gateways != undefined;
    let listGateways = null;
    if (dataLoaded && this.userPage) {
      listGateways = this.state.gateways.content.map((item, i) => {
        this.key++;
        return (
          <MenuItem key={this.key} value={item.id}>
            {item.name}
          </MenuItem>
        );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='gatewayLabel'>{t('gateway')}</InputLabel>
          <Select
            ref={this.select3Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
            }}
            variant='standard'
            name='gateways'
            className='titleProperty'
            labelId='gatewayLabel'
            id='gateways'
            label={t('gateway')}
            required
            onChange={(e) => this.handleChangeGateways(e, 'select3Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listGateways}
          </Select>
        </FormControl>
      );
    } else if (!dataLoaded && this.userPage) {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  setDateRange(dates){
    this.setState({dates});
  }

  render() {
    const { t } = this.props;

    const handleCloseModal = () => {
      this.props.onSaveClose();
    };

    let dataLoaded = true; //this.state.data != undefined && this.state.mySites != undefined;

    return (
      <React.Fragment>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

        {dataLoaded ? (
          <div>
            <form id='divForm' onSubmit={(e) => this.saveDataInvitation(e)}>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <TextField
                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onChange={(e) => e.target.setCustomValidity('')}
                    variant='standard'
                    id='txtMail'
                    label={t('invitation.mail')}
                    placeholder={t('invitation.mail')}
                    helperText=''
                    fullWidth
                    required
                    margin='normal'
                    type='email'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=''
                  />
                </div>
                <div className='form-group col-md-12'>
                  <div style={{ width: '100%' }}>
                    <InputLabel id='invitationTypeLabel'>{t('invitation.invitationType')}</InputLabel>
                    <Select
                      ref={this.select4Ref}
                      inputProps={{
                        onInvalid: (event) => {
                          event.target.setCustomValidity(t('general.error_required'));
                        },
                      }}
                      style={{ width: '100%' }}
                      variant='standard'
                      name='InvitationType'
                      className='titleProperty'
                      labelId='invitationTypeLabel'
                      id='ddlExpired'
                      label={t('invitation.invitationType')}
                      value={this.state.invitationType}
                      required
                      onChange={(e) => this.handleChangeInvitationType(e, 'select4Ref')}
                    >
                      <MenuItem value='InstallationAccess'>{t('invitation.installationAccess')}</MenuItem>
                      <MenuItem value='InstallationTransferOwnership'>{t('invitation.installationTransferOwnership')}</MenuItem>
                      <MenuItem value='GatewayTransferOwnership'>{t('invitation.gatewayTransferOwnership')}</MenuItem>
                    </Select>
                  </div>
                </div>
                {this.state.invitationType == 'InstallationAccess' ? (
                  <div className='form-group col-md-12'>
                    <div style={{ width: '100%' }}>
                      <InputLabel id='expiredLabel'>{t('invitation.accessPeriod')}</InputLabel>
                      <DatePicker style={{BorderBottom: 'solid 1px', width: '100%'}}
                        selectsRange
                        startDate={this.state.dates[0]}
                        endDate={this.state.dates[1]}
                        onChange={(update) => {
                          this.setDateRange(update);
                        }}
                        isClearable={true}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  {this.state.invitationType == 'InstallationAccess' ||
                  this.state.invitationType == 'InstallationTransferOwnership'
                    ? this.dropdownInstallationsAndSites()
                    : null}
                  {this.state.invitationType.toLowerCase() == 'gatewaytransferownership' ? this.dropdownGateways() : null}
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  {this.state.saving ? (
                    <div style={{ position: 'absolute' }} className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary active'>
                    {t('actions.save')}
                  </Button>
                </div>
                <div className='form-group col-md-6'>
                  <Button className='primary pull-right' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Invitation);
