import Style from '../styles/EmptyBarChartView.module.scss';
import { EmptyBarchartProps } from '../types/ChartsComponents';
import ReactECharts from 'echarts-for-react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptyPieChartView = (props: EmptyBarchartProps) => {
  const option = {
    tooltip: {},
    series: [
      {
        type: 'pie',
        radius: ['45%', '30%'],
        label: {
          show: false,
        },
      },
    ],
    title: {
      text: '-',
      left: 'center',
      top: 'center',
      textStyle: {
        rich: {
          liters: {
            fontSize: 13,
          },
        },
      },
    },
  };

  return (
    <div className={Style.EmptyBarChartView}>
      <div className='container-EmptyBarChartView'>
        <div className='box-error'>
          {props.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
          {props.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
          <p className='error-text'>{props.message}</p>
        </div>
        <div className='box-chart'>
          <ReactECharts style={{ height: '400px' }} option={option} />
        </div>
      </div>
    </div>
  );
};

export default EmptyPieChartView;
