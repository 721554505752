import { t } from 'i18next';
import { ProductsGeneralInfoLocationViewProps } from '../types/ProductsGeneralInfoLocation';
import Style from '../styles/ProductsGeneralInfoLocationView.module.scss';
import NoData from '../../../../NoData';
import ModalProdLocationZoomView from './ModalProdLocationZoomView';
import { CSSProperties } from 'react';

const ProductsGeneralInfoLocationView = (props: ProductsGeneralInfoLocationViewProps) => {
  return (
    <div className={Style.ProductsGeneralInfoLocationView}>
      <div className='productsGeneralInfoLocationView-content'>
        <div>
          <div className='titleGeneralInfo d-flex mb-4'>
            <h3 className='p-0'>{t('productspages.general.floormap')}</h3>
            {props.placed ? <div className='containerZoom col m-0' onClick={() => props.handleShowModal(true)}></div> : null}
          </div>
          <div className='prodChildNoZoom'>
            <div id='locationContent' className='row rowChildInfo'>
              <div className='col'>
                <div className='row'>
                  <div className='box-row'>
                    <div className='inside-box-row'>
                      {props.placed ? (
                        <div style={props.placed as CSSProperties} id='drop' className='not-drag-drop'>
                          <div className='prodInstallationManagment centerObj'>
                            <div className={props.productConnectionState}>
                              <div className='stateDiv'>
                                <div>
                                  <img className={'state-' + props.product.content.connectionState} src='' />
                                  <img src={props.iconUri} />
                                  <span>
                                    {props.letter}
                                    {props.product.content.tag}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {props.floorPlanUri ? (
                        <canvas
                          id='inner-dropzone'
                          className='dropzone'
                          style={{
                            backgroundImage: `url('${props.floorPlanUri}')`,
                          }}
                        ></canvas>
                      ) : (
                        <div className='NoData-box'>
                          <NoData />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.showModal && <ModalProdLocationZoomView {...props} />}
        </div>
      </div>
    </div>
  );
};

export default ProductsGeneralInfoLocationView;
