import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

class NoData extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { t } = this.props;
        let style = {}
        if (this.props.propStyle)
            style = this.props.propStyle

        return (
            <div style={style}>
                <NotInterestedIcon style={{ margin: '0 auto', display: 'block'}} fontSize="large"/>
                <div> <h4 style={{textAlign: 'center'}}>{t('nodata')}</h4></div>
            </div>
        );
    }
}
export default withTranslation()(NoData)