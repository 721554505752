import React, { Component, useContext } from 'react';
import '../../App.css';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import Invitations from './Invitations';
import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Message from '../Message/Message';
import Table from '../CustomComponents/ReactTable/Table';

class InvitationsOutbox extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.state = {
      invitations: {},
      numInvitations: 0,
      page: localStorage.getItem('InvitationsOutboxPage') ? parseInt(localStorage.getItem('InvitationsOutboxPage')) : 1,
      sizePerPage: localStorage.getItem('InvitationsOutboxSizePerPage') ? parseInt(localStorage.getItem('InvitationsOutboxSizePerPage')) : 5,
      showDelete: false,
      showInvitation: false,
      message: null,
    };

    this.paginaActual = 1;

    this.confirmDelete = this.confirmDelete.bind(this);
    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'receiverMail',
        text: this.props.t('invitation.to'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'installationId',
        text: 'InstallationId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'gatewayId',
        text: 'GatewayId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'installationName',
        text: this.props.t('invitation.sitesInstallations'),
        align: 'left',
        sort: true,
        hidden: !this.userPage,
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'gatewayName',
        text: 'Gateways',
        align: 'left',
        sort: true,
        hidden: !this.userPage,
        headerStyle: () => {
          return { width: '11%' };
        },
      },
      {
        dataField: 'type',
        text: this.props.t('invitation.type'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.TypeFormatter.bind(this),
        headerStyle: () => {
          return { width: '15%' };
        },
      },
      {
        dataField: 'state',
        text: this.props.t('invitation.state'),
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '9%' };
        },
      },
      {
        dataField: 'expiredAt',
        text: this.props.t('invitation.expiredAt'),
        align: 'left',
        formatter: this.DateFormatter.bind(this),
        sort: true,
        headerStyle: () => {
          return { width: '11%' };
        },
      },
      {
        dataField: 'EditFormatter',
        text: ' ',
        align: 'center',
        formatter: this.EditFormatter.bind(this),
        hidden: true,
        headerStyle: () => {
          return { width: '50' };
        },
      },
      {
        dataField: 'DeleteFormatter',
        text: ' ',
        align: 'center',
        hidden: false,
        formatter: this.DeleteFormatter.bind(this),
        headerStyle: () => {
          return { width: '50' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {this.props.t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  EditFormatter({ row, value }) {
    const onClick = (evt) => {
      evt.preventDefault();
      this.setState({
        showInvitation: true,
        role: evt.currentTarget.getAttribute('data-role'),
        invitationId: evt.currentTarget.id.split('_')[1],
        userId: evt.currentTarget.getAttribute('data-user'),
      });
    };

    let idEle = 'btnEdit_' + row.id;
    return (
      <EditOutlinedIcon
        id={idEle}
        style={{ width: '24px', cursor: 'pointer' }}
        onClick={(evt) => onClick(evt)}
        data-role={row.roleId}
        data-user={row.userId}
      />
    );
  }

  TypeFormatter({ row, value }) {
    if (row.type) {
      switch (row.type.toLowerCase()) {
        case 'installationaccess':
          return <span>Installation access</span>;
        case 'installationtransferownership':
          return <span>Installation Transfer ownership</span>;
        case 'gatewaytransferownership':
          return <span>Gateway transfer ownership</span>;
      }
    } else return <span>Installation access</span>;
  }

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  DeleteFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showDelete: true, invitationId: evt.currentTarget.id.split('_')[1] });
    };

    let idEle = 'btnDelete_' + row.id;
    if (row.state == null || row.state == 'Pending') {
      return <DeleteOutlineIcon id={idEle} style={{ width: '24px', cursor: 'pointer' }} onClick={(evt) => onClick(evt)} />;
    } else {
      return null;
    }
  }

  async confirmDelete(t) {
    let messageObj = {};
    let showDelete = false;

    try {
      let data = await this.http.deleteInvitation(this.state.invitationId);
      if (data.errors == null) {
        showDelete = false;
        messageObj.message = this.props.t('invitation.invitationDeleted');
        messageObj.type = 'success';
      } else {
        showDelete = false;
        messageObj.message = this.props.t('invitation.errorDeletingInvitation');
        messageObj.type = 'error';
      }
      this.setState({ showDelete: showDelete, invitations: null }, () => {
        this.getInvitations(this.state.page, this.state.sizePerPage);
      });
      if (messageObj.type) {
        this.renderMessage(messageObj.type, messageObj.message);
      }
    } catch (error) {
      console.error(error);
      this.setState({ showDelete: false });
      this.renderMessage('error', this.props.t('invitation.errorDeletingInvitation'));
    }
  }

  componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.getRoles();
    this.getInvitations(this.state.page, this.state.sizePerPage);
  }

  componentWillUnmount(){
    localStorage.removeItem("InvitationsOutboxSizePerPage");
    localStorage.removeItem("InvitationsOutboxPage");
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.getInvitations(page, sizePerPage);
  };

  async getInvitations(page, sizePerPage) {
    try {
      let data = null;
      if (this.id != null) data = await this.http.getInvitationsSentInstallation(this.id, page, sizePerPage);
      else data = await this.http.getInvitationsSent(page, sizePerPage);

      this.setState({ invitations: data, numInvitations: data.content.length });
    } catch (error) {
      console.error(error);
      this.renderMessage('error', this.props.t('invitation.errorReceivingInvitation'));
    }
  }

  dropdownPermissions() {
    const handleChange = (event) => {
      this.setState({ newRole: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.state.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.state.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='permissionLabel'>Select permission level</InputLabel>
          <Select
            variant='standard'
            name='permission'
            className='titleProperty1'
            labelId='permissionLabel'
            placeholder='Select an option'
            id='ddlRoles'
            value={this.state.role}
            onChange={handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }
  toggleInvitations() {
    this.setState({ showInvitation: !this.state.showInvitation });
  }

  async getRoles() {
    let data = await this.http.getRoles();
    this.setState({ roles: data.content });
  }

  render() {
    let dataLoaded = this.state.invitations?.content != undefined;
    if (dataLoaded) {}

    const handleDeleteInvitation = () => {
      this.setState({ showDelete: false });
    };

    const handleClose = (data) => {
      let messageObj = {};
      let showMessage = false;
      let showInvitation = false;

      if (data) {
        showMessage = true;

        if (data.errors) {
          messageObj.message = data.errors[0];
          messageObj.type = 'error';
        } else if (data.status > 200) {
          messageObj.message = data.title;
          messageObj.type = 'error';
        } else if (data.content?.hasUpdated) {
          messageObj.message = this.props.t('invitation.invitationUpdated');
          messageObj.type = 'info';
        } else {
          messageObj.message = this.props.t('invitation.userInvited');
          messageObj.type = 'success';
        }
      }

      this.setState({ showInvitation: showInvitation, invitations: null }, () => {
        this.getInvitations(this.state.page, this.state.sizePerPage);
      });

      if (showMessage == true) {
        if (messageObj.type) {
          this.renderMessage(messageObj.type, messageObj.message);
        }
      }
    };

    const onSizePerPageChange = (sizePerPage) => {
      localStorage.setItem('InvitationsOutboxSizePerPage', sizePerPage)
      this.setState({ sizePerPage: sizePerPage });
    }
  
    const onPageChange = (newPage) => {
      localStorage.setItem('InvitationsOutboxPage', newPage)
      this.paginaActual = newPage;
      this.setState({ page: newPage });
    }

    return (
      <div className='col' style={{ padding: 0, margin: 0 }}>
        <div className='row col-12' style={{ padding: 0, margin: 0 }}>
          {dataLoaded ? (
            <div className='col chartContainer' style={{ padding: 0, margin: 0 }}>
              {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
              {!this.userPage ? (
                <div className='row ml-1'>
                  {' '}
                  <h3>{this.props.t('invitation.invitationtitle')}</h3>{' '}
                </div>
              ) : null}
              <Table
                name={'InvitationsOutbox-locales'}
                t={this.props.t}
                resize={true}
                columns={this.columns}
                onSizePerPageChange={onSizePerPageChange}
                onPageChange={onPageChange}
                rows={this.state.invitations.content.sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })}
                search={true}
                page={this.state.page}
                pageSize={this.state.sizePerPage}
              />

              <div className='form-row'>
                <div className='form-group col-md-6 buttonInvitations'>
                  <Button className='primary' onClick={() => this.toggleInvitations()}>
                    {this.props.t('actions.addname', { name: this.props.t('general.invitation') })}
                  </Button>
                </div>
              </div>

              <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleDeleteInvitation}>
                <DialogTitle>{this.props.t('actions.deletename', { name: this.props.t('general.invitation') })}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{this.props.t('actions.confirmdelete', { name: this.props.t('general.invitation') })}</span>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button className='primary pull-right' onClick={() => this.confirmDelete(this.props.t)}>
                    {this.props.t('actions.yes')}
                  </Button>
                  <Button className='primary pull-right' onClick={handleDeleteInvitation}>
                    {this.props.t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog disableEscapeKeyDown={false} open={this.state.showInvitation}>
                <DialogTitle>{this.props.t('invitation.newInvitation')}</DialogTitle>
                <DialogContent>
                  <div id='divForm'>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <Invitations
                          installationId={this.id}
                          roles={this.state.roles}
                          userPage={this.userPage}
                          roleSelected={this.state.role}
                          userId={this.state.userId}
                          invitationId={this.state.invitationId}
                          onSaveClose={handleClose}
                          installationImage={this.props.installationImage}
                        ></Invitations>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </div>
          ) : (
            <div className='loadingParent'>
              <div className='loadingImg'></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(InvitationsOutbox);
