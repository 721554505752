import React, { useState, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const Message = ({ type, content, onClose, fixed = false, ms = 5000 }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!fixed) {
      const timer = setTimeout(() => {
        setOpen(false);
        onClose && onClose();
      }, ms);
      return () => clearTimeout(timer);
    }
  }, [type, fixed]);

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckIcon style={{ color: '#2ecc71' }} />;
      case 'error':
        return <ErrorOutlineOutlinedIcon style={{ color: '#e74c3c' }} />;
      case 'warning':
        return <ReportProblemOutlinedIcon style={{ color: '#f1c40f' }} />;
      case 'info':
      default:
        return <InfoOutlinedIcon style={{ color: '#2980b9' }} />;
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      classes={{ root: 'responseDialogRoot messageDialog', container: 'responseDialogContainer' }}
      BackdropProps={{ classes: { root: 'responseDialogBackdrop' } }}
    >
      <DialogContent>
        {renderIcon()} <span className='t2'>{content}</span>
      </DialogContent>
    </Dialog>
  );
};

export default Message;