import React, { Component } from 'react';
import IconButton from "@mui/material/IconButton";
import { withTranslation } from 'react-i18next';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { withRouter } from '../../withRouter';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FilterListPage from '../../FilterComponents/FilterListPage';

class GatewayGeneral extends Component {
    constructor(props) {
        super(props);
    }

    goToInstallation = (id, parentId) => {

        let url = ""

        if (parentId == 0 || !parentId) {
            url = "/site/" + id
        }
        else {
            url = "/installation/" + id
        }

        this.props.navigate(url)
    }

    calculateSignalLevel(rssi, numLevels) {
        const MIN_RSSI = -100;
        const MAX_RSSI = -55;

        if (rssi <= MIN_RSSI) {
            return 0;
        } else if (rssi >= MAX_RSSI) {
            return numLevels - 1;
        } else {
            let inputRange = (MAX_RSSI - MIN_RSSI);
            let outputRange = (numLevels - 1);
            let value = ((rssi - MIN_RSSI) * outputRange / inputRange);
            return parseInt(((rssi - MIN_RSSI) * outputRange / inputRange));
        }
    }
    renderTooltip = (text) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    render() {
        const { t } = this.props;

        
        if (this.props.gateway) {
            let gateway = this.props.gateway
            let products = gateway.products ?? []

            return (
                <React.Fragment>
                    <div className="row search-box">
                        <div className="col-md-3 col-12">
                            {
                                (gateway.installations && gateway.installations.length > 0 && gateway.installations[0] != null) ? (
                                    <div>
                                        <h3 className="mb-2">{t('installations')}:</h3>
                                        {
                                            gateway.installations.map(el => <h4 key={el.id}>{el.name}
                                                <IconButton
                                                    style={{ padding: '5px' }}
                                                    onClick={() => { this.goToInstallation(el.id, el.parentId) }}
                                                    size="large">
                                                    <OpenInNewOutlinedIcon />
                                                </IconButton> </h4>)
                                        }
                                    </div>
                                ) : (null)
                            }
                        </div>
                        <div className="col-md-9 col-12">
                            <FilterListPage content={products} type={"gatewayProducts"} />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return <div className="row loadingParent">< div className="loadingImg"></div></div >;
        }
    }
}
export default withTranslation()(withRouter(GatewayGeneral))