// TODO finish types, clean anys

import { WithTranslation, withTranslation } from "react-i18next"
import { SWPackageProps, TableRowItem } from "../types/SWPackageTypes"
import React from "react";
import Error from '../../../../../Error';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '../../../../../CustomComponents/ReactTable/Table';
import Message from '../../../../../Message/Message';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SWAddPackage from "./SWAddPackage";
import SelectSearch from 'react-select'

const managePage = (errors) => {
    if (errors) {
        return (
            <Error errors={{ status: errors.code}} />
        );
    }
    else {
        return (
            <div className='row loadingParent'>
                <div className='loadingImg'></div>
            </div>
        );
    }
}

const filter = (props: SWPackageProps & WithTranslation) => (<React.Fragment>
    <h6 className="d-inline-block mr-3 mb-0 col-auto pl-0" >{props.t('tables.filter')}:</h6>

    <SelectSearch
        options={props.systemIds}
        value={props.selectedValue}
        className="device-custom-select-control"
        isClearable
        placeholder={props.t('firmwareManagementPage.systemId')}
        onChange={props.handleSystemChange}
        styles={{
            control: (provided, state) => ({
                ...provided,
                minHeight: '32px',
                height: '32px',
                borderRadius: '3px !important'
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '32px',
            }),
        }}
    />
</React.Fragment>);

const rowDetail = (props: SWPackageProps & WithTranslation) => (tableItem: TableRowItem) => {
    const { row } = tableItem;
    let list: any[] = [];
    row.smartComponentFirmwares.forEach((element, i) => {
        let v = (
            <div key={"smartComponentFirmwares" + i} className="col-12 pb-3 pl-2">
                <span className="t3">{element.smartComponentType.name} -&gt; {element.FirmwareVersion} </span>
            </div>);
        list.push(v);
    });
    if (list.length > 0) {
        return (<div className="row pl-5">
            <div className="col-12 mb-2">
                <h6>{props.t('firmwareManagementPage.smartComponentsFirmware')}</h6>
            </div>
            <div className="col pl-5">
                <div className="row">
                    {list}
                </div>
            </div>
        </div>)
    } else {
        return null;
    }
};

const SoftwarePackageView = (props: SWPackageProps & WithTranslation) => {
    var filterDropdown = filter(props)
    return (
        <React.Fragment>
            {props.packageLoaded && props.errors == null? (
                <React.Fragment>
                    {props.message && <Message {...props.message} onClose={props.handleMessageClose} />}
                    {/* Modal delete */}
                    <Dialog
                        disableEscapeKeyDown
                        open={props.showModalDelete}
                        onClose={props.handleClose}>
                        <DialogTitle>{props.t('actions.deletename', { name: props.t('firmwareManagementPage.package') })}</DialogTitle>
                        <DialogContent>
                            <div>
                                <span>{props.t('actions.confirmdelete', { name: props.t('firmwareManagementPage.package') })}</span>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="primary" onClick={() => props.confirmDelete()}>{props.t('actions.yes')}</button>
                            <button className="primary" onClick={props.handleCloseDelete}>{props.t('actions.no')}</button>
                        </DialogActions>
                    </Dialog>
                    {/**/}

                    {<Dialog classes={{ paper: "installationModal custom" }} disableEscapeKeyDown open={props.showPackage} onClose={props.handlePackageClose}>
                        <DialogActions className="jc-spaceBetween">
                            <h2 className="mb-0 ml-4">{props.t('firmwareManagementPage.stepOfTotal', { step: props.step, total: props.totalSteps })}</h2>
                            <IconButton color="primary" onClick={props.handlePackageClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogContent>
                            { <SWAddPackage
                                unmountMe={props.handlePackageCloseUpdate}
                                dataPackages={props.dataPackages}
                                productSystems={props.productSystems}
                                systemIds={props.systemIds}
                                handleFirmwareClick={props.handleFirmwareClick}
                                handleStepper={props.handleStepper}
                                showMessageInTable={props.showMessageInTable}
                                edit={props.editPackage}
                                package={props.packageRow}
                                modeForm={props.modeFirmwarePackageForm}
                                allSmartComponentTypes={props.allSmartComponentTypes}
                            /> }
                        </DialogContent>
                    </Dialog>}
                    <div className="card-container mt-4">
                        <div className="p-4">
                            <div className="row">
                                <div className="col">
                                    <h3>{props.t('firmwareManagementPage.packageManagement')}</h3>
                                </div>
                                <button onClick={props.openPackageDialog} className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right" tabIndex={0} type="button">
                                    <span className="MuiButton-label">{props.t('firmwareManagementPage.addPackage')}</span>
                                    <span className="MuiTouchRipple-root"></span>
                                </button>
                            </div>
                            {props.dataLoaded ?
                                <Table name={"PackageManagementGeneral-locales"} filter={filterDropdown} t={props.t} resize={true} columns={props.columns} rows={props.dataPackagesFiltered} search={false} page={props.page} pageSize={props.sizePerPage} RowDetail={rowDetail(props)} onSizePerPageChange={props.onSizePerPageChange} onPageChange={props.onPageChange}/>
                                : <div className="loadingParent"><div className="loadingImg"></div></div>
                            }
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                managePage(props.errors)
            )}
        </React.Fragment >
    );
}

export default withTranslation()(SoftwarePackageView)