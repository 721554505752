import React, { useState, useEffect, useContext } from 'react';
import CustomDateSelector from '../../CustomComponents/CustomDateSelector';
import GatewayInfoGrid from './GatewayInfoGrid';
import DeviceToGatewayTable from './DeviceToGatewayTable';
import GatewaysHttpClient from '../../../HttpClient/GatewaysHttpClient';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';

const GatewayStatistics = ({ gateway, t }) => {
  const [dateRange, setDateRange] = useState(null);
  const [groupBySelected, setGroupBySelected] = useState(null);
  const [kpiInformation, setKpiInformation] = useState(false);

  const context = useContext(AuthenticationContext);


  useEffect(() => {
    (async () => {
      await getKpiinformation();
    })();
  }, [dateRange, groupBySelected]);

  const getKpiinformation = async () => {
    if (kpiInformation) {
      setKpiInformation(false);
    }
    const today = new Date();
    const http = new GatewaysHttpClient(context);
    const startDate = dateRange ? formatDate(dateRange[0]) : formatDate(today, 'start');
    const endDate = dateRange ? formatDate(dateRange[1]) : formatDate(today, 'end');
    const groupBy = groupBySelected ? groupBySelected : 'day';
    try {
      const kpiInformation = await http.GetKpiInformation(gateway.id, startDate, endDate, groupBy);
      if (kpiInformation.data && !kpiInformation.data.errors && kpiInformation.data.content) {
        setKpiInformation(kpiInformation.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (date, mode) => {
    if (mode === 'start') {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
    } else if (mode === 'end') {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleDateRangeChange = (value) => {
    if (JSON.stringify(value) != JSON.stringify(dateRange)) {
      setDateRange(value);
    }
  };

  const handleGroup = (value) => {
    if (value != groupBySelected) {
      setGroupBySelected(value);
    }
  };

  return (
    <div className='col-12 container-gateway-statistics'>
      <div className="header-statistics sticky-div">
        <div className='calendar sticky-calendar'>
          <CustomDateSelector
            style={{ justifyContent: 'right' }}
            className={'row w-100 m-0'}
            defaultGranularity={'day'}
            onDateChange={handleDateRangeChange}
            onGranularityChange={handleGroup}
            timeframes={['day', 'week', 'month', 'year']}
          />
        </div>
      </div>

      <div className='body-statistics'>
        {kpiInformation ? (
          <>
            <div className='infogrid-deviceToGateway'>
              <h3>{t('gatewayspages.statistics.gatewayHeader')}</h3>
              <GatewayInfoGrid gatewaykpis={kpiInformation.gatewaykpis} />
            </div>

            <div className='card-container mt-4 table-deviceToGateway'>
              <h3>{t('gatewayspages.statistics.deviceGatewayHeader')}</h3>
              <DeviceToGatewayTable productKPI={kpiInformation.prductKPI} />
            </div>
          </>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GatewayStatistics;
