import { Configuration } from '@azure/msal-browser';
import * as msal from '@azure/msal-browser';

const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_SuSiRGPWebAPI',
    forgotPassword: 'B2C_1_ResetPWDRGPWebAPI',
    editProfile: 'B2C_1_EditProfileRGPWebAPI',
    signUpSignInLaufen: 'B2C_1_SuSiRGPWebAPILaufen',
    forgotPasswordLa: 'B2C_1_ResetPWDRGPWebAPILaufen',
    changePasswordLa: 'B2C_1A_ProfileEditPasswordChange_RGP_WebApi_Laufen',
    editProfileLaufen: 'B2C_1_EditProfileRGPWebAPILaufen',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_SuSiRGPWebAPI',
    },
    forgotPassword: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_ResetPWDRGPWebAPI',
    },
    editProfile: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_EditProfileRGPWebAPI',
    },
    signUpSignInLaufen: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_SuSiRGPWebAPILaufen',
    },
    forgotPasswordLaufen: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_ResetPWDRGPWebAPILaufen',
    },
    changePasswordLaufen: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1A_ProfileEditPasswordChange_RGP_WebApi_Laufen',
    },
    editProfileLaufen: {
      authority: 'https://rgpb2c.b2clogin.com/rgpb2c.onmicrosoft.com/B2C_1_EditProfileRGPWebAPILaufen',
    },
  },
  authorityDomain: 'rgpb2c.b2clogin.com',
};

const scopes = ['https://rgpb2c.onmicrosoft.com/RGP-WebAPI/read', 'openid', 'profile'];

export const msalConfig: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/" /* https://localhost:44392", */// You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigEditProfile: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.editProfile.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigResetPassword: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.forgotPassword.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigChangePassword: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.changePasswordLaufen.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigLaufen: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignInLaufen.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigEditProfileLaufen: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.editProfileLaufen.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const msalConfigResetPasswordLaufen: Configuration = {
  auth: {
    clientId: '82e27c77-216c-43d2-8662-bab03bc7831c', // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.forgotPasswordLaufen.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "https://azapp-rgpiotcore-webapp-dev.azurewebsites.net/", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const loginRequest = {
  scopes: ['https://rgpb2c.onmicrosoft.com/RGP-WebAPI/read'],
  forceRefresh: false,
};

export const tokenRequest = {
  scopes: [...scopes], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
const msalInstanceEditProfile = new msal.PublicClientApplication(msalConfigEditProfile);
const msalInstanceResetPassword = new msal.PublicClientApplication(msalConfigResetPassword);

const msalInstanceLaufen = new msal.PublicClientApplication(msalConfigLaufen);
const msalInstanceEditProfileLaufen = new msal.PublicClientApplication(msalConfigEditProfileLaufen);
const msalInstanceResetPasswordLaufen = new msal.PublicClientApplication(msalConfigResetPasswordLaufen);

export const MSALInstance = msalInstance;
export const MSALInstanceEditProfile = msalInstanceEditProfile;
export const MSALInstanceResetPassword = msalInstanceResetPassword;
export const MSALInstanceLaufen = msalInstanceLaufen;
export const MSALInstanceEditProfileLaufen = msalInstanceEditProfileLaufen;
export const MSALInstanceResetPasswordLaufen = msalInstanceResetPasswordLaufen;

export const Scopes = { scopes: scopes };

export const LoginRequest = { loginRequest: loginRequest };

export const B2cPolicies = { b2cPolicies: b2cPolicies };

export const MSAL_CONFIG = {
  b2cPolicies,
  scopes,
  msalConfig,
  msalConfigEditProfile,
  msalConfigResetPassword,
  msalConfigChangePassword,
  msalConfigLaufen,
  msalConfigEditProfileLaufen,
  msalConfigResetPasswordLaufen,
  loginRequest,
  tokenRequest,
};
