import Style from '../styles/HWPackageView.module.scss';
import Table from '../../../../../../CustomComponents/ReactTable/Table';
import { t } from 'i18next';
import { filter, rowDetail, tableColumnsHWPackage } from '../config/HWPackageTableConfig';
import { HWPackageViewProps } from '../types/HWPackageTypes';
import ModalAddPackageContainer from '../container/ModalAddPackageContainer';
import ModalConfirmDeletePackageView from './ModalConfirmDeletePackageView';

const HWPackageView = (props: HWPackageViewProps) => {
  return (
    <div className={Style.HWPackageView}>
      <div className='HWPackageView-content'>
        <div className='card-container mt-4'>
          <div className='p-4'>
            <div className='row'>
              <div className='col'>
                <h3>{t('firmwareManagementPage.hardwarePackageManagement')}</h3>
              </div>
              <button
                onClick={() => props.handleShowAddPackageModal(true)}
                className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right'
                tabIndex={0}
                type='button'
              >
                <span className='MuiButton-label'>{t('firmwareManagementPage.addPackage')}</span>
                <span className='MuiTouchRipple-root'></span>
              </button>
            </div>
            <Table
              name={'HWPackage-table'}
              filter={filter(props.filter, props.handleChangeFilter, props.allHardwarePackage)}
              RowDetail={rowDetail}
              t={t}
              resize={true}
              columns={tableColumnsHWPackage(
                props.patchCheck,
                props.handleShowConfirmDeletePackageModal,
                props.handleEditPackage
              )}
              rows={props.allHardwarePackageFiltered}
              page={props.page}
              pageSize={props.sizePerPage}
              onSizePerPageChange={props.onSizePerPageChange}
              onPageChange={props.onPageChange}
            />
          </div>
        </div>
      </div>

      {props.showAddPackageModal && (
        <ModalAddPackageContainer
          open={props.showAddPackageModal}
          handleShowAddPackageModal={props.handleShowAddPackageModal}
          systemIds={props.systemIds}
          allSmartComponentTypes={props.allSmartComponentTypes}
          allHardwarePackage={props.allHardwarePackage}
          allNoSmartComponents={props.allNoSmartComponents}
          allNoSmartComponentsTypes={props.allNoSmartComponentsTypes}
          refreshAllNoSmartComponents={props.refreshAllNoSmartComponents}
          rowSelectedToEdit={props.rowSelectedToEdit}
        />
      )}
      
      {props.showConfirmDeletePackageModal && (
        <ModalConfirmDeletePackageView
          open={props.showConfirmDeletePackageModal}
          handleShowConfirmDeletePackageModal={props.handleShowConfirmDeletePackageModal}        
          deletePackage={props.deletePackage} 
        />
      )}
    </div>
  );
};

export default HWPackageView;
