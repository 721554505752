import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import GatewaysHttpClient from '../../../HttpClient/GatewaysHttpClient';
import ProductHttpClient from '../../../HttpClient/ProductHttpClient';
import InstallationHttpClient from '../../../HttpClient/InstallationsHttpClient';

import * as jsonpatch from 'fast-json-patch/index.mjs';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Message from '../../Message/Message';

class ProductEdit extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.id = this.props.id;

    this.saveData = this.saveData.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmSave = this.confirmSave.bind(this);
    this.product = {};

    this.product = Array.isArray(props.product.content) ? null : props.product.content;

    let product = this.product ? { ...this.product } : null;
    let parentid = this.product && this.product.installation ? this.product.installation.id : null;

    this.state = {
      product: product,
      installations: [],
      parentid: parentid,
      gateways: 0,
      open: false,
      showModalDelete: false,
      showModalSave: false,
      showModalError: false,
      installationChanged: false,
      chartcolor: '#007DBA',
      message: null,
    };
  }

  componentDidMount() {
    this.getProduct();
    this.getInstallations();
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getInstallations() {
    if (this.state.product.gatewayId) {
      let installations = [];
      this.httGateway = new GatewaysHttpClient(this.context);
      let dataGateway = await this.httGateway.GetGateway(this.state.product.gatewayId);
      if (dataGateway.data.content.installations) {
        installations = dataGateway.data.content.installations;
      }
      this.setState({ installations: installations });
    } else {
      this.httpInstallation = new InstallationHttpClient(this.context);
      let datos = await this.httpInstallation.InstallationGetInstallations();
      this.setState({ installations: datos.content });
    }
  }

  async getProduct() {
    this.http = new ProductHttpClient(this.context, this.id);
    let data = await this.http.ProductsGetProduct();

    this.product = {};

    if (data && data.data && data.data.content) {
      this.product.name = data.data.content.name;
      this.product.description = data.data.content.description;
      this.product.id = data.data.content.id;
      this.product.tag = data.data.content.tag;
      this.product.InstallationId = data.data.content.installation.id;
      this.product.gatewayId = data.data.content.gatewayId;
      this.product.chartsColor = data.data.content.chartsColor;
      this.product.puuid = data.data.content.puuid;
      this.setState({ product: this.product, parentid: data.data.content.installation.id });
    } else {
      this.setState({ product: {}, parentid: null, showModalError: true });
    }
  }

  async saveData(e, t) {
    //Modificamos los datos en this.installation para luego comparar con los datos del this.state.data y generar el patch

    e.preventDefault();
    let nombre = document.getElementById('txtName').value;
    let desc = document.getElementById('txtDescription').value;
    let tag = document.getElementById('txtTag').value;
    let chartsColor = document.getElementById('inputchartcolor').value;

    let product = {};
    product.name = nombre;
    product.description = desc;
    product.tag = parseInt(tag);
    product.id = parseInt(this.id);
    product.InstallationId = this.state.parentid;
    product.chartsColor = chartsColor;

    //debugger;
    let oldInstallation;
    if (this.props.product.content.installation != undefined) {
      //Vienen desde la ficha del producto
      oldInstallation = this.props.product.content.installation.id;
    } else {
      //vienen desde el tree
      oldInstallation = this.props.product.content[0].children[0].id;
    }

    let oldProduct = {
      name: this.state.product.name,
      tag: this.state.product.tag,
      description: this.state.product.description,
      id: parseInt(this.id),
      InstallationId: oldInstallation,
      chartsColor: this.state.product.chartsColor,
    };

    let patch = jsonpatch.compare(oldProduct, product);
    product = {};
    product.id = this.id;
    product.data = patch;

    const formulario = new FormData();
    formulario.append('patchoptions', JSON.stringify(product));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    let data;
    switch (this.props.mode) {
      case 'add':
        break;
      case 'edit':
        data = await this.SendPatch(formulario);
        break;
      case 'delete':
        break;
    }

    if (data.errors == null) {
      this.setState({ showModalSave: false });
      this.props.onSaveClose();
    }
  }

  async SendPatch(formulario) {
    let data;
    this.http = new ProductHttpClient(this.context, this.id);
    data = await this.http.PatchProduct(formulario);

    return data;
  }

  async deleteProduct(id, t) {
    let data;
    try {
      this.http = new ProductHttpClient(this.context, this.id);

      var puuid = document.getElementById('txtNameDelete').value;

      if (puuid.toLowerCase() == this.product.puuid.toLowerCase()) {
        data = await this.http.DeleteProduct(id, puuid);
        if (data.content != undefined && data.content) {
          if (window.location.href.includes('/product')) {
            document.getElementById('redirectToParent').click();
          } else {
            this.props.onSaveClose();
          }
        } else if (data.status != undefined) {
          if (data.status > 200) {
            this.setState({
              showModalDelete: false,
              saving: false,
            });
            this.renderMessage('error', data.title == undefined ? t('productspages.productedit.forbid') : data.errors[0]);
          } else {
            if (window.location.href.includes('/product')) {
              document.getElementById('redirectToParent').click();
            } else {
              this.props.onSaveClose();
            }
          }
        } else if (data.errors != undefined) {
          this.setState({
            showModalDelete: false,
            saving: false,
          });
          this.renderMessage('error', data.title == undefined ? t('productspages.productedit.forbid') : data.errors[0]);
        } else if (data.startsWith('System.InvalidOperation')) {
          // Forbid
          this.setState({
            showModalDelete: false,
            saving: false,
          });
          this.renderMessage('error', data.title == undefined ? t('productspages.productedit.forbid') : data.title);
        } else {
          //ok

          this.setState({ showModalDelete: false });
          this.renderMessage('success', 'Product deleted successfully');

          if (window.location.href.includes('/product')) {
            document.getElementById('redirectToParent').click();
          } else {
            this.props.onSaveClose();
          }
        }
      } else {
        this.setState({
          showModalDelete: false,
          saving: false,
        });
        this.renderMessage('error', 'Product PUUID and PUUID entered do not match.');
      }
    } catch (e) {
      if (e.response.status != undefined && e.response.status > 200) {
        this.setState({
          showModalDelete: false,
          saving: false,
        });
        this.renderMessage(
          'error',
          e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.data.title
        );
      } else if (e.response.errors != undefined) {
        this.setState({
          showModalDelete: false,
          saving: false,
        });
        this.renderMessage(
          'error',
          e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.errors[0]
        );
      } else if (e.response.startsWith('System.InvalidOperation')) {
        // Forbid
        this.setState({
          showModalDelete: false,
          saving: false,
        });
        this.renderMessage(
          'error',
          e.response.data.title == undefined ? t('installationspages.installationsummaryedit.forbid') : e.response.data.title
        );
      }
    }

    return data;
  }
  async confirmDelete(t) {
    await this.deleteProduct(this.state.product.id, t);
    this.setState({ showModalDelete: false });
  }

  async confirmSave(e, t) {
    e.preventDefault();

    if (this.state.installationChanged) {
      this.setState({ showModalSave: true });
    } else {
      await this.saveData(e, t);
      this.setState({ showModalSave: false });
    }
  }

  handleChange = (event) => {
    let changeInstallation = false;
    let oldValue;
    if (this.props.product.content.installation != undefined) {
      //Vienen desde la ficha del producto
      oldValue = this.props.product.content.installation.id;
    } else {
      //vienen desde el tree
      oldValue = this.props.product.content[0].children[0].id;
    }

    if (oldValue != event.target.value) changeInstallation = true;

    this.setState({ parentid: event.target.value, installationChanged: changeInstallation });
  };

  dropdownInstallations() {
    const { t } = this.props;

    let dataLoaded = this.state.installations != undefined;
    let listitems = [];

    if (dataLoaded) {
      let selected = this.state.parentid ? this.state.parentid.toString() : '';

      listitems.push(
        this.state.installations.map((item, i) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>;
        })
      );

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='installationLabel'>{t('installation')}</InputLabel>
          <Select
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
              onInput: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              },
            }}
            variant='standard'
            name='installation'
            className='titleProperty'
            labelId='installationLabel'
            id='ddlInstallation'
            label={t('installation')}
            value={selected}
            required
            onChange={this.handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }

  render() {
    const { t } = this.props;

    const deleteProduct = () => {
      this.setState({ showModalDelete: true });
    };

    const handleClose = () => {
      this.setState({ showModalDelete: false, showModalSave: false });
    };
    const handleCloseModal = () => {
      this.props.onSaveClose();
    };
    const handleCloseError = () => {
      this.props.onSaveClose();
    };

    let dataLoaded =
      this.state.product && this.state.product != {} && this.state.installations && this.state.installations.length > 0;

    var pathToParent = '';

    try {
      this.pathToParent = '/installation/' + this.product.installation.id;
    } catch {
      this.pathToParent = '/installation/' + this.product.InstallationId;
    }

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
            {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

            <Dialog disableEscapeKeyDown open={this.state.showModalSave} onClose={handleClose}>
              <DialogTitle>{t('productspages.productedit.changeinstallationtitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('productspages.productedit.changeinstallationmessage')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={(e) => this.saveData(e, t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalError} onClose={handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.messageError}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalDelete} onClose={handleClose}>
              <DialogTitle>{t('actions.deletename', { name: t('product') })}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('actions.confirmdelete', { name: t('product') })}</span>
                  <p className='mini-legend'>{t('productspages.productedit.confirmdelete')}</p>
                  <TextField
                    variant='standard'
                    id='txtNameDelete'
                    label={this.state.product.puuid}
                    placeholder='PUUID'
                    helperText=''
                    style={{ width: '100%' }}
                    margin='normal'
                    inputlabelprops={{
                      shrink: true,
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <form id='divForm' onSubmit={(e) => this.confirmSave(e, t)}>
              <div className='prodChildNoZoom'>
                <div style={{ margin: '15px' }}>
                  <div className='form-row'>
                    <div className='form-group col-md-12'>
                      <TextField
                        onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                        onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                        onChange={(e) => e.target.setCustomValidity('')}
                        variant='standard'
                        id='txtName'
                        label={t('forms.name')}
                        placeholder={t('forms.name')}
                        helperText=''
                        style={{ width: '100%' }}
                        required
                        margin='normal'
                        inputlabelprops={{
                          shrink: true,
                        }}
                        defaultValue={this.state.product.name}
                        inputProps={{ maxLength: 22 }}
                      />
                    </div>
                    <div className='form-group col-md-12'>
                      <TextField
                        variant='standard'
                        id='txtDescription'
                        label={t('forms.description')}
                        placeholder={t('forms.description')}
                        helperText=''
                        style={{ width: '100%' }}
                        margin='normal'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={this.state.product.description}
                        inputProps={{ maxLength: 32 }}
                      />
                    </div>
                    <div className='form-group col-md-12'>
                      <TextField
                        onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                        onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                        onChange={(e) => e.target.setCustomValidity('')}
                        variant='standard'
                        id='txtTag'
                        label='Tag'
                        placeholder='Tag'
                        helperText=''
                        style={{ width: '100%' }}
                        required
                        margin='normal'
                        inputlabelprops={{
                          shrink: true,
                        }}
                        defaultValue={this.state.product.tag}
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-12'>{this.dropdownInstallations()}</div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-12'>
                      <TextField
                        variant='standard'
                        id='txtPUUID'
                        label='PUUID'
                        disabled={true}
                        helperText=''
                        style={{ width: '100%' }}
                        margin='normal'
                        inputlabelprops={{
                          shrink: true,
                        }}
                        defaultValue={this.product.puuid}
                      />
                    </div>
                    <div className='form-group col-md-12'>
                      <label
                        className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required'
                        data-shrink='true'
                        htmlFor='inputchartcolor'
                        id='inputchartcolor-label'
                      >
                        {t('installationspages.installationsummaryedit.installationcolor')}
                        <span aria-hidden='true' className='MuiFormLabel-asterisk'>
                           
                        </span>
                      </label>
                      <input
                        id='inputchartcolor'
                        name='inputchartcolor'
                        type='color'
                        label={t('installationspages.installationsummaryedit.installationcolor')}
                        value={this.state.chartcolor}
                        onChange={(e) => this.setState({ chartcolor: e.target.value })}
                        placeholder={t('installationspages.installationsummaryedit.installationcolor')}
                        style={{ width: '100%', marginTop: '18px' }}
                        margin='normal'
                        inputlabelprops={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-8'>
                  <Button type='submit' className='primary active d-inline'>
                    {t('actions.save')}
                  </Button>
                  {this.props.mode == 'edit' ? (
                    <Button className='primary d-inline' onClick={deleteProduct}>
                      {t('actions.delete')}
                    </Button>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className='form-group col-md-4'>
                  <Button className='primary pull-right' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>
              </div>
            </form>

            <Link
              id='redirectToParent'
              to={{
                pathname: this.pathToParent,
                message: t('actions.deleted', { name: t('product') + ' "' + this.product.name + '" ' }),
              }}
              style={{ display: 'none' }}
            >
              AA
            </Link>

            <Link id='redirect' to={{ pathname: '/filter/productsFilter', param1: 'Par1' }} style={{ display: 'none' }}></Link>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProductEdit);
