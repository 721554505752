import { PureComponent } from 'react';
import UserHttpClient from '../../../HttpClient/UserHttpClient';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';

import ConfirmEmailView from '../views/ConfirmEmailView';
import { ConfirmEmailStateType } from '../types/ConfirmEmailStateType';
import { t } from 'i18next';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';
import { withTranslation, WithTranslation } from 'react-i18next';

export class ConfirmEmailContainer extends PureComponent<WithTranslation, ConfirmEmailStateType> {
  static contextType = AuthenticationContext;
  private httpClient: UserHttpClient;
  constructor(props: WithTranslation) {
    super(props);
    this.httpClient = new UserHttpClient(this.context);
    this.state = {
      loading: false,
      brandLogo: '',
      brandUser: 'roca',
      message: null,
      mail: '',
      language: this.props.i18n.language,
    };
  }

  componentDidMount() {
    const context = this.context as AuthContextType;
    const isLaufenUser =
      context.brandCode == '11' || window.location.href.includes('laufen') || window.location.href.includes('localhost');
    const logoUrl = `https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_${
      isLaufenUser ? 'laufen_standard.png' : 'roca_white.jpg'
    }`;
    this.setState({ brandUser: isLaufenUser ? 'laufen' : 'roca', brandLogo: logoUrl });
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  setMail = (mail: string) => {
    console.log(`input mail changed to: ${mail}`);
    this.setState({ mail });
  };

  register = () => {
    console.log(`register fn, starting process inside container...`);
    let redirectApp = '';
    let redirect = '';
    let language = this.state.language;
    console.log(`language: ${language}`);
    const query = new URLSearchParams(window.location.search);
    console.log(`query ${query}`);
    const timezone = query.get('timezone') || '0';
    console.log(`query ${timezone}`);
    const mail = this.state.mail;
    let timezoneParsed = parseFloat(timezone) * -1;

    console.log(`brandUser: ${this.state.brandUser}`);
    if (this.state.brandUser) {
      console.log(`inside --> if (this.state.brandUser)`);
      const host = query.get('host');
      const lang = query.get('lang');
      console.log(`host: ${host}, lang: ${lang}`);
      if (host == 'app') {
        console.log(`inside --> if (host == 'app')`);
        if (this.state.brandUser == 'roca') {
          console.log(`inside --> if (this.state.brandUser == 'roca')`);
          redirectApp = 'roca-app://registration';
        } else {
          console.log(`inside --> else (this.state.brandUser == 'roca')`);
          redirectApp = 'laufen-app://registration';
        }
        console.log(`redirect to ${redirectApp}`);
      }

      redirect = document.location.origin + '/welcome';

      console.log(`timezoneParsed: ${timezoneParsed}`);
      if (timezoneParsed != null) {
        console.log(`inside --> if (timezoneParsed != null)`);
        let date = new Date();
        timezoneParsed = date.getTimezoneOffset() / 60;
        console.log(`timezoneParsed ${timezoneParsed}`);
      }
      console.log(`lang: ${lang}`);
      if (lang != null) {
        console.log(`inside --> if (lang != null)`);
        language = lang;
      }
    }

    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      console.log(`inside --> if (!regex)`);
      let msg = t('register.invalidPassword');
      this.renderMessage('error', msg);
      return;
    }

    console.log(`redirect ${redirect}`);
    redirect = redirect.replaceAll('/', '%2F');
    console.log(`redirect after replace --> ${redirect}`);

    if (!this.state.brandUser || !mail || (timezoneParsed == null && (redirect || redirectApp))) {
      // console.log(`inside --> if (!this.state.brandUser || !mail || (!timezoneParsed && (redirect || redirectApp)))`);
      console.log(
        `brandUser: ${this.state.brandUser}, mail: ${mail}, timezoneParsed: ${timezoneParsed}, redirect: ${redirect}, redirectApp: ${redirectApp}`
      );
      console.log('error, missing data');
      return;
    } else {
      console.log(`inside --> else (!this.state.brandUser || !mail || (!timezoneParsed && (redirect || redirectApp)))`);
      this.setState({ loading: true });
      this.httpClient
        .Register(this.state.brandUser, mail, redirect, redirectApp, timezoneParsed, language)
        .then((res) => {
          console.log(`response from registerFN ${res}`);
          //TODO: Redirect to confirm register
          window.location.href = '/register/confirm-register';
          // this.setState({ loading: false });
        })
        .catch((err) => {
          console.error('response error');
          console.error(err);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    return (
      <>
        <ConfirmEmailView
          {...this.state}
          handleDialogClose={this.handleDialogClose}
          register={this.register}
          setMail={this.setMail}
        />
      </>
    );
  }
}

export default withTranslation()(ConfirmEmailContainer);
