import React, { Component } from 'react';
import '../../App.css';
import { withTranslation } from 'react-i18next';
import ProductHttpClient from "../../HttpClient/ProductHttpClient";
import CustomSlider from './CustomSlider';
import CustomNumberSlider from './CustomNumberSlider';
import CustomSelectContainer from './CustomSelect/container/CustomSelectContainer';
import CustomCheckboxText from './CustomCheckboxText';
import CustomCheckbox from './CustomCheckbox';
import CustomButton from './CustomButton';
import CustomTextField from './CustomTextField';
import CustomStatus from './CustomStatus';
import CustomTabSelect from './CustomTabSelect';
import Error from '../Error';
import NoData from '../NoData';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material//Tab';
import Accordions from '@mui/material/Accordion';
import AccordionsSummary from '@mui/material/AccordionSummary';
import AccordionDetail from '@mui/material/AccordionDetails';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Message from '../Message/Message';

class ProductParameters extends Component {
    static contextType = AuthenticationContext;    
    constructor(props) {
        super(props);
        let cols = props.cols ? props.cols : "col-12"
        this.state = { tabValue: 0, dataLoaded: false, productTelemetry: this.props.productTelemetry, parameters: [], parametersInit: [], notParameters: true, cols: cols, show: false, disabledSendParameters: true, disabledCancelParameters: true, loadingParametersConfirmed: false, loadingCommandConfirmed: false, showConfirmCommand: false, showConfirm: false, message:null };
        this.toSend = this.toSend.bind(this);
        this.getParameter = this.getParameter.bind(this);
        this.tabValues = {}
        sessionStorage.removeItem("parametersChangeLocationIDs");
        this.key = 0;
    }

    async componentDidMount() {
        let parametersViewZones = this.props.parametersViewZones.content;
        let parameters = [];
        this.gatewayId = this.props.product.content.gatewayId;
        this.bleWriteCommandToSend = {};
        this.bleWriteCommandToSend["productId"] = this.props.product.content.id;
        this.bleWriteCommandToSend["name"] = "BleWriteCommand";
        this.bleWriteCommandToSend["params"] = [];

        this.bleWriteToSend = {};
        this.bleWriteToSend["productId"] = this.props.product.content.id;
        this.bleWriteToSend["name"] = "BleWrite";
        this.bleWriteToSend["params"] = [];
        this.arrayCommandsToSendC2D = [];

        this.deviceSettingsModeFound = false

        try {
            if (parametersViewZones && parametersViewZones.length > 0) {
                for (let item of parametersViewZones) {
                    if (item.page == 'Product-subzones' || item.page == 'Product') {
                        for (let smartComponentType of item.smartComponentTypes) {
                            for (let zone of smartComponentType.zones) {
                                if (zone.pageZonePosition == this.props.pageZonePosition) {
                                    for (let subZone of zone.subZonesLists) {
                                        subZone.smartComponentTypeName = smartComponentType.description;
                                        parameters.push(subZone);
                                    }
                                }
                            }
                        }
                    }
                }
                this.setState({ dataLoaded: true, parameters: parameters, parametersInit: JSON.parse(JSON.stringify(parameters)) });
            }

        } catch (err) {
            console.error(err);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.productTelemetry !== prevState.productTelemetry) {
            return ({ productTelemetry: nextProps.productTelemetry })
        }
        return null
    }

    handleDisabledMessage(disabled){
        const {t} = this.props;
        
        if(disabled.readOnly){
            this.setState({
                message: {
                    type: '',
                    content: `${t("productspages.parameters.readOnlyParameterInfo")}`
                }
            })
        } else if (!disabled.online){
            this.setState({
                message: {
                    type: '',
                    content: `${t("productspages.parameters.offlineParameterInfo")}`
                }
            })
        } else if(disabled.deviceSetting){
            this.setState({
                message: {
                    type: '',
                    content: `${t("productspages.parameters.deviceSettingParameterInfo")}`
                }
            })
        }        
    }

    handleDialogClose = () => {
        this.setState({ message: null });       
    };

    componentDidUpdate(prevProps) {
        let idproduct = parseInt(window.location.pathname.split('/')[2]);
        if (idproduct != this.id) {
            this.id = idproduct;
            this.setState();
        }
    }
    sendSerialNumber(obj, val) {
        if (typeof this.props.sendSerialNumber === 'function') {
            this.props.sendSerialNumber(obj, val);
        }
    }
    changeHandler(value) {
        let listParametersToSend = [];
        let idP = 0;
        if (this.gatewayId == 0) {
            for (let param of this.arrayCommandsToSendC2D) {
                let value = param.value;
                if (param.parameter.units == "enum") {
                    let finded = param.parameter.values.find(elem => elem.value == value.toString());
                    if (finded != undefined) {
                        const { t } = this.props;
                        value = t("parametersLabels:" + param.parameter.id + "." + finded.value + ".name");
                    }
                } else if (param.parameter.type == "Flag") {
                    if (value == 0) {
                        value = "unchecked";
                    } else if (value == 1) {
                        value = "checked";
                    }
                }
                else if (value == null) {
                    value = "";
                }

                let name = param.businessLabel;
                let {table, smartComponentTypeId, businessLabel} = param.parameter;
                if(table && smartComponentTypeId && businessLabel){
                    name = this.props.t(`${table}:${smartComponentTypeId}.${businessLabel}.name`);
                }

                let t = this.getTelemetry(this.state.productTelemetry.data.content, param.parameter);

                if(t?.value != param.value){
                    listParametersToSend.push(<li key={'p'+idP} className="MuiListItem-root MuiListItem-gutters" >
                    <div key={'p' + idP} className="MuiListItemText-root">
                        <span className="t2 MuiTypography-displayBlock"><strong>{name}:</strong> {value}</span>
                    </div>
                </li>);
                idP++;
                }
            }
        }
        else {

            for (let param of this.bleWriteToSend.params) {
                let value = param.value;
                if (param.parameter.units == "enum") {
                    let finded = param.parameter.values.find(elem => elem.value == value.toString());
                    if (finded != undefined) {
                        const { t } = this.props;
                        value = t("parametersLabels:" + param.parameter.id + "." + finded.value + ".name");
                    }
                } else if (param.parameter.type == "Flag") {
                    if (value == 0) {
                        value = "unchecked";
                    } else if (value == 1) {
                        value = "checked";
                    }
                }
                else if (value == null) {
                    value = "";
                }

                let name = param.businessLabel;
                let {table, smartComponentTypeId, businessLabel} = param.parameter;
                if(table && smartComponentTypeId && businessLabel){
                    name = this.props.t(`${table}:${smartComponentTypeId}.${businessLabel}.name`);
                }

                let t = this.getTelemetry(this.state.productTelemetry.data.content, param.parameter);

                if(t?.value != param.value){
                    listParametersToSend.push(<li key={'tech' + idP} className="MuiListItem-root MuiListItem-gutters" >
                    <div key={'tech' + idP} className="MuiListItemText-root">
                        <span className="t2 MuiTypography-displayBlock"><strong>{name}:</strong> {value}</span>
                    </div>
                    </li>);
                    idP++;
                }
            }
        }
        this.changeFloatParameterIndicator(listParametersToSend.length);
       
        this.setState({ listParametersToSend: listParametersToSend })
    }
    changeFloatParameterIndicator(count, idOptional = null) {
        try {

            let id = idOptional == null ? ("editParametersBox" + this.props.pageZonePosition) : idOptional;

            let doc = document.getElementById("parameterChanges");
            let doc2 = document.getElementById(id);

            let sessionItems = JSON.parse(sessionStorage.getItem("parametersChangeLocationIDs"));
            if (count > 0) {

                sessionStorage.setItem("parametersChangeLocationID", id);
                doc.classList.remove("d-none");
                doc2.classList.remove("d-none");
                document.getElementById("parameterChanges-content").textContent = count;

                
                if (!sessionItems) {
                    sessionItems = {};
                }
                sessionItems[id] = count;
                
            } else {
               
                if (sessionItems) {
                    delete sessionItems[id];
                }
                
                doc.classList.add("d-none");
                doc2.classList.add("d-none");
               
            }
            sessionStorage.setItem("parametersChangeLocationIDs", JSON.stringify(sessionItems));


            if (count <= 0) {
                let keys = Object.keys(sessionItems);
                if (keys.length > 0) {
                    this.changeFloatParameterIndicator(sessionItems[keys[0]], keys[0]);
                }
                
            }

        } catch (e) {

        }
        
    }
    componentWillUnmount() {
        //Hide parameter block
        this.changeFloatParameterIndicator(0);
        sessionStorage.removeItem("parametersChangeLocationIDs");
    }
    async toSend(parameter, value) {
        if (this.props.product.content.connectionState.toLowerCase() == "online") { //gatewayConnection
            if (this.gatewayId == 0) { //Cloud to device
                if (value == null) {//button
                    this.setState({ "showConfirm": true });
                }
                if (typeof parameter.permissionType !== "undefined" && parameter.permissionType.includes("W")) {
                    let commandToSendC2D = {"productId": this.props.product.content.id, "smartComponentId": parameter.smartComponentId, "businessLabel": parameter.businessLabel, "parameter": parameter, "value": value };
                    const index = this.arrayCommandsToSendC2D.findIndex(obj => obj.parameter.businessLabel == parameter.businessLabel);
                    if (index >= 0) {
                        this.arrayCommandsToSendC2D.splice(index, 1);
                    }
                    this.arrayCommandsToSendC2D.push(commandToSendC2D);
                    this.setParametersModifications(false, false);
                    this.changeHandler('toSend');
                }
            }
            else { //direct methods
                if (parameter.value != null) {
                    this.bleWriteCommandToSend["params"].splice(this.bleWriteCommandToSend["params"].findIndex(obj => obj.businessLabel == parameter.businessLabel), 1);
                    this.bleWriteCommandToSend["params"].push({ "businessLabel": parameter.businessLabel });
                }
                if (typeof parameter.permissionType !== "undefined" && parameter.permissionType.includes("W")) {
                    const index = this.bleWriteToSend["params"].findIndex(obj => obj.parameter.businessLabel == parameter.businessLabel);
                    if (index >= 0) {
                        this.bleWriteToSend["params"].splice(index, 1);
                    }
                    if (parameter.table == 'commands') {
           
                        this.bleWriteCommandToSend["params"] = [];
                        this.bleWriteCommandToSend["params"].push({"businessLabel": parameter.businessLabel});
                        this.setState({ "showConfirmCommand": true });
                    }
                    else {
                        this.bleWriteToSend["params"].push({ "parameter": parameter, "businessLabel": parameter.businessLabel, "value": value });
                        this.setParametersModifications(false, false);
                        this.changeHandler('toSend');
                    }
                }
            }

        }
    }
    setParametersModifications(disabledSendParameters, disabledCancelParameters) {
        //We are editing parameters, so we call the parent to stop/start automatic refresh
        if (typeof this.props.stopRefresh === 'function') {
            this.props.stopRefresh(disabledSendParameters);
        }
        this.setState({ disabledSendParameters: disabledSendParameters, disabledCancelParameters: disabledCancelParameters });

        if (disabledSendParameters && disabledCancelParameters) {
            this.changeFloatParameterIndicator(0);
        }
    }
    onChangeParent(value, id) {
        try {

            let container = document.getElementById(id + "-children")
            if (container) {
                let children = container.childNodes
                let showing = 0

                children.forEach(el => {
                    try {
                        let viewId = el.getAttribute("viewId")

                        if (viewId == value) {
                            showing++
                            if (el.classList.contains("d-none")) el.classList.remove("d-none");
                        }
                        else if (viewId != value && !el.classList.contains("d-none")) el.classList.add("d-none");
                    }
                    catch (err) { }
                })
                if (showing == 0 && !container.classList.contains("d-none")) container.classList.add("d-none");
                else if (showing != 0 && container.classList.contains("d-none")) container.classList.remove("d-none");
            }
        }
        catch (err) {
            console.error(err);
        }
    }
    getTelemetry(telemetry, paramObj) {
        let t = telemetry; //content
        let res = null;
        if (t[paramObj.businessLabel] != undefined && t[paramObj.businessLabel] != null) {
            res = t[paramObj.businessLabel];
            //parameterValue = t[paramObj.businessLabel].value;
            //telemetryObject = t[paramObj.businessLabel];
        } else {
       
            if (t != null && t != undefined) {
                try {
                    res = t.find(v => v.key == paramObj.businessLabel && v.id == paramObj.id);

                    if (res == undefined) {
                        res = t.find(v => v.key == paramObj.businessLabel);
                    }
                    if (res != undefined) {
                        //parameterValue = res.value;
                        //telemetryObject = res;
                    }
                } catch (err) {
                    // Is not updated to new list yet
                }
            }
        }
        return res;
    }

    validateObjReference(referenceParameter,referenceValue,objValue) {
        let operator = referenceParameter.operator;
        let evaluation = eval(referenceValue + " " + operator + " " + objValue);
        if (evaluation) return referenceParameter.action;
        else return "";
    }

    getChildren(children, id, value, cols) {
        let zoneCols = cols;

        if (children && children.length > 0) {

            let readOnly = this.props.product?.content?.connectionState?.toLowerCase() == "app"
            let showing = 0;

            let listitems = []
            let idC = 0;
            children.forEach(parameter => {
                if (parameter) {
                    if(parameter.fullRow){
                        cols = "col-12"
                    } else {
                        cols = zoneCols
                    }

                    let paramObj;
                    let type = parameter.type;
                    let operator = parameter.operator != undefined ? parameter.operator : "==";
                    let parameterValue = null;
                    let telemetryObject = null;
                    if (value === true) value = 1;
                    else if (value === false) value = 0;

                    let show = eval(value +" "+ operator +" "+ parameter.viewId) ? "" : " d-none";
                    if (show == "") showing++;

                    

                    if (type.toLowerCase() == 'button') {
                        paramObj = parameter.command;

                        if (paramObj != null) {
                            if (readOnly) paramObj.permissionType = 'R'
                            paramObj.online = this.isProductOnline();
                            listitems.push(<div key={'c' + idC} viewId={parameter.viewId} style={{ padding: 0, margin: 0 }} className={"paramContainer col-12 paramHeader" + show}><CustomButton style={{ marginLeft: "15px" }} paramObj={paramObj} handleDisabledMessage={this.handleDisabledMessage.bind(this)}/></div>);
                            idC++;
                        }
                        else {
                            let error = {};
                            error["message"] = parameter.name;
                            error["stack"] = "object empty";
                        }
                    }
                    else { //command
                        paramObj = parameter.parameter;
                        if (readOnly && paramObj) paramObj.permissionType = 'R'
                        let responseProductTelemetry = this.state.productTelemetry;
                        if (paramObj != null || type.toLowerCase() == 'empty') {
                            paramObj.online = this.isProductOnline();
                            let index;
                            let indexReference = -1;
                            let resultValidateObjReference = "";
                            if (this.gatewayId == 0) {
                                index = this.arrayCommandsToSendC2D.findIndex(obj => obj.parameter && obj.parameter.businessLabel == paramObj.businessLabel);
                            } else {
                                index = this.bleWriteToSend["params"].findIndex(obj => obj.parameter && obj.parameter.businessLabel == paramObj.businessLabel);
                            }
                            if (index >= 0) {
                                if (this.gatewayId == 0) {
                                    parameterValue = this.arrayCommandsToSendC2D[index].value;
                                    paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                    if (parameter.referenceParameters != undefined) {
                                        let referenceValue = -1;
                                        for (let ref of parameter.referenceParameters) {
                                            indexReference = this.arrayCommandsToSendC2D.findIndex(obj => obj.parameter && obj.parameter.businessLabel == ref.name);
                                            if (indexReference >= 0) {
                                                referenceValue = this.arrayCommandsToSendC2D[index].value;
                                                resultValidateObjReference = this.validateObjReference(ref, referenceValue, parameterValue);
                                            }
                                        }
                                    }
                                }
                                else {
                                    parameterValue = this.bleWriteToSend["params"][index].value;
                                    paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                    if (parameter.referenceParameters != undefined) {
                                        let referenceValue = -1;
                                        for (let ref of parameter.referenceParameters) {
                                            indexReference = this.bleWriteToSend["params"].findIndex(obj => obj.parameter && obj.parameter.businessLabel == ref.name);
                                            if (indexReference >= 0) {
                                                referenceValue = this.arrayCommandsToSendC2D[index].value;
                                                resultValidateObjReference = this.validateObjReference(ref, referenceValue, parameterValue);
                                            }
                                        }
                                    }
                                }
                            }
                            else if (responseProductTelemetry.status == 200) {
                                if (responseProductTelemetry.data.content != null && type.toLowerCase() != 'empty') {

                                    let t = this.getTelemetry(responseProductTelemetry.data.content, paramObj);
                                    if (t != undefined && t!=null) {
                                        parameterValue = paramObj.businessLabel === "DeviceSettingsApp" ? Number(t.value): t.value;
                                        paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                        telemetryObject = t;

                                        if (parameter.referenceParameters != undefined) {
                                            let referenceValue;
                                            for (let ref of parameter.referenceParameters) {
                                                let res = responseProductTelemetry.data.content.find(v => v.key == ref.name);
                                                if (res != undefined) {
                                                    referenceValue = res.value;
                                                    resultValidateObjReference = this.validateObjReference(ref, referenceValue, parameterValue);  
                                                }
                                            }
                                        }

                                    }
                                }
                            }

                            switch (resultValidateObjReference) {
                                case "hidden":
                                    show = " d-none";
                                    break;
                                case "show":
                                    show = "";
                                    break;
                                case "disable":
                                    paramObj.permissionType = 'R'
                                    break;
                            }

                            if (type.toLowerCase() == 'dropdown') {
                                listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomSelectContainer style={{ width: '100%' }} value={parameterValue} parameter={paramObj} toSend={this.toSend} onChange={this.changeHandler.bind(this)}  onChangeSelect={this.onChangeParent} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>);
                                idC++;
                            }
                            else if (type.toLowerCase() == 'slider') {
                                listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomSlider parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} toSend={this.toSend} onChange={this.changeHandler.bind(this)}  handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>);
                                idC++;
                            }
                            else if (type.toLowerCase() == 'numberslider') {
                               // debugger;
                                listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomNumberSlider parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} toSend={this.toSend} onChange={this.changeHandler.bind(this)}  handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>);
                                idC++;
                            }
                            else if (type.toLowerCase() == 'checkbox') {
                                if(paramObj.values && paramObj.values.length > 0 && paramObj.permissionType === 'R') {
                                    listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomCheckboxText parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>);
                                } else {
                                    listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomCheckbox parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} toSend={this.toSend} onChange={this.changeHandler.bind(this)}  handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>);
                                }
                                idC++;
                            }
                            else if (type.toLowerCase() == 'text') {
                                listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomTextField parameterValue={parameterValue} paramObj={paramObj} /></div>);
                                idC++;
                            }
                            else if (type.toLowerCase() == 'status') {
                                listitems.push(<div key={'d' + idC} viewid={parameter.viewId} className={"paramContainer " + show + " " + cols}><CustomStatus parameterValue={parameterValue} paramObj={paramObj} /></div>);
                                idC++;
                            }
                        }
                    }
                }
            })

            let show = showing == 0 ? " d-none" : ""
            return (<div key={id + "-children"} id={id + "-children"} className={"row" + show} style={{ paddingLeft: '30px', paddingRight: '15px', paddingTop: '10px', paddingBottom: 0, margin: 0, width: "100%" }}> {listitems} </div>)
        }
        else {
            return null;
        }
    }

    handleChange = (event, newValue, subzone) => {
        this.setState({ tabValue: newValue });
        this.tabValues[subzone] = newValue;
    };

    isProductOnline(){
        return this.props.product?.content?.connectionState?.toLowerCase() == "online";
    }

    isDeviceSettingsMode(parameter, parameterValue){
        if(!this.deviceSettingsModeFound){
            this.deviceSettingsModeFound = parameter.businessLabel == 'DeviceSettingsApp' && parameterValue == 0
        }
        
        return this.deviceSettingsModeFound;
    }

    getParameter(parameter, cols, readOnly) {

        //cols = "col-6"

        let paramObj;
        let type = parameter.type;
        let parameterValue = null;
        let telemetryObject = null;

        if(parameter.fullRow){
            cols = "col-12"
        } 

        if (type.toLowerCase() == 'button') {
            paramObj = parameter.command;
            if (paramObj != null) {
                if (readOnly) paramObj.permissionType = 'R'
                paramObj.online = this.isProductOnline();
                return (<div key={'cols' + paramObj.id} className={cols + " paramContainer"}><CustomButton paramObj={paramObj} toSend={this.toSend} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
            }
            else {
                let error = {};
                error["message"] = parameter.name;
                error["stack"] = "object empty";
                return (<div key={'colsError' + paramObj?.id} className={cols + " paramContainer"}><Error errors={error} /></div>)
            }
        }
        else { //command
            paramObj = parameter.parameter;
            if (readOnly && paramObj) paramObj.permissionType = 'R'
            let responseProductTelemetry = this.state.productTelemetry;
            if (paramObj != null || type.toLowerCase() == 'empty') {
                paramObj.online = this.isProductOnline();
                let index = -1;
                try {
                    if (this.gatewayId == 0) {
                        index = this.arrayCommandsToSendC2D.findIndex(obj => obj.parameter && obj.parameter.businessLabel == paramObj.businessLabel);
                    } else {
                        index = this.bleWriteToSend["params"].findIndex(obj => obj.parameter && obj.parameter.businessLabel == paramObj.businessLabel);
                    }
                }
                catch (err) { }
                if (index >= 0) {
                    if (this.gatewayId == 0) {
                        parameterValue = this.arrayCommandsToSendC2D[index].value
                    }
                    else {
                        parameterValue = this.bleWriteToSend["params"][index].value
                    }
                    paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                }
                else if (responseProductTelemetry.status == 200) {
                    if (responseProductTelemetry.data.content != null && type.toLowerCase() != 'empty') {
                        let t = this.getTelemetry(responseProductTelemetry.data.content, paramObj);
                        if (t != undefined && t != null) {
                            parameterValue = paramObj.businessLabel === "DeviceSettingsApp" ? Number(t.value): t.value;
                            paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                            telemetryObject = t;
                        }
                        
                    }



                }
                if (paramObj != undefined) {
                    let serialNumber = paramObj.businessLabel == 'ProductSerialNumber' ? true : false;
                    if (serialNumber) {
                        this.sendSerialNumber(paramObj, parameterValue);
                        return ('');
                    }
                }
                
                // adding infoText if exist in Parameters table
                if (
                    parameter &&
                    parameter.parameter &&
                    parameter.parameter.table === "parameters" &&
                    parameter.parameter.smartComponentTypeId &&
                    parameter.parameter.businessLabel
                    ) {
                    const { smartComponentTypeId, businessLabel } = parameter.parameter;
                    const key = `${smartComponentTypeId}.${businessLabel}.infoText`;
                    
                    const translatedText = this.props.t(`parameters:${key}`);
                    if (translatedText !== key) {
                        paramObj.infoText = translatedText;
                    }
                }
                this.key++;
                if (type.toLowerCase() == 'dropdown') {
                    return (<div key={'dropdown' + this.key} className={cols + " paramContainer"}><CustomSelectContainer style={{ width: '100%' }} value={parameterValue} parameter={paramObj} toSend={this.toSend} onChangeSelect={this.onChangeParent} onChange={this.changeHandler.bind(this)} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
                }
                else if (type.toLowerCase() == 'slider') {
                    return (<div key={'slider' + this.key} className={cols + " paramContainer"}><CustomSlider parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} toSend={this.toSend} onChange={this.changeHandler.bind(this)} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
                }
                else if (type.toLowerCase() == 'numberslider') {
                    return (<div key={'numberSlider' + this.key} className={cols + " paramContainer"}><CustomNumberSlider parameterValue={parameterValue} paramObj={paramObj} telemetryObject={telemetryObject} toSend={this.toSend} onChange={this.changeHandler.bind(this)} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
                }
                else if (type.toLowerCase() == 'checkbox') {
                    if(paramObj.values && paramObj.values.length > 0) {
                        return (<div key={'checkboxText' + this.key} className={cols + " paramContainer"}><CustomCheckboxText parameterValue={parameterValue} paramObj={paramObj} toSend={this.toSend} telemetryObject={telemetryObject} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
                    } else {
                        return (<div key={'checkbox' + this.key} className={cols + " paramContainer"}><CustomCheckbox parameterValue={parameterValue} paramObj={paramObj} toSend={this.toSend} telemetryObject={telemetryObject} onChangeCheckbox={this.onChangeParent} onChange={this.changeHandler.bind(this)} handleDisabledMessage={this.handleDisabledMessage.bind(this)} /></div>)
                    }
                }
                else if (type.toLowerCase() == 'text') {
                    return (<div key={'text' + this.key} className={cols + " paramContainer"}><CustomTextField parameterValue={parameterValue} paramObj={paramObj} /></div>)
                }
                else if (type.toLowerCase() == 'status') {
                    return (<div key={'status' + this.key} className={cols + " paramContainer"}><CustomStatus parameterValue={parameterValue} paramObj={paramObj} /></div>)
                }
                else if (type.toLowerCase() == 'empty') {
                    return (<div key={'empty' + this.key} className={cols + " paramContainer"}><div></div></div>)
                }
                else {
                    let error = {};
                    error["message"] = parameter.name;
                    error["stack"] = "object empty";
                    return (<div key={'emptyObject' + this.key} className={cols + " paramContainer customError"}><Error errors={error} /></div>)
                }
                this.key++;
            }
        }

    }
    sendParameters = async () => {
        this.setState({ showConfirm: true })

       
    }
    cancelParameters = () => {
        this.bleWriteCommandToSend["params"] = [];
        this.bleWriteToSend["params"] = [];
        this.arrayCommandsToSendC2D =[];
        this.setParametersModifications(true,true);
        setTimeout(() => {
            this.setState({ parameters: JSON.parse(JSON.stringify(this.state.parametersInit)) });
        }, 1);


    }
    updateParent = () => {
        if (typeof this.props.updateParameters === 'function') {
            this.props.updateParameters();
        }
    }
    isExpanded(name, expand = null) {
        let arr = JSON.parse(sessionStorage.getItem("accordionsExpandedIds"));
        let v = expand != null ? expand : false;
        if (!arr) {
            arr = {};
        }
        if (arr[name] == undefined || (arr[name] != undefined && expand != null)) {
            arr[name] = v;
        }

        sessionStorage.setItem("accordionsExpandedIds", JSON.stringify(arr));

        return arr[name];

    }

    confirmSendParameters = async () => {
        const { t } = this.props;

        let form;
        let httpProduct = new ProductHttpClient(this.context, this.props.product.content.id);
        this.setState({ loadingParametersConfirmed: true });
        this.loadingParametersConfirmed = true;
        let result = null;
        let errors = [];
        let success = [];
        if (this.gatewayId == 0) { //Cloud to device
            for (let command of this.arrayCommandsToSendC2D) {
                form = new FormData();
                form.append('data', JSON.stringify(command));
                result = await httpProduct.CloudToDevice(form);
                if (result.errors) {
                    let errorList = this.viewErrors(result.errors, command)
                    errors.push(errorList);
                }
                else {
                    success.push(t('messages.successParametersSend') + ": [" + command.businessLabel + "]")
                }
            }
        }
        else {  //direct Methods
            form = new FormData();
            form.append('data', JSON.stringify(this.bleWriteToSend));
            result = await httpProduct.DirectMethod(form);
            if (result.errors) {
                let errorList = this.viewErrors(result.errors, null)
                errors.push(errorList);
            }
            else {
                success.push(t('messages.successParametersSend'))
            }
        }
        // if (typeof this.props.renderMessage === 'function') {
           
        //     if (errors.length > 0) {
        //         // this.props.message(errors.join(", "), "error");
        //         this.props.renderMessage("error", (errors.join(", ")));
        //     } else {
        //         // this.props.message(success.join(", "), "success");
        //         this.props.renderMessage("success", (success.join(", ")));
        //     }
        // }
        if (errors.length > 0) {
            this.props.renderMessage("error", errors.join(", "));
            this.setState({ loadingParametersConfirmed: false, parameters: [], showConfirm: false, show: false });
            this.setParametersModifications(true, true);
        } else {
            this.props.renderMessage("success", success.join(", "));
            this.setState({loadingParametersConfirmed: false, parameters: [], showConfirm: false, show: false });
            this.setParametersModifications(true, true);
        }

        this.updateParent();
        this.bleWriteToSend["params"] = [];
        this.arrayCommandsToSendC2D = [];
        setTimeout(() => {
            this.setState({ parameters: JSON.parse(JSON.stringify(this.state.parametersInit)) });
        }, 1);
        if (typeof this.props.refresh === 'function') {
            this.props.refresh();
        }
    }

    confirmSendCommand = async () => {

        const { t } = this.props;

        const form = new FormData();
        form.append('data', JSON.stringify(this.bleWriteCommandToSend));
        let httpProduct = new ProductHttpClient(this.context, this.props.product.content.id);
        this.setState({loadingCommandConfirmed: true });
        let result = await httpProduct.DirectMethod(form);
        let errors = [];
        let success = [];
        if (result.errors) {
            let errorList = this.viewErrors(result.errors, null)
            errors.push(errorList);
        }
        else {
            success.push(t('messages.successParametersSend'))
        }
        if (errors.length > 0) {
            this.props.renderMessage("error", errors.join(", "));
            this.setState({ loadingParametersConfirmed: false, parameters: [], showConfirm: false, show: false });
            this.setParametersModifications(true, true);
        } else {
            this.props.renderMessage("success", success.join(", "));
            this.setState({ loadingParametersConfirmed: false, parameters: [], showConfirm: false, show: false });
            this.setParametersModifications(true, true);
        }

        this.handleCloseConfirmCommand();

        this.updateParent();
        this.bleWriteToSend["params"] = [];
        this.arrayCommandsToSendC2D = [];
        setTimeout(() => {
            this.setState({ parameters: JSON.parse(JSON.stringify(this.state.parametersInit)), loadingCommandConfirmed: false });
        }, 1);
        if (typeof this.props.refresh === 'function') {
            this.props.refresh();
        }
    };

    handleClose = (ev) => {
        this.setState({ show: false })
    }

    handleCloseConfirm = (ev) => {
        this.setState({ showConfirm: false })
    }
    handleCloseConfirmCommand = (ev) => {
        this.setState({ showConfirmCommand: false })
    }

    viewErrors(errors, command) {
        const { t } = this.props;
        let returnErrors = [];
        let c = "";
        if (command != null) {
            c = "[" + command.businessLabel + "]";
        }
        for (let error of errors) {
            if (this.isJson(error)) {
                let e = JSON.parse(error);
                returnErrors.push(t('messages.errorParametersSend') + ":" + c + e.error.code + ":" + t('messages.errorParametersSend' + (e.error.code + "").replace("-", "")))
            } else {
                returnErrors.push(t('messages.errorParametersSend') + ": " + c + error);
            }
        }
        return returnErrors;
    }

    
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const { t } = this.props;
        let dataLoaded = false;
        if (this.state.dataLoaded == true) {
            dataLoaded = true;
        }
        let notAreKPI = true;
        if (this.state.notDataKPI == false) {
            notAreKPI = false;
        }
        let parametersList = [];


        const handleChange = (panel) => (event, newExpanded) => {
            this.isExpanded(panel, newExpanded);
        };

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    key={'verticalTabPanel' + index}
                    role="tabpanel"
                    hidden={value !== index}
                    id={`vertical-tabpanel-${index}`}
                    aria-labelledby={`vertical-tab-${index}`}
                    {...other}
                    className="w-100 verticalTabPadding"
                >
                    {value === index && (
                        <div key={'parentDiv' + index} className="p-4">
                            <div key={'childrenDiv' + index}>{children}</div>
                        </div>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
        };

        function a11yProps(index) {
            return {
                id: `vertical-tab-${index}`,
                'aria-controls': `vertical-tabpanel-${index}`,
            };
        }
        function calcCols(numCols) {
            switch (numCols) {
                case 0:
                    return "col";
                case 1:
                    return "col-12";
                case 2:
                    return "col-12 col-lg-6";
                case 3:
                    return "col-12 col-md-6 col-lg-4";
                case 4:
                    return "col-12 col-md-4 col-lg-3";
                default:
                    return "col-" + (12 / numCols).toString()[0]
            }
        }

        if (dataLoaded) {
            const { t } = this.props;
            let parametersListTemp = [];
            let readOnly = this.props.product?.content?.connectionState?.toLowerCase() == "app"

            let i = 0;
            for (let subZone of this.state.parameters) {
                parametersListTemp = [];
                if (subZone != undefined) {
                    let subZoneTitle = subZone.title.trim();
                    if (subZoneTitle != "") {
                        subZoneTitle = t('parametersPage:titles.' + subZoneTitle);
                    }
                    if (this.tabValues[subZone.title] == null) this.tabValues[subZone.title] = 0;

                    parametersListTemp.push(
                        <AccordionsSummary 
                            key={this.key}
                            expandIcon={<img src={require('../../assets/icons/chevron-down.svg').default} style={{ width: '24px' }} />}
                            aria-controls="panel1a-content" ><h4>{subZone.smartComponentTypeName}{subZone.title && subZone.title != " " ? " - " + subZoneTitle : ""}</h4>
                        </AccordionsSummary>);

                    this.key++;

                    let cols = subZone.numColumns ? calcCols(subZone.numColumns) : this.state.cols

                    let mainTab = []
                    let extraTabsData = {};
                    let standardTabName = t("productspages.parameters.standard")
                    let smartComponentTypeId = -1

                    for (let parameter of subZone.parametersLists) {
                        if (parameter.children && parameter.children.length != 0) {
                            if (parameter.parameter != null) {
                                smartComponentTypeId = smartComponentTypeId == -1 && parameter.parameter.smartComponentTypeId ? parameter.parameter.smartComponentTypeId : smartComponentTypeId;
                                if(smartComponentTypeId === 30){
                                    standardTabName = t(parameter.parameter.table + ':' + parameter.parameter.smartComponentTypeId + '.CleaningMode.name');
                                } 
                                let name = t(parameter.parameter.table + ':' + parameter.parameter.smartComponentTypeId + '.' + parameter.parameter.businessLabel + '.name');
                                extraTabsData[name] = parameter
                            }
                        }
                        else {
                            mainTab.push(this.getParameter(parameter, cols, readOnly))
                        }

                        if (parameter != undefined) {
                            let paramObj;
                            let type = parameter.type;
                            let parameterValue = null;
                            let telemetryObject = null;
                            if (type.toLowerCase() == 'button') {
                                paramObj = parameter.command;
                                if (paramObj != null) {
                                    if (readOnly) paramObj.permissionType = 'R'
                                }
                                else {
                                    let error = {};
                                    error["message"] = parameter.name;
                                    error["stack"] = "object empty";
                                }
                            }
                            else { //command
                                paramObj = parameter.parameter;
                                if (readOnly && paramObj) paramObj.permissionType = 'R'
                                let responseProductTelemetry = this.state.productTelemetry;
                                if (paramObj != null || type.toLowerCase() == 'empty') {

                                    var index = -1;
                                    try {
                                        index = this.bleWriteToSend["params"].findIndex(obj => obj.parameter.businessLabel == paramObj.businessLabel);
                                    }
                                    catch (err) { }
                                    if (index >= 0) {
                                        parameterValue = this.bleWriteToSend["params"][index].value
                                        paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                    }
                                    else if (responseProductTelemetry.status == 200) {
                                        if (responseProductTelemetry.data.content != null && type.toLowerCase() != 'empty') {

                                            let t = this.getTelemetry(responseProductTelemetry.data.content, paramObj);
                                            if (t != undefined && t != null) {
                                                parameterValue = paramObj.businessLabel === "DeviceSettingsApp" ? Number(t.value): t.value;
                                                paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                                telemetryObject = t;
                                            }
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // ONE TAB GROUP FOR EACH SUBZONE
                    let tabs = [], tabPanels = [], i = 1;
                                  
                    /* PL10 does not want standard tab, just detection mode tab */
                    if(subZone.title === 'Product characteristics' && smartComponentTypeId === 30){
                        tabs.push(<Tab label={''} {...a11yProps(0)} style={{display: 'none !important'}} />)
                        this.tabValues[subZone.title] = 1
                    } else {
                        tabs.push(<Tab label={standardTabName} {...a11yProps(0)} />)
                    }
                    

                    for (let key in extraTabsData) {
                        let parameter = extraTabsData[key]
                        let paramObj = parameter.parameter;
                        let valuesIds = parameter.valuesIds;
                        let type = parameter.type;
                        let parameterValue = null;
                        let telemetryObject = null;
                        let responseProductTelemetry = this.state.productTelemetry;
                        paramObj.online = this.isProductOnline();
                        let index = -1;
                        try {
                            index = this.bleWriteToSend["params"].findIndex(obj => obj.parameter.businessLabel == paramObj.businessLabel);
                        }
                        catch (err) { }
                        if (index >= 0) {
                            parameterValue = this.bleWriteToSend["params"][index].value
                            paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                        }
                        else if (responseProductTelemetry.status == 200) {
                            if (responseProductTelemetry.data.content != null && type.toLowerCase() != 'empty') {
                                let t = this.getTelemetry(responseProductTelemetry.data.content, paramObj);
                                if (t != undefined && t != null) {
                                    parameterValue = paramObj.businessLabel === "DeviceSettingsApp" ? Number(t.value): t.value;
                                    paramObj.isDeviceSettingsMode = this.isDeviceSettingsMode(paramObj, parameterValue);
                                    telemetryObject = t;
                                }
                               
                            }
                        }

                        tabs.push(<Tab key={'tab' + paramObj.id} label={key} {...a11yProps(i)} />)
                    
                        const definedViewIdArray = parameter.children.filter(element => element.viewId !== -1);
                        const undefinedViewIdArray = parameter.children.filter(element => element.viewId === -1);
                        var undefinedViewIdParameters = []
                        for (let i = 0; i < undefinedViewIdArray.length; i++) {
                            if(undefinedViewIdArray[i].parameter != null){
                                undefinedViewIdParameters.push(this.getParameter(undefinedViewIdArray[i], cols, readOnly))
                            }
                        }
                        tabPanels.push(
                            <TabPanel key={'tabPanel' + paramObj.id} value={this.tabValues[subZone.title]} index={i}>
                                <div key={'tabdiv' + paramObj.id} style={{ paddingLeft: '15px' }}>
                                    <div key={'tabdivRow' + paramObj.id} className="row">
                                        {undefinedViewIdParameters}
                                        <div key={'tabdivCol' + paramObj.id} className="col-12">
                                            <CustomTabSelect type={type} parameterValue={parameterValue} paramObj={paramObj} valuesIds={valuesIds} toSend={this.toSend} onChangeSelect={this.onChangeParent} handleDisabledMessage={this.handleDisabledMessage.bind(this)} />
                                        </div>
                                        {this.getChildren(definedViewIdArray, paramObj.id, parameterValue, cols)}
                                    </div>
                                </div>
                            </TabPanel >
                        )

                        i++
                    }

                    parametersListTemp.push(
                        <AccordionDetail key={'accordion' + this.key}>
                            <div key={'acc' + this.key} style={{ flexGrow: 1, bgcolor: 'background.paper' }} className={"parentTabGrid " + (tabs.length == 0 ? "onlyOneTabChild": "")}>
                                <Tabs
                                    key={this.key}
                                    orientation="vertical"
                                    value={this.tabValues[subZone.title]}
                                    onChange={(event, newValue) => this.handleChange(event, newValue, subZone.title)}
                                    aria-label="Vertical tabs"
                                    style={{ borderRight: 1, borderColor: 'divider' }}>
                                    {tabs.map((tab, index) => {
                                        if(tab.props.label === ''){
                                            return <div></div>
                                        } else {
                                            return (
                                            <Tab
                                                key={index}
                                                label={tab.props.label}
                                                style={{
                                                display: tab.props.label === "hide" ? "hidden !important" : "block",
                                                }}
                                            />
                                            );
                                        }
                                        
                                    })}
                                </Tabs>
                                <TabPanel key={'tabPanel'+this.key} value={this.tabValues[subZone.title]} index={0}>
                                    <div key={'tabPaneldiv' + this.key} style={{ paddingLeft: '15px' }}>
                                        <div key={'tabPaneldivRow2' + this.key} className="row">
                                            {mainTab}
                                        </div>
                                    </div>
                                </TabPanel>
                                {tabPanels}
                            </div>
                        </AccordionDetail>
                    )
                    this.key++;


           
                    let n = subZone.smartComponentTypeName + " " + (subZone.title && subZone.title != " " ? " - " + subZone.title : "");
                    parametersList.push(
                        <Accordions key={this.key} defaultExpanded={subZone.expanded ? subZone.expanded : this.isExpanded(n)} classes={{ root: 'accordion-root-custom' }} onChange={handleChange(n)}>                     
                            {parametersListTemp}
                        </Accordions>);
                    this.key++;
                }
            }
        }
                
        return (
            <React.Fragment>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <div style={{ width: '100%' }}>
                    {dataLoaded ? (
                        <div className="row" style={{ overflow: 'visible', margin: '0 2px' }}>
                            <Dialog disableEscapeKeyDown open={this.state.show} onClose={this.handleClose}>
                                <DialogTitle>{t('actions.sendParameters')}</DialogTitle>
                                <DialogContent>
                                    <List>
                                        {this.state.listParametersToSend}
                                    </List>
                                </DialogContent>
                                <DialogActions>
                                    <Button className="primary pull-right float-right" onClick={this.handleClose}>{t('actions.close')}</Button>
                                    <Button className="primary pull-right float-right" onClick={this.sendParameters}>{t('actions.sendParameters')}</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                disableEscapeKeyDown
                                open={this.state.showConfirmCommand}
                                onClose={this.handleCloseConfirmCommand}>
                                <DialogTitle>{t('actions.confirm')}</DialogTitle>
                                <DialogContent>
                                    {t('actions.confirmSendCommand')}
                                    {this.state.loadingCommandConfirmed ? (<div className="row loadingParent mt-3 mb-3">< div className="loadingImg"></div></div>) : ""}
                                </DialogContent>
                                {!this.state.loadingCommandConfirmed ? (
                                    <DialogActions>
                                        <Button className="primary pull-right float-right" onClick={this.handleCloseConfirmCommand}>{t('actions.close')}</Button>
                                        <Button className="primary pull-right float-right" onClick={this.confirmSendCommand}>{t('actions.confirm')}</Button>
                                    </DialogActions>) : ""}
                            </Dialog>
                            <Dialog
                                disableEscapeKeyDown
                                open={this.state.showConfirm}
                                onClose={this.handleCloseConfirm}>
                                <DialogTitle>{t('actions.confirm')}</DialogTitle>
                                <DialogContent>
                                    {this.state.loadingParametersConfirmed ? t('actions.confirmSendingParameters') : t('actions.confirmSendParameters')}
                                    {this.state.loadingParametersConfirmed ? (<div className="row loadingParent mt-3 mb-3 ">< div className="loadingImg pt-2"></div></div>) : ""}
                                </DialogContent>
                                {!this.state.loadingParametersConfirmed ?
                                    (<DialogActions>
                                        <Button className="primary pull-right float-right" onClick={this.handleCloseConfirm}>{t('actions.close')}</Button>
                                        <Button className="primary pull-right float-right" onClick={this.confirmSendParameters}>{t('actions.confirm')}</Button>
                                    </DialogActions>) : ""}
                            </Dialog>
                            <div className="col-12 pb-3">
                                {parametersList}
                            </div>


                        </div>
                    ) : (
                        notAreKPI ? (<NoData />) : (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                    )}
                </div>
                <div id={"editParametersBox" + this.props.pageZonePosition} className={"editParametersBox " + (this.state.disabledSendParameters ? 'd-none' : '')}>
                    {dataLoaded ? (
                        <div className={"w-100 row "}>
                            <h3 className="col-2" style={{ marginLeft: "15px" }}>
                                Changes
                            </h3>
                            <div className="col" style={{ padding: '12px 0px' }}>
                                <List>
                                    {this.state.listParametersToSend}
                                </List>
                            </div>
                            <div className="">
                                <div className="" style={{ marginTop: "14px" }}>
                                    <Button className="primary" disabled={this.state.disabledSendParameters} onClick={this.sendParameters}>{t('actions.sendParameters')}</Button>
                                    <Button className="primary white" disabled={this.state.disabledCancelParameters} onClick={this.cancelParameters}>{t('actions.cancel')}</Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NoData />
                    )}
                </div>
            </ React.Fragment>
        );
    }
}
export default withTranslation()(ProductParameters)