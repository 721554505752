// TODO pending refactoring to tsx

import { useTranslation } from 'react-i18next';
import InvitationsList from '../../../../Invitations/InvitationsList';

const UserInvitations = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='row ml-1'>
        <h2>{t('productspages.user.tab.invitations')}</h2>
      </div>

      <div className='col-12 invitation-table card-container mb-5 pb-3'>
        <InvitationsList userPage={true} />
      </div>
    </div>
  );
};

export default UserInvitations;
