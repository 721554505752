import React from 'react';
import { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import AuthGate from '../config/AuthGate';
import { UserMeasure } from '@ComponentsRoot/UsersComponents/Tabs/UserConfigUnits/types/UserConfigUnitsTypes';

export type AccessToken = {
  accessToken: string;
  expiresOn: string;
};

export type FullAccessToken = {
  authority: string;
  uniqueId: string;
  tenantId: string;
  scopes: string[];
  account: AccountInfo;
  idToken: string;
  idTokenClaims: {
    ver: string;
    iss: string;
    sub: string;
    aud: string;
    exp: number;
    acr: string;
    nonce: string;
    iat: number;
    auth_time: number;
    termsOfUseConsentRequired: boolean;
    emails: string;
    name: string;
    given_name: string;
    family_name: string;
    city: string;
    country: string;
    telephoneNumber: string;
    postalCode: string;
    state: string;
    streetAddress: string;
    extension_brand: string;
    tid: string;
    at_hash: string;
    nbf: number;
  };
  accessToken: string;
  fromCache: boolean;
  expiresOn: Date;
  correlationId: string;
  requestId: string;
  extExpiresOn: Date;
  familyId: string;
  tokenType: string;
  state: string;
  cloudGraphHostName: string;
  msGraphHost: string;
  code?: undefined;
  fromNativeBroker: boolean;
};

export type UserData = {
  userBasicData: UserBasicData;
  userB2CData: UserB2CData;
  userThumbnailData: string;
  expiresOn: string;
};

export type AuthInfo = {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name: string;
  idTokenClaims: {
    ver: string;
    iss: string;
    sub: string;
    aud: string;
    exp: number;
    acr: string;
    nonce: string;
    iat: number;
    auth_time: number;
    termsOfUseConsentRequired: boolean;
    emails: string;
    name: string;
    given_name: string;
    family_name: string;
    city: string;
    country: string;
    telephoneNumber: string;
    postalCode: string;
    state: string;
    streetAddress: string;
    extension_brand: string;
    tid: string;
    at_hash: string;
    nbf: number;
  };
};

export type UserB2CData = {
  id: number;
  email: string;
  locale: string;
  lastSignInDateTime: string;
  guid: string;
  givenName: string;
  surname: string;
  displayName: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  streetAddress: string;
  telephoneNumber: string;
  emailConfirmed: boolean;
  createdDateTime: string;
  title: string;
  termsOfUseConsentDateTime: string;
  brand: 'ROCA' | 'LAUFEN';
  userRoles: userItemAssociated[];
  userInstallations: userItemAssociated[];
};

export type userItemAssociated = {
  code: number;
  name: string;
};

export type UserBasicData = {
  userMeasureUnits: UserMeasure[];
  scope: string;
  userId: string;
  userRoles: number[];
  locale: string;
  email: string;
  displayName: string;
  city: string;
  streetAddress: string;
  state: string;
  country: string;
  expiration: string;
  brand: 'ROCA' | 'LAUFEN';
};

export type AuthContextType = {
  isAuthenticated: boolean;
  authInfo: AuthInfo | null;
  brandCode: string | null;
  brandName: string | null;
  freeAccess: boolean;
  pathname: string;
  userData: UserData | null;
  userPending: {
    invitations: number;
    requests: number;
  } | null;
  userPreferences: {
    language: string;
    measures: UserMeasure[];
  } | null;
  login: AuthGate['login'];
  logout: AuthGate['logout'];
  forgotPassword: AuthGate['forgotPassword'];
  changePassword: AuthGate['changePassword'];
  editProfile: AuthGate['editProfile'];
  getToken: AuthGate['getToken'];
  refreshUserData: AuthGate['refreshUserData'];
  refreshInvitationsAndRequestsPending: AuthGate['refreshInvitationsAndRequestsPending'];
  setNewLanguage: AuthGate['setNewLanguage'];
  setNewMeasures: AuthGate['setNewMeasures'];
  fullScreen: AuthGate['fullScreen'];
  manageLocalStorage: AuthGate['manageLocalStorage'];
};

const AuthenticationContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  authInfo: null,
  brandCode: null,
  brandName: null,
  freeAccess: false,
  pathname: '',
  userData: null,
  userPending: null,
  userPreferences: null,
  login: AuthGate['login'],
  logout: AuthGate['logout'],
  forgotPassword: AuthGate['forgotPassword'],
  changePassword: AuthGate['changePassword'],
  editProfile: AuthGate['editProfile'],
  getToken: AuthGate['getToken'],
  refreshUserData: AuthGate['refreshUserData'],
  refreshInvitationsAndRequestsPending: AuthGate['refreshInvitationsAndRequestsPending'],
  setNewLanguage: AuthGate['setNewLanguage'],
  setNewMeasures: AuthGate['setNewMeasures'],
  fullScreen: AuthGate['fullScreen'],
  manageLocalStorage: AuthGate['manageLocalStorage']
});

export default AuthenticationContext;
