import axios from 'axios';
export default class HttpClient {
  constructor(context) {
    this.context = context;
    this.debug = false;

    this.language = window.navigator.language;
  }

  // userGetMe = async () => {
  //   var accessToken = await this.context.getToken();

  //   let config = {
  //     headers: {
  //       Authorization: `${accessToken.accessToken}`,
  //     },
  //   };
  //   var response = await axios.get('users/me', config);
  //   if (this.debug) {
  //     console.log('response: ' + JSON.stringify(response));
  //   }
  //   return response.data;
  // };

  // userGetMeGraphApi = async () => {
  //   var accessToken = await this.context.getToken();

  //   let config = {
  //     headers: {
  //       Authorization: `${accessToken.accessToken}`,
  //     },
  //   };
  //   var response = await axios.get('users/me/graphApi', config);
  //   if (this.debug) {
  //     console.log('response: ' + JSON.stringify(response));
  //   }
  //   return response.data;
  // };

  // userGetMeGraphApiThumbnailPhoto = async () => {
  //   var accessToken = await this.context.getToken();

  //   let config = {
  //     headers: {
  //       Authorization: `${accessToken.accessToken}`,
  //     },
  //   };
  //   var response = await axios.get('users/me/graphApi/thumbnail-photo', config);
  //   if (this.debug) {
  //     console.log('response: ' + JSON.stringify(response));
  //   }
  //   return response.data;
  // };

  userGetWithUserIdOrGuid = async (userIdOrGuid) => {
    var accessToken = await this.context.getToken();

    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get('users/graph/' + userIdOrGuid, config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  userGetAll = async () => {
    var accessToken = await this.context.getToken();

    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get('users/all', config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  getUserByEmail = async (email) => {
    const accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get('users/byEmail/' + email, config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  unsubscribe = async () => {
    try {
      var accessToken = await this.context.getToken();

      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      var response = await axios.delete('users/me', config);
      if (this.debug) {
        console.log('response: ' + JSON.stringify(response));
      }
      var response = await axios.delete('users/me/graphApi/', config);
      if (this.debug) {
        console.log('response: ' + JSON.stringify(response));
      }
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  };

  userPostMeGraphApiThumbnailPhoto = async (data) => {
    let accessToken = await this.context.getToken();

    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
        'content-type': 'multipart/form-data',
      },
    };

    var response = await axios.post('users/me/graphApi/thumbnail-photo', data, config);
    if (this.debug) {
      console.log('calling UserUploadFile: ' + 'users/me/graphApi/thumbnail-photo');
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  userPostMeGraphApi = async (elements) => {
    try {
      let accessToken = await this.context.getToken();
      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      var response = await axios.post('users/me/graphApi', elements, config);
      if (this.debug) {
        console.log('calling: users/me/graphApi');
        console.log('response: ' + JSON.stringify(response));
      }
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  };

  userPostGraphApi = async (elements) => {
    try {
      let accessToken = await this.context.getToken();
      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      var response = await axios.post('users/graphApi/' + elements.guid, elements, config);
      if (this.debug) {
        console.log('calling: users/graphApi');
        console.log('response: ' + JSON.stringify(response));
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  userPatchMe = async (patch) => {
    let accessToken = await this.context.getToken();

    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };

    var response = await axios.put('users/me', patch, config);
    if (this.debug) {
      console.log('calling: users/me');
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  GetTimezones = async () => {
    var accessToken = await this.context.getToken();

    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get('users/timezones', config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  userGetStatistics = async (id) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };

    var response = await axios.get('users/me/export-statistics/' + id, config);
    if (this.debug) {
      console.log('calling: ' + '/users/me/export-statistics/');
      //console.log("response: " + JSON.stringify(response));
    }
    return response.data;
  };

  getConfigB2c = async () => {
    let config = {};
    var response = await axios.get('config/msal', config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  searchDirection = async (direction) => {
    let config = {};
    var response = await axios.get(
      'https://atlas.microsoft.com/search/address/json?&subscription-key=xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo&api-version=1.0&language=' +
        this.language +
        '&query=' +
        escape(direction),
      config
    );
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };
  searchDirectionByLocation = async (longitude, latitude) => {
    let config = {};

    var response = await axios.get(
      'https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo&language=' +
        this.language +
        '&query=' +
        latitude +
        ',' +
        longitude,
      config
    );
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  userAddRole = async (roles) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    var response = await axios.post('users/roles/add', roles, config);
    return response.data;
  };

  userRemoveRole = async (roles) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    var response = await axios.post('users/roles/remove', roles, config);
    return response.data;
  };
}
