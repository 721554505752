import React, { Component } from 'react';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import Form from 'react-bootstrap/Form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Message from '../../Message/Message';
import { withTranslation } from 'react-i18next';
import DateSelector from '../../CustomComponents/CustomDateSelector';
import KPIsChartContainer from '../../ChartsComponents/container/KPIsChartContainer';


class InstallationsReporting extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            productTypes: null,
            products: null,
            families: null,
            productTypesSelected:[],
            productsSelected: [],
            familiesSelected: [],
            filterByAttribute : "",
            visibilityAggregate: "productType",
            gender: [],
            type:[],
            area:[],
            environment:[],
            productsFilter: [],
            familiesFilter: [],
            productsFilter: [],
            dateRange: this.props.dateRange,
            groupBySelected: this.props.groupBySelected,
            message: null,
        };
    }

    componentDidMount() {
        this.getProductsType();
        document.getElementsByClassName('installationsReportingCharts').onscroll = function () {
            this.classList.toggle("notop", this.scrollTop > 90);
        }
    }

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
          }
        });
      };
    
      handleDialogClose = () => {
        this.setState({ message: null });
      };

    getProductsType() {
        let productTypes = [];
        let productTypesSelected = [];
        let products = [];
        let productsSelected = [];
        let families = [];
        let familiesSelected = [];
        if (this.props.data.products != undefined) {
            for (let product of this.props.data.products) {
                if(product.hasAccess){
                    let prod = {};
                    prod['id'] = product.id;
                    let name = (product.name != "" && product.name != undefined) ? product.name : product["productType"]["name"];
                    console.log("name", name)
                    prod['name'] = name+'-'+ product["productType"]["letter"]+product.tag;
                    prod['productTypeId'] = product.productTypeId;
                    prod['letter'] = product["productType"]["letter"];
    
                    let prodType = {};
                    prodType['id'] = product["productType"]["id"];
                    prodType['name'] = product["productType"]["name"];
                    prodType['letter'] = product["productType"]["letter"];
                    if (productTypes.find(e => e.id == product["productType"]["id"]) == undefined) {
                        productTypes.push(prodType);
                        productTypesSelected.push(prodType.id);
                    }
    
                    let family = {};
                    family['id'] = product["productType"]["letter"];
                    family['name'] = product["productType"]["letter"];
                    if (families.find(e => e.id == product["productType"]["letter"]) == undefined) {
                        families.push(family);
                        familiesSelected.push(family.id);
                    }
                    products.push(prod);
                    productsSelected.push(product.id);
                }         
            }
        }
        
        this.setState({ productTypes: productTypes, products:products, families:families, productTypesSelected:productTypesSelected, familiesSelected:familiesSelected, productsSelected:productsSelected});
    }

    handleChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };

    handleGranularityChange(value) {
        this.groupBySelected = value;
    }
    handleDateRangeChange(value) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, this.groupBySelected);
        }
        this.setState({ dateRange: value, groupBySelected: this.groupBySelected });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.chartViewZones?.content !== prevState.chartViewZones?.content) {
            return ({ chartViewZones: nextProps.chartViewZones })
        }
        return null
    }

    render() {

        const { t } = this.props;

        const handleChangeFamily = (evnt) => {
            let familiesSelected = this.state.familiesSelected;
            let family = evnt.target.id.split("-")[1];
            let value = evnt.target.checked ? 1 : 0
            if (value == 1) {
                if (familiesSelected.indexOf(family) == -1) {
                    familiesSelected.push(family);
                    this.setState({ familiesSelected: familiesSelected });
                }
            }
            else {
                let position = familiesSelected.indexOf(family);

                if (position > -1) {
                    familiesSelected.splice(position, 1);
                }
            }
            let productTypesSelected = [];

            for (let productType of this.state.productTypes) {
                if (familiesSelected.filter(e => e === productType.letter).length > 0) {
                    if (productTypesSelected.filter(e => e.id === productType.id).length <= 0) {
                        productTypesSelected.push(productType.id);
                    }
                }
            }
            let productsSelected = [];
            for (let product of this.state.products) {
                if (productTypesSelected.filter(e => e === product.productTypeId).length > 0) {
                    productsSelected.push(product.id);
                }
            }
            this.setState({ productTypesSelected: productTypesSelected, productsSelected: productsSelected });
        };

        const handleChangeProductType = (evnt) => {
            let productTypesSelected = this.state.productTypesSelected;
            let productType = parseInt(evnt.target.id.split("-")[1]);
            let value = evnt.target.checked ? 1 : 0
            if (value == 1) {
                if (productTypesSelected.indexOf(productType) == -1) {
                    productTypesSelected.push(productType);
                    this.setState({ productTypesSelected: productTypesSelected });
                }
            }
            else {
                let position = productTypesSelected.indexOf(productType);

                if (position > -1) {
                    productTypesSelected.splice(position, 1);
                    this.setState({ productTypesSelected: productTypesSelected });
                }
            }
            let productsSelected = [];
            for (let product of this.state.products) {
                if (productTypesSelected.find(productType => productType == product.productTypeId)) {
                    productsSelected.push(product.id);
                }
            }
            this.setState({ productTypesSelected: productTypesSelected, productsSelected:productsSelected });
        };

        const handleChangeProduct = (evnt) => {
            let productsSelected = this.state.productsSelected;
            let product = parseInt(evnt.target.id.split("-")[1]);
            let value = evnt.target.checked ? 1 : 0
            if (value == 1) {
                productsSelected.push(product);
                this.setState({ productsSelected: productsSelected });
            }
            else {
                let position = productsSelected.indexOf(product);

                if (position > -1) {
                    productsSelected.splice(position, 1);
                    this.setState({ productsSelected: productsSelected });
                }
            }
        };

        const handleChangeVisibility = (visibility) => {
            //if (visibility == "family") {
            // this.renderMessage('error', "under construction");
            //}
            //else {
                this.setState({ visibilityAggregate: null });
                setTimeout(() => { this.setState({ visibilityAggregate: visibility }) }, 1);
            //}
        }

        const handleChangeFilterByAttribute = (evnt) => {
            this.renderMessage('error', "under construction");

            switch (evnt.target.value) {
                case "gender":
                    this.setState({ gender: ["Ladies", "Gentlemen", "Neutral"], type: [], area: [], environment: [], filterByAttribute: evnt.target.value });
                    break;
                case "type":
                    this.setState({ gender: [], type: ["Handicapped", "Service", "Nursing", "Shower", "Family", "Other"], area: [], environment: [], filterByAttribute: evnt.target.value });
                    break;
                case "area":
                    this.setState({ gender: [], type: [], area: ["Entrance", "Staff", "Public", "Other"], environment: [], filterByAttribute: evnt.target.value });
                    break;
                case "environment":
                    this.setState({ gender: [], type: [], area: [], environment: ["Hotel", "Shopping", "Infrastructure", "Education", "Business", "Other"], filterByAttribute: evnt.target.value });
                    break;
                case "without":
                default:
                    this.setState({ gender: [], type: [], area: [], environment: [], filterByAttribute: evnt.target.value});
                    break;
            }
        };

        let filter = {};
        filter["gender"] = this.state.gender;
        filter["type"] = this.state.type;
        filter["area"] = this.state.area;
        filter["environment"] = this.state.environment;
        filter["familiesSelected"] = this.state.familiesSelected;
        filter["productTypesSelected"] = this.state.productTypesSelected;
        filter["productsSelected"] = this.state.productsSelected;
        return (
            <React.Fragment>
                {
                    (this.state.productTypes != null && this.state.products != null && this.state.families != null && this.state.visibilityAggregate != null) ? (
                    <div className="row">
                            {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                            <div className="col-12 col-md-3 menuRaportng">
                            <div className="subGroupMenuRaporting">
                                    <span className={"titleMenuRaporting" + ((this.state.visibilityAggregate == 'family') ? "" : "  colorLight")}>Families {(this.state.visibilityAggregate == 'family') ? (<Visibility style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("family")} />) : (<VisibilityOff style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("family")} />)}</span>
                                {(this.state.families.map((value, p) => (
                                    <div className="flexContainer" key={p}>

                                        <Form style={{ marginTop: '12px' }}>
                                            <Form.Check
                                                type="checkbox"
                                                id={'cf-' + value.id}
                                                label=""
                                                checked={this.state.familiesSelected.filter(e => e === value.id).length > 0}
                                                onChange={(evt) => handleChangeFamily(evt, this.context)}
                                            />
                                        </Form>
                                        <span className="t1" className="itemMenuRaporting" > {t('letters.'+value.id)} </span>
                                    </div>
                                )))}
                            </div>
                            <div className="subGroupMenuRaporting">
                                    <span className={"titleMenuRaporting" + ((this.state.visibilityAggregate == 'productType') ? "" : "  colorLight")}>{t('productspages.general.producttype')}{(this.state.visibilityAggregate == 'productType') ? (<Visibility style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("productType")} />) : (<VisibilityOff style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("productType")} />)}</span>
                                {(this.state.productTypes.map((value, p) => (
                                    <div className="flexContainer" key={p}>
                                    
                                    <Form style={{ marginTop: '12px'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id={'cpt-' + value.id}
                                                label=""
                                                checked={this.state.productTypesSelected.filter(e => e === value.id).length > 0}
                                            onChange={(evt) => handleChangeProductType(evt, this.context)}
                                        />
                                    </Form>
                                        <span className="t1" className="itemMenuRaporting" > {value.name} </span>
                                </div>
                                )))}
                                
                            </div>
                            <div className="subGroupMenuRaporting">
                                    <span className={((this.state.visibilityAggregate == 'product') ? "titleMenuRaporting" : " titleMenuRaporting colorLight")}>{t('products')}{(this.state.visibilityAggregate == 'product') ? (<Visibility style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("product")} />) : (<VisibilityOff style={{ cursor: 'pointer' }} onClick={() => handleChangeVisibility("product")}/>)}</span>
                               {(this.state.products.map((value, p) => (
                                   <div className="flexContainer" key={p}>

                                       <Form style={{ marginTop: '12px'}}>
                                           <Form.Check
                                               type="checkbox"
                                               id={'cp-' + value.id}
                                               label=""
                                               checked={this.state.productsSelected.filter(e => e === value.id).length >0}
                                               onChange={(evt) => handleChangeProduct(evt, this.context)}
                                           />
                                       </Form>
                                       <span className="t1" className="itemMenuRaporting"> {value.name}</span>
                                       
                                    </div>
                                )))}
                            </div>
                            {/*<div className="subGroupMenuRaporting">*/}
                            {/*        <div className="flexContainer">*/}
                            {/*           <FormControl component="fieldset">*/}
                            {/*                <FormLabel component="legend">{t('actions.filterByAttribute')}</FormLabel>*/}
                            {/*                <RadioGroup aria-label="attribute" name="attribute" value={this.state.filterByAttribute} onChange={handleChangeFilterByAttribute}>*/}
                            {/*                    <FormControlLabel className="radioButtonReporting" value="" control={<Radio />} label="Without" />*/}
                            {/*                    <FormControlLabel className="radioButtonReporting" value="gender" control={<Radio />} label="Gender" />*/}
                            {/*                    <FormControlLabel className="radioButtonReporting" value="type" control={<Radio />} label="Type" />*/}
                            {/*                    <FormControlLabel className="radioButtonReporting" value="area" control={<Radio />} label="Area" />*/}
                            {/*                    <FormControlLabel className="radioButtonReporting" value="environment" control={<Radio />} label="Environment" />*/}
                            {/*               </RadioGroup>*/}
                            {/*           </FormControl>*/}
                            {/*        </div>*/}
                            {/*</div>*/}
                        </div>
                            <div className={`col-12 col-md-9 installationsReportingCharts`}>
                                <div className="sticky-div">
                                    <DateSelector
                                        style={{ justifyContent: "right" }}
                                        className={"row w-100 m-0"}
                                        defaultGranularity={this.props.groupBySelected}
                                        onDateChange={this.handleDateRangeChange.bind(this)}
                                        onGranularityChange={this.handleGranularityChange.bind(this)}
                                        timeframes={['day', 'week','month','year']}
                                        defaultDate={ this.props.dateRange }
                                    />
                                </div>

                                {this.props.isDashboard != undefined && this.props.isDashboard == true ?
                                    (<div className="dashboard-charts">
                                        <KPIsChartContainer page="installation" installation={this.props.data} aggregateBy={this.state.visibilityAggregate} filter={filter} groupBySelected={this.state.groupBySelected} dateRange={this.state.dateRange} chartViewZones={this.state.chartViewZones} pageZonePosition="Dashboard-Reporting" />
                                    </div>
                                    )
                                    :
                                    (<div className="row w-100 pt-2 m-0 ">
                                        <KPIsChartContainer page="installation" installation={this.props.data} aggregateBy={this.state.visibilityAggregate} groupBySelected={this.state.groupBySelected} filter={filter} dateRange={this.state.dateRange} chartViewZones={this.props.chartViewZones} legendTranslate={false} pageZonePosition="Products-Reporting" />
                                        <KPIsChartContainer page="installation" className="InstallationReportingKPIlastValues" cols="col-3" installation={this.props.data} groupBySelected={this.state.groupBySelected} aggregateBy={this.state.visibilityAggregate} filter={filter} dateRange={this.state.dateRange} chartViewZones={this.state.chartViewZones} legendTranslate={false} pageZonePosition="Products-General-LastValues" />                                        
                                    </div>
                                    )

                                }



                            </div>
                    </div>
                    ): (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                }
            </React.Fragment>
        );
    }
} export default withTranslation()(InstallationsReporting)