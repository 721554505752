import axios from 'axios';

class powerBIRepository {
  getPowerBiToken = async () => {
    try {
      const response = await axios.get('users/powerbi/token');
      return response.data.access_token;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  getReportValue = async (powerBiToken: string) => {
    let config = {
      headers: {
        Authorization: powerBiToken,
      },
    };
    try {
      const response = await axios.get('users/powerbi/reportvalue', config);
      if (response.data?.value?.length) {
        return response.data.value[0];
      }
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  getEmbedToken = async (powerBiToken: string, datasetId: string, reportId: string) => {
    let config = {
      headers: {
        Authorization: powerBiToken,
      },
    };
    try {
      const response = await axios.get(`users/powerbi/embedtoken/${datasetId}/${reportId}`, config);
      if(response.data){
        return response.data;
      }
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
}

export default powerBIRepository;
