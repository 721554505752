import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Error from '../Error';

class CustomButton extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false};
    }

    render() {
        let dataLoaded = false;
        
        if (this.props.paramObj != null) {
            dataLoaded = true;
        //    this.setState();
        }

        const { t } = this.props;

        const handleOnClick = () => {
            this.props.toSend(this.props.paramObj,null);
        };

        const handleDisabledClick = (disabled)=>{
            if(this.props.handleDisabledMessage && disabled){
                this.props.handleDisabledMessage(disabled);
            }
        }

        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
            let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
            try {
                return (
                    <FormControl variant="standard">
                        <div onClick={() => handleDisabledClick({deviceSetting:this.props.paramObj.isDeviceSettingsMode, readOnly: this.props.paramObj.permissionType == 'R', online: this.props.paramObj.online})}>
                            <Button
                                className="primary paramButton"
                                id={"b-" + this.props.paramObj.businessLabel}
                                variant="contained"
                                color="primary"
                                disabled={disabled}
                                onClick={handleOnClick}
                                style={{height:"auto", minHeight: "45px"}}
                                //endIcon={<Icon>send</Icon>}
                            >
                                {(name) ? name : this.props.paramObj.businessLabel}
                                
                            </Button>
                        </div>
                    </FormControl>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomButton)