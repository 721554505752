export const selectDropdownStyle = ({ height = 250 }) => ({
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown menu is above other elements
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight:
        state.options.length >= 3 ? `${height}px` : `${state.options.length * 40}px`, // Adjust height based on number of options
      overflowY: "auto", // Ensure the list is scrollable
    }),
  })