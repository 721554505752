import React, { Component } from 'react';
import '../../App.css';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';
import Error from '../../components/Error';
import { manageInfoUser } from '../Common/utils/helpers';

class DictionaryTool extends Component {

    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);

        this.state = {
            userMe: null,
            dataLoaded: false
        }
    }

    componentDidMount() {
        this.getUserMe();
    }

    async getUserMe() {
        const userMe = await manageInfoUser(this.context, "userBasicData");
        this.setState({ userMe, dataLoaded: true });        
    }

    render() {
        const url = "https://apps.powerapps.com/play/e/default-d1c8e415-85f1-44ab-9e62-f98f14bc289a/a/7d2beeb3-26cd-40bc-a82c-1f40aa2c8af2?tenantId=d1c8e415-85f1-44ab-9e62-f98f14bc289a";

        return (

            (this.state.dataLoaded ? (

                (this.state.userMe != null && this.state.userMe.userRoles != null && (this.state.userMe.userRoles.includes(100) || this.state.userMe.userRoles.includes(110))) ? (

                    <div className="dictionaryContainer">
                        <div className="row generalInfo">

                            {/*<iframe src={url} style={{ width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />*/}
                            <iframe src={url + "&username=" + this.state.userMe.email} style={{ display: "block", width: "100%", height: "768px" }} frameBorder="0" allowFullScreen />

                        </div>
                    </div>

                ) : (
                        <Error errors={{ status: 401 }} />
                )

            ) : (
                <div className="loadingParent">< div className="loadingImg"></div></div >)
            )
        );
    }

}

export default withTranslation()(DictionaryTool);