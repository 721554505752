// TODO REFACTOR, clean obsolete code

import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import React from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import { SWPackageContainerProps, SWPackageContainerState, SWPackageProps, TableRowItem } from "../types/SWPackageTypes";
import SWPackageView from "../views/SWPackageView";
import FirmwaresHttpClient from "../../../../../../HttpClient/FirmwaresHttpClient";
import { DataPackage } from "../../types/FirmwareTypes";

export class SWPackageContainer extends Component<WithTranslation & SWPackageContainerProps, SWPackageContainerState> {

    constructor(props: WithTranslation & SWPackageContainerProps) {
        super(props);

        this.state = {
            dataPackages: [],
            dataFirmwares: [],
            dataPackagesFiltered: [],
            dataLoaded: false,
            page: localStorage.getItem('FirmwarePackageTablePage') ? parseInt(localStorage.getItem('FirmwarePackageTablePage') ?? "1") : 1,
            sizePerPage: localStorage.getItem('FirmwarePackageTableSizePerPage') ? parseInt(localStorage.getItem('FirmwarePackageTableSizePerPage') ?? "5") : 5,
            totalSize: 0,
            showPackage: false,
            showFirmware: false,
            showModalDelete: false,
            message: null,
            step: 0,
            errors : null,
            productSystems: this.props.systemIds,
            allSmartComponentTypes: this.props.allSmartComponentTypes,
            editPackage: false,
            editPackageInUse: false,
            modeFirmwarePackageForm: null,
            idCompoDelete: -1,
            selectedValue: null,
            systemIds: this.props.systemIds,
            packageRow: null,
            showDialog: false
        }
        this.checkFormatter = this.checkFormatter.bind(this)
        this.actionFormatter = this.actionFormatter.bind(this)
        this.handleStepper = this.handleStepper.bind(this)
        this.handleFirmwareClick= this.handleFirmwareClick.bind(this)
        this.renderMessage = this.renderMessage.bind(this)
        this.showMessageInTable = this.showMessageInTable.bind(this)
        this.delete = this.delete.bind(this)

    }

    componentDidMount() {
        this.getFirmwarePackages();
    }

    componentWillUnmount(){
        localStorage.removeItem("FirmwarePackageTableSizePerPage");
        localStorage.removeItem("FirmwarePackageTablePage");
    }

    patchAvailableCheck = async (value: boolean, objCheck: TableRowItem, columnName: string) => {
        const { t } = this.props;

        const patchObjValue = [{
            op: "replace",
            path: `/${columnName}`,
            value: String(value)            
            }]

        const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context);
        let data = await http.patchSmartComponent(objCheck.row.id, patchObjValue);
        if(data.content){
            this.renderMessage('success', t('firmwareManagementPage.PackageChanged.ok'));
        }else{
            this.renderMessage('success',  t('firmwareManagementPage.PackageChanged.ko'));
        }
        this.getFirmwarePackages();
    }

    renderMessage(type: string, content: string){
        this.setState({
            message: {
              type: type,
              content: content,
            }
          });
    }

    async getFirmwarePackages() {
        try { 
            //Get smartcomponentPackage
            this.setState({ dataLoaded: false });
            const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context);
            let data = await http.GetSmartComponentPackage();

            //Filtered SystemsId to set the filter.
            let filteredSystemsId: any = [...new Set(data.content.map(item => item.systemId))];
            filteredSystemsId = filteredSystemsId.map(obj => ({ label: obj, value: obj }));
            this.setState({ systemIds: filteredSystemsId });

            //If we already have a filter set, apply it to the data we show when refreshing the Firmwares
            if(this.state.selectedValue){
                let f = [];
                f = data.content.filter(obj => obj.systemId == this.state.selectedValue.value);    
                this.setState({ dataPackages: data.content, dataPackagesFiltered: f, dataLoaded: true });
            }
            else{
                this.setState({ dataPackages: data.content, dataPackagesFiltered: data.content, dataLoaded: true });
            }
        }
        catch (err: any) {
            console.log("error loading Firware Packages")
            console.log(err)
            this.setState({ dataPackages: [], totalSize: 0, dataPackagesFiltered: [], dataLoaded: false, errors: { 'code': err.response.status, 'message': err.message } });
        }
    }

    async delete() {
        const { t } = this.props;
        const { idCompoDelete } = this.state
        try {
            const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context);
            const selectedPackage = this.state.dataPackages.find(pckg => pckg.id === idCompoDelete);
            let error = false;
            if (selectedPackage && selectedPackage.smartComponentFirmwares.length) {
                await Promise.all(selectedPackage.smartComponentFirmwares.map(async (compo) => {
                    const response = await http.deleteSmartComponentPackageDependencyId(compo.packageDependencyId);
                    if (response.status && response.status !== 200) {
                        error = true;
                    }
                }));
            }
            if (!error) {
                const response = await http.deleteSmartComponentPackage(selectedPackage!.id);
                if (response.status && response.status === 200) {
                    this.setState({ showModalDelete: false });
                    this.renderMessage('success', t('messages.successDeletePackage'));
                    this.getFirmwarePackages();
                }
            }
        } catch (e) {
            this.setState({ showModalDelete: false });
            this.renderMessage('error', t('messages.errorDeletePackage'));
          }
    }

    onSizePerPageChange = (sizePerPage: number) => {
        localStorage.setItem('FirmwarePackageTableSizePerPage', sizePerPage.toString())
        this.setState({ sizePerPage: sizePerPage });
    }
    
    onPageChange = (newPage: number) => {
        localStorage.setItem('FirmwarePackageTablePage', newPage.toString())
        this.setState({ page: newPage });
    }

    openPackageDialog = () => {
        this.setState({ modeFirmwarePackageForm: "new", showPackage: true, editPackage: false, editPackageInUse: false, packageRow: null })
    }

    handlePackageClose = () => {
        this.setState({ showPackage: false, editPackage: false, editPackageInUse: false, packageRow: null })

    }
    handlePackageCloseUpdate = (update: boolean = true) => {
        this.handlePackageClose();
        if (update) {
            this.getFirmwarePackages();
        }
    }
    handleSystemChange = (event) => {
        this.updateTable(event);
    };

    handleDialogClose = () => {
        this.setState({ showDialog: false })
    }

    handleMessageClose = () => {
        this.setState({ message: null })
    }

    handleCloseDelete = () => {
        this.setState({ showModalDelete: false });
    }

    updateTable(event) {
        this.setState({ dataLoaded: false }, () => {
            const {dataPackages} = this.state
            let filteredDataPackages = dataPackages;
            if (event != null) {
                filteredDataPackages = dataPackages.filter(obj => obj.systemId == event.value);
            } 

            this.setState({ selectedValue: event, dataPackagesFiltered: filteredDataPackages, dataLoaded: true });
        });
    }

    handleFirmwareClick() {
        let val = !this.state.showFirmware;
        this.setState({ showFirmware: val });
    }

    handleStepper(step: number) {
        this.setState({ step: step });
    }

    showMessageInTable(message: string, messageType: string) {
        const { t } = this.props
        this.renderMessage(messageType, t(message));
      }

    onDeleteClick = (row: DataPackage) => {
        this.setState({ showModalDelete: true, idCompoDelete: row.id ?? 0 });
    }
     onEditClick = (row: DataPackage) => {
        this.setState({ modeFirmwarePackageForm: "edit", showPackage: true, editPackage: true, editPackageInUse: row.inUse, packageRow: row });
    }

    getTableColumns() {
        const {t} = this.props;

        return [
            {
                dataField: 'name',
                text: t('forms.name'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'description',
                text: t('forms.description'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'systemId',
                text: t('firmwareManagementPage.systemId'),
                align: 'left',
                sort: true,
                hidden: false,
                headerStyle: () => {
                    return { width: "5%" };
                }
            },
            {
                dataField: 'smartComponentTypeId',
                text: t('firmwareManagementPage.smartComponentTypeId'),
                align: 'left',
                sort: true,
                hidden: false,
                headerStyle: () => {
                    return { width: "5%" };
                }
            },
            {
                dataField: 'version',
                text: t('firmwareManagementPage.version'),
                align: 'center',
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                }
            },
            {
                dataField: 'available',
                text: t('firmwareManagementPage.available'),
                align: 'center',
                formatter: this.checkFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                }
            },
            {
                dataField: 'production',
                text: t('firmwareManagementPage.production'),
                align: 'center',
                formatter: this.checkFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                }

            },
            {
                dataField: 'actions',
                text: '',
                align: 'center',
                formatter: this.actionFormatter,
                headerStyle: () => {
                    return { width: "5%" };
                }
            }
        ]
    }

    checkFormatter(objCheck: TableRowItem) {
        const { row, value, column } = objCheck
        var id = `custom-switch_${column.name}_${row.id}`;
        return (
            <div>
                <Form>
                    <Form.Check
                        type="checkbox"
                        id={id}
                        label=""
                        defaultChecked={value}
                        onChange={(e) => this.patchAvailableCheck(e.target.checked, objCheck, column.name)}
                        />
                </Form>
            </div>
        );
    }

    actionFormatter(objCheck: TableRowItem){
        const {row} = objCheck
        return (
            <React.Fragment>
                <EditOutlinedIcon id={"btnEdit_" + row.id} style={{ width: '24px',marginRight: '5px', cursor: "pointer", float: 'left' }} onClick={() => this.onEditClick(row)} />
                {!row.inUse ? <DeleteOutlineIcon id={"btnDelete_" + row.id} style={{ width: '24px', cursor: "pointer" }} onClick={() => this.onDeleteClick(row)} />: ""}
            </React.Fragment>
        
        );
    }

    getSWPackageViewProps(): SWPackageProps  {
        return {
            packageLoaded: this.state.dataPackages != null,
            errors: this.state.errors,
            handleDialogClose: this.handleDialogClose,
            message: this.state.message,
            showModalDelete: this.state.showModalDelete,
            handleClose: this.handleDialogClose,
            confirmDelete: this.delete,
            handleCloseDelete: this.handleCloseDelete,
            showPackage: this.state.showPackage,
            handlePackageClose: this.handlePackageClose,
            step: this.state.step,
            totalSteps: 3,
            dataLoaded: this.state.dataLoaded,
            columns: this.getTableColumns(),
            dataPackagesFiltered: this.state.dataPackagesFiltered,
            page: this.state.page,
            handlePackageCloseUpdate: this.handlePackageCloseUpdate,
            dataPackages: this.state.dataPackages,
            productSystems: this.state.productSystems,
            systemIds: this.state.systemIds,
            handleFirmwareClick: this.handleFirmwareClick,
            handleStepper: this.handleStepper,
            showMessageInTable: this.showMessageInTable,
            editPackage: this.state.editPackage,
            packageRow: this.state.packageRow,
            modeFirmwarePackageForm: this.state.modeFirmwarePackageForm,
            allSmartComponentTypes: this.state.allSmartComponentTypes,
            openPackageDialog: this.openPackageDialog,
            sizePerPage: this.state.sizePerPage,
            onSizePerPageChange: this.onSizePerPageChange,
            onPageChange: this.onPageChange,
            handleSystemChange: this.handleSystemChange,
            selectedValue: this.state.selectedValue,
            handleMessageClose: this.handleMessageClose
        }
    }

    render() {
        return (
          <SWPackageView {...this.getSWPackageViewProps()}></SWPackageView>
        );
      }
}

export default withTranslation()(SWPackageContainer)