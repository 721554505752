import { Component } from 'react';
import '../../App.css';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Table from '../CustomComponents/ReactTable/Table';
import Message from '../Message/Message';
class RequestsInbox extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    if (this.props.id) this.id = this.props.id;
    else this.id = null;
    this.state = {
      requests: {},
      numRequests: 0,
      page: localStorage.getItem('RequestsInboxPage') ? parseInt(localStorage.getItem('RequestsInboxPage')) : 1,
      sizePerPage: localStorage.getItem('RequestsInboxSizePerPage') ? parseInt(localStorage.getItem('RequestsInboxSizePerPage')) : 5,
      showDelete: false,
      showRequest: false,
      showAcceptRequest: false,
      showRejectRequest: false,
      message: null,
    };

    this.paginaActual = 1;

    this.confirmAcceptRequest = this.confirmAcceptRequest.bind(this);
    this.confirmRejectRequest = this.confirmRejectRequest.bind(this);

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '5%' };
        },
      },
      {
        dataField: 'userMail',
        text: t('invitation.from'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '17%' };
        },
      },
      {
        dataField: 'installationName',
        text: t('invitation.sitesInstallations'),
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'gatewayName',
        text: 'Gateways',
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '14%' };
        },
      },
      {
        dataField: 'requestType',
        text: t('invitation.type'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.TypeFormatter.bind(this),
        headerStyle: () => {
          return { width: '16%' };
        },
      },
      {
        dataField: 'state',
        text: t('invitation.state'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'AcceptRequestFormatter',
        text: ' ',
        align: 'center',
        formatter: this.AcceptRequestFormatter.bind(this),
        headerStyle: () => {
          return { width: '5%' };
        },
      },
      {
        dataField: 'RejectRequestFormatter',
        text: ' ',
        align: 'center',
        formatter: this.RejectRequestFormatter.bind(this),
        headerStyle: () => {
          return { width: '5%' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  TypeFormatter({ row, value }) {
    if (row.requestType) {
      switch (row.requestType.toLowerCase()) {
        case 'installationaccess':
          return <span>Installation access</span>;
        case 'installationtransferownership':
          return <span>Installation Transfer ownership</span>;
        case 'gatewaytransferownership':
          return <span>Gateway transfer ownership</span>;
      }
    } else return <span>Installation access</span>;
  }

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  AcceptRequestFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showAcceptRequest: true, invitationId: evt.currentTarget.id.split('_')[1] });
    };

    let idEle = 'btnAcceptRequest_' + row.id;
    if (row.state == null || row.state == 'Pending') {
      return (
        <CheckCircleOutlineIcon
          id={idEle}
          title='Accept Request'
          style={{ width: '24px', cursor: 'pointer', color: '#2ecc71' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    } else {
      return null;
    }
  }

  RejectRequestFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showRejectRequest: true, invitationId: evt.currentTarget.id.split('_')[1] });
    };

    let idEle = 'btnRejectRequest_' + row.id;
    if (row.state == null || row.state == 'Pending') {
      return (
        <HighlightOffIcon
          id={idEle}
          title='Reject Request'
          style={{ width: '24px', cursor: 'pointer', color: '#e74c3c' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    } else {
      return null;
    }
  }

  async confirmAcceptRequest(t) {
    let data = await this.http.AcceptRequest(this.state.invitationId);
    if (data.errors == null) {
      this.setState({ showAcceptRequest: false });
      this.renderMessage('success', t('invitation.requestAccepted'));
    } else {
      this.setState({ showAcceptRequest: false });
      this.renderMessage('error', t('invitation.errorAcceptingRequest'));
    }
    this.getRequests(this.state.page, this.state.sizePerPage);
  }

  async confirmRejectRequest(t) {
    let data = await this.http.RejectRequest(this.state.invitationId);
    if (data.errors == null){
        this.setState({ showRejectRequest: false });
        this.renderMessage('success', t('invitation.requestRejected'));
    }else{
        this.setState({ showRejectRequest: false });
        this.renderMessage('error', t('invitation.errorRejectingRequest'));
    } 
    this.getRequests(this.state.page, this.state.sizePerPage);
  }

  componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.getRequests(this.state.page, this.state.sizePerPage);
  }

  componentWillUnmount(){
    localStorage.removeItem("RequestsInboxSizePerPage");
    localStorage.removeItem("RequestsInboxPage");
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.getRequests(page, sizePerPage);
  };

  async getRequests(page, sizePerPage) {
    this.setState({ requests: {} });
    let data = null;
    if (this.id != null) data = await this.http.getRequestsReceivedInstallation(this.id, page, sizePerPage);
    else data = await this.http.getRequestsReceived(page, sizePerPage);

    this.setState({ requests: data, numRequests: data.content.length });
  }

  dropdownPermissions() {
    const handleChange = (event) => {
      this.setState({ newRole: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.state.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.state.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='permissionLabel'>Select permission level</InputLabel>
          <Select
            variant='standard'
            name='permission'
            className='titleProperty1'
            labelId='permissionLabel'
            placeholder='Select an option'
            id='ddlRoles'
            value={this.state.role}
            onChange={handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }
  toggleRequests() {
    this.setState({ showRequest: !this.state.showRequest });
  }

  async getRoles() {
    let data = await this.http.getRoles();
    this.setState({ roles: data.content });
  }

  render() {
    //const { SearchBar } = Search;
    const { t } = this.props;
    let dataLoaded = this.state.requests.content != undefined;
    if (dataLoaded) {
    }

    const handleDeleteRequest = () => {
      this.setState({ showDelete: false });
    };
    const handleAcceptRequest = () => {
      this.setState({ showAcceptRequest: false });
    };
    const handleRejectRequest = () => {
      this.setState({ showRejectRequest: false });
    };

    const onSizePerPageChange = (sizePerPage) => {
      localStorage.setItem('RequestsInboxSizePerPage', sizePerPage)
      this.setState({ sizePerPage: sizePerPage });
    }
  
    const onPageChange = (newPage) => {
      localStorage.setItem('RequestsInboxPage', newPage)
      this.paginaActual = newPage;
      this.setState({ page: newPage });
    }

    return (
      <div className='col' style={{ padding: 0, margin: 0 }}>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
        {dataLoaded ? (
          <div className='col chartContainer' style={{ padding: 0, margin: 0 }}>
            <Table
              name={'RequestInbox-locales'}
              t={t}
              resize={true}
              columns={this.columns}
              onSizePerPageChange={onSizePerPageChange}
              onPageChange={onPageChange}
              rows={this.state.requests.content.sort(function (a, b) {
                return new Date(b.id) - new Date(a.id);
              })}
              search={true}
              page={this.state.page}
              pageSize={this.state.sizePerPage}
            />
            <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleDeleteRequest}>
              <DialogTitle>{t('invitation.deleteRequest')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('invitation.confirmDeleteRequest')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary pull-right' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary pull-right' onClick={handleDeleteRequest}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showAcceptRequest} onClose={handleAcceptRequest}>
              <DialogTitle>{t('invitation.acceptRequest')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('invitation.confirmAcceptRequest')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary pull-right' onClick={() => this.confirmAcceptRequest(t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary pull-right' onClick={handleAcceptRequest}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showRejectRequest} onClose={handleRejectRequest}>
              <DialogTitle>{t('invitation.rejectRequest')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('invitation.confirmRejectRequest')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary pull-right' onClick={() => this.confirmRejectRequest(t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary pull-right' onClick={handleRejectRequest}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(RequestsInbox);
