import React, { Component } from 'react';
import '../../App.css';

import AuthenticationContext from "../../Authentication/types/AuthContextType";
import SitesList from "./SitesList";
import SiteReportingModal from './Main/SiteReportingModal';

import { withTranslation } from 'react-i18next';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/material/styles';

function SetClasses() {
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
        },
    }));
    return useStyles();
}

function SetStyles() {
    const classes = SetClasses();
    return classes;
}

function SetTheme() {
    const theme = useTheme();
    return theme;
}


class SitesTabs extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0, tabIndex: 0, _data: []
        }
    }

    handleSiteUpdate() {
        if (typeof this.props.handleUpdate === 'function') {
            this.props.handleUpdate();
        }
    }

    handleChange = (event, newValue) => {

        this.setState({ tabValue: newValue });
    };

    handleChangeIndex = (index) => {

        this.setState({ tabIndex: index });
    };

    handleClick() {
        if (typeof this.props.handleClick === 'function') {
            this.props.handleClick();
        }
    }
    render() {
        const { t } = this.props;

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography component={'span'}>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }

        return (
            <React.Fragment>
                <div>
                    <AppBar position="static" color="default" className={"headerStickyCustom siteheader"}>
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            indicatorColor='primary'
                            variant="fullWidth"
                            className="container"
                        >
                            <Tab label={<h3 className="tabTitle">{t('sites')}</h3>} {...a11yProps(0)} />
                            <Tab label={<h3 className="tabTitle"> {t('productspages.alertreporting.reporting')}</h3>} {...a11yProps(1)} />

                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.tabValue}
                        onChangeIndex={this.handleChangeIndex}
                        className="container mt-0 siteReporting-swipeable" 
                    >

                        <TabPanel value={this.state.tabValue} index={0} dir={SetTheme.direction}>
                            <SitesList notificationAlerts={this.props.notificationAlerts} mySites={this.props.mySites} handleUpdate={this.handleSiteUpdate.bind(this)} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1} dir={SetTheme.direction}>
                            
                            <SiteReportingModal />
                        </TabPanel>

                    </SwipeableViews>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(SitesTabs)