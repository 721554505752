import React, { Component, useEeffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { Dropdown, Popover, Whisper, IconButton, Divider } from 'rsuite';
import LegacyMoreIcon from '@rsuite/icons/legacy/More';
import '../InstallationTree.css';
import InstallationSummaryEdit from '../Main/InstallationsSummaryEdit';
import ProductEdit from '../../ProductsComponents/Main/ProductEdit';
import SiteInstallationGatewayTransferOwnership from '../../../components/SiteInstallationGatewayTransferOwnership';
import Message from '../../Message/Message';

import InstallationsHttpClient from '../../../HttpClient/InstallationsHttpClient';
import HttpClient from '../../../HttpClient/HttpClient';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { withRouter } from '../../withRouter';
import NoData from '../../NoData';

class InstallationsTree extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.data = [];
    this.state = {
      data: [],
      dataLoaded: false,
      expandedRowKeys: this.props.selectedKey,
      showModal: false,
      type: '',
      showModalSite: false,
      estatistics: '',
      userPage: false,
      actualPage: [],
      message: null,
    };
    this.id = parseInt(window.location.pathname.split('/')[2]);
    this.type = window.location.pathname.split('/')[1]; // indicará si estamos en site/installation
    this.route = [];
    this.element = null;
    this.parent = [];
    this.brand = 11;
    this.onExpand = this.onExpand.bind(this);
    this.expandedAllNodes = [];
    this.isExternal = false;
    //this.state.expandedRowKeys = this.props.selectedKey;
    this.handleModalClose = this.handleModalClose.bind(this);
    this.itemsList = [];
    this.expandedRowKeys = this.props.selectedKey;
    this.key = 0;
  }

  componentDidMount() {
    if (this.props.userPage != null && this.props.userPage) {
      this.setState({ userPage: true });
    }
    this.getTree();
    this.setState({ dataLoaded: true });

    this.brand = this.context.brandCode;
    this.setState({ actualPage: window.location.pathname.split('/') });
    this.addStyleToParents('mount');
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteId != prevProps.siteId) {
      this.getTree();
    }
    this.addStyleToParents('update');
  }
  useEeffect() {
    this.addStyleToParents('efffect');
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  renderTooltipDescrip = (text, maxLenght) => (
    <OverlayTrigger placement='top' overlay={<Tooltip id={`${text}-tooltip`}>{text}</Tooltip>}>
      <div title={text} className='truncate-text'>
        {`${text.slice(0, maxLenght)}...`}
      </div>
    </OverlayTrigger>
  );

  findDescriptionProduct = (rowData) => {
    if(this.props.products){
      const product = this.props.products.filter((product) => product.puuid === rowData.puuid);
      return product && product.length ? product[0]?.productType?.description : rowData.puuid;
    } else {
      return rowData.puuid
    }
  };

  renderNameAndDescription = (rowData) => {
    const maxNameLength = 22;
    const maxDescriptionLength = 32;

    let name = rowData.name ? rowData.name : `${rowData.typeName} ${rowData.puuid.slice(-4)}`;
    let description =  rowData.description && rowData.description.length ? rowData.description : this.findDescriptionProduct(rowData);    
    if (name.length > maxNameLength) {
      name = this.renderTooltipDescrip(name, maxNameLength);
    }

    if (description.length > maxDescriptionLength) {
      description = this.renderTooltipDescrip(description, maxDescriptionLength);
    }

    return (
      <>
        <h4 className='col-12 p-0 m-0 productType'>{name}</h4>
        <div className='m-0 TreeDetailProduct'>{description}</div>
      </>
    );
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  addStyleToParents(donde) {
    let tree = document.querySelectorAll('.rs-table-row.typeproducts');

    tree.forEach((treeItem) => {
      let x = treeItem.querySelector('.eventSelector');
      //we take the class with the id
      x.classList.forEach((c) => {
        if (c.startsWith('prdEventId')) {
          let vClass = '.' + c;
          treeItem.addEventListener(
            'mouseenter',
            function (event) {
              let vmap = document.querySelector('.canvaMapCustomSelector');
              if (vmap == null) {
                return null;
              }
              let m = vmap.querySelector(vClass);
              if (m != null) {
                vmap.classList.add('darkModeSelector');
                m.classList.add('outstanding');
              }
            },
            false
          );
          treeItem.addEventListener(
            'mouseleave',
            function (event) {
              let vmap = document.querySelector('.canvaMapCustomSelector');
              if (vmap == null) {
                return null;
              }
              let m = vmap.querySelector(vClass);
              if (m != null) {
                vmap.classList.remove('darkModeSelector');
                m.classList.remove('outstanding');
              }
            },
            false
          );
        }
      });
    });
  }
  searchElement(ele) {
    if (ele.type.startsWith(this.type) && ele.id == parseInt(this.id)) {
      this.element = ele;
      return;
    } else {
      if (this.element === null) {
        this.parent.push(ele.ref);
        ele.children.map((c) => this.searchElement(c));
      }
    }
  }
  restrictAccess(nestedProp, compareKey, compareId, arr) {
    return arr.filter((o) => {
      let keep = o[compareKey] != compareId;
      if (keep == false && o['type'] == 'sites') {
        keep = true;
      }
      if (keep && o[nestedProp]) {
        o[nestedProp] = this.restrictAccess(nestedProp, compareKey, compareId, o[nestedProp]);
      }
      return keep;
    });
  }

  async getTree() {
    try {
      let id = this.props.siteId ? this.props.siteId : 'NaN';
      this.http = new InstallationsHttpClient(this.context, this.id);
      let data = await this.http.InstallationGetTree();

      let res = { content: [] };
      res.content = this.restrictAccess('children', 'hasAccess', false, data.content);

      if (res.content.length > 0) {
        this.searchElement(res.content[0]);
        if (this.element != null) {
          this.route = this.parent.concat(this.element.ref);
        }
        res.content.map((item, i) => this.generarArbolDesplegado(item));
      }

      this.expandedRowKeys = this.route;
      this.setState({ expandedRowKeys: this.route, data: res });
    } catch (err) {}
  }

  onCollapse = (evt, id) => {};

  /**
   * Función para rellenar el array con todo el árbol expandido.
   * @param {any} ele
   */
  generarArbolDesplegado(ele) {
    if (ele.type != 'products') {
      this.expandedAllNodes.push(ele.ref);
      ele.children.map((c) => this.generarArbolDesplegado(c));
    }
  }

  onExpand(expanded, item) {
    let expandedRows = this.state.expandedRowKeys;
    if (expanded) {
      expandedRows.push(item.ref);
    } else {
      expandedRows = expandedRows.filter((_) => _ !== item.ref);
    }
    this.isExternal = false;
    this.expandedRowKeys = expandedRows;

    this.setState({ expandedRowKeys: expandedRows });
  }

  showModal(e, id, mode, type, action) {
    this.setState({ showModal: !this.state.showModal, installationId: id, mode: mode, type: type });
  }

  handleModalClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  async exportEstatistics() {
    this.http = new HttpClient(this.context, this.id);
    let data = await this.http.userGetStatistics(this.id);

    this.setState({ estatistics: data });
    return data;
  }

  routeChange(rowData) {
    let route = 'site';

    if (rowData.type == 'installations') {
      route = 'installation';
    }
    if (rowData.type == 'products') {
      route = 'product';
    }
    if (rowData.type == 'sites') {
      route = 'site';
    }

    this.props.navigate('/' + route + '/' + rowData.id);
  }

  searchNotificationProducts(nameKey) {
    if (this.props.notifications == undefined) {
      return [];
    } else if (this.props.notifications.length <= 0) {
      return [];
    }
    let noti = this.props.notifications[0].alertslist;
    let ret = [];
    for (var i = 0; i < noti.length; i++) {
      if (noti[i].transactionalProduct.id === nameKey) {
        ret.push(noti[i]);
      }
    }
    return this.notificationsSelectPriority(ret);
  }
  notificationListContentSingle(content, header = false, className = '') {
    this.key++;

    return (
      <div key={this.key} className={'cellNotification ' + (header ? 'header ' : '') + className}>
        <span>{content.name}</span>
      </div>
    );
  }
  notificationsSelectPriority(arr) {
    if (arr.length <= 0) return null;
    let type = { rules: { qtt: 0, content: [] }, alarms: { qtt: 0, content: [] }, warnings: { qtt: 0, content: [] } };
    let res = { content: [], defaultClass: '' };

    const { t } = this.props;
    for (var i = 0; i < arr.length; i++) {
      let content = this.notificationListContentSingle(arr[i].individualRule);
      switch (arr[i].individualRule.type) {
        case 'rule':
          type.rules.qtt += 1;
          type.rules.content.push(content);
          break;
        case 'alarm':
          type.alarms.qtt += 1;
          type.alarms.content.push(content);
          break;
        case 'warning':
          type.warnings.qtt += 1;
          type.warnings.content.push(content);
          break;
      }
    }
    const names = {
      rule: t('productspages.alertreporting.rules'),
      alarm: t('productspages.alertreporting.alarms'),
      warning: t('productspages.alertreporting.warnings'),
    };
    const name = {
      rule: t('productspages.alertreporting.rule'),
      alarm: t('productspages.alertreporting.alarm'),
      warning: t('productspages.alertreporting.warning'),
    };
    if (type.alarms.qtt > 0) res.defaultClass = 'alarmNot';
    else if (type.warnings.qtt > 0) res.defaultClass = 'warningNot';
    else if (type.rules.qtt > 0) res.defaultClass = 'ruleNot';

    this.key++;
    if (type.alarms.qtt > 0) {
      type.alarms.content.unshift(
        this.notificationListContentSingle(
          { name: type.alarms.qtt + ' ' + (type.alarms.qtt > 1 ? names['alarm'] : name['alarm']) },
          true,
          'alarm'
        )
      );
      res.content.push(
        <div key={this.key} className='group'>
          {type.alarms.content}
        </div>
      );
    }
    this.key++;
    if (type.warnings.qtt > 0) {
      type.warnings.content.unshift(
        this.notificationListContentSingle(
          { name: type.warnings.qtt + ' ' + (type.warnings.qtt > 1 ? names['warning'] : name['warning']) },
          true,
          'warning'
        )
      );
      res.content.push(
        <div key={this.key} className='group'>
          {type.warnings.content}
        </div>
      );
    }
    this.key++;
    if (type.rules.qtt > 0) {
      type.rules.content.unshift(
        this.notificationListContentSingle(
          { name: type.rules.qtt + ' ' + (type.rules.qtt > 1 ? names['rule'] : name['rule']) },
          true,
          'rule'
        )
      );
      res.content.push(
        <div key={this.key} className='group'>
          {type.rules.content}
        </div>
      );
    }

    return res;
  }

  render() {
    const { t } = this.props;
    const { data } = this.state;

    const ImageCell = ({ rowData, dataKey, ...props }) => {
      let imagen = rowData[dataKey];
      let letter = rowData.letter;
      var level = rowData.type;
      let status = rowData.gateway != null ? rowData.gateway.status : rowData.status;
      if (level == undefined) level = 'sites';

      var route = rowData.type;
      let hiddeArrow = false;
      if (route == undefined) {
        route = 'site';
      }
      if (rowData.type == 'installations') {
        route = 'installation';
      }
      if (rowData.type == 'products') {
        route = 'product';
      }
      if (rowData.type == 'sites') {
        route = 'site';
      }

      if (this.state.actualPage[this.state.actualPage.length - 1] == rowData.id) {
        if (route.includes(this.state.actualPage[this.state.actualPage.length - 2])) {
          hiddeArrow = true;
        }
      }

      //if (rowData.image == null || level == 'installations') {
      if ((level == 'installations' || level == 'sites') && !hiddeArrow) {
        return (
          <Cell {...props} className='link-group span goToArrow'>
            <Link to={'/' + route + '/' + rowData.id}>
              <IconButton
                appearance='subtle'
                icon={<img src={require('../../../assets/icons/external-18w.svg').default} className='pl-0' />}
                size='lg'
              />
            </Link>
          </Cell>
        );
      } else if (level == 'installations' || level == 'sites') {
        return null;
      }

      if (rowData.iconUri != undefined) {
        imagen = rowData.iconUri.replace('black', 'white');
        // imagen = imagen.replace("svg", "png");
        // if (imagen.includes("_00.31_")) {}
      }
      if (letter == undefined) letter = '';

      return (
        <Cell {...props} style={{ padding: 0 }} className={rowData.type}>
          <div
            onClick={() => this.routeChange(rowData)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='cursor-pointer w-100'
          >
            <span className='m-0 t2'>
              {letter}
              {rowData.tag}
            </span>
          </div>
        </Cell>
      );
    };

    const Menu = ({ onSelect, item }) => (
      <Dropdown.Menu onSelect={onSelect}>
        //<Dropdown.Item eventKey={5}>Download As...</Dropdown.Item>
        //<Dropdown.Item eventKey={6}>Export PDF</Dropdown.Item>
        //<Dropdown.Item eventKey={7}>Export HTML</Dropdown.Item>
        //<Dropdown.Item eventKey={8}>Settings</Dropdown.Item>
        //<Dropdown.Item eventKey={9}>About</Dropdown.Item>
      </Dropdown.Menu>
    );
    //{ t('products.general.producttype') }
    const MenuSite = ({ onSelect, item }) => {
      var list = [];
      this.key++;

      if (this.state.userPage && item.hasOwnerAccess) {
        list.push(
          <Dropdown.Item key={this.key} eventKey={6}>
            {t('installationspages.installationTree.transferownership')}
          </Dropdown.Item>
        );
      }
      this.key++;
      if (!this.state.userPage) {
        list.push(
          <Dropdown.Item key={this.key} eventKey={5} disabled={!item.hasOwnerAccess}>
            {t('actions.editname', { name: t('site') })}
          </Dropdown.Item>
        );

        this.key++;

        list.push(
          <Dropdown.Item key={this.key} eventKey={1} disabled={!item.hasOwnerAccess}>
            {t('actions.addname', { name: t('installation') })}
          </Dropdown.Item>
        );
      }
      return <Dropdown.Menu onSelect={onSelect}>{list}</Dropdown.Menu>;
    };
    const MenuInstallation = ({ onSelect, item }) => {
      var list = [];
      this.key++;

      if (this.state.userPage && item.hasOwnerAccess) {
        list.push(
          <Dropdown.Item key={this.key} eventKey={7}>
            {t('installationspages.installationTree.transferownership')}
          </Dropdown.Item>
        );
      }
      this.key++;

      if (!this.state.userPage) {
        list.push(
          <Dropdown.Item key={this.key} eventKey={1} disabled={!item.hasOwnerAccess}>
            {t('actions.addname', { name: t('installation') })}
          </Dropdown.Item>
        );
        this.key++;
        list.push(
          <Dropdown.Item key={this.key} eventKey={2} disabled={!item.hasOwnerAccess}>
            {t('actions.editname', { name: t('installation') })}
          </Dropdown.Item>
        );
      }
      return <Dropdown.Menu onSelect={onSelect}>{list}</Dropdown.Menu>;
    };
    const MenuProduct = ({ onSelect, item }) => {
      var list = [];
      this.key++;

      if (!this.state.userPage && item.hasOwnerAccess) {
        list.push(
          <Dropdown.Item key={this.key} eventKey={4}>
            {t('actions.editname', { name: t('product') })}
          </Dropdown.Item>
        );
      }
      return <Dropdown.Menu onSelect={onSelect}>{list}</Dropdown.Menu>;
    };
    const expandAll = (e) => {
      if (this.expandedAllNodes.length <= 0 && this.state.expandedRowKeys.length > 0) {
        this.expandedAllNodes = this.state.expandedRowKeys;
      }
      this.isExternal = true;
      this.setState({ expandedRowKeys: this.expandedAllNodes });
    };
    const downloadSite = async (...args) => {
      //let data =  this.exportEstatistics();
      document.body.style.cursor = 'wait';
      let data = null;
      try {
        this.http = new HttpClient(this.context, this.id);
        data = await this.http.userGetStatistics(this.id);
      } catch {
        document.body.style.cursor = 'default';
      }

      if (data != null) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'site.csv');
        document.body.appendChild(link);
        link.click();
      }

      document.body.style.cursor = 'default';
    };
    const collapseAll = (e) => {
      if (this.expandedAllNodes.length <= 0 && this.state.expandedRowKeys.length > 0) {
        this.expandedAllNodes = this.state.expandedRowKeys;
      }
      this.isExternal = true;
      this.setState({ expandedRowKeys: [] });
    };
    const MenuPopover = ({ onSelect, ...rest }) => (
      <Popover {...rest} full>
        <Menu onSelect={onSelect} />
      </Popover>
    );
    const MenuPopoverSite = React.forwardRef(({ onSelect, ...rest }, ref) => (
      <Popover ref={ref} {...rest} full>
        <MenuSite onSelect={onSelect} item={rest.data} />
      </Popover>
    ));
    const MenuPopoverInstallation = React.forwardRef(({ onSelect, ...rest }, ref) => (
      <Popover ref={ref} {...rest} full>
        <MenuInstallation onSelect={onSelect} item={rest.data} />
      </Popover>
    ));
    const MenuPopoverProduct = React.forwardRef(({ onSelect, ...rest }, ref) => (
      <Popover ref={ref} {...rest} full>
        <MenuProduct onSelect={onSelect} item={rest.data} />
      </Popover>
    ));

    let tableBody;

    class CustomWhisper extends React.Component {
      constructor(props) {
        super(props);
        this.handleSelectMenu = this.handleSelectMenu.bind(this);
        this.ref = React.createRef();
      }

      handleSelectMenu(eventKey, event) {
        var mode = '';
        switch (eventKey) {
          case 1:
            mode = 'add';
            this.props.onShowModal(eventKey, this.props.id, mode, 'installation');
            break;
          case 2:
            mode = 'edit';
            this.props.onShowModal(eventKey, this.props.id, mode, 'installation');
            break;
          case 3:
            mode = 'add';
            this.props.onShowModal(eventKey, this.props.id, mode, 'site');
            break;
          case 4:
            mode = 'edit';
            this.props.onShowModal(eventKey, this.props.id, mode, 'product');
            break;
          case 5:
            mode = 'edit';
            this.props.onShowModal(eventKey, this.props.id, mode, 'site');
            break;
          case 6:
            mode = 'transfer';
            this.props.onShowModal(eventKey, this.props.id, mode, 'site');
            break;
          case 7:
            mode = 'transfer';
            this.props.onShowModal(eventKey, this.props.id, mode, 'installation');
            break;
        }
      }

      render() {
        let speaker;
        var level = this.props.data.type;
        if (level == undefined) level = 'sites';

        switch (level) {
          case 'sites':
            speaker = (
              <MenuPopoverSite onSelect={(eventKey, event) => this.handleSelectMenu(eventKey, event)} data={this.props.data} />
            );
            break;
          case 'installations':
            speaker = (
              <MenuPopoverInstallation
                onSelect={(eventKey, event) => this.handleSelectMenu(eventKey, event)}
                data={this.props.data}
              />
            );
            break;
          case 'products':
            speaker = (
              <MenuPopoverProduct onSelect={(eventKey, event) => this.handleSelectMenu(eventKey, event)} data={this.props.data} />
            );
            break;
          default:
            speaker = (
              <MenuPopoverSite onSelect={(eventKey, event) => this.handleSelectMenu(eventKey, event)} data={this.props.data} />
            );
            break;
        }

        return (
          <Whisper
            placement='leftStart'
            trigger='click'
            ref={this.ref}
            container={() => {
              return tableBody;
            }}
            speaker={speaker}
          >
            {this.props.children}
          </Whisper>
        );
      }
    }

    const ActionCell = ({ rowData, dataKey, ...props }) => {
      var route = rowData.type;
      let hiddeArrow = false;
      if (route == undefined) {
        route = 'site';
      }
      if (rowData.type == 'installations') {
        route = 'installation';
      }
      if (rowData.type == 'products') {
        route = 'product';
      }
      if (rowData.type == 'sites') {
        route = 'site';
      }

      if (this.state.actualPage[this.state.actualPage.length - 1] == rowData.id) {
        if (route.includes(this.state.actualPage[this.state.actualPage.length - 2])) {
          hiddeArrow = true;
        }
      }

      let cellContent = <Cell></Cell>;
      if (rowData.type == 'products' && rowData.gateway != null) {
        cellContent = (
          <Cell {...props} className='link-group span '>
            <Link to={'/gateway/' + rowData.gateway.id} className='goToGw'>
              <IconButton
                appearance='subtle'
                icon={<img style={{ height: '23px' }} src={require('../../../assets/icons/11_00.31_black.svg').default} />}
                size='lg'
              />
            </Link>
          </Cell>
        );
      } else if (rowData.type == 'products') {
        cellContent = <Cell></Cell>;
      } else if (!hiddeArrow && false) {
        cellContent = (
          <Cell {...props} className='link-group span'>
            <Link to={'/' + route + '/' + rowData.id}>
              <IconButton
                appearance='subtle'
                icon={<img src={require('../../../assets/icons/external-18w.svg').default} className='pl-0' />}
                size='lg'
              />
            </Link>
            <Divider vertical />
            {(!rowData.hasOwnerAccess && rowData.type == 'products') || !rowData.hasAccess ? null : (
              <CustomWhisper data={rowData} id={rowData.id}>
                <IconButton appearance='subtle' icon={<LegacyMoreIcon />} size='lg' />
              </CustomWhisper>
            )}
          </Cell>
        );
      } else {
        cellContent = (
          <Cell {...props} className='link-group span'>
            <Divider className={!hiddeArrow ? '' : 'invisible'} vertical />
            {(!rowData.hasOwnerAccess && rowData.type == 'products') || !rowData.hasAccess ? null : (
              <CustomWhisper
                data={rowData}
                onShowModal={(e, i, mode, type) => this.showModal(e, rowData.id, mode, type)}
                id={rowData.id}
              >
                <IconButton appearance='subtle' icon={<LegacyMoreIcon />} size='lg' />
              </CustomWhisper>
            )}
          </Cell>
        );
      }

      return cellContent;
    };

    const NotificationCell = ({ rowData, dataKey, ...props }) => {
      let cellContent = <Cell></Cell>;
      let objs = this.searchNotificationProducts(rowData.id);
      if (objs == null) {
        return <Cell></Cell>;
      }

      if (rowData.type == 'products' && objs.content != undefined) {
        cellContent = (
          <Cell {...props} className='link-group span '>
            <Whisper
              placement='leftStart'
              trigger='hover'
              enterable
              speaker={
                <Popover className={'custom'} arrow={false}>
                  {' '}
                  <div className='listNotification'>{objs.content}</div>
                </Popover>
              }
            >
              <div className={'notificationCell ' + objs.defaultClass} id={'not-cell-' + rowData.id}></div>
            </Whisper>
          </Cell>
        );
      }

      return cellContent;
    };
    const handleClose = (res) => {
      this.setState({ showModal: false, showModalSite: false });
      if (res && res.id) {
        this.renderMessage('success', t('actions.success'));
        this.getTree();
      }
    };
    const handleCloseSite = (id) => {
      if (id != undefined) {
        this.setState({ showModal: false, showModalSite: true, newsiteid: id.id });
        //this.getTree();
      }
    };

    const redirect = () => {
      this.setState({ showModal: false, showModalSite: false });
      document.getElementById('redirect').click();
    };

    let dataLoaded = data.content != undefined && data.content.length > 0;

    this.expanded = [];
    //alert(this.state.expandedRowKeys[this.state.expandedRowKeys.length-1]);
    let editmodecomponent, titulo;

    if (this.state.showModal) {
      switch (this.state.type) {
        case 'product':
          titulo = t('actions.editname', { name: t('product') });
          editmodecomponent = (
            <ProductEdit
              product={this.state.data}
              id={this.state.installationId}
              onSaveClose={handleClose}
              mode={this.state.mode}
            />
          );
          break;
        case 'installation':
          titulo = t('actions.editname', { name: t('installation') });
          if (this.state.mode == 'add') {
            titulo = t('actions.addname', { name: t('installation') });
          }
          editmodecomponent = (
            <InstallationSummaryEdit
              type='installation'
              createSite={false}
              installation={this.state.data}
              id={this.state.installationId}
              onSaveClose={handleClose}
              mode={this.state.mode}
              showdelete={true}
            />
          );

          if (this.state.mode == 'transfer') {
            titulo = t('installations.installationTree.transferownership');
            editmodecomponent = (
              <SiteInstallationGatewayTransferOwnership
                installation={this.state.data}
                id={this.state.installationId}
                onSaveClose={handleClose}
                mode={this.state.mode}
                type={this.state.type}
              />
            );
          }
          break;
        case 'site':
          titulo = t('actions.editname', { name: t('site') });
          editmodecomponent = (
            <InstallationSummaryEdit
              type='site'
              createSite={false}
              installation={this.state.data}
              id={this.state.installationId}
              onSaveClose={handleClose}
              mode={this.state.mode}
              showdelete={true}
            />
          );

          if (this.state.mode == 'add') {
            titulo = t('actions.addname', { name: t('site') });
            editmodecomponent = (
              <InstallationSummaryEdit
                type='site'
                createSite={true}
                installation={this.state.data}
                id={this.state.installationId}
                onSaveClose={handleCloseSite}
                mode={this.state.mode}
                showdelete={true}
              />
            );
          } else if (this.state.mode == 'transfer') {
            titulo = t('installations.installationTree.transferownership');
            editmodecomponent = (
              <SiteInstallationGatewayTransferOwnership
                installation={this.state.data}
                id={this.state.installationId}
                onSaveClose={handleClose}
                mode={this.state.mode}
                type={this.state.type}
              />
            );
          }
          break;
      }
    }
    const renderTooltip = (text) => (props) =>
      (
        <Tooltip id='button-tooltip' {...props}>
          {text}
        </Tooltip>
      );

    return (
      <React.Fragment>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
        {dataLoaded ? (
          <div className='row mb-5 installationTree' style={{ height: '100%', alignContent: 'flex-start' }}>
            <Dialog disableEscapeKeyDown={false} open={this.state.showModalSite} onClose={handleClose}>
              <DialogTitle>{t('installationspages.installationsummaryedit.sitecreated')}</DialogTitle>
              <DialogContent>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <button color='primary' onClick={() => redirect()}>
                      {t('actions.yes')}
                    </button>
                    <button color='primary' onClick={handleClose}>
                      {t('actions.no')}
                    </button>
                    <Link id='redirect' to={'/site/' + this.state.newsiteid}></Link>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              classes={{ paper: this.state.type == 'product' ? '' : 'installationModal' }}
              disableEscapeKeyDown={false}
              open={this.state.showModal}
              onClose={handleClose}
            >
              <DialogTitle>{titulo}</DialogTitle>
              <DialogContent>{editmodecomponent}</DialogContent>
            </Dialog>
            <div className='col-12 ' style={{ height: 'fit-xontent' }}>
              <div className='row mb-3'>
                <div className='col-9'>
                  <h3 style={{ textTransform: 'uppercase' }}>{t('installationspages.installationTree.installationExplorer')}</h3>
                </div>
                <div className='col-3' style={{ alignSelf: 'center' }}>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(t('installationspages.installationTree.exportDeviceData'))}
                  >
                    <button
                      className={'tree float-right ' + (this.type == 'site' || this.type == '' ? '' : 'd-none')}
                      onClick={downloadSite}
                    >
                      <img
                        width='20'
                        className='darkOnHover'
                        src={'https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/' + this.brand + '_download.svg'}
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(t('installationspages.installationTree.expandTree'))}
                  >
                    <button className='tree float-right' onClick={expandAll}>
                      <img
                        width='20'
                        className='darkOnHover'
                        src={'https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/' + this.brand + '_maximize.svg'}
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(t('installationspages.installationTree.collapseTree'))}
                  >
                    <button className='tree float-right' onClick={collapseAll}>
                      <img
                        width='20'
                        className='darkOnHover'
                        src={'https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/' + this.brand + '_minimize.svg'}
                      />
                    </button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-12 scrollTree' style={{ minHeight: '155px', overflowY: 'overlay' }}>
              <Table
                isTree
                rowKey='ref'
                defaultExpandedRowKeys={this.state.expandedRowKeys}
                onExpandChange={this.onExpand}
                {...(this.isExternal ? { expandedRowKeys: this.state.expandedRowKeys } : {})}
                ref={tableBody}
                data={data.content}
                renderTreeToggle={(icon, rowData) => {
                  if (rowData == undefined || (rowData.children && rowData.children.length) === 0) {
                    return null;
                  }
                  return icon;
                }}
                rowHeight={55}
                autoHeight
                rowClassName={(rowData) => {
                  let r = '';
                  if (rowData != undefined) {
                    let level = rowData.type == undefined ? 'sites' : rowData.type;
                    r += ' type' + level;
                    if (this.state.actualPage[this.state.actualPage.length - 1] == rowData.id) {
                      if (level.includes(this.state.actualPage[this.state.actualPage.length - 2])) {
                        r += ' actualLevel';
                      }
                    }
                  }
                  return r;
                }}
                onRowClick={(rowData, e) => {
                  this.isExternal = false;
                }}
              >
                <Column flexGrow={6}>
                  <HeaderCell></HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      var level = rowData.type;
                      if (level == undefined) level = 'sites';
                      if (rowData.type == 'products') {
                        let status = rowData.gateway != null ? rowData.gateway.status : rowData.status;
                        return (
                          <div
                            onClick={() => this.routeChange(rowData)}
                            className={'p-0 pl-4 m-0 cursor-pointer treeH4Parent' + ' eventSelector prdEventId-' + rowData.id}
                            style={{ verticalAlign: 'middle', textTransform: 'none', position: 'inherit' }}
                          >
                            <div className='treeImage' style={{ backgroundImage: "url('" + rowData.image + "')" }}>
                              <div className={'dotState ' + status.toLowerCase()}></div>
                            </div>

                            <div className='row ml-1'>
                              {this.renderNameAndDescription(rowData)}
                              {/* <h4 className='col-12 p-0 m-0 productType'>
                                {rowData.typeName == null ? rowData.designLines : rowData.typeName}
                              </h4>
                              <div className='m-0 TreeDetailProduct'>{rowData.puuid}</div> */}
                            </div>
                          </div>
                        );
                      } else if (rowData.type == 'installations') {
                        return (
                          <h4
                            onClick={() => this.routeChange(rowData)}
                            className='p-0 m-0 cursor-pointer'
                            style={{ display: 'table-cell', verticalAlign: 'middle', textTransform: 'none' }}
                          >
                            {rowData.hasOwnerAccess ? (
                              <OverlayTrigger
                                placement='bottom'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(t('installationspages.installationTree.owner'))}
                              >
                                <img
                                  src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/key.svg'
                                  style={{ height: '20px', marginRight: '10px' }}
                                />
                              </OverlayTrigger>
                            ) : (
                              ''
                            )}{' '}
                            {rowData.name}
                          </h4>
                        );
                      } else {
                        return (
                          <h4
                            onClick={() => this.routeChange(rowData)}
                            className='p-0 m-0 cursor-pointer'
                            style={{ display: 'table-cell', verticalAlign: 'middle', textTransform: 'none' }}
                          >
                            {rowData.hasOwnerAccess ? (
                              <OverlayTrigger
                                placement='bottom'
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(t('installationspages.installationTree.owner'))}
                              >
                                <img
                                  src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/key.svg'
                                  style={{ height: '20px', marginRight: '10px' }}
                                />
                              </OverlayTrigger>
                            ) : (
                              ''
                            )}{' '}
                            {rowData.name}
                          </h4>
                        );
                      }
                    }}
                  </Cell>
                </Column>
                <Column flexGrow={2} minWidth={80}>
                  <HeaderCell></HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      var level = rowData.type;
                      if (level == undefined) level = 'sites';
                      var display = 'none';
                      if (rowData.errorCount != null && rowData.errorCount > 0) {
                        if (rowData.type != 'products') {
                          display = '';
                        }
                      }
                      if (rowData.type != 'products') {
                        return <div></div>;

                        let d1 = rowData.counters.Disconnected ?? 0;
                        let d2 = rowData.counters.Offline ?? 0;

                        let offline = d1 + d2;

                        let displayApp = rowData.counters.App && rowData.counters.App > 0 ? '' : 'none';
                        let displayOffline = offline > 0 ? '' : 'none';
                        let displayOnline = rowData.counters.Online && rowData.counters.Online > 0 ? '' : 'none';

                        return (
                          <div
                            className='row cursor-pointer customNotificationDots'
                            onClick={() => this.routeChange(rowData)}
                            style={{}}
                          >
                            <div className='squareTreeIcon squareTreeActivations' style={{ display: displayOnline }}>
                              {rowData.counters.Online ?? 0}
                            </div>
                            <div className='squareTreeIcon squareTreeDesactivations' style={{ display: displayOffline }}>
                              {offline ?? 0}
                            </div>
                            <div className='squareTreeIcon squareTreeApp' style={{ display: displayApp }}>
                              {rowData.counters.App ?? 0}
                            </div>
                            <div className='squareTreeIcon squareTreeError' style={{ display: display }}>
                              {rowData.errorCount ?? 0}
                            </div>
                          </div>
                        );
                      } else {
                        let lastData = t('nodata');
                        let titleLastData = t('installationspages.installationTree.lastdata');
                        if (rowData.status.toLowerCase() == 'online') {
                          if (rowData.lastProcessedValue != null) {
                            lastData = moment(rowData.lastProcessedValue).format('D/MM/YYYY HH:mm');
                          }
                        } else {
                          titleLastData = t('productspages.general.lastTimeSeen');
                          if (rowData.lastTimeSeen != null) {
                            lastData = moment(rowData.lastTimeSeen).format('D/MM/YYYY HH:mm');
                          }
                        }

                        return (
                          <div onClick={() => this.routeChange(rowData)} className='cursor-pointer'>
                            <div className='row d-none'>
                              <div className='TreeDetailProductBold '>{t('installationspages.installationTree.productsku')}:</div>
                              <div className='TreeDetailProduct'>{rowData.puuid}</div>
                            </div>
                            <div className='' style={{ whiteSpace: 'normal', width: '100%' }}>
                              <div className='TreeDetailProductBold d-none'>{titleLastData}:</div>
                              <div className='TreeDetailProduct'>{lastData}</div>
                            </div>
                          </div>
                        );
                      }
                    }}
                  </Cell>
                </Column>

                <Column width={50}>
                  <HeaderCell></HeaderCell>
                  <ImageCell dataKey='image' />
                </Column>
                <Column width={50}>
                  <HeaderCell></HeaderCell>
                  <ActionCell dataKey='id' />
                </Column>
                <Column width={0}>
                  <HeaderCell></HeaderCell>
                  <NotificationCell dataKey='id' />
                </Column>
              </Table>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {data.content?.length <= 0 ? (
              <div>
                <NoData />
              </div>
            ) : (
              <div className='loadingParent'>
                <div className='loadingImg'></div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(InstallationsTree));
