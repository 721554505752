import Style from '../styles/EmptyBarChartView.module.scss';
import { EmptyBarchartProps } from '../types/ChartsComponents';
import ReactECharts from 'echarts-for-react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptyBarChartView = (props: EmptyBarchartProps) => {
  const option = {
    xAxis: {
      type: 'category',
      data: ['0', '0', '0', '0', '0', '0', '0'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        type: 'bar',
      },
    ],
  };

  return (
    <div className={Style.EmptyBarChartView}>
      <div className='container-EmptyBarChartView'>
        <div className='box-error'>
          {props.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
          {props.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
          <p className='error-text'>{props.message}</p>
        </div>
        <div className='box-chart'>
          <ReactECharts style={{ height: '500px', width: '100%', padding: '10px' }} option={option} />
        </div>
      </div>
    </div>
  );
};

export default EmptyBarChartView;