import { ChangeEvent, PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import HttpClient from '../../../../../HttpClient/HttpClient';
import Loader from '../../../../Common/Loader';
import UserProfileEditView from '../views/UserProfileEditView';
import { t } from 'i18next';
import { UserProfileEditContainerPropsType, UserProfileEditContainerStateType } from '../types/UserProfileTypes';
import { Role } from '../types/Role';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';

export class UserProfileEditContainer extends PureComponent<
  UserProfileEditContainerPropsType,
  UserProfileEditContainerStateType
> {
  private httpUser: HttpClient | null = null;
  static contextType = AuthenticationContext;

  constructor(props: UserProfileEditContainerPropsType) {
    super(props);
    this.state = {
      loading: false,
      user: this.props.user,
      changeThumbnailPhoto: false,
      fileUserThumbnailPhoto: null,
      userThumbnailPhoto: null,
      showModalUnsubscribe: false,
      imgUser: this.props.user.imgUser ? this.props.user.imgUser : '',
    };
  }

  closeModal = () => {
    this.setState({ user: this.props.user });
    this.props.setToggleEditModal();
  };

  cleanUser = () => {
    this.setState({ user: this.props.user });
  };

  handleUnsubscribe = (value: boolean) => {
    this.setState({ showModalUnsubscribe: value });
  };

  userFieldChange = (nameField: string, value: string | number) => {
    this.setState({ user: { ...this.state.user, [nameField]: value } });
  };

  localeAvailable = () => {
    const language = {
      '11': ['EN', 'ES', 'DE', 'FR', 'IT'], // Laufen
      '14': ['EN', 'ES', 'DE', 'FR', 'IT'], // Roca
    };
    const userLocale = String((this.context as AuthContextType).brandCode);
    return language[userLocale];
  };

  saveData = async () => {
    this.setState({ loading: true });
    this.httpUser = new HttpClient(this.context);
    try {
      if (
        this.props.user.locale.toLocaleUpperCase() !== this.state.user.locale.toLocaleUpperCase() &&
        (this.props.personalAccount || this.props.profileType === 'personal')
      ) {
        const patch = {
          op: 'replace',
          path: '/locale',
          value: this.state.user.locale.toLocaleLowerCase(),
        };
        await this.httpUser.userPatchMe(patch);
        // refresh ctx with new locale
        await (this.context as AuthContextType).setNewLanguage(this.state.user.locale.toLocaleUpperCase());
      }
      let { imgUser, id, locale, userInstallations, termsOfUseConsentDateTime, termsOfUseConsentChoice, ...newDataUser } =
        this.state.user;
      // se esta tipando como any porque este endpoint necesita los roles de la siguiente manera -> [100, 150, 156...]
      newDataUser.userRoles = (newDataUser.userRoles as unknown as Role[]).map((role) => Number(role.code)) as any;
      let data = await this.httpUser.userPostGraphApi(newDataUser);
      if (data.status > 200) {
        this.setState({ loading: false });
        this.props.renderMessage('error', 'Error updating user profile');
      } else {
        if (this.state.changeThumbnailPhoto) {
          this.uploadFile();
        }
        // clean user data
        await (this.context as AuthContextType).refreshUserData();
        //** */
        if (data.errors) {
          this.props.renderMessage('error', 'Error updating user profile');
        } else {
          this.props.renderMessage('success', 'User profile updated');
        }
        this.props.setToggleEditModal();
      }
    } catch (error) {
      this.props.renderMessage('error', t('installationspages.installationsummaryedit.errormessage'));
    }
  };

  uploadFile = async () => {
    if (this.state.fileUserThumbnailPhoto) {
      const formulario = new FormData();
      formulario.append('file', this.state.fileUserThumbnailPhoto);
      this.httpUser = new HttpClient(this.context);

      try {
        const response = await this.httpUser.userPostMeGraphApiThumbnailPhoto(formulario);

        if (response.errors) {
          this.props.renderMessage('error', 'Error updating user profile');
        } else {
          this.props.renderMessage('success', 'User profile updated');
        }

        this.setState({ loading: false });
      } catch (error) {
        // Handle the error
        this.props.renderMessage('error', 'An error occurred while uploading the file');
        this.setState({ loading: false });
      }
    } else {
      this.props.renderMessage('error', 'No file selected');
    }
  };

  handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader = new FileReader();

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      let fileSizeInKB = file.size / 1024; // Tamaño en kilobytes

      if (fileSizeInKB > 90) {
        // const fileSizeLimitKB = 90; // Límite de tamaño
        // this.renderMessage('error', t('messages.errorSizeFile', { size: `${fileSizeLimitKB.toString()}kb` }));
      } else {
        reader.onloadend = () => {
          if (reader.result) {
            this.setState({
              fileUserThumbnailPhoto: file,
              userThumbnailPhoto: reader.result,
              changeThumbnailPhoto: true,
              user: { ...this.state.user, imgUser: reader.result as string },
            });
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  btnImageChange = () => {
    document.getElementById('inputUserThumbnailPhoto')!.click();
  };

  handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.required) {
      event.target.setCustomValidity('');
      if (!event.target.validity.valid) {
        event.target.setCustomValidity(t('general.error_required'));
      }
    }
    this.userFieldChange(event.target.name, event.target.value);
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <UserProfileEditView
            user={this.state.user}
            userFieldChange={this.userFieldChange}
            saveData={this.saveData}
            closeModal={this.closeModal}
            handleImageChange={this.handleImageChange}
            handleUnsubscribe={this.handleUnsubscribe}
            btnImageChange={this.btnImageChange}
            handleChange={this.handleChange}
            showModalUnsubscribe={this.state.showModalUnsubscribe}
            profileType={this.props.profileType}
            personalAccount={this.props.personalAccount}
            localeAvailable={this.localeAvailable()}
          />
        )}
      </>
    );
  }
}

export default UserProfileEditContainer;
