import { t } from 'i18next';
import Style from '../styles/ProductsSummaryView.module.scss';
import { ProductsSummaryViewProps } from '../types/ProductsSummary';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import CheckIcon from '@mui/icons-material/Check';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ProductsSummaryView = (props: ProductsSummaryViewProps) => {
  const renderNotifications = () => {
    const types = ['alarm', 'warning', 'rule'];
    const names = {
      rule: t('productspages.alertreporting.rules'),
      alarm: t('productspages.alertreporting.alarms'),
      warning: t('productspages.alertreporting.warnings'),
    };
    const name = {
      rule: t('productspages.alertreporting.rule'),
      alarm: t('productspages.alertreporting.alarm'),
      warning: t('productspages.alertreporting.warning'),
    };
    if (props.notificationAlerts == undefined || props.notificationAlerts == null) {
      return null;
    } else if (props.notificationAlerts.length <= 0) {
      return null;
    }
    let notifications = props.notificationAlerts[0].alerts;

    let arr: JSX.Element[] = [];
    let idNotification = 0;
    types.forEach((element) => {
      let obj = {};
      let data = notifications.find((o) => o.type === element);
      if (data == null) {
        return;
      }
      let total = data.total != null ? data.total : 0;

      if (total <= 0) {
        return;
      }

      arr.push(
        <div key={`idNotif${idNotification}`} className='col-auto pl-0 pr-1'>
          <span className={`custom-button-span ${element}`}>{total + ' ' + (total > 1 ? names[element] : name[element])}</span>
        </div>
      );
      idNotification++;
    });

    let ret = (
      <div className='row mb-1'>
        <div className='col-12 '>
          <div className='row m-0 listNotificationsItems'>{arr}</div>
        </div>
      </div>
    );

    return ret;
  };

  const DrawTechDocs = (texto, url, type, nombre, brand) => {
    if (!url) {
      return null;
    }
    switch (type) {
      case 1:
        return (
          <div className='row'>
            <div className='col-12'>
              <img
                className='DrawTechDocs-img'
                src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg'
              />
              <span onClick={() => () => props.handleDialog(url)} className='atributDesc animateHover'>
                {texto}
              </span>
            </div>
          </div>
        );
      case 2:
        if (props.product.content && props.product.content.technicalDrawings) {
          for (var i = 0; i <= props.product.content.technicalDrawings.length - 1; i++) {
            if ((nombre = props.product.content.technicalDrawings[i].name)) {
              if (brand == 'Roca') {
                return (
                  <div className='row'>
                    <div className='col-12'>
                      <img
                        className='DrawTechDocs-img'
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg'
                      />
                      <span
                        className='atributDesc animateHover'
                        onClick={() =>
                          props.handleDialog(
                            `https://www.roca.es/RocaResourceServlet/resources/getTransformation?idObject=${url}&trName=TF_Mob_tecn`
                          )
                        }
                      >
                        {texto}
                      </span>
                    </div>
                  </div>
                );
              } else if ((brand = 'Laufen')) {
                return (
                  <div className='row'>
                    <div className='col-12'>
                      <img
                        className='DrawTechDocs-img'
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg'
                      />
                      <span
                        className='atributDesc animateHover'
                        onClick={() =>
                          props.handleDialog(
                            `https://www.laufen.com/LaufenResourceServlet/resources/getTransformation?idObject=${url}&trName=TF_TechDrawing_High`
                          )
                        }
                      >
                        {texto}
                      </span>
                    </div>
                  </div>
                );
              }
              break;
            }
          }
        } else {
          return null;
        }

        break;
    }
  };

  const renderPin = () => {
    if (props.product?.content?.installation?.hasOwnerAccess) {
      if (String(props.product.content.productType.brandCode) === '11' && props.product.content.bluetoothPassword) {
        return (
          <div className='pin-box'>
            <h6 className={`title-pin mb-1 ${props.product.content.bluetoothPassword ? '' : 'd-none'}`}>
              PIN:
              <span className='text-pin t3'>
                {props.showPassword
                  ? props.product.content.bluetoothPassword
                  : props.product.content.bluetoothPassword.replace(/./g, '•')}
              </span>
              <div className='inside-pin-box' onClick={() => props.handleClickShowPassword()}>
                {props.showPassword ? <Visibility sx={{ fontSize: '1.2rem' }} /> : <VisibilityOff sx={{ fontSize: '1.2rem' }} />}
              </div>
            </h6>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={Style.ProductsSummaryView}>
      <div className='productsSummaryView-content'>
        <div>
          <Dialog
            classes={{ paper: 'ruleModal' }}
            disableEscapeKeyDown
            open={props.dialogOpen}
            onClose={() => props.handleDialog()}
          >
            <DialogContent>
              <img className='imgDialog' src={props.urlDialog} />
            </DialogContent>
            <DialogActions>
              <Button className='primary pull-right' onClick={() => props.handleDialog()}>
                {t('actions.close')}
              </Button>
            </DialogActions>
          </Dialog>

          <div className='row prodInfo'>
            <div className='col-12'>
              <div className='row prodInfo inbox'>
                <div className='col-12 col-md-3'>
                  <img className='prodInfo-img' src={props.product.content.productType.imageUri} />
                  <div className='prodState'>
                    <div className={props.product.content.connectionState}></div>
                    <div className='stateDiv'>
                      <div>
                        <img className={`state-${props.product.content.connectionState}`} />
                        <img src={props.imagen} />
                        <span>
                          {props.product.content.productType.letter}
                          {props.product.content.tag}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-9'>
                  <div className='row mb-1'>
                    <h2 className='mb-0 col'>{props.prodName}</h2>
                    {props.gwId ? (
                      <div className='col-auto'>
                        <Link to={`/gateway/${props.gwId}`} className='goToGw'>
                          <img className='goToGw-img' src={require('../../../assets/icons/11_00.31_black.svg').default} />
                        </Link>
                      </div>
                    ) : null}

                    {props.product.content.installation?.hasOwnerAccess ? (
                      <div className='edit-parent col-auto'>
                        <Button className='primary w-sm-100' onClick={() => props.handleEditProduct()}>
                          <img src={require('../../../assets/icons/edit-18.svg').default} />
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='row summaryInfo'>
                    <div className={`dimensions ${props.product.content.dimensions ? 'col-12 col-md-4' : 'col-12 col-md-5'}`}>
                      {renderNotifications()}
                      <h4 className='h4Dimensions d-none'>{props.prodDetail}</h4>
                      <h6 className='mb-1'>
                        {t('forms.name')}: <span className='t3'>{props.product.content.name}</span>{' '}
                      </h6>
                      {props.prodRef ? (
                        <h6 className='mb-1'>
                          REF: <span className='t3'>{props.prodRef}</span>
                        </h6>
                      ) : (
                        <div></div>
                      )}
                      <h6 className='mb-1'>
                        {t('installationspages.installationsummaryedit.family')}:
                        <span className='t3'>{t(`letters${props.product.content.productType.letter}`)}</span>
                      </h6>
                      {props.product.content.description ? (
                        <h6 className='mb-1'>
                          {t('forms.description')}:{' '}
                          <span className='t3'>{props.product.content.description.replace(/\u00A0/g, ' ')}</span>{' '}
                        </h6>
                      ) : null}
                    </div>
                    <div
                      className={`dimensions-installation ${
                        props.product.content.dimensions ? 'col-12 col-md-4' : 'col-12 col-md-7'
                      }`}
                    >
                      <h6 className='mb-1'>
                        {t('installation')}: <span className='t3'>{props.installationName}</span>
                      </h6>
                      <h6 className='mb-1'>
                        PUUID: <span className='t3'>{props.product.content.puuid}</span>
                      </h6>
                      <h6 className='mb-1 d-none'>
                        {t('productspages.summary.warranty')}: <span className='t3'>{props.prodWarranty}</span>
                      </h6>

                      <h6 className='mb-1 d-none'>
                        TagId: <span className='t3'>{props.product.content.tag}</span>
                      </h6>
                      {props.product.content.connectionState.toLowerCase() == 'online' ? (
                        <h6 className='mb-1'>
                          {t('productspages.general.lastestdatareceived')}:{' '}
                          <Moment className='t3' format='D MMM YYYY HH:mm' withTitle>
                            {props.product.content.lastProcessedValue}
                          </Moment>
                        </h6>
                      ) : (
                        <h6 className='mb-1'>
                          {t('productspages.general.lastTimeSeen')}:{' '}
                          <Moment className='t3' format='D MMM YYYY HH:mm' withTitle>
                            {props.product.content.lastTimeSeen}
                          </Moment>
                        </h6>
                      )}

                      <h6 className='dimensions-tagId mb-1'>
                        {t('productspages.summary.telemetryenabled')}
                        <CheckIcon style={{ color: props.enabled ? 'green' : 'red', height: '18px', marginTop: '-4px' }} />
                      </h6>

                      {renderPin()}

                      <h6 className='mb-1 d-none'>
                        {t('productspages.summary.registerstatus')}: <span className='t3'>{props.product.content.tag}</span>
                      </h6>

                      {/* {props.alert == 'error' ? (
                        <div className='alertTxt'>
                          <span className='row atributDesc'>
                            <a className='titleProperty'>{t('productspages.general.alert')}:</a>{' '}
                            {t('productspages.general.alertDesc')}
                          </span>
                          <span className='row atributDesc'>
                            <a className='titleProperty'>{t('productspages.general.time')}:</a>{' '}
                            {t('productspages.general.alertTime')}
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )} */}
                    </div>
                    <div
                      className={`dimensions-box ${props.product.content.dimensions ? 'col-12 col-md-4' : 'd-none'} mt-3 mt-md-0`}
                    >
                      {props.product.content.dimensions ? (
                        <div>
                          <h6 className='mb-2'>
                            {t('productspages.summary.dimensions')}: {props.product.content.dimensions}
                          </h6>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='t2'>
                                ({t('productspages.summary.long')},{t('productspages.summary.wide')},
                                {t('productspages.summary.height')})
                              </div>
                            </div>

                            <div className='col-12 '>
                              <div className='t3'>
                                {DrawTechDocs(
                                  t('productspages.summary.productsheet'),
                                  null,
                                  1,
                                  null,
                                  props.product.content.productType.brand
                                )}
                                {DrawTechDocs(
                                  t('productspages.summary.productmeasures'),
                                  props.measuresCode,
                                  2,
                                  t('productspages.summary.productmeasureskey'),
                                  props.product.content.productType.brand
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSummaryView;
