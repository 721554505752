import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { t } from 'i18next';
import UserPersonalProfileContainer from '../Tabs/UserProfile/container/UserPersonalProfileContainer';
import UserManageProfileContainer from '../Tabs/UserProfile/container/UserManageProfileContainer';
import Style from '../styles/UserView.module.scss';
import UserPreferencesContainer from '../Tabs/UserPreferences/container/UserPreferencesContainer';
import UserInvitationsContainer from '../Tabs/UserInvitations/container/UserInvitationsContainer';
import UserRequestsContainer from '../Tabs/UserRequests/container/UserRequestsContainer';
import { UserTabsTypes } from '../types/UserTabsTypes';
import { useNavigate } from 'react-router-dom';

const UserTabsView = (props: UserTabsTypes) => {
  const navigate = useNavigate();
  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <section className={Style.userView}>
      <div className='container container-userView'>
        {/* <p className={`text ${props.profileType}`}>{props.profileType} {t('userpages.profile')}</p> */}
        <div>
          <AppBar position='static' color='default' className={'headerStickyCustom userpage'}>
            <Tabs
              value={props.tabValue}
              onChange={(_event, newValue) => props.changeTab(newValue)}
              indicatorColor='primary'
              variant='fullWidth'
              className='container'
            >
              <Tab label={<h3 className='tabTitle'>{t('productspages.user.tab.profile')}</h3>} {...a11yProps(0)} />
              <Tab
                disabled={props.profileType === 'manage' && !props.personalAccount}
                label={<h3 className='tabTitle'>{t('productspages.user.tab.preferences')} </h3>}
                {...a11yProps(1)}
              />
              <Tab
                disabled={props.profileType === 'manage' && !props.personalAccount}
                label={
                  <h3 className='tabTitle'>
                    {t('productspages.user.tab.invitations')}{' '}
                    {props.numInvitationsReceived > 0 && <span className='box-notification'>{props.numInvitationsReceived}</span>}
                  </h3>
                }
                {...a11yProps(2)}
              />
              <Tab
                disabled={props.profileType === 'manage' && !props.personalAccount}
                label={
                  <h3 className='tabTitle'>
                    {t('productspages.user.tab.requests')}{' '}
                    {props.numRequestsReceived > 0 && <span className='box-notification'>{props.numRequestsReceived}</span>}
                  </h3>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          {/* {props.profileType === 'manage' && ( */}
          <div className='go-back'>
            <button className='btn btn-primary primary' onClick={() => navigate(-1)}>
              <i className='fas fa-arrow-left'></i>
              {t('actions.back')}
            </button>
          </div>
          {/* )} */}
          <SwipeableViews axis={'x'} index={props.tabValue} className='container userTabsAux'>
            <TabPanel value={props.tabValue} index={0}>
              {props.profileType === 'personal' && (
                <UserPersonalProfileContainer profileType={props.profileType} personalAccount={true} />
              )}
              {props.profileType === 'manage' && (
                <UserManageProfileContainer profileType={props.profileType} personalAccount={props.personalAccount} />
              )}
            </TabPanel>
            <TabPanel value={props.tabValue} index={1}>
              <UserPreferencesContainer />
            </TabPanel>
            <TabPanel value={props.tabValue} index={2}>
              <UserInvitationsContainer />
            </TabPanel>
            <TabPanel value={props.tabValue} index={3}>
              <UserRequestsContainer />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </section>
  );
};

const TabPanel = (props: { index: number; value: number; children: ReactNode }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default UserTabsView;