import axios from 'axios';
export default class WorkOrdersHttpClient {
  constructor(context, language, level, id) {
    this.context = context;
    this.debug = false;
    this.level = level;
    this.id = id;
    this.language = language;
  }

  getAllProductionCenters = async () => {
    var accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get('workorders/all-production-centers', config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  getProductionCentersById = async (id) => {
    var accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.get(`workorders/production-centers/${id}`, config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  setFactoryToUser = async (objUpdate) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.post(`workorders/set-factory-user`, objUpdate, config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };

  removeFactoryUser = async (objUpdate) => {
    let accessToken = await this.context.getToken();
    let config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    var response = await axios.post(`workorders/remove-factory-user`, objUpdate, config);
    if (this.debug) {
      console.log('response: ' + JSON.stringify(response));
    }
    return response.data;
  };
}