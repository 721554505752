import { Link } from 'react-router-dom';
import Style from '../styles/LandingView.module.scss';
import { Suspense } from 'react';
import NavFooter from '../../../components/NavFooter';
import { LandingStateType } from '../types/LandingTypes';

const LandingView = (props: LandingStateType) => {
  return (
      <div className={Style.LandingView}>
        <div className={`landing-content ${props.brandUser}`}>
          <header className='headerFixed'>
            <nav className='headerSpace pt-2 navbar navbar-light'>
              <Link to='/'>
                <img src={props.logoUrl} alt={props.brandUser} />
              </Link>
              <button className='loginBtn' name='login' id='login' onClick={() => props.redirectLogin!()}>
                Login
              </button>
            </nav>
          </header>
          <main className='main' />
          <Suspense fallback='loading'>
            <NavFooter isLogged={false} brandCode={props.brandCode} />
          </Suspense>
        </div>
      </div>
  );
};

export default LandingView;