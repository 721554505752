// TODO pending refactoring to tsx

import { useTranslation } from 'react-i18next';
import Requests from '../../../../Requests/RequestsList';

const UserRequests = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='row ml-1'>
        <h2>{t('productspages.user.tab.requests')}</h2>
      </div>

      <div className='col-12 card-container mb-5 pb-3'>
        <Requests userPage={true} />
      </div>
    </div>
  );
};

export default UserRequests;
