import { PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';
import UserProfileView from '../views/UserProfileView';
import Loader from '../../../../Common/Loader';
import { MessageType } from '@ComponentsRoot/Management/types/MessageType';
import { User } from '@ComponentsRoot/Management/types/User';
import { withRouter } from '../../../../withRouter';
import { ProfileType } from '../types/UserProfileTypes';
import { manageInfoUser } from '../../../../Common/utils/helpers';
export class UserPersonalProfileContainer extends PureComponent<
  { profileType: ProfileType; personalAccount: boolean },
  { loading: boolean; user: User | null; message: MessageType | null; editModalStatus: boolean }
> {
  static contextType = AuthenticationContext;

  constructor(props: { profileType: ProfileType; personalAccount: boolean }) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      message: null,
      editModalStatus: false,
    };
  }

  componentDidMount = async () => {
    await this.getUserInfo();
  };

  componentDidUpdate = () => {
    // refresh user data with actual locale
    if (this.state.user?.locale !== (this.context as AuthContextType).userPreferences!.language) {
      this.setState({ user: { ...this.state.user!, locale: (this.context as AuthContextType).userPreferences!.language } });
    }
  };

  renderMessage = (type: string, content: string) => {
    this.setState({ message: { type: type, content: content } });
  };

  getUserInfo = async () => {
    // Optional loading
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    //
    const userDataContext = this.context as AuthContextType;
    const userB2CData = await manageInfoUser(userDataContext, 'userB2CData');
    const userThumbnailPhoto = await manageInfoUser(userDataContext, 'userThumbnailData');
    const user = { ...userB2CData, imgUser: userThumbnailPhoto, locale: userDataContext.userPreferences!.language };
    this.setState({
      user,
      loading: false,
      message: null,
      editModalStatus: false,
    });
  };

  closeMessage = async () => {
    await this.getUserInfo();
    // this.setState({ message: null, editModalStatus: false });
  };

  setToggleEditModal = async () => {
    this.setState({ editModalStatus: !this.state.editModalStatus });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <UserProfileView
            user={this.state.user as User}
            profileType={this.props.profileType}
            message={this.state.message}
            editModalStatus={this.state.editModalStatus}
            closeMessage={this.closeMessage}
            setToggleEditModal={this.setToggleEditModal}
            renderMessage={this.renderMessage}
            personalAccount={this.props.personalAccount}
          />
        )}
      </>
    );
  }
}

export default withRouter(UserPersonalProfileContainer);