import React, { Component } from 'react';
import '../../App.css';
import SitesHttpClient from "../../HttpClient/SitesHttpClient";
import AlertHttpClient from "../../HttpClient/AlertHttpClient";

import SitesTabs from "../SitesComponents/SitesTabs";

import AuthenticationContext from "../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';

export class Sites extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        
        this.state = {
            notificationAlerts: null,
            //groupBySelected: 'week',
            //dateRange: null
        };

        this.dateRange = this.getCurrentDateRange();
        this.groupBy = this.getCurrentGroupBy();
    }
    async componentDidMount() {
        localStorage.setItem('CurrentDateRange', null);
        localStorage.setItem('CurrentGroupBy', 'week');

        this.dateRange = null;
        this.groupBy = 'week';

        window.scrollTo({ top: 0 });
        this.httpSites = new SitesHttpClient(this.context);
        await this.getMySites();
    }

    getCurrentDateRange() {
        var currentDateRange = null
        try {
            currentDateRange = JSON.parse(localStorage.getItem('CurrentDateRange')) || null;
        }
        catch (e) {
            currentDateRange = null;
        }
        return currentDateRange;
    }
    getCurrentGroupBy() {
        var currentGroupBy = 'week'
        try {
            currentGroupBy = JSON.parse(localStorage.getItem('CurrentGroupBy')) || 'week';
        }
        catch (e) {
            currentGroupBy = 'week';
        }
        return currentGroupBy;
    }

    async getMySites() {
        this.setState({ mySites: null });
        let data = await this.httpSites.GetSites();
        this.setState({ mySites: data.content });

        this.getNotificationAlerts(data.content);
    }
    async getNotificationAlerts(sites) {
        let notifications = [];
        for (const site of sites) {
            if (site.hasAccess == true && (site.hasAccessExpirationAt == undefined || new Date(site.hasAccessExpirationAt) > new Date()) ) {
                this.httpAlerts = new AlertHttpClient(this.context, site.id, null, null, null, 0, 10);
                let data = await this.httpAlerts.GetProducedIndividualRulesTotalsBySite();
                let dataList = await this.httpAlerts.GetProducedIndividualRulesBySite({ all: true });
                if (data.content.length > 0) {
                    let list = null;
                    if (dataList.content.length > 0) {
                        list = dataList.content;
                    }
                    notifications.push({ id: site.id, alerts: data.content, alertslist: list, name: site.name });
                }
            }
        }

        this.setState({ notificationAlerts: notifications });
    }


    render() {
        let dataLoaded = this.state.mySites != undefined && this.state.notificationAlerts != null;
        return (
            <React.Fragment >
            { dataLoaded ? (
                <React.Fragment >
                        <SitesTabs notificationAlerts={this.state.notificationAlerts} mySites={this.state.mySites} handleUpdate={this.getMySites.bind(this)}/>
                </React.Fragment>) : (

                        <div className="loadingParent"><div className="loadingImg"></div></div>
    
            )}
            </React.Fragment >
       );
    }
}

export default withTranslation()(Sites);