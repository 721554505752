import { Skeleton } from '@mui/material';

const ActiveNotificationsCardsSkeleton = (props: { dev?: boolean }) => {
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
        <Skeleton variant='rectangular' height={75} style={{margin: '0 5px', width: '33%'}} />
        <Skeleton variant='rectangular' height={75} style={{margin: '0 5px', width: '33%'}} />
        <Skeleton variant='rectangular'  height={75} style={{margin: '0 5px', width: '33%'}}/>
      </div>
    </div>
  );
};

export default ActiveNotificationsCardsSkeleton;
