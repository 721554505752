import React, { Component } from 'react';
import '../../App.css';

import { withTranslation } from 'react-i18next';

import AuthenticationContext from '../../Authentication/types/AuthContextType';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import InstallationsHttpClient from '../../HttpClient/InstallationsHttpClient';
import SitesHttpClient from '../../HttpClient/SitesHttpClient';
import GatewaysHttpClient from '../../HttpClient/GatewaysHttpClient';
import UserHttpClient from '../../HttpClient/HttpClient';
import Message from '../Message/Message';
import moment from 'moment';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import { manageInfoUser } from '../Common/utils/helpers';

class Request extends Component {
  static contextType = AuthenticationContext;

  // References are declared as an empty property without initializing it in the constructor (because it does not exist in the dom yet)
  select1Ref = null;
  select2Ref = null;
  select3Ref = null;
  select4Ref = null;
  select5Ref = null;

  constructor(props) {
    super(props);

    this.select1Ref = React.createRef();

    this.installationId = this.props.installationId;
    this.gatewayId = null;
    this.userPage = false;
    this.state = {
      _data: {},
      open: false,
      expired: '',
      requestType: '',
      requestState: 'Pending',
      saving: false,
      page: 0,
      sizePerPage: 5,
      roleSelected: this.props.roleSelected,
      message: null,
    };
    this.saveDataRequest = this.saveDataRequest.bind(this);
    this.request = {};
  }
  async componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.httpUser = new UserHttpClient(this.context);
    this.httpGateway = new GatewaysHttpClient(this.context);
    if (this.props.userPage != null && this.props.userPage != undefined) {
      this.userPage = this.props.userPage;
      await this.getInstallations();
      await this.getSites();
      await this.getGateways(this.state.page, this.state.sizePerPage);
    }
  }

  componentDidUpdate(prevProps) {}

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getInstallations() {
    let data = null;
    this.httpInstallation = new InstallationsHttpClient(this.context);
    data = await this.httpInstallation.InstallationGetInstallations();
    this.setState({ installations: data });
  }

  async getSites() {
    let data = null;
    this.httpSite = new SitesHttpClient(this.context);
    data = await this.httpSite.GetSites();
    this.setState({ sites: data });
  }

  async getGateways(page, sizePerPage) {
    this.setState({ gateways: {} });
    let me = await this.getMe();
    let data = await this.getMeOwershipGateways(me.userId, page, sizePerPage);
    this.setState({ gateways: data, numGateways: data.content.length });
  }

  async getMeOwershipGateways(userId, page, sizePerPage) {
    const { t } = this.props;
    let data = await this.httpGateway.GetGatewaysFilter('userId!=' + userId, page, sizePerPage);
    if (data.errors == null) return data;
    else {
      this.renderMessage('error', t('messages.errorGettingGateways'));
    }
  }

  async getMe() {
    const userMe = await manageInfoUser(this.context, "userBasicData");
    if (userMe){
      return userMe;
    }else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async saveDataRequest(e) {
    this.setState({ saving: true });
    // Modificamos los datos en this.installation para luego comparar con los datos del this.state.data y generar el patch
    e.preventDefault();
    try {
      let expirationDate;
      switch (this.state.expired) {
        case 'never':
          expirationDate = null;
          break;
        case 'days':
          expirationDate = moment().add(1, 'days');
          break;
        case 'weeks':
          expirationDate = moment().add(1, 'weeks');
          break;
        case 'months':
          expirationDate = moment().add(1, 'months');
          break;
      }

      let newRequest = {
        installationId: this.installationId,
        gatewayId: this.gatewayId,
        requestType: this.state.requestType,
        state: this.state.requestState,
        roleId: null,
        name: document.getElementById('txtName').value,
        description: document.getElementById('txtDescription').value,
        expiredAt: expirationDate == null ? null : expirationDate.format(),
        brand: this.context.brandCode == 11 ? 'Laufen' : 'Roca',
        callbackUrl: window.location.href,
      };

      const formulario = new FormData();
      formulario.append('patchoptions', JSON.stringify(newRequest));

      let data;

      data = await this.SendPost(formulario);

      this.props.onSaveClose(data);
    } catch (e) {
      this.setState({ saving: false });
    }
  }

  async SendPost(formulario) {
    let data;

    data = await this.http.PostRequest(formulario);

    this.props.onSaveClose(data);

    return data;
  }

  cleanCustomValidityByNameRef = (nameRef) => {
    const select = this[nameRef].current.childNodes[1];
    select.setCustomValidity('');
  }

  handleChange = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.setState({ roleSelected: event.target.value });
  };
  handleChangeRequestType = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.setState({ requestType: event.target.value });

    // Create and assign the reference when the component is displayed
    if(!this.select2Ref || !this.select3Ref || !this.select4Ref|| !this.select5Ref){
      this.select2Ref = React.createRef();    
      this.select3Ref = React.createRef();
      this.select4Ref = React.createRef();
      this.select5ef = React.createRef();
    } 
  };
  handleChangeExpired = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.setState({ expired: event.target.value });
  };

  handleChangeInstallations = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.installationId = event.target.value;
   };

  handleChangeGateways = (event, nameRef) => {
    this.cleanCustomValidityByNameRef(nameRef);
    this.gatewayId = event.target.value;
  };

  dropdownRoles() {
    const handleChange = (event) => {
      this.setState({ parentid: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.props.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.props.roles.map((item, i) => {
        this.key++;
        return (
          <MenuItem key={this.key} value={item.id}>
            {item.name}
          </MenuItem>
        );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='roleLabel'>{t('invitation.role')}</InputLabel>
          <Select
            ref={select4Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              }
            }}
            variant='standard'
            name='Role'
            className='titleProperty'
            labelId='roleLabel'
            id='ddlRoles'
            label='Roles'
            type='email'
            value={this.state.roleSelected != null ? this.state.roleSelected : ''}
            required
            onChange={(e) => this.handleChange(e, 'select4Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  dropdownInstallationsAndSites() {
    const { t } = this.props;

    let dataLoaded = this.state.installations != undefined && this.state.sites != undefined;
    let listInstallations = null;
    let listSites = null;
    if (dataLoaded && this.userPage) {
      listInstallations = this.state.installations.content.map((item, i) => {
        this.key++;
        if (!item.hasOwnerAccess)
          return (
            <MenuItem key={this.key} value={item.id}>
              {item.name}
            </MenuItem>
          );
      });
      listSites = this.state.sites.content.map((item, i) => {
        this.key++;
        if (!item.hasOwnerAccess)
          return (
            <MenuItem key={this.key} value={item.id}>
              {item.name}
            </MenuItem>
          );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='installationLabel'>{t('installation')}</InputLabel>
          <Select
            ref={this.select3Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              }
            }}
            variant='standard'
            name='installations'
            className='titleProperty'
            labelId='installationLabel'
            id='installations'
            label={t('installation')}
            required
            onChange={(e) => this.handleChangeInstallations(e, 'select3Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listSites}
            {listInstallations}
          </Select>
        </FormControl>
      );
    } else if (!dataLoaded && this.userPage) {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  dropdownGateways() {
    const { t } = this.props;

    let dataLoaded = this.state.gateways != undefined;
    let listGateways = null;
    if (dataLoaded && this.userPage) {
      listGateways = this.state.gateways.content.map((item, i) => {
        this.key++;
        return (
          <MenuItem key={this.key} value={item.id}>
            {item.name}
          </MenuItem>
        );
      });

      return (
        <FormControl variant='standard' style={{ width: '100%' }}>
          <InputLabel id='gatewayLabel'>{t('gateway')}</InputLabel>
          <Select
            ref={this.select5Ref}
            inputProps={{
              onInvalid: (event) => {
                event.target.setCustomValidity(t('general.error_required'));
              }
            }}
            variant='standard'
            name='gateways'
            className='titleProperty'
            labelId='gatewayLabel'
            id='gateways'
            label={t('gateway')}
            required
            onChange={(e) => this.handleChangeGateways(e, 'select5Ref')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                },
              },
            }}
          >
            {listGateways}
          </Select>
        </FormControl>
      );
    } else if (!dataLoaded && this.userPage) {
      return (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }

  render() {
    const { t } = this.props;

    const handleCloseModal = () => {
      this.props.onSaveClose();
    };

    let dataLoaded = true; //this.state.data != undefined && this.state.mySites != undefined;

    return (
      <React.Fragment>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

        {dataLoaded ? (
          <div>
            <form id='divForm' onSubmit={(e) => this.saveDataRequest(e)}>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <TextField
                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onChange={(e) => e.target.setCustomValidity('')}
                    variant='standard'
                    id='txtName'
                    label={t('forms.name')}
                    placeholder={t('forms.name')}
                    helperText=''
                    style={{ width: '100%' }}
                    required
                    margin='normal'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=''
                  />
                </div>
                <div className='form-group col-md-12'>
                  <TextField
                    variant='standard'
                    id='txtDescription'
                    label={t('forms.description')}
                    placeholder={t('forms.description')}
                    helperText=''
                    style={{ width: '100%' }}
                    margin='normal'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue=''
                  />
                </div>
                <div className='form-group col-md-12'>
                  <div variant='standard' style={{ width: '100%' }}>
                    <InputLabel id='requestTypeLabel'>{t('invitation.invitationType')}</InputLabel>
                    <Select
                      ref={this.select1Ref}
                      inputProps={{
                        onInvalid: (event) => {
                          event.target.setCustomValidity(t('general.error_required'));
                        }
                      }}
                      variant='standard'
                      name='RequestType'
                      className='titleProperty'
                      labelId='requestTypeLabel'
                      id='ddlExpired'
                      label={t('invitation.invitationType')}
                      value={this.state.requestType}
                      style={{ width: '100%' }}
                      required
                      onChange={(e) => this.handleChangeRequestType(e, 'select1Ref')}
                    >
                      <MenuItem value='InstallationAccess'>{t('invitation.installationAccess')}</MenuItem>
                      <MenuItem value='InstallationTransferOwnership'>{t('invitation.installationTransferOwnership')}</MenuItem>
                      <MenuItem value='GatewayTransferOwnership'>{t('invitation.gatewayTransferOwnership')}</MenuItem>
                    </Select>
                  </div>
                </div>
                {this.state.requestType == 'InstallationAccess' ? (
                  <div className='form-group col-md-12'>
                    <div variant='standard' style={{ width: '100%' }}>
                      <InputLabel id='expiredLabel'>{t('invitation.expired')}</InputLabel>
                      <Select
                        ref={this.select2Ref}
                        inputProps={{
                          onInvalid: (event) => {
                            event.target.setCustomValidity(t('general.error_required'));
                          },
                        }}
                        variant='standard'
                        name='Expired'
                        className='titleProperty'
                        labelId='expiredLabel'
                        id='ddlExpired'
                        style={{ width: '100%' }}
                        label={t('invitation.expired')}
                        value={this.state.expired}
                        required
                        onChange={(e) => this.handleChangeExpired(e, 'select2Ref')}
                      >
                        <MenuItem value='days'>{t('invitation.day')}</MenuItem>
                        <MenuItem value='weeks'>{t('invitation.week')}</MenuItem>
                        <MenuItem value='months'>{t('invitation.month')}</MenuItem>
                        <MenuItem value='never'>{t('invitation.never')}</MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  {this.state.requestType == 'InstallationAccess' || this.state.requestType == 'InstallationTransferOwnership'
                    ? this.dropdownInstallationsAndSites()
                    : null}
                  {this.state.requestType.toLowerCase() == 'gatewaytransferownership' ? this.dropdownGateways() : null}
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  {this.state.saving ? (
                    <div style={{ position: 'absolute' }} className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary'>
                    {t('actions.save')}
                  </Button>
                </div>
                <div className='form-group col-md-6'>
                  <Button className='primary pull-right' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Request);
