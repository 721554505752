import React, { Component } from 'react';
import '../../App.css';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import Request from './Request';
import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Message from '../Message/Message';

import Table from '../CustomComponents/ReactTable/Table';

class RequestsOutbox extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    if (this.props.id) this.id = this.props.id;
    else this.id = null;
    this.state = {
      requests: {},
      numRequests: 0,
      page: localStorage.getItem('RequestsOutboxPage') ? parseInt(localStorage.getItem('RequestsOutboxPage')) : 1,
      sizePerPage: localStorage.getItem('RequestsOutboxSizePerPage') ? parseInt(localStorage.getItem('RequestsOutboxSizePerPage')) : 5,
      showDelete: false,
      showRequest: false,
      message: null,
    };

    this.paginaActual = 1;

    this.confirmDelete = this.confirmDelete.bind(this);
    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'userMailReceiver',
        text: t('invitation.to'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '19%' };
        },
      },
      {
        dataField: 'installationId',
        text: 'InstallationId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'installationName',
        text: t('invitation.sitesInstallations'),
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'gatewayId',
        text: 'GatewayId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'gatewayName',
        text: 'Gateways',
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '15%' };
        },
      },
      {
        dataField: 'requestType',
        text: t('invitation.type'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.TypeFormatter.bind(this),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'state',
        text: t('invitation.state'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '15%' };
        },
      },
      {
        dataField: 'EditFormatter',
        text: ' ',
        align: 'center',
        formatter: this.EditFormatter.bind(this),
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'DeleteFormatter',
        text: ' ',
        align: 'center',
        formatter: this.DeleteFormatter.bind(this),
        headerStyle: () => {
          return { width: '5%' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  TypeFormatter({ row, value }) {
    if (row.requestType) {
      switch (row.requestType.toLowerCase()) {
        case 'installationaccess':
          return <span>Installation access</span>;
        case 'installationtransferownership':
          return <span>Installation Transfer ownership</span>;
        case 'gatewaytransferownership':
          return <span>Gateway transfer ownership</span>;
      }
    } else return <span>Installation access</span>;
  }

  EditFormatter({ row, value }) {
    const onClick = (evt) => {
      evt.preventDefault();
      this.setState({
        showRequest: true,
        role: evt.currentTarget.getAttribute('data-role'),
        requestId: evt.currentTarget.id.split('_')[1],
        userId: evt.currentTarget.getAttribute('data-user'),
      });
    };

    let idEle = 'btnEdit_' + row.id;
    return (
      <EditOutlinedIcon
        id={idEle}
        style={{ width: '24px', cursor: 'pointer' }}
        onClick={(evt) => onClick(evt)}
        data-role={row.roleId}
        data-user={row.userId}
      />
    );
  }

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  DeleteFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showDelete: true, requestId: evt.currentTarget.id.split('_')[1] });
    };

    let idEle = 'btnDelete_' + row.id;
    if (row.state == null || row.state == 'Pending') {
      return <DeleteOutlineIcon id={idEle} style={{ width: '24px', cursor: 'pointer' }} onClick={(evt) => onClick(evt)} />;
    } else {
      return null;
    }
  }

  async confirmDelete(t) {
    let data = await this.http.deleteRequest(this.state.requestId);
    if (data.errors == null) {
      this.setState({ showDelete: false, requests: null });
      this.renderMessage('success', t('invitation.requestDeleted'));
    } else {
      this.setState({ showDelete: false });
      this.renderMessage('error', t('invitation.errorDeletingRequest'));
    }
    this.getRequests(this.state.page, this.state.sizePerPage);
  }

  componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.getRequests(this.state.page, this.state.sizePerPage);
  }

  componentWillUnmount(){
    localStorage.removeItem("RequestsOutboxSizePerPage");
    localStorage.removeItem("RequestsOutboxPage");
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.getRequests(page, sizePerPage);
  };

  async getRequests(page, sizePerPage) {
    let data = null;
    if (this.id != null) data = await this.http.getRequestsSentInstallation(this.id, page, sizePerPage);
    else data = await this.http.getRequestsSent(page, sizePerPage);

    this.setState({ requests: data, numRequests: data.content.length });
  }

  handleChange(evt) {
    //his.setState({ checkboxChecked: evt.target.checked });
  }

  dropdownPermissions() {
    const handleChange = (event) => {
      this.setState({ newRole: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.state.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.state.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='permissionLabel'>Select permission level</InputLabel>
          <Select
            variant='standard'
            name='permission'
            className='titleProperty1'
            labelId='permissionLabel'
            placeholder='Select an option'
            id='ddlRoles'
            value={this.state.role}
            onChange={handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }
  toggleRequests() {
    this.setState({ showRequest: !this.state.showRequest });
  }

  async getRoles() {
    let data = await this.http.getRoles();
    this.setState({ roles: data.content });
  }

  render() {
    const { t } = this.props;
    let dataLoaded = this.state.requests?.content != undefined;
    if (dataLoaded) {
    }

    const handleDeleteRequest = () => {
      this.setState({ showDelete: false });
    };

    const handleClose = (data) => {
      let messageObj = {}; 
      let showMessage = false;
      let showRequest = false;

      if (data) {
        showMessage = true;

        if (data.errors) {
          messageObj.message = data.errors[0];
          messageObj.type = 'error';
        } else if (data.status > 200) {
          messageObj.message = data.title;
          messageObj.type = 'error';
        } else if (data.content?.hasUpdated) {
          messageObj.message = t('invitation.requestUpdated');
          messageObj.type = 'info';
        } else {
          messageObj.message = t('invitation.requestCreated');
          messageObj.type = 'success';
        }
      }

      this.setState({ showRequest: showRequest, requests: null }, () => {
        this.getRequests(this.state.page, this.state.sizePerPage);
      });

      if (showMessage == true) {
        if(messageObj.type){
            this.renderMessage(messageObj.type, messageObj.message);
        }
      }
    };

    const onSizePerPageChange = (sizePerPage) => {
      localStorage.setItem('RequestsOutboxSizePerPage', sizePerPage)
      this.setState({ sizePerPage: sizePerPage });
    }
  
    const onPageChange = (newPage) => {
      localStorage.setItem('RequestsOutboxPage', newPage)
      this.paginaActual = newPage;
      this.setState({ page: newPage });
    }

    return (
      <div className='col' style={{ padding: 0, margin: 0 }}>
        {dataLoaded ? (
          <div className='col chartContainer' style={{ padding: 0, margin: 0 }}>
            {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
            <Table
              name={'RequestOutbox-locales'}
              t={t}
              resize={true}
              columns={this.columns}
              onSizePerPageChange={onSizePerPageChange}
              onPageChange={onPageChange}
              rows={this.state.requests.content.sort(function (a, b) {
                return new Date(b.id) - new Date(a.id);
              })}
              search={true}
              page={this.state.page}
              pageSize={this.state.sizePerPage}
            />
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <Button className='primary' onClick={() => this.toggleRequests()}>
                  {t('invitation.addRequest')}
                </Button>
              </div>
            </div>

            <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleDeleteRequest}>
              <DialogTitle>{t('invitation.deleteRequest')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('invitation.confirmDeleteRequest')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary pull-right' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary pull-right' onClick={handleDeleteRequest}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={false} open={this.state.showRequest}>
              <DialogTitle>{t('invitation.newRequest')}</DialogTitle>
              <DialogContent>
                <div id='divForm'>
                  <div className='form-row'>
                    <div className='form-group col-md-12'>
                      <Request
                        installationId={this.id}
                        roles={this.state.roles}
                        userPage={this.userPage}
                        roleSelected={this.state.role}
                        userId={this.state.userId}
                        requestId={this.state.requestId}
                        onSaveClose={handleClose}
                      ></Request>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(RequestsOutbox);
