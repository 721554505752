import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, id, productId, page, pagesize) {
        this.context = context;
        this.installationID = id;
        this.productId = productId;
        this.language = i18n.language;
        this.debug = false
        this.page = page;
        this.pagesize = pagesize;
    }

    GetInstallationsFilter = async (filter,page,sizePerPage) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("installations/" + this.language+"/"+page+"/"+sizePerPage+"/"+filter, config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/" + page + "/" + sizePerPage + "/" + filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    InstallationGetInstallations = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("installations/" + this.language, config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    InstallationGetInstallationsMenu = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("installations/" + this.language + "/menu", config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    InstallationGetInstallationsById = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("installations/" + this.language + "/" + this.installationID, config);
            if (this.debug) {
                console.log("calling: " + "installations/" + this.language + "/id");
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        } catch (e) {
            return e.response;
        }
    }

    InstallationGetTree = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("installations/" + this.language + "/" + this.installationID + "/gettree", config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/gettree");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }

    rotateInstallationPlan = async (degrees) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("installations/" + this.language + "/" + this.installationID + "/rotate/"+degrees, config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/rotate/" + degrees);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    SiteGetProductTree = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("installations/" + this.language + "/" + this.productId + "/getProductTree", config);
        if (this.debug) {
            console.log("calling: " + "sites/" + this.language + "/" + this.installationId + this.productId + "/tree");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }
    InstallationUploadFile = async (data, installationId) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        let url = await axios.get("installations/"+ this.language + "/getBaseUrl",config);

        if (url && url.data) {
            config = {
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                    "content-type": "multipart/form-data",
                }
            }

            let response = await axios.post(url.data + "/" + installationId+"/floor-plan", data, config);
            if (this.debug) {
                console.log("response: " + JSON.stringify(response));
            }
            return response.data
        }
    }

    AvatarUploadFile = async (data, installationId) => {

        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        let url = await axios.get("installations/" + this.language + "/getBaseUrl",config);

        if (url && url.data) {
            config = {
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                    "content-type": "multipart/form-data",
                }
            }
            let response = null;
            try {
                response = await axios.post(url.data + "/" + installationId + "/avatar-image", data, config);

                if (this.debug) {
                    console.log("response: " + JSON.stringify(response));
                }
            }
            catch (e){
                response = e.response;
            }
            this.props.installation.avatarUri = response.data

            return response.data

        }

    }


    InstallationGetChartViewZones = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("charts/" + this.language + "/viewchartszone/Installation", config);
        if (this.debug) {
            console.log("calling: " + "charts/" + this.language + "//api/v{version}/{culture}/charts/{page}/viewchartszone/Product");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }

    PatchInstallations = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response;
        try {
            response = await axios.post("installations/" + this.language + "/" + this.installationID + "/updateInstallation/", elements, config);
            if (this.debug) {
                console.log("calling: installations/" + this.language + "/" + this.installationID + "/updateInstallation/");
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }
    PostInstallations = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("installations/" + this.language + "/installation/", elements, config);
        if (this.debug) {
            console.log("calling: installations/" + this.language + "/installation/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    DeleteInstallations = async (id, name) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        var response;
        try {
            var body = {
                name: name
            }
            response = await axios.delete("installations/" + this.language + "/" + id, {
                headers: {
                    Authorization: `${accessToken.accessToken}`
                },
                data: body
            });
            if (this.debug) {
                console.log("calling: installations/" + this.language + "/installation/" + id);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
            throw e;
        }

        return response.data
    }

    getRoles = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("installations/" + this.language + "/roles", config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/Roles");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }

    getInvitations = async (id) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("installations/" + this.language + "/" + "invitations/" + id + "/" + this.page + "/" + this.pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: installations/" + this.language + "/" + "invitations/" + this.page + "/" + this.pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    deleteInvitation = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.delete("installations/" + this.language + "/invitation/" + idInvitation, config);
        if (this.debug) {
            console.log("calling: " + this.language + " /invitation/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PostInvitation = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response;
        try {
            response = await axios.post("installations/" + this.language + "/invitation/", elements, config);
            if (this.debug) {
                console.log("calling: installations/" + this.language + "/invitation/");
                console.log("response: " + JSON.stringify(response));
            }
        }
        catch (e) {
            response = e.response;
        }

        return response.data
    }

    PatchUserInstallation = async (userId, roleId, newRoleId) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }

        var response;
        try {
            response = await axios.post("installations/" + this.language + "/" + userId + "/" + roleId + "/" + newRoleId, null, config);
            if (this.debug) {
                console.log("calling: installations/" + this.language + "/" + userId + "/" + roleId + "/" + newRoleId);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }

    deleteUserInstallations = async (installationId, userId) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`

            }
        }
        var response;
        try {
            response = await axios.delete("installations/" + this.language + "/" + installationId + "/" + userId, config);
            if (this.debug) {
                console.log("calling: installations/" + this.language + "/" + installationId + "/" + userId);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response
    }

    deleteFloorplan = async (installationId) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.delete("installations/" + this.language + "/floorplan/" + installationId, config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/floorplan/" + installationId);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    deleteAvatar = async (installationId) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.delete("installations/" + this.language + "/avatar/" + installationId, config);
        if (this.debug) {
            console.log("calling: " + "installations/" + this.language + "/avatar/" + installationId);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }
}