import { Skeleton } from '@mui/material';

const HeatMapChartSkeleton = () => {
  const renderSkeletonYaxisItems = (count: number, width: number, height: number) => {
    return Array.from({ length: count }).map((_, index) => (
      <Skeleton key={index} variant='rectangular' width={width} height={height} style={{
        margin: '5px 5px',
        borderRadius: '5px'
      }} />
    ));
  };

  const renderSkeletonLegendItems = (count: number, width: number, height: number) => {
    return Array.from({ length: count }).map((_, index) => (
      <Skeleton key={index} variant='rectangular' width={width} height={height} style={{
        borderRadius: '5px',
        margin: '0px 10px'
      }} />
    ));
  };

  return (
    <div style={{ display: 'table', alignItems: 'center', padding: '30px 20px 20px 25px', width: '100%' }}>
      <div style={{ marginBottom: '10px' }}>
        <Skeleton variant='rectangular' width={100} height={25} style={{ margin: '0px 5px 0px 25px', borderRadius: '5px' }} />
      </div>

      <div style={{ display: 'flex', alignItems: 'end', width: '100%'  }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%'  }}>
          <div style={{ display: 'inline-grid' }}>
            {renderSkeletonYaxisItems(7, 50, 25)}
          </div>
          <Skeleton variant='rectangular' height={250} style={{ margin: '0 5px', borderRadius: '5px', width: '100%'  }} />
        </div>
        <Skeleton variant='rectangular' width={75} height={25} style={{ margin: '0 5px', borderRadius: '5px' }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '25px' }}>
        {renderSkeletonLegendItems(4, 75, 25)}
      </div>

      <div style={{ height: '10px' }}></div>
    </div>
  );
};

export default HeatMapChartSkeleton;