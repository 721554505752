import { Component } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ErrorBoundaryProps, ErrorBoundaryPropsState } from '@ComponentsRoot/Common/types/ErrorBoundaryTypes';
import { t } from 'i18next';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryPropsState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, previousLocation: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    // console.log(error);
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    // Check if URL has changed
    if (this.state.hasError && prevProps.location.pathname !== this.props.location.pathname) {
      // Reset hasError state and update previousLocation
      this.setState({ hasError: false, previousLocation: this.props.location });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <ErrorOutlineIcon style={{ margin: '0 auto', display: 'block' }} fontSize='large' />
          <div style={{ textAlign: 'center' }}>
            <h1>{t('translation:error').toLocaleUpperCase()}</h1>
            <div>{t('translation:somethingWrong')}</div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
