import React, { Component } from 'react';
import '../App.css';
import './ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from '../Authentication/types/AuthContextType';
import InstallationsHttpClient from '../HttpClient/InstallationsHttpClient';
import InvitationsHttpClient from '../HttpClient/InvitationsHttpClient';
import SitehttpClient from '../HttpClient/SitesHttpClient';
import GatewayhttpClient from '../HttpClient/GatewaysHttpClient';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Message from './Message/Message';
import Typography from '@mui/material/Typography';

class SiteInstallationGatewayTransferOwnership extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.id = this.props.id;

    this.type = props.type;
    this.email = '';
    this.confirmData = this.confirmData.bind(this);
    this.sendData = this.sendData.bind(this);
    this.state = {
      _userId: null,
      _users: null,
      _name: '',
      saving: false,
      showModalError: false,
      errorMessage: '',
      loadingSite: false,
      message: null,
    };
    //this.saveData = this.saveData.bind(this);
  }
  async componentDidMount() {
    await this.getInformation();
  }

  componentDidUpdate(prevProps) {}
  componentWillUnmount() {
    this.props.onSaveClose();
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getInformation() {
    if (this.id != 0) {
      let data = null;
      if (this.props.type == 'installation') {
        this.http = new InstallationsHttpClient(this.context, this.id);
        let response = await this.http.InstallationGetInstallationsById();
        if (response.status == 200) {
          data = response.data;
        }
      } else if (this.props.type == 'site') {
        this.http = new SitehttpClient(this.context, this.id);
        let response = await this.http.GetSite();
        if (response.status == 200) {
          data = response.data;
        }
      } else if (this.props.type == 'gateway') {
        this.http = new GatewayhttpClient(this.context);
        let response = await this.http.GetGateway(this.id);
        if (response.status == 200) {
          data = response.data;
        }
      }
      if (data != null) {
        if (this.props.type == 'installation' || this.props.type == 'site')
          this.setState({
            _userId: data.content.userId,
            _users: data.content.userInstallations,
            _name: data.content.name,
            _type: 'InstallationTransferOwnership',
          });
        else if (this.props.type == 'gateway')
          this.setState({ _userId: data.content.userId, _name: data.content.name, _type: 'GatewayTransferOwnership' });
      }
    }
  }

  async confirmData(e, t) {
    this.setState({ saving: true });
    e.preventDefault();
    this.setState({ showModalConfirm: true });
  }

  async sendData(t) {
    let data = await this.SendTransferOwnership(this.email, this.id, this.type);
    let messageBody = {};
    if (data.errors != null) {
      messageBody.type = 'error';
      messageBody.message = 'Error: ' + data.errors[0];      
    } else if (data.status > 200) {
        messageBody.type = 'error';
        messageBody.message = 'Error: ' + data.title;
    } else {
        messageBody.type = 'success';
        messageBody.message = 'Invitation sended successfully';
    }
    this.setState({ saving: false });
    this.props.onSaveClose();
    this.renderMessage(messageBody.type, messageBody.message);
  }

  async SendTransferOwnership(userId, id, type) {
    let newInvitation;
    if (this.state._type == 'InstallationTransferOwnership') {
      newInvitation = {
        installationId: id,
        type: this.state._type,
        mail: userId,
        expiredAt: null,
        brand: this.context.brandCode == 11 ? 'Laufen' : 'Roca',
        callbackUrl: window.location.href,
      };
    } else if (this.state._type == 'GatewayTransferOwnership') {
      newInvitation = {
        gatewayId: id,
        type: this.state._type,
        mail: userId,
        expiredAt: null,
        brand: this.context.brandCode == 11 ? 'Laufen' : 'Roca',
        callbackUrl: window.location.href,
      };
    }
    const formulario = new FormData();
    formulario.append('patchoptions', JSON.stringify(newInvitation));

    let data = await this.SendPost(formulario);
    return data;
  }

  async SendPost(formulario) {
    this.httpInvitations = new InvitationsHttpClient(this.context);
    let data = await this.httpInvitations.PostInvitation(formulario);
    this.props.onSaveClose(data);

    return data;
  }

  render() {
    const { t } = this.props;

    const handleClose = () => {
      this.setState({ showModalConfirm: false, saving: false });
    };

    const handleChange = (event) => {
      this.email = event.target.value;
    };

    let dataLoaded = this.state._name != null;

    //<Select
    //    name="users"
    //    className="titleProperty1"
    //    labelId="usersLabel"
    //    id="users"
    //    required
    //    onChange={handleChange}
    //>
    //    <MenuItem className="titleProperty1" value="">{t('installationspages.installationTree.selectUserEmail')}</MenuItem>
    //    {(this.state._users.map((value, p) => (
    //        this.state._userId != value.userId ? (<MenuItem value={value.userMail}>{value.userMail}</MenuItem>) : (null)
    //    )))}
    //</Select>

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
                
            {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

            <Dialog disableEscapeKeyDown open={this.state.showModalConfirm} onClose={handleClose}>
              <DialogTitle>{t('installationspages.installationTree.titleConfirmTransferOwnership')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>
                    {t('installationspages.installationTree.questionConfirmTransferOwnership')} {this.state._name}?
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={() => this.sendData(t)}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Form id='divForm' onSubmit={(e) => this.confirmData(e, t)}>
              <div className='form-row'>
                <div className='form-group col-md-12 form-row'>
                  <FormControl variant='standard' style={{ width: '100%' }}>
                    <Typography className='t1' id='t-{paramObj.businessLabel}'>
                      {t('installationspages.installationTree.userEmail')}
                    </Typography>
                    <TextField variant='standard' id='users' type='text' onChange={handleChange} />
                  </FormControl>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary active'>
                    {t('installationspages.installationTree.send')}
                  </Button>
                </div>
                <div className='form-group col-md-6'>
                  <Button className='primary pull-right' onClick={this.props.onSaveClose}>
                    {t('actions.close')}
                  </Button>
                </div>
                <div className='form-group col-md-2'>
                  {this.state.saving ? (
                    <div className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withTranslation()(SiteInstallationGatewayTransferOwnership);
