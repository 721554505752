import  { Component } from 'react';
import '../../App.css';

import AuthenticationContext from '../../Authentication/types/AuthContextType';
import UserHttpClient from '../../HttpClient/HttpClient';
import SitesHttpClient from '../../HttpClient/SitesHttpClient';
import InstallationsHttpClient from '../../HttpClient/InstallationsHttpClient';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import SiteInstallationGatewayTransferOwnership from '../SiteInstallationGatewayTransferOwnership';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Send from '@mui/icons-material/Send';
import Mail from '@mui/icons-material/Mail';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Message from '../Message/Message';
import Table from '../CustomComponents/ReactTable/Table';
import { manageInfoUser } from '../Common/utils/helpers';

class TransferOwnershipSites extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.state = {
      sites: {},
      invitations: {},
      numSites: 0,
      page: 1,
      sizePerPage: 5,
      showSendTransferOwnership: false,
      showDelete: false,
      message: null,
      data: {},
      siteId: '',
    };

    this.paginaActual = 1;

    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'name',
        text: t('site'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'isDefault',
        text: t('invitation.isDefault'),
        align: 'left',
        sort: true,
        formatter: this.IsDefaultFormatter.bind(this),
        headerStyle: () => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'SiteId',
        text: 'SiteId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'IsSendedInvitationFormatter',
        text: t('invitation.invitationSent'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.IsSendedInvitationFormatter.bind(this),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'TransferOwnerShipFormatter',
        text: ' ',
        align: 'center',
        formatter: this.TransferOwnerShipFormatter.bind(this),
        headerStyle: () => {
          return { width: '12%' };
        },
      },
      {
        dataField: 'DeleteFormatter',
        text: ' ',
        align: 'center',
        formatter: this.DeleteFormatter.bind(this),
        headerStyle: () => {
          return { width: '12%' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  IsDefaultFormatter({ row, value }) {
    if (row.isDefault) {
      return <span>True</span>;
    } else {
      return <span>False</span>;
    }
  }

  IsSendedInvitationFormatter({ row, value }) {
    let idSite = row.id;
    let invitations = this.state.invitations.content;
    let filterObj = invitations.filter(function (e) {
      return e.installationId == idSite;
    });
    let out = '';
    filterObj.forEach((element) => (out += element.receiverMail + ' - ' + element.state + ' | '));
    if (out) {
      return (
        <div>
          <Mail title='Send Invitation' style={{ width: '24px' }} />
          <span>{out}</span>
        </div>
      );
    } else {
      return null;
    }
  }
  TransferOwnerShipFormatter({ row, value }) {
    const onClick = (evt) => {
      let siteId = evt.currentTarget.id.split('_')[1];

      this.setState({ showSendTransferOwnership: true, siteId: siteId });
    };
    let idEle = 'btnTransferOwnership_' + row.id;

    let idSite = row.id;
    let invitations = this.state.invitations.content;
    let filterObj = invitations.filter(function (e) {
      return e.installationId == idSite && e.state != 'Declined';
    });
    if (filterObj.length > 0) {
      return null;
    } else {
      return (
        <Send
          id={idEle}
          title='Send transfer ownership Invitation'
          style={{ width: '24px', cursor: 'pointer' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    }
  }

  DeleteFormatter({ row, value }) {
    const onClick = (evt) => {
      let siteId = evt.currentTarget.id.split('_')[1];

      this.setState({ showDelete: true, siteIdDelete: siteId });
    };
    let idEle = 'btnDelete_' + row.id;

    if (row.installations.length > 0) {
      return null;
    } else {
      //Delete if not have products
      return (
        <DeleteOutline
          id={idEle}
          alt='Delete Site'
          style={{ width: '24px', cursor: 'pointer' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    }
  }

  async componentDidMount() {
    this.httpUser = new UserHttpClient(this.context);
    this.httpSite = new SitesHttpClient(this.context);
    this.httpInstallation = new InstallationsHttpClient(this.context);
    this.httpInvitation = new InvitationsHttpClient(this.context);
    await this.getSites(this.state.page, this.state.sizePerPage);
    await this.getInvitations('type=InstallationTransferOwnership', 0, 5);
  }

  handleTableChange = async (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    await this.getSites(page, sizePerPage);
  };

  async getSites(page, sizePerPage) {
    this.setState({ sites: {} });
    let me = await this.getMe();
    let data = await this.getMeOwershipSites(me.userId, page, sizePerPage);
    this.setState({ sites: data, numSites: data.content.length });
  }

  async getInvitations(filter, page, sizePerPage) {
    this.setState({ invitations: {} });
    let data = await this.httpInvitation.getInvitationsSentFilter(filter, page, sizePerPage);
    this.setState({ invitations: data });
  }

  async getMe() {
    const userMe = await manageInfoUser(this.context, "userBasicData");
    if (userMe){
      return userMe;
    }else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async getMeOwershipSites(userId, page, sizePerPage) {
    const { t } = this.props;
    let data = await this.httpSite.GetSitesFilter('userId=' + userId, 0, 1000);
    if (data.errors == null) return data;
    else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingSites'));
    }
  }

  async deleteSite(siteId) {
    const { t } = this.props;
    let data = this.httpSite.DeleteSites(siteId);
    if (data.errors == null) {
      this.setState({ showDelete: false });
      this.renderMessage('success', t('messages.successDeleteSite'));

      await this.getSites(this.state.page, this.state.sizePerPage);
      await this.getInvitations('type=InstallationTransferOwnership', 0, 5);
      this.props.verification();
    } else {
      this.setState({ showDelete: false });
      this.renderMessage('error', t('messages.errorDeleteSite'));
    }
  }

  render() {
    const { t } = this.props;
    let dataLoaded = this.state.sites.content != undefined && this.state.invitations.content != undefined;

    const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      const isActive = active ? 'active' : '';
      if (active) {
        activeStyle.margin = '5px';
      } else {
        activeStyle.margin = '5px';
      }
      if (typeof page === 'string') {
        activeStyle.margin = '5px';
      }

      return (
        <li className={'page-item paginate_button ' + isActive}>
          <a href='#' className='page-link' onClick={handleClick} style={activeStyle}>
            {page}
          </a>
        </li>
      );
    };

    this.options = {
      pageButtonRenderer,
      page: this.paginaActual,
      paginationSize: this.state.sizePerPage,
      pageStartIndex: 1,
      sizePerPage: this.state.sizePerPage,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      paginationTotalRenderer: this.customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: t('tables.all'),
          value: this.state.numSites,
        },
      ],
      onSizePerPageChange: (sizePerPage, page) => {
        this.setState({ sizePerPage: sizePerPage }, () => console.log('this.state.sizePerPage' + this.state.sizePerPage));
      },
      onPageChange: (newPage) => {
        this.paginaActual = newPage;
        this.setState({ page: newPage });
      },
    };

    const handleClose = async (data) => {
      this.setState({ showSendTransferOwnership: false });
      await this.getSites(this.state.page, this.state.sizePerPage);
      await this.getInvitations('type=InstallationTransferOwnership', 0, 5);
    };

    const handleCloseDelete = (data) => {
      this.setState({ showDelete: false });
    };

    return (
      <div className='col' style={{ margin: '10px' }}>
        <div className='row col-12'>
          {dataLoaded ? (
            <div className='col chartContainer'>
              {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

              <Table
                t={t}
                name={'TransferOwnershipSites-locales'}
                resize={true}
                columns={this.columns}
                rows={this.state.sites.content}
                search={true}
                page={this.state.page}
                pageSize={this.state.sizePerPage}
              />
              <Dialog disableEscapeKeyDown open={this.state.showSendTransferOwnership} onClose={handleClose}>
                <DialogTitle>Send transfer ownership invitation</DialogTitle>
                <DialogContent>
                  <div>
                    <SiteInstallationGatewayTransferOwnership id={this.state.siteId} onSaveClose={handleClose} type='site' />
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleCloseDelete}>
                <DialogTitle>{t('actions.deletename', { name: t('installation') })}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{t('actions.confirmdelete', { name: t('installation') })}</span>
                    {this.state.saving ? (
                      <div className='loadingParent'>
                        <div className='loadingImg'></div>
                      </div>
                    ) : null}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button color='primary' onClick={async () => await this.deleteSite(this.state.siteIdDelete)}>
                    {t('actions.yes')}
                  </Button>
                  <Button color='primary' onClick={handleCloseDelete}>
                    {t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            <div className='loadingParent'>
              <div className='loadingImg'></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TransferOwnershipSites);
