import { useMemo, useState, useEffect } from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
} from 'react-azure-maps';
import { AuthenticationType, data } from 'azure-maps-control';

const layerOptions = {
  textOptions: {
    textField: ['get', 'title'],
    offset: [0, 1.2],
  },
};

const renderPoint = (coordinates) => (
  <AzureMapFeature key={coordinates.toString()} id={coordinates.toString()} type='Point' coordinate={coordinates} />
);

const SinglePointMap = (props) => {
  const height = props.height ? props.height : '150px';
  const { longitude, latitude } = props.installation;
  const initialPoint = longitude && !isNaN(longitude) ? [new data.Position(longitude, latitude)] : [];
  const center = initialPoint[0] || props.startLocation || [0, 0];

  const [markers, setMarkers] = useState(initialPoint);
  const [renderKey, setRenderKey] = useState(0);

  const mapOptions = {
    center,
    zoom: 9,
    view: 'Auto',
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: 'xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo',
    },
    showFeedbackLink: false,
    showLogo: false,
  };

  const memoizedMarkerRender = useMemo(() => markers.map(renderPoint), [markers]);

  const handleMapClick = (e) => {
    if (props.onMapClick) {
      props.onMapClick(e, 'fromClick');
      const newPoint = new data.Position(e.position[0], e.position[1]);
      setMarkers([newPoint]);
    }
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  useEffect(() => {
    if (props.changePoint === 'fromInput') {
      const { longitude, latitude } = props.installation;
      if (longitude && !isNaN(longitude) && latitude && !isNaN(latitude)) {
        const newPoint = new data.Position(longitude, latitude);
        setMarkers([newPoint]);
        setRenderKey((prevKey) => prevKey + 1);
      }
    }
  }, [props.changePoint, props.installation]);

  return (
    <AzureMapsProvider key={renderKey}>
      <div id='azureMaps' className='azureMaps' style={{ height: height }}>
        <AzureMap options={mapOptions} events={{ click: handleMapClick }}>
          <AzureMapDataSourceProvider id='singlePointMapDataSource' options={{ cluster: true, clusterRadius: 2 }}>
            <AzureMapLayerProvider id='singlePointMapLayerProvider' options={layerOptions} type='SymbolLayer' />
            {memoizedMarkerRender}
          </AzureMapDataSourceProvider>
        </AzureMap>
      </div>
    </AzureMapsProvider>
  );
};

export default SinglePointMap;
