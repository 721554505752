import React, { Component } from 'react';
import '../App.css';
import PageHttpClient from "../HttpClient/PagesHttpClient";
import parse from 'html-react-parser';
import AuthenticationContext from "../Authentication/types/AuthContextType";

export class PageContent extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);

        
        this.state = {
            thereIsData: false, page: {}, culture:this.props.match.params.culture
        };
    }

    async getPage() {
        let culture = this.props.match.params.culture;
        document.documentElement.lang = culture.toLowerCase();
        let pageId = this.props.match.params.pageId;
        let brand = this.props.match.params.brandId;
        this.setState({ thereIsData: false, page: {} });
        this.http = new PageHttpClient(this.context, culture, pageId, brand);
        let page = await this.http.GetPage();
        if (page.data.content.description != "") {
            this.setState({ thereIsData: true, page: page });
        }
    }

    componentDidMount() {
        this.getPage();
        this.cookiesPolicy();
    }


    componentDidUpdate(prevProps) {
        if (this.props.match != prevProps.match) {
            this.getPage();
            this.cookiesPolicy();
        }
    }

    cookiesPolicy() {
        //let laufen = window.location.hostname.includes("laufen") ? true : false;
        let brand = this.props.match.params.brandId;

        let laufen = brand == 11;


        let oldScript = document.getElementById("privacyOneTrust");

        if (oldScript) oldScript.remove();

        const script = document.createElement("script");
        script.id = "privacyOneTrust";
        script.src = "/scripts/one-trust.js";
        //script.async = true;
        document.body.appendChild(script);

        let oldScriptCookies = document.getElementById("cookiesOneTrust");

        if (oldScriptCookies) oldScriptCookies.remove();

        const scriptCookies = document.createElement("script");
        scriptCookies.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        scriptCookies.id = "cookiesOneTrust"
        scriptCookies.type = "text/javascript";
        scriptCookies.charset = "UTF-8";

        laufen ? scriptCookies.setAttribute("data-domain-script", "8505cdb8-f574-4507-b84d-583f5b784840") : scriptCookies.setAttribute("data-domain-script", "10148db1-74d0-4f50-9c0b-05966de60fa9");
        document.body.appendChild(scriptCookies);
    }

    render() {
        const { t } = this.props;
        if (this.state.thereIsData) {
            this.cookiesPolicy();
            return (
                <div>
                    {(this.state.page.status == 200) ?

                        (<div className="container">
                            <h1>{this.state.page.data.content.title}</h1>
                            {parse(this.state.page.data.content.description)}
                        </div>) :
                        (<div>error</div>)
                    }
                </div>
            )
        }
        else {
            return <div className="row loadingParent"><div className="loadingImg"></div></div>
        }
    }
}
export default PageContent