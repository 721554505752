import { t } from 'i18next';
import { ConfirmRegisterStateType } from '../types/ConfirmRegisterStateType';
import { useNavigate } from 'react-router-dom';
import Style from '../styles/Registration.module.scss'

const ConfirmRegisterView = (props: ConfirmRegisterStateType) => {
  const navigate = useNavigate();  
  return(
            <div className={Style.Registration}>
              <div className="register confirmRegister">
                    {props.brandUser  ? (
                    <div className="registerContainer unified_container">
                        <div className="registerRow">
                            <div className="register-col-lg-6">
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <img alt={props.brandUser} className="companyLogo" src={props.brandLogo} />
                                            <img src={require('../../../assets/icons/handshake.svg').default} style={{ marginTop: '20px', opacity: 0.4 }} />
                                        <div id="api" data-name="Unified">
                                            <div id="localAccountForm" className="localAccount" aria-label="Inicie sesión con su cuenta existente.">
                                                <div className="intro">
                                                        <span className="h2">{t('register.checkEmail')}</span>
                                                        <br/><br/>
                                                        <span className="t2">{t('register.checkSpam2')}</span>
                                                </div>
                                                <div className="entry">
                                                    <div className="buttons">
                                                        <button id="back" onClick={() => navigate(-1)}>{t('actions.back')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (<div className="row loadingParent">< div className="loadingImg"></div></div >)
                    }
                </div>
            </div>    
  ) 
};

export default ConfirmRegisterView;