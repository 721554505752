import { PureComponent } from 'react';
import { InstallationsAlertReportingContainerProps, InstallationsAlertReportingContainerState } from '../types/InstallationsAlertReportingTypes';
import AuthenticationContext from "../../../../../Authentication/types/AuthContextType"
import InstallationsAlertReportingView from '../view/InstallationsAlertReportingView';

class InstallationsGeneralMainChartsContainer extends PureComponent<InstallationsAlertReportingContainerProps, InstallationsAlertReportingContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: InstallationsAlertReportingContainerProps) {
    super(props);
    this.state = {
      loadHistoricNotifications: true
    };

    this.refreshNotifications = this.refreshNotifications.bind(this);
  }

  refreshNotifications() {
    this.setState({ loadHistoricNotifications: false }, () => { this.setState({ loadHistoricNotifications: true })});
  }

  render() {
    return (
      <>
        <InstallationsAlertReportingView
          loadHistoricNotifications={this.state.loadHistoricNotifications}
          installation={this.props.installation}
          refreshNotifications={this.refreshNotifications}
        ></InstallationsAlertReportingView>
      </>
    );
  }
}

export default InstallationsGeneralMainChartsContainer;
