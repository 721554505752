import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import GeneralNavMenuConfig from "../config/GeneralNavMenuConfig";
import {
  GeneralNavMenuContainerProps,
  GeneralNavMenuContainerState,
  GeneralNavMenuViewProps,
} from "../types/GeneralNavMenuType";
import GeneralNavMenu from "../view/GeneralNavMenu";
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { withRouter } from "../../withRouter";

export class GeneralNavMenuContainer extends Component<
  WithTranslation & GeneralNavMenuContainerProps,
  GeneralNavMenuContainerState
> {
  static contextType = AuthenticationContext;
  private generalNavMenuConfig: GeneralNavMenuConfig;

  constructor(props: WithTranslation & GeneralNavMenuContainerProps) {
    super(props);
    this.generalNavMenuConfig = new GeneralNavMenuConfig(this);

    this.state = {
      pointerType: "mouse",
      language: this.props.i18n.language,
      isLaufen: this.isLaufen(),
      collapsed: true,
      showModalAdvSearch: false,
      showDropdownSites: false,
      showDropdownInstallations: false,
      showDropdownProducts: false,
      myProducts: {content: []},
      mySites:  {content: []},
      myInstallations:  {content: []},
      myGateways:  {content: []},
      showMega1: false,
      showMega2: false,
      showMega3: false,
      showMega4: false,
      search: "",
      showSearch: true,
      userThumbnailPhoto: undefined,
      user: null,
      userMe: null,
      numReceived: (this.props.context.userPending?.invitations || 0) + (this.props.context.userPending?.requests || 0),
      numInvitationsReceived: this.props.context.userPending?.invitations || 0,
      numRequestsReceived: this.props.context.userPending?.requests || 0,
      showPreferences: false,
      wrapperRef: null,
    };
  }

  isLaufen() {
    return this.props.brandcode == 11 || this.props.brandcode == "11"
      ? true
      : false;
  }

  async componentDidMount() {
    await this.generalNavMenuConfig.getMyProducts();
    await this.generalNavMenuConfig.getMyInstallations();
    await this.generalNavMenuConfig.getMySites();
    await this.generalNavMenuConfig.getMyGateways();
    await this.generalNavMenuConfig.getGraphApiThumbnailPhoto();
    await this.generalNavMenuConfig.getGraphApiUser();
    await this.generalNavMenuConfig.emailBadge();
    await this.generalNavMenuConfig.getUserMe();
    this.props.context.refreshInvitationsAndRequestsPending();

    /*
      document.addEventListener("mousedown", this.generalNavMenuConfig.handleClickOutside);
      document.addEventListener("pointerdown", ({ pointerType, target }) => {
          if (pointerType === "mouse") this.pointerType = "mouse";
          if (pointerType === "touch") this.pointerType = "mouse";
      });
    */
  }

  componentDidUpdate(_prevProps: Readonly<WithTranslation<"translation", undefined> & GeneralNavMenuContainerProps>, prevState: Readonly<GeneralNavMenuContainerState>): void {
    if (this.props.context.userPending && this.props.context.userPending.invitations !== undefined && this.props.context.userPending.requests !== undefined) {
      if (prevState.numInvitationsReceived !== this.props.context.userPending.invitations || prevState.numRequestsReceived !== this.props.context.userPending.requests) {
         this.setState({
             numInvitationsReceived: this.props.context.userPending.invitations,
             numRequestsReceived: this.props.context.userPending.requests,
             numReceived: this.props.context.userPending.invitations + this.props.context.userPending.requests
         });
       }
    }  
    if (this.state.language !== this.props.i18n.language) {
      this.setState({ language: this.props.i18n.language });
    }
  }

  getGeneralNavMenuViewProps(): GeneralNavMenuViewProps {
    let generalNavMenuContainerProps: GeneralNavMenuViewProps = {
      user: this.state.user,
      language: this.state.language,
      isLaufen: this.state.isLaufen,
      collapsed: this.state.collapsed,
      setWrapperRef: this.generalNavMenuConfig.setWrapperRef,
      dropDownChange: this.generalNavMenuConfig.dropDownChange,
      showMenu: this.generalNavMenuConfig.showMenu,
      showDropdownSites: this.state.showDropdownSites,
      handleShowDropdownSites:
        this.generalNavMenuConfig.handleShowDropdownSites,
      numReceived: this.state.numReceived,
      numRequestsReceived: this.state.numRequestsReceived,
      numInvitationsReceived: this.state.numInvitationsReceived,
      userMe: this.state.userMe,
      changePassword: this.generalNavMenuConfig.changePassword,
      checkRoles: this.generalNavMenuConfig.checkRoles,
      checkRolesPowerBI: this.generalNavMenuConfig.checkRolesPowerBI,
      userThumbnailPhoto: this.state.userThumbnailPhoto,
      toggleNavbar: this.generalNavMenuConfig.toggleNavbar,
      sitesExists:
        this.state.mySites.content != undefined &&
        this.state.mySites.content.length > 0,
      installationsExists:
        this.state.myInstallations.content != undefined &&
        this.state.myInstallations.content.length > 0,
      productsExists:
        this.state.myProducts.content != undefined &&
        this.state.myProducts.content.length > 0,
      gatewaysExists:
        this.state.myGateways.content != undefined &&
        this.state.myGateways.content.length > 0,
      handleTouchStart: this.generalNavMenuConfig.handleTouchStart,
      urlsMenu: this.generalNavMenuConfig.getUrlMenus(),
      searchChange: this.generalNavMenuConfig.searchChange,
      searchHandleKeyDown: this.generalNavMenuConfig.searchHandleKeyDown,
      search: this.state.search,
      showSearch: !window.location.pathname.includes("generalFilter"),
      showSearchOnClick: this.generalNavMenuConfig.showSearchOnClick,
      display1: this.state.showMega1 === true ? "display-menu" : "",
      display2: this.state.showMega2 === true ? "display-menu" : "",
      display3: this.state.showMega3 === true ? "display-menu" : "",
      display4: this.state.showMega4 === true ? "display-menu" : "",
      hideSharedPreferences: this.generalNavMenuConfig.hideSharedPreferences,
      showPreferences: this.state.showPreferences,
      myProducts: this.state.myProducts,
      mySites: this.state.mySites,
      myInstallations: this.state.myInstallations,
      myGateways: this.state.myGateways,
      hover1: "",
      hover2: "",
      hover3: "",
      hover4: "",
    };

    let hover1 = this.state.showMega1 === true ? "hover-menu" : "";
    let hover2 = this.state.showMega2 === true ? "hover-menu" : "";
    let hover3 = this.state.showMega3 === true ? "hover-menu" : "";
    let hover4 = this.state.showMega4 === true ? "hover-menu" : "";

    generalNavMenuContainerProps.hover1 =
      generalNavMenuContainerProps.sitesExists
        ? hover1
        : hover1 + " disabledNavItem";
    generalNavMenuContainerProps.hover2 =
      generalNavMenuContainerProps.installationsExists
        ? hover2
        : hover2 + " disabledNavItem";
    generalNavMenuContainerProps.hover3 =
      generalNavMenuContainerProps.productsExists
        ? hover3
        : hover3 + " disabledNavItem";
    generalNavMenuContainerProps.hover4 =
      generalNavMenuContainerProps.gatewaysExists
        ? hover4
        : hover4 + " disabledNavItem";

    return generalNavMenuContainerProps;
  }

  render() {
    return (
      <GeneralNavMenu {...this.getGeneralNavMenuViewProps()}></GeneralNavMenu>
    );
  }
}

export default withTranslation()(withRouter(GeneralNavMenuContainer));
