import { Skeleton } from "@mui/material";
const ProductsTechnicalInformationComponentsSkeleton = () => {
  return (
    <div style={{ width: "100%", display: "table" }}>
        <div style={{ display: "flex", margin: "10px 0px" }}>
        <Skeleton variant="rectangular" width={150} height={45} />
      </div>
      <div style={{ width: "100%", margin: "25px 0px 10px 0px" }}>
        <Skeleton variant="rectangular" height={350} />
      </div>
      
      
    </div>
  );
};

export default ProductsTechnicalInformationComponentsSkeleton;
