import React, { Component } from 'react';
import '../../../../App.css';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";

export class ProductsTechnicalInformationComponents extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        const { t } = props;
        this.title = t("productspages.technical.components");
        this.componentInfo = [];
    }

    rendercomponent(key, value,i) {
        if (value == "") {
            return (null)
        }
        else {
            this.props.hasInfo(true);
            if (key == 'Created Date' || key == 'Updated Date' || key == 'Last Data') {
                return (
                    <div className="row" key={i}>
                        <div className="col menuSpan">
                            <h4 className="d-inline">{key}: </h4>
                            <span className="t3 d-inline">
                                <Moment format="D MMM YYYY" withTitle locale="es">{value}</Moment>
                            </span>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="row" key={i}>
                        <div className="col menuSpan">
                            <h4 className="d-inline">{key}: </h4>
                            <span className="t3 d-inline">{value}</span>
                        </div>
                    </div>
                )
            }
        }
    }

    render() {

        let dataLoaded = this.props.product.content != undefined && this.props.product.content.smartComponents.length > 0;
        
        let listitems = [];
        //this.componentInfo = [];
        if (dataLoaded)
            listitems = this.props.product.content.smartComponents.map((item, i) => {
                let fec_update, fec_create;
                //fec_update = (item.updateAt != undefined ? moment(item.updateAt) : "-----------");
                //fec_create = (item.createAt != undefined ? moment(item.createAt) : "-----------");
                let component = {
                    title: "Address: "+ item.address,
                    information: [
                        { key: "Component Status", value: (item.smartComponentType.status != undefined ? item.smartComponentType.status : "") },
                        { key: "Component Name", value: (item.smartComponentType.name != undefined ? item.smartComponentType.name : "") },
                        { key: "Reference", value: item.uuid },
                        { key: "Serial Number", value: (item.serialNumber != undefined ? item.serialNumber : "") },
                        { key: "Created Date", value: (item.createAt != undefined ? item.createAt : "") },
                        { key: "Updated Date", value: (item.updateAt != undefined ? item.updateAt : "") },
                        { key: "Last Data", value: (item.lastData != undefined ? item.lastData : "") },
                        { key: "Hardware Version", value: (item.stringHardwareVersion || item.hardwareVersion || "") },
                        { key: "Software Version", value: (item.stringSoftwareVersion || item.softwareVersion || "") },
                    ]
                };

                return component;
            })

        return (
            <div>
                <div className="row">
                    <div className="prodChildNoZoom col">
                    
                        <div className="row">
                            {listitems.map((item, p) => (
                            <div className="col-3" key={p}>
                                <div className="table">
                                    <div className="col table-cell">
                                        <div className="row" >
                                            <h3 className="col"> {item.title}</h3>
                                        </div>
                                        {item.information.map((info, i) => (
                                            this.rendercomponent(info.key, info.value, i)
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ProductsTechnicalInformationComponents);