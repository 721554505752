import React, { Component } from 'react';
import '../../../../App.css';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import HttpClient from '../../../../HttpClient/ProductHttpClient';

import moment from 'moment';
import Button from '@mui/material/Button';
import DateSelector from '../../../CustomComponents/CustomDateSelector';

export class ProductsThermalDisinfection extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        const { t } = props;
        this.title = t("productspages.technical.thermaldisinfection");
        this.state = {
            isVisible: false,
            dateRange: this.props.dateRange,
            groupBySelected: this.props.groupBySelected
        };
    }
    groupBySelected = null;

    componentDidMount() {
        if (this.props.product.content != undefined) {
            if (this.props.product.content.productType?.smartComponentTypeId == '6') {
                this.setState({ isVisible: true });
                this.props.hasInfo(true);
            }
        }
        

        //Check if product is showertronic
    }
    handleGranularityChange(value) {
        this.groupBySelected = value;
    }
    handleDateRangeChange(value) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, this.groupBySelected);
        }
        this.setState({ dateRange: value, groupBySelected: this.groupBySelected });
    }

    getTimeZone = () => {
        let timeZoneOffset = this.props?.product?.content?.installation?.parentTimeZone?.timeZoneOffset
        return timeZoneOffset ? timeZoneOffset: this.props?.installation?.parentTimeZone?.timeZoneOffset
    }

    async GetData() {

        let dateRange = this.state.dateRange;
        let endTimeStamp = moment(dateRange[1]).format("YYYY-MM-DDT23:59:59");
        let startTimeStamp = moment(dateRange[0]).format("YYYY-MM-DDT00:00:00");
        let brand = this.context.brandCode == 11 ? "laufen" : "roca";

        this.http = new HttpClient(this.context, this.props.product.content.id);
        const timeZone = this.getTimeZone();
        let data = await this.http.ThermalDisinfectionCertificate(startTimeStamp, endTimeStamp, brand, false, timeZone);

        window.open(data.content.uri, "_blank", 'noreferrer');
    }

    render() {
        const handleOnClick = () => {
            this.GetData();
        };
        const { t } = this.props;
        return this.state.isVisible ? (
            <div>
                <div className="row mb-5">
                    <div className="technicalInfo col">
                        <div style={{display:"flex"}}>
                            <h2 className="col p-0 m-0" style={{alignSelf:"center"}}> {this.title} </h2>

                            <DateSelector
                                style={{ justifyContent: "right" }}
                                className={"col w-100 d-flex"}
                                defaultGranularity={this.props.groupBySelected == undefined ? "week" : this.props.groupBySelected }
                                onDateChange={this.handleDateRangeChange.bind(this)}
                                onGranularityChange={this.handleGranularityChange.bind(this)}
                                timeframes={['custom','day', 'week', 'month', 'year']}
                                defaultDate={this.props.dateRange}
                            />
                        </div>

                        
                       
                        <div className="col-12 col-md-4 col-xl-3 mt-3">

                            <Button
                                className="primary paramButton"
                                variant="contained"
                                color="primary"
                                onClick={handleOnClick}
                            >
                                <img src={require('../../../../assets/icons/legionella.svg').default} className="legionella-icon" />
                                {t('productspages.summary.legionella')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>


        ) : null;
        
    }
}
export default withTranslation()(ProductsThermalDisinfection)