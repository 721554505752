import { PureComponent } from "react";
import {
  InstallationsSummaryContainerProps,
  InstallationsSummaryContainerState,
} from "../types/InstallationsSummaryTypes";
import InstallationsSummaryView from "../view/InstallationsSummaryView";
import AuthenticationContext, {
  AuthContextType,
} from "../../../../../Authentication/types/AuthContextType";

class InstallationsSummaryContainer extends PureComponent<
  InstallationsSummaryContainerProps,
  InstallationsSummaryContainerState
> {
  static contextType = AuthenticationContext;
  id;

  constructor(props: InstallationsSummaryContainerProps) {
    super(props);
    this.id = parseInt(window.location.pathname.split("/")[2]);

    this.state = {
    };
  }

  componentDidUpdate(prevProps) {
    let idInstallation = parseInt(window.location.pathname.split("/")[2]);

    if (idInstallation != this.id || this.props.refresh) {
      this.id = idInstallation;
      this.props.onRefresh();
      //this.getNotifications();
    }
  }

  handleClick = () => {
    if (typeof this.props.buttonClick === "function") {
      this.props.buttonClick();
    }
  };

  getOnlineProducts() {
    let aggregatedInfo = {};
    if (this.props.installation)
      aggregatedInfo = this.props.installation.aggregatedInfo
        ? this.props.installation.aggregatedInfo
        : {};

    if (aggregatedInfo) {
      if (aggregatedInfo["deviceStatus"] !== undefined) {
        if (aggregatedInfo["deviceStatus"]["Online"] !== undefined)
          return aggregatedInfo["deviceStatus"]["Online"];
      }
    }

    return 0;
  }

  getAppProducts() {
    let aggregatedInfo = {};
    if (this.props.installation)
      aggregatedInfo = this.props.installation.aggregatedInfo
        ? this.props.installation.aggregatedInfo
        : {};

    if (aggregatedInfo) {
      if (aggregatedInfo["deviceStatus"] !== undefined) {
        if (aggregatedInfo["deviceStatus"]["App"] !== undefined)
          return aggregatedInfo["deviceStatus"]["App"];
      }
    }

    return 0;
  }

  getDisconnectedProducts() {
    let aggregatedInfo = {};
    if (this.props.installation)
      aggregatedInfo = this.props.installation.aggregatedInfo
        ? this.props.installation.aggregatedInfo
        : {};

    let disconnectedProducts = 0;
    if (aggregatedInfo) {
      if (aggregatedInfo["deviceStatus"] !== undefined) {
        if (aggregatedInfo["deviceStatus"]["Offline"] !== undefined)
          disconnectedProducts +=
            aggregatedInfo["deviceStatus"]["Offline"];
        if (aggregatedInfo["deviceStatus"]["Disconnected"] !== undefined)
          disconnectedProducts +=
            aggregatedInfo["deviceStatus"]["Disconnected"];
      }
    }

    return disconnectedProducts;
  }

  getAddress() {
    let address = "";
    if (this.props.installation) {
      if (
        this.props.installation.address &&
        this.props.installation.address != ""
      ) {
        address += this.props.installation.address;
      }
      if (
        this.props.installation.postalCode &&
        this.props.installation.postalCode != ""
      ) {
        if (address.length > 1) address += " - ";
        address += this.props.installation.postalCode;
      }
      if (this.props.installation.city && this.props.installation.city != "") {
        if (address.length > 1) address += ", ";
        address += this.props.installation.city;
      }
      if (
        this.props.installation.country &&
        this.props.installation.country != ""
      ) {
        if (address.length > 1) address += ", ";
        address += this.props.installation.country;
      }
    }

    return address
  }

  render() {
    return (
      <>
        <InstallationsSummaryView
          installation={this.props.installation}
          isLaufen={(this.context as AuthContextType).brandCode == "11"}
          handleClick={this.handleClick.bind(this)}
          onlineProducts={this.getOnlineProducts()}
          appProducts={this.getAppProducts()}
          disconnectedProducts={this.getDisconnectedProducts()}
          aggregatedInfo={
            this.props.installation.aggregatedInfo
              ? this.props.installation.aggregatedInfo
              : {}
          }
          address={this.getAddress()}
          notifications={this.props.notifications}
        ></InstallationsSummaryView>
      </>
    );
  }
}

export default InstallationsSummaryContainer;
