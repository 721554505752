import React, { Component } from 'react';
import '../App.css';
import NavFooter from './NavFooter';
import AuthenticationContext from "../Authentication/types/AuthContextType";
import LoginButton from './LoginButton';
import PageContent from './PageContent';
import BreadCrumb from './BreadCrumb';
import { Link } from 'react-router-dom';
import { withRouter } from './withRouter';
import { withTranslation } from 'react-i18next';

export class PageNoAccess extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state={
            language: this.props.i18n.language
        }

        let isLaufenUser = window.location.href.includes("laufen") || window.location.href.includes("localhost");

        if (isLaufenUser) {
            this.brand = 11;
        } else {
            this.brand = 14;
        }

        this.pageId = window.location.href.split("/").pop()

        this.match = {
            params: {
                brandId: this.brand,
                culture: this.state.language,
                pageId: this.pageId     
            }
        }
    }

    componentDidUpdate() {
        if (this.state.language !== this.props.i18n.language) {
            this.setState({ language: this.props.i18n.language });
        }
    }

    render() {

        let isLaufen = this.brand == 11;
        let logoUrl = isLaufen ? "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_laufen_standard.png" : "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo-roca-cabecera.png"

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div>
                    <header className="headerFixed" >
                        <nav className="headerSpace pt-2  navbar navbar-light" style={{ paddingLeft: '60px', height: '90px' }}>
                            <Link tag={Link} to="/"> <img src={logoUrl} /> </Link>
                            <LoginButton redirect={true} className="float-right " />
                        </nav>
                    </header>
                    <BreadCrumb empty={true} pageNoAccess={true}></BreadCrumb>
                    
                    <div style={{minHeight: '100vh', marginTop: "15px"}}>
                        <PageContent match={this.match} />
                    </div>
                    <NavFooter brandCode={this.brand} style={{ clear: 'both', position: 'relative', height: '200px', marginTop: '-200px' }} />
                </div>
            </div>
        );
    }
}
export default withTranslation()(withRouter(PageNoAccess, [{ path: '/page' }, { path: '/page/:culture/:brandId/:pageId' }]));
