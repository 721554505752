import axios from "axios";

export default class UserHttpClient {

    constructor(context) {
        this.context = context;
        this.debug = false
    }


    Register = async (brand, email, redirect, redirectApp, timezone,language) => {
        console.log(`inside Register FN`);        
        try {

            let element = {
                brand: brand,
                email: email,
                redirect: redirect,
                redirectApp: redirectApp,
                timezone: timezone,
                language: language

            }
            console.log('element');
            console.log(element);
            var response = await axios.post("users/register", element);
            console.log('response --> users/register')
            console.log(`response: ${JSON.stringify(response)}`);
            if (this.debug) {
                console.log("calling: " + "users/register/" + brand + "/" + email + "/" + redirect);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch (e) {
            console.log("response error");
            console.log(e);
            return e.response;
        }
    }

    GetNotificationPreferences = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("users/" + this.language + "/settings-notifications", config);
        if (this.debug) {
            console.log("users/" + this.language + "/settings-notifications");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }


    PostNotificationPreferences = async (settings) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.post("users/" + this.language + "/settings-notifications", settings, config);
        if (this.debug) {
            console.log("users/" + this.language + "/settings-notifications");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PostMeasureUnits = async (userId, settings) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.post(`users/en/${userId}/measure-units`, settings, config);
        if (this.debug) {
            console.log(`users/${this.language}/measure-units`);
            console.log(`response: ${JSON.stringify(response)}`);
        }
        return response.data;
    }
    
}