import React from "react";
import { InstallationUserEditViewProps } from "../types/InstallationUserEditTypes";
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import { t } from "i18next";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const InstallationUserEditView = (props: InstallationUserEditViewProps) => {
    return (
        <React.Fragment>
        {props.dataLoaded ? (
          <div>
            <Dialog disableEscapeKeyDown open={props.showModalError} onClose={props.handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{props.errorMessage}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={props.handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={props.showModalDelete} onClose={props.handleClose}>
              <DialogTitle>{t('actions.deletename', { name: t('product') })}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('actions.confirmdelete', { name: t('product') })}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={props.confirmDelete}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={props.handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <form id='divForm' onSubmit={(e) => props.saveData(e)}>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <TextField
                    variant='standard'
                    id='txtNombre'
                    label='Nombre'
                    defaultValue={props.mail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className='form-group col-md-6'>{dropdownRoles(props)}</div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <Button type='submit' className='primary'>
                    {t('actions.save')}
                  </Button>
                </div>

                <div className='form-group col-md-6'>
                  <Button className='primary pull-right' onClick={props.handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>

                <div className='form-group col-md-12'>
                  {props.saving ? (
                    <div className='loadingParent'>
                      <div className='loadingImg'></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  };

  
  const dropdownRoles = (props: InstallationUserEditViewProps) =>{

    let dataLoaded = props.roles != undefined && props.roles.length > 0;
    let listitems = null;
    if (dataLoaded) {
      listitems = props.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='roleLabel'>{t('invitation.role')}</InputLabel>

          {/* TODO: When this select gets implemented, the setCustomValidity has to be parched/fixed. Check task #6103 in Dev-Ops for more information.*/}
          <Select
            inputProps={{
              onInvalid: (event) => {
                (event.target  as any).setCustomValidity(t('general.error_required'));
              },
              onInput: (event) => {
                (event.target as any).setCustomValidity(t('general.error_required'));
              },
            }}
            variant='standard'
            name='Role'
            className='titleProperty'
            labelId='roleLabel'
            id='ddlRoles'
            label='Roles'
            type='email'
            value={props.roleId != null ? props.roleId : ''}
            required
            onChange={props.handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }

  
  export default InstallationUserEditView;