import React, { Component } from 'react';
import '../../../../App.css';
import './ActiveNotificationsCards.css';
import AlertHttpClient from "../../../../HttpClient/AlertHttpClient";
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import CheckCircle from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Tooltip from "@mui/material/Tooltip";
import ActiveNotificationsCardsSkeleton from './ActiveNotificationsCardsSkeleton';

export class ActiveNotificationsCards extends Component {

    static contextType = AuthenticationContext;


    constructor(props) {
        super(props);
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.getNotifications();
    }

    async getNotifications() {
        try {
            
            this.http = new AlertHttpClient(this.context, this.id, null, null, null, 0, 10);
            let data;
            if (this.props.isSite) {
                data = await this.http.GetProducedIndividualRulesTotalsBySite();
            } else {
                data = await this.http.GetProducedIndividualRulesTotals();
            }

            if (data && data.content && data.content.length != 0) {
                let notifications = data.content;
                this.setState({ notifications: notifications, loading: false })
            }
            else {
                this.setState({ noAlerts : true, loading: false })
            }
        } catch (err) {
            console.error(err);
            this.setState({ noAlerts: true })
        }

    }


    bodyNotification( pType,t) {

        if (pType.toLowerCase() !== "alarm" && pType.toLowerCase() !== "warning" && pType.toLowerCase() !== "rule") {
            return "";
        }
        let data = this.state.notifications != undefined ? this.state.notifications.find(o => o.type === pType.toLowerCase()) : null;
        if (data == null) {
            data = { total: 0, ack: 0, unack: 0 };
        }
        let cnt = data.total != null ? data.total : 0;
        let ack = data.ack != null ? data.ack : 0;
        let unack = data.unack != null ? data.unack : 0;
        let icon = "@type_icon.svg".replace("@type", pType.toLowerCase());
        let color = pType.toLowerCase() == "alarm" ? "white" : "black";
        let name = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };


        return(<div className={"col-12 p-0 primary " + pType.toLowerCase()  + " " + color}>
            <div className="row m-0">
                <div className="col-7 separator ">
                    <div className="row h-100">
                        <div className="col-5 align-self-center text-center">
                            <img src={require('../../../../assets/icons/' + icon)} className="img-fluid" />
                        </div>
                        <div className="col-7 align-self-center apl-5" style={{textAlign: "center" }}>
                            <h3 className="t-number big d-block"> {cnt} </h3><h3 className="d-block">{name[pType]}</h3>
                        </div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="col-5 ">
                    <div className="row h-100 p-md-3 p-xl-4 p-sm-4">
                        <div className="col-6 align-self-center" style={{ marginTop: "5px", textAlign: "center" }}>
                            <h6 className="t-number d-block" style={{ minWidth: "24px" }}> {ack} </h6><h6 className="m-0" >
                                <Tooltip title={t("productspages.alertreporting.ack")}>
                                        <CheckCircle></CheckCircle>
                                </Tooltip>
                            </h6>
                        </div>
                        <div className="col-6 align-self-center" style={{ marginTop: "5px", textAlign: "center"}}>
                            <h6 className="t-number d-block" style={{minWidth: "24px"}}>  {unack} </h6><h6 className="m-0" >
                                <Tooltip title={t("productspages.alertreporting.unack")}>
                                    <ScheduleIcon></ScheduleIcon>
                                </Tooltip>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>);


    }
    
    buildActiveNotificationCards() {

        const t = this.props.t

        let listItems = [];
        if (this.state.loading) {
            listItems = [
                <ActiveNotificationsCardsSkeleton></ActiveNotificationsCardsSkeleton>
            ]
        }
        else{
            listItems.push(
                <div className="container alerts container-scroll p-0">
                    <div className="row m-0">
                        <div className="col-12 col-md-4 primary-parent pb-sm-2 p-1">
                            {this.bodyNotification("alarm",t)}
                        </div>
                        <div className="col-12 col-md-4 primary-parent pb-sm-2 p-1">
                            {this.bodyNotification("warning",t)}
                        </div>
                        <div className="col-12 col-md-4 primary-parent  p-1">
                            {this.bodyNotification("rule",t)}
                        </div>
                    </div>
                </div>
            );

        }
        
        return listItems[0];
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {
                        this.buildActiveNotificationCards()
                    }
                </div>
            </React.Fragment>
        );
        
    }

} export default withTranslation()(ActiveNotificationsCards)