import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import AuthenticationContext from "../Authentication/types/AuthContextType";

class NoConfiguration extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
    }

    render() {

        const { t } = this.props;
        let style = {minHeight:"150px", display:"flex", justifyContent:"center"}
        if (this.props.propStyle)
            style += this.props.propStyle

        return (
            <div style={style} >
               
                <div style={{alignSelf:"center"}}>
                    <ReportProblemOutlinedIcon style={{ margin: '0 auto', display: 'block'}} fontSize="large"/>
                    <div> <h4 style={{textAlign: 'center'}}>{t('noconfiguration')}</h4></div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(NoConfiguration)