import React, { Component, useContext } from 'react';
import '../../../../App.css';
import './ActiveNotificationsCards.css';
import AlertHttpClient from "../../../../HttpClient/AlertHttpClient";
import ProductHttpClient from "../../../../HttpClient/ProductHttpClient";
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import moment from 'moment';
import Moment from 'react-moment';
import SelectSearch, { AriaOnFocus } from 'react-select'
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { withRouter } from '../../../withRouter';
import Table from '../../../CustomComponents/ReactTable/Table';
import TableSkeleton from '../../../CustomComponents/ReactTable/TableSkeleton';

class ActiveNotificationsList extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.id != undefined && this.props.location.pathname.split('/').length > 1)
            this.id = this.props.match.params.id == undefined ? parseInt(this.props.location.pathname.split('/')[2]) : this.props.match.params.id;
        else {
            if (window.location.pathname.split('/').length > 0)
                this.id = parseInt(window.location.pathname.split('/')[2]);
        }
        if (this.props.isSite && this.props.installation != undefined) {
            this.id = this.props.installation.id;
        }
        
        this.filterDefault = function () {
            return (
                {
                    all: true,
                    activeAlarms: false,
                    activeWarnings: false,
                    activeRules: false,
                    unacked: false,
                    KeyProduct: null
                }
            );
        };
        let filter = this.filterDefault();
        try {
            let localFilter = null;
            localFilter = localStorage.getItem("ActiveNotificationsListFilter");
            if (localFilter != undefined && localFilter != null) {
                filter = JSON.parse(localFilter);
            }
        } catch (err) {

        }
       
        
        this.state = {
            _data: {},
            page: 1,
            sizePerPage: 5,
            totalSize: 0,
            allData: [],
            filter: filter,
            selectedValue:null
        };

        this.paginaActual = this.getCurrentPage();
        this.state.sizePerPage = this.getCurrentSizePerPage();

        this.products = [];

        const { t } = props

        this.columns = [
            {
                dataField: 'id',
                text: 'id',
                align: 'left',
                sort: true,
                hidden: true,
                headerStyle: () => {
                    return { width: "0%" };
                }
            },
            {
                dataField: 'individualRule.transactionalProduct',
                text: t('productspages.alertreporting.device'),
                classes: 'h-1',
                formatter: this.ProductFormatter.bind(this),
                hidden: this.props.isSite ? false : true,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: this.props.isSite ? 'individualRule.description' : 'individualRule.description-hidden',
                text: t('tables.notification'),
                classes: 'h-1',
                formatter: this.DescriptionFormatter.bind(this),
                hidden: this.props.isSite ? false : true,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'individualRule.name',
                text: t('tables.notification'),
                hidden: this.props.isSite ? true : false,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "22%" };
                }
            },

            {
                dataField: this.props.isSite ? 'individualRule.description-hidden' : 'individualRule.description',
                text: t('tables.description'),
                hidden: this.props.isSite ? true : false,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "33%" };
                }
            }, 

            {
                dataField: 'individualRule.type',
                text: t('tables.type'),
                align: 'left',
                sort: true,
                hidden: false,
                formatter: this.TypeFormatter.bind(this),
                headerStyle: () => {
                    return { width: (this.props.isSite ? "8%" : "12%") };
                    //return { width: '13%' };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            }, {
                dataField: 'timestamp',
                text: t('tables.creationDate'),
                align: 'left',
                sort: true,
                formatter: this.DateFormatterTimestamp.bind(this),
                headerStyle: () => {
                    return { width: "16%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            }, {
                dataField: 'checkedUser',
                text: t("productspages.alertreporting.ack"),
                align: 'left',
                sort: true,
                formatter: this.priceFormatter.bind(this),
                headerStyle: () => {
                    return { width: "14%" };
                }
            }
        ];

        this.customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total" style={{ marginLeft: '5px' }}>
                {t('tables.customTotal', { from: from, to: to, size: size })}
            </span>
        );

        this.defaultSorted = [{
            dataField: 'timestamp',
            order: 'desc'
        }];

    }

    getCurrentPage() {
        let currentPage = 1
        try {
            currentPage = JSON.parse(localStorage.getItem('currentPageActive')) || 1;
        }
        catch (e) {
            currentPage = 1;
        }
        return parseInt(currentPage);
    }
    getCurrentSizePerPage() {
        let currentSizePerPage = -1
        try {
            currentSizePerPage = JSON.parse(localStorage.getItem('sizePerPageActive'));
            if(currentSizePerPage == null || (currentSizePerPage > 0 && currentSizePerPage < 5)){
                currentSizePerPage = 5
            }
        }
        catch(e){
            currentSizePerPage = 5;
        }
        return parseInt(currentSizePerPage);
    }

    componentDidMount() {
        this.getProductHistoricalAlerts(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter, true);
    }

    filterChange(option,value=null) {
        let filter = null;

        if (option == "all") {
            filter = this.filterDefault();
            this.setState({ selectedValue: null });
            filter.all = true;
        }
        else {
            filter = this.state.filter;
            filter.all = false;
            if (value != null) {
                filter[option] = value == ''? null : value;
            } else {
                let valOption = !filter[option];
                filter[option] = valOption;
            }
            
        }

        if (!filter.all && !filter.activeAlarms && !filter.activeWarnings && !filter.activeRules && !filter.unacked && filter.KeyProduct == null ) {
            filter.all = true;
        }
        localStorage.setItem("ActiveNotificationsListFilter", JSON.stringify(filter));
        this.setState({ filter: filter });
        this.getProductHistoricalAlerts(this.getCurrentPage(), this.getCurrentSizePerPage(),filter);
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        const currentIndex = (page - 1) * sizePerPage;
        this.getProductHistoricalAlerts(page,sizePerPage,this.state.filter);
    }

    handleTableChanged(page, sizePerPage) {
        return this.getProductHistoricalAlerts(page, sizePerPage, this.state.filter);
    }

    selectUniqueDeviceTypes(arr) {
        let valors = [...new Map(arr.map(item =>
            [item["keyProduct"], item])).values()];
        let result = [];
        valors.forEach(producto => {
            result.push({ name: producto.transactionalProduct.description, puuid: producto.transactionalProduct.puuid, value: producto.keyProduct, label: producto.transactionalProduct.description });
        })

        return result;
    }

    async getProductHistoricalAlerts(page, sizePerPage,filter, firstLoad = false) {
        
        this.setState({ dataLoaded: false });
        this.http = new AlertHttpClient(this.context, this.id, null, null, null, page, sizePerPage);
        let data = '';
        if (this.props.isSite) {
            data = await this.http.GetProducedIndividualRulesBySite(filter);
        } else {
            data = await this.http.GetProducedIndividualRules(filter);
        }
        const dataWithTranslation = this.translateData(data);
        this.setState({ _data: dataWithTranslation, dataLoaded: true, totalSize: data.pagination.totalItems, page: page, sizePerPage: sizePerPage, filter: filter});
        if (firstLoad) {
            this.setState({ allData: data.content });
        }
        return data.pagination.totalItems;
    }


    getTranslationIfExist = (keyTrans) => {
        return keyTrans !== this.props.t(`alerts:${keyTrans}`);
      };
      
      translateData = (data) => {
        if (data && data.content && data.content.length) {
          data.content.forEach(row => {
            if (row.individualRule) {
              const { smartComponentTypeId, businessLabel } = row.individualRule;
              if (smartComponentTypeId && businessLabel) {
                row.individualRule.name = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.name`)
                  ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.name`)
                  : row.individualRule.name;
      
                row.individualRule.description = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.description`)
                  ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.description`)
                  : row.individualRule.description;
              } else {
                console.log(row); // Fila con campos faltantes
              }
            }
          });
        }
        return data;
      };
    
    DateFormatter({ row, value }) {
        if (row) {
            if (row.timeActive == null) {
                return (<span></span>);
            }
            else {
                return (<span>{moment(row.timeActive).format("HH:mm:ss")}</span>);
            }
        }
    }

    DateFormatterTimestamp({ row, value }) {
        if (row) {
            if (row.timestamp == null) {
                return (<span></span>);
            }
            else {
                return (<span>{moment(row.timestamp).format("DD/MM/YYYY HH:mm")}</span>);
            }
        }

    }
    DescriptionFormatter({ row, value }) {
        if (row) {
            if (!this.props.isSite) {
                return row.individualRule.description;
            }
            let content = (
                <div className="t-container">
                    <div className="box t2 bold">{row.individualRule.name}</div>
                    <div className="box t2">{row.individualRule.description}</div>
                </div>
            );
            return content;
        }

    }

    ProductFormatter({ row, value }) {
        if (row) {
            let content = (

                <Link tag={Link} to={`/product/${row.transactionalProduct?.id}`} style={{ textDecoration: "none" }}>
                    <div className="d-flex h-100">
                        <div style={{ width: "50px", minWidth: "50px", alignSelf: "center", position: "relative" }}>
                            <img src={row.transactionalProduct?.productType?.imageUri} style={{ width: "100%" }} />
                            <div className={"dotState " + row.transactionalProduct?.connectionState.toLowerCase()} style={{ position: "absolute", top: "5px", left: "-5px" }} ></div>
                        </div>
                        <div className="t-container ml-2">
                            <h4 className="box m-0" style={{ fontWeith: 'bold!important' }}>{row.transactionalProduct?.name}</h4>
                            <div className="box TreeDetailProduct m-0">{row.transactionalProduct?.puuid}</div>
                        </div>
                    </div>
                </Link>

            );
            return content;
        }
    }

    
    
                
    priceFormatter({ row, value }) {
        if (row) {
            const onChange = (evt) => {

                let datos = postState(evt.target.id.split('_')[1], evt.target.checked);
            }
            const postState = (id, checked) => {

                this.http = new ProductHttpClient(this.context, row.transactionalId, null, null);
                let datos = this.http.IndividualRuleChecked(id, checked);

                let dades = setTimeout(() => {
                    this.getProductHistoricalAlerts(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter);

                    if (typeof this.props.refreshNotifications === "function") {
                        this.props.refreshNotifications()
                    }
                }, 600);

                this.setState({ _data: dades });
            }

            let id = "custom-switch_" + row.id;
            return (
                <Form>
                    <Form.Check

                        type="checkbox"
                        id={id}
                        label=""
                        defaultChecked={row.checkedUser}
                        onChange={(evt) => onChange(evt, this.context)}
                        disabled={row.checkedUser}
                    />
                </Form>

            );
        }
    }

    handleChange(evt) {
        this.setState({ checkboxChecked: evt.target.checked });
    }

    TypeFormatter({ row, value }) {

        if (row) {
            if (row.individualRule.type != undefined && row.individualRule.type != null) {
                let iType = row.individualRule.type.toLowerCase();
                const { t } = this.props;
                let rType = "";
                let tType = "";
                if (iType.includes("warning")) {
                    rType = "warning";
                    tType = t("productspages.alertreporting.warning");
                } else if (iType.includes("alarm")) {
                    rType = "alarm";
                    tType = t("productspages.alertreporting.alarm");
                } else if (iType.includes("parameter") || iType.includes("rule")) {
                    rType = "rule";
                    tType = t("productspages.alertreporting.rule");
                }

                return (<span className={rType + " custom-button-span"}>{tType != "" ? tType : row.individualRule.type}</span>);
            } else {
                return (<span className={" custom-button-span"}></span>);
            }
        }
    }

    onSizePerPageChange (sizePerPage) {
        localStorage.setItem('sizePerPageActive', sizePerPage);
        this.setState({ sizePerPage: sizePerPage }, () => console.log("this.getCurrentSizePerPage()" + this.getCurrentSizePerPage()));
    }

    onPageChange (newPage) {
        this.paginaActual = newPage;
        localStorage.setItem('currentPageActive', newPage);
        this.setState({ page: newPage });
    }

    render() {
        //const { SearchBar } = Search;
        const { t } = this.props;

        const handleProductChange = (event) => {

            this.setState({ selectedValue: event });
            if (event != null) {
                this.filterChange("KeyProduct", event.value);
            } else {
                this.filterChange("KeyProduct", '');
            }
            
            
        };

        if (this.state.dataLoaded) {
            const pageButtonRenderer = ({
                page,
                active,
                disable,
                title,
                onPageChange
            }) => {
                const handleClick = (e) => {

                    e.preventDefault();
                    onPageChange(page);
                };
                const activeStyle = {};
                const isActive = active ? "active" : "";
                if (active) {
                    activeStyle.margin = '5px';
                } else {
                    activeStyle.margin = '5px';
                }
                if (typeof page === 'string') {
                    activeStyle.margin = '5px';
                }


                return (
                    <li className={"page-item paginate_button " + isActive}>
                        <a href="#" className="page-link" onClick={handleClick} style={activeStyle}>{page}</a>
                    </li>
                );
            };

            this.options = {
                pageButtonRenderer,
                page: this.paginaActual,
                paginationSize: this.getCurrentSizePerPage(),
                pageStartIndex: 1,
                sizePerPage: this.getCurrentSizePerPage(),
                hidePageListOnlyOnePage: true,
                showTotal: true,
                paginationTotalRenderer: this.customTotal,
                disablePageTitle: true,
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: t('tables.all'), value: this.state.totalSize
                }],
                onSizePerPageChange: (sizePerPage, page) => {
                    localStorage.setItem('sizePerPageActive', sizePerPage);
                    this.setState({ sizePerPage: sizePerPage }, () => console.log("this.getCurrentSizePerPage()" + this.getCurrentSizePerPage()));
                },
                onPageChange: (newPage) => {
                    this.paginaActual = newPage;
                    localStorage.setItem('currentPageActive', newPage);
                    this.setState({ page: newPage });
                },
            };
        }
        const OptionLayout = props => {
            const { innerProps, innerRef } = props;
            return (
                <article ref={innerRef} {...innerProps} className={'custom-option ' + (props.isSelected ? "selected":"")}>
                    <div className={"sub"}>{props.data.name}</div>
                    <h6 className={'desc'}>{(props.data.puuid)}</h6>
                </article>
            );
        };

        const filter = (<React.Fragment>
            <h6 className="d-inline-block mr-3 mb-0 col-auto pl-0" >{t('tables.filter')}:</h6>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('all')} id="notifications-filter-all" defaultChecked={this.state.filter.all ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-all">{t("tables.all")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('activeAlarms')} id="notifications-filter-alarms" defaultChecked={this.state.filter.activeAlarms ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-alarms">{t("productspages.alertreporting.alarms")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('activeWarnings')} id="notifications-filter-warnings" defaultChecked={this.state.filter.activeWarnings ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-warnings">{t("productspages.alertreporting.warnings")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('activeRules')} id="notifications-filter-rules" defaultChecked={this.state.filter.activeRules ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-rules">{t("productspages.alertreporting.rules")}</label>
            <div className="separator-small mr-2 mb-0"></div>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('unacked')} id="notifications-filter-unacked" autoComplete="off" defaultChecked={this.state.filter.unacked ? "checked" : ""} />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-unacked">{t("productspages.alertreporting.unack")}</label>
                                    {this.props.isSite ? <div className="separator-small mr-2 mb-0"></div> : <div className="d-none"></div>}

            {this.props.isSite ?

                <SelectSearch
                    options={this.selectUniqueDeviceTypes(this.state.allData)}
                    search
                    value={this.state.selectedValue}
                    className="device-custom-select-control"
                    emptyMessage="Not found"
                    isClearable
                    placeholder={t("productspages.alertreporting.device") + "..."}
                    onChange={handleProductChange}
                    components={{ Option: OptionLayout }}
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            minHeight: '32px',
                            height: '32px',
                            borderRadius: '3px !important'
                        }),

                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: '30px',
                            padding: '0 6px'
                        }),

                        input: (provided, state) => ({
                            ...provided,
                            margin: '0px',
                        }),
                        indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: '32px',
                        }),
                    }}
                />


                : <div className="d-none"></div>
            }


        </React.Fragment>);
        return (
            <div>
                <div className="card-container p-3">
                        {this.state.dataLoaded ?<Table name={"ActiveNotificationList-locales" + (this.props.isSite ? '-site' : '')}  t={t} resize={true} columns={this.columns} rows={this.state._data.content} filter={filter} search={false} page={this.getCurrentPage()} pageSize={this.getCurrentSizePerPage()}  onSizePerPageChange={this.onSizePerPageChange.bind(this)} onPageChange={this.onPageChange.bind(this)} /> : <TableSkeleton />} {/*pageChanged={this.handleTableChanged.bind(this)}*/}
                    </div>
            </div>
            
        );
    }
}

export default withTranslation()(withRouter(ActiveNotificationsList, [{ path: '/product/:id' }]));