import { PureComponent } from 'react';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import LandingView from '../views/LandingView';
import { LandingStateType, LandingPropsType } from '../types/LandingTypes';
import Loader from '../../Common/Loader';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';

class LandingContainer extends PureComponent<LandingPropsType, LandingStateType> {
  static contextType = AuthenticationContext;

  constructor(props: LandingPropsType) {
    super(props);
    this.state = {
      logoUrl: '',
      brandCode: 0,
      brandName: '',
      brandUser: 'roca',
      redirectLogin: null,
      loader: true,
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.checkBrand();
  }

  checkAccess = () => {
    const context = this.context as AuthContextType;
    if (
      context.isAuthenticated ||
      !this.props.locationHref?.includes('state') || !this.props.locationHref?.includes('home')
    ) {
      this.setState({ loader: false });
    }
    if (window.location.pathname != '/' && window.location.pathname != '/home' && !context.isAuthenticated) {
      if (!this.props.freeAccess) {
        window.location.href = '/';
      }
    }
  };

  isLoading = () => {
    return this.state.loader || (this.props.locationHref?.includes('state') || this.props.locationHref?.includes('home'));
  }

  checkBrand = () => {
    let newStateValues = {};
    let brandUser = this.state.brandUser;
    if (window.location.href.includes('laufen')) {
      brandUser = 'laufen';
      newStateValues = {
        brandCode: 11,
        brandName: 'laufen',
      };
    } else if (window.location.href.includes('localhost')) {
      brandUser = 'roca';
      newStateValues = {
        brandCode: 11,
        brandName: 'roca',
      };
    } else {
      brandUser = 'roca';
      newStateValues = {
        brandCode: 14,
        brandName: 'roca',
      };
    }
    newStateValues = {
      ...newStateValues,
      logoUrl: `https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/${
        brandUser === 'roca' ? 'logo-roca-cabecera' : 'logo_laufen_standard'
      }.png`,
      faviconHref: `https://www.laufen.com/o/${brandUser}-theme/images/favicon.ico`,
      redirectLogin: this.props.login,
      brandUser,
    };
    this.setState({ ...newStateValues });
  };

  render() {
    return <>{this.isLoading() ? <Loader /> : <LandingView {...this.state} />}</>;
  }
}

export default LandingContainer;