import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

class CustomStatus extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false };
    }

    render() {
        let dataLoaded = false;

        if (this.props.paramObj != null) {
            dataLoaded = true;
        //    this.setState();
        }

        const { t } = this.props;

        if (dataLoaded) {

            try {
                let name;
                let businessLabel;
                if (this.props.paramObj) {
                    name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
                    businessLabel = this.props.paramObj.businessLabel
                }

                return (

                    <div className="flexContainer customStyle" >
                        <span className="t2 col nobold"> {name} </span>
                        {this.props.paramObj.infoText ?
                            <div className='info-tooltip-parameter'>
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        :""}
                        <div id={'t-' + businessLabel} style={{  marginLeft: 'auto' }}>
                            {this.props.parameterValue == "0" ?
                                <CheckIcon style={{color:"green"}}/>
                                :
                                <ClearIcon style={{ color: "red" }}/>
                                }
                        </div>
                    </div>
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomStatus)