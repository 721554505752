import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../App.css';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import FilterCard from './FilterCard';


class FilterListPage extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {

            actualMenuFilter: ""
        };
    }

    getComponent(t) {

        let type = this.props.type;
        let translateName = type;
        let items = this.props.content;
        let cardsComponent = null;
        let header = this.props.header != null ? this.props.header : false;
        let actualMenuFilter = this.props.actualMenuFilter;
        let idType = "resetDefault";
        if (type != null) {

            switch (type) {
                case "products":
                    idType = "sectionAPRODUCTS";
                    break;
                case "installations":
                    idType = "sectionAINSTALLATION";
                    break;
                case "gateways":
                    idType = "resetDefault";
                    break;
                case "gatewayProducts":
                    idType = "resetDefault";
                    translateName = "products";
                    break;
                case "spareParts":
                    break;
                default:
                    translateName = type;
                    break;
            }
          
        }
        if (items.length <= 0) {
            return null;
        }

        if (type == "installations" || type == "sites") {
            items.forEach((i) => { i.name = i.name ?? " " })
            items = items.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        }

        cardsComponent =
            <div className="product-list" {...(type == "spareParts" ? { id: 'productPlanContentsDiv' } : {})}>
                {header ? (<div className="product-list__header">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="uppercase">
                                {t(translateName)} <span>({items.length})</span></h2>
                            {(typeof this.props.viewAll === 'function' &&
                               
                                <p className="cta"><a className={actualMenuFilter != null && this.props.actualMenuFilter.includes(idType) ? "hidden t3" : "t3" + " cta-black"} onClick={this.props.viewAll(this.props.customType)} href='javascript.void(0)' >{t('productspages.filterproducts.seeall')} </a></p>
                            )}
                        </div>
                    </div>
                </div>) : null }
                
                <div className={"product-list__body " + (type == "spareParts" ? 'sparepart-category-detail-body' : '')}>
                    <div className="row">
                        {
                            items.map((item, i) => (
                                <FilterCard key={item.id} type={type} content={item} customFlex={this.props.customFlex} shadow={type != 'spareParts'} noMargin={type == 'spareParts'} />
                            ))
                        }
                    </div>
                </div>
            </div>

        return cardsComponent;

    } 

    render() {
        const { t } = this.props;

        return (

            <div id="resultsParentDiv" className="col-12">
                <div id="resultsDiv">
                    {this.getComponent(t)}
                </div>
            </div>

        )
    }

}
export default withTranslation()(FilterListPage);